import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import React from 'react'
// components
import { isBrowser } from 'react-device-detect'
import CustomButton from '../atoms/buttons/CustomButton'
import EditInputText from '../atoms/inputs/EditInputText'
import EditSelect from '../atoms/selects/EditSelect'
/* hooks */
import useDirecciones from '../../pages/Account/Direcciones/useDirecciones'

import { Alert } from '@material-ui/lab'
import TextArea from '../atoms/inputs/TextArea'

/**
 *
 * Componente de Formulario para agregar o editar direcciones del usuario.
 *
 * @param {boolean} isSelectAddress - Determina si la direccion a editar esta seleccionada (true).
 * @param {object} addressValue - Objeto con los datos de direccion a agregar o editar.
 * @param {Function} setAddressValue - Funcion para almacenar los datos de addressValue.
 * @param {string} nameError - Mensaje de error cuando el nombre de direccion es invalido.
 * @param {string} obligatoryFliedsError - Mensaje de error cuando los campos obligatorios estan vacios.
 * @param {string} numberRangeError - Mensaje de error cuando el rango de numeros es invalido.
 * @param {boolean} tumacoSelected - Determina si la ciudad seleccionada es Tumaco.
 * @param {Function} handleChange - Funcion encargada de cambiar los valores de cada campo.
 * @param {Function} onCancelFormClick - Encargada de emitir una funcion al boton de Cancelar.
 * @param {Function} onValidateClick - Funcion encargada de la validacion de los campos.
 * @param {Function} onSaveClick - Funcion encargada de guardar los cambios.
 * @returns {JSX.Element} - Formulario de Direccion.
 */
const FormAddress = (props) => {
	const {
		isSelectAddress,
		addressValue,
		setAddressValue,
		nameError,
		obligatoryFliedsError,
		numberRangeError,
		tumacoSelected,
		handleChange,
		onCancelFormClick,
		onValidateClick,
		onSaveClick
	} = props

	const { cityOptions, dirOptions } = useDirecciones()

	const minNumValue = 1
	const maxNumValue = 999

	return (
		<div className={`formAddress${isBrowser ? 'Desktop' : 'Mobile'}`}>
			<span className='color-blue text-16px-700'>
				{isSelectAddress == true ? 'Modificar Dirección' : 'Nueva Dirección'}{' '}
			</span>
			{addressValue?.city === 'Tumaco' && (
				<Alert severity='info'>
					Merca z se abstiene para ingresar a ciertas zonas de la ciudad.
				</Alert>
			)}
			<Grid container spacing={2} className='margin-form'>
				<Grid item xs={12}>
					<EditInputText
						label='Nombre de dirección*'
						value={addressValue?.name}
						onChange={(e) => handleChange('name', e.target.value)}
						errorMessage={nameError}
					/>
				</Grid>
				<Grid item xs={12}>
					<EditSelect
						label='Ciudad'
						options={cityOptions}
						selectedIndex={cityOptions.findIndex(
							(option) => option.value === addressValue?.city
						)}
						onSelect={(e) => {
							handleChange('city', e.target.value)
							handleChange('branch', e.target.value)
						}}
					/>
				</Grid>
				<Grid item xs={5} className='margin-form'>
					<EditSelect
						options={dirOptions}
						selectedIndex={dirOptions.findIndex(
							(option) => option.value === addressValue?.splitAddress.street
						)}
						onSelect={(e) =>
							setAddressValue({
								...addressValue,
								splitAddress: {
									...addressValue.splitAddress,
									street: e.target.value
								}
							})
						}
					/>
				</Grid>
				<Grid item xs={3}>
					<EditInputText
						label={`No.${tumacoSelected ? '' : '*'}`}
						value={addressValue.splitAddress.first}
						onChange={(e) => {
							setAddressValue({
								...addressValue,
								splitAddress: {
									...addressValue.splitAddress,
									first: e.target.value
								}
							})
						}}
						inputProps={{
							min: minNumValue,
							max: maxNumValue,
							type: 'number'
						}}
					/>
				</Grid>
				<Grid item xs={4}>
					<EditInputText
						label='Compl.'
						value={addressValue.splitAddress.second}
						onChange={(e) =>
							setAddressValue({
								...addressValue,
								splitAddress: {
									...addressValue.splitAddress,
									second: e.target.value
								}
							})
						}
						inputProps={{
							maxLength: '5'
						}}
					/>
				</Grid>
				<Grid item xs={4}>
					<EditInputText
						label={`No.${tumacoSelected ? '' : '*'}`}
						value={addressValue.splitAddress.third}
						onChange={(e) => {
							setAddressValue({
								...addressValue,
								splitAddress: {
									...addressValue.splitAddress,
									third: e.target.value
								}
							})
						}}
						inputProps={{
							min: '1',
							max: '999',
							type: 'number'
						}}
					/>
				</Grid>
				<Grid item xs={4}>
					<EditInputText
						label='Compl.'
						value={addressValue.splitAddress.fourth}
						onChange={(e) =>
							setAddressValue({
								...addressValue,
								splitAddress: {
									...addressValue.splitAddress,
									fourth: e.target.value
								}
							})
						}
						inputProps={{
							maxLength: '5'
						}}
					/>
				</Grid>
				<Grid item xs={4}>
					<EditInputText
						label={`No.${tumacoSelected ? '' : '*'}`}
						value={addressValue.house}
						onChange={(e) => {
							handleChange('house', e.target.value)
						}}
						inputProps={{
							min: '1',
							max: '999',
							type: 'number'
						}}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextArea
						label='Indicaciones'
						value={addressValue.observation}
						onChange={(e) => handleChange('observation', e.target.value)}
						inputProps={{
							rows: 4
						}}
					/>
				</Grid>

				{obligatoryFliedsError && (
					<span className='text-12px-400 error-message'>
						{obligatoryFliedsError}
					</span>
				)}
				{numberRangeError && (
					<span className='text-12px-400 error-message'>
						{numberRangeError}
					</span>
				)}

				<Grid
					container
					direction='row'
					justifyContent='center'
					alignContent='center'
					className='button-direcciones'>
					{isSelectAddress ? (
						<div className='btn-container'>
							<CustomButton
								label={'Cancelar'}
								variant='outlined'
								onClick={(e) => {
									e.preventDefault()
									onCancelFormClick()
								}}
							/>
							<CustomButton
								label={isSelectAddress == true ? 'Guardar' : 'Validar'}
								onClick={(e) => {
									e.preventDefault()
									if (isSelectAddress == false) {
										onSaveClick()
									} else {
										onValidateClick()
									}
								}}
							/>
						</div>
					) : (
						<CustomButton
							label={'Validar'}
							onClick={(e) => {
								e.preventDefault()
								onValidateClick()
							}}
						/>
					)}
				</Grid>
			</Grid>
		</div>
	)
}

FormAddress.propTypes = {
	isSelectAddress: PropTypes.bool,
	openUpdateModal: PropTypes.func,
	openUpdate: PropTypes.bool,
	addressValue: PropTypes.any,
	setAddressValue: PropTypes.func,
	nameError: PropTypes.string,
	obligatoryFliedsError: PropTypes.string,
	numberRangeError: PropTypes.string,
	tumacoSelected: PropTypes.bool,
	handleChange: PropTypes.func,
	onCancelFormClick: PropTypes.func,
	onValidateClick: PropTypes.func,
	onSaveClick: PropTypes.func
}

export default FormAddress
