import React, { useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
// hooks
import useAuth from '../../../../hooks/auth/useAuth'
import useMarkets from '../../../../hooks/markets/useMarkets'
// components
import CircularLoader from '../../../../components/loaders/CircularLoader'
import MercadoCard from '../../../../components/MercadoCard/MercadoCard'
import CustomButton from '../../../../components/atoms/buttons/CustomButton'
import DeleteMarketModal from '../../../../components/MercadoCard/DeleteMarketModal'
import AddressValidationModal from '../../../../components/Modals/AddressValidationModal'
// routes
import { routes, routeFormatter } from './marketsRoutes'

const MercadosList = () => {
	// delete modal control variables
	const [openDM, setOpenDM] = useState(false)
	// addresses validation modal control variables
	const [openAV, setOpenAV] = useState(false)

	const [marketToDelete, setMarketToDelete] = useState('')

	const history = useHistory()

	const { url } = useRouteMatch()
	const { markets, deleteMarket, getUserMarkets, loading } = useMarkets()

	const { authValues, getCityStorage, getCityBranchOffice } = useAuth()
	const city = getCityStorage()
	const branchOffice = getCityBranchOffice()

	const addresses = authValues.addresses

	// market actions
	const confirmationToDelete = (marketId) => (e) => {
		e.stopPropagation()
		setOpenDM(true)
		setMarketToDelete(marketId)
	}

	const goToEdit = (marketId) => (e) => {
		if (addresses.length === 0) {
			setOpenAV(true)
			return null
		}
		e.stopPropagation()
		history.push(`${url}${routeFormatter.toEdit(marketId)}`)
	}

	const handleDelete = () => {
		setOpenDM(false)
		deleteMarket(marketToDelete)
	}

	const createMarket = () => {
		if (addresses.length === 0) {
			setOpenAV(true)
			return null
		}
		history.push(`${url}${routes.create.path}`)
	}

	// effects
	useEffect(() => {
		getUserMarkets()
	}, [authValues])

	return (
		<div className='mercados-mobile'>
			<span className='color-blue text-21px-700'>Mis mercados</span>
			<div className='mercados-container'>
				{!markets || loading ? (
					<CircularLoader />
				) : markets.length === 0 ? (
					<span className='text-14px-400 color-gray'>
						No has creado mercados aún
					</span>
				) : (
					markets.map((market) => (
						<MercadoCard
							key={`mercadoCard-${market.id}`}
							marketId={market.id}
							address={market.address}
							name={market.name}
							products={market.products}
							city={city}
							branchOffice={branchOffice}
							onDelete={confirmationToDelete(market.id)}
							onEdit={goToEdit(market.id)}
							to={`${url}${routeFormatter.toProducts(market.id)}`}
						/>
					))
				)}
			</div>
			<CustomButton
				label='Crear nuevo mercado'
				width='100%'
				onClick={createMarket}
			/>
			<DeleteMarketModal
				open={openDM}
				handleClose={() => setOpenDM(false)}
				onDelete={handleDelete}
			/>
			<AddressValidationModal
				open={openAV}
				handleClose={() => setOpenAV(false)}
				actionToValidate='crear un mercado'
			/>
		</div>
	)
}

export default MercadosList
