import React from 'react'
import PropTypes from 'prop-types'
import { Stepper, Step, StepLabel, StepConnector } from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/styles'
import clsx from 'clsx'

const ColorlibConnector = withStyles({
	alternativeLabel: {
		top: 15
	},
	line: {
		height: 3,
		border: 0,
		backgroundColor: '#E3E8F0'
	}
})(StepConnector)

const useStepStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: '#ccc',
		zIndex: 1,
		color: '#fff',
		width: 35,
		height: 35,
		display: 'flex',
		borderRadius: '50%',
		justifyContent: 'center',
		alignItems: 'center'
	},
	active: {
		backgroundColor: theme.palette.primary.main
	},
	completed: {
		backgroundColor: '#B8C6E0'
	}
}))

const CustomStep = ({ active, completed, icon }) => {
	const classes = useStepStyles()

	const icons = [
		'historial-icon -white', // received
		'food-1-icon -white', // Enlistment
		'historial-icon -white', // Dispatched
		'historial-icon -white' // Finalized
	]

	return (
		<div
			className={clsx(classes.root, {
				[classes.active]: active,
				[classes.completed]: completed
			})}
		>
			<i className={icons[Number(icon) - 1]} />
		</div>
	)
}

CustomStep.propTypes = {
	active: PropTypes.bool,
	completed: PropTypes.bool,
	icon: PropTypes.node
}

const useStepperStyles = makeStyles({
	root: {
		borderRadius: 10,
		boxShadow: '0px 5px 20px rgba(33, 74, 142, 0.15);',
		padding: 5,
		paddingTop: 10
	}
})

const useLabelStyles = makeStyles({
	label: {
		marginTop: '5px !important'
	}
})

const OrderSteps = ({ status }) => {
	const stepperClasses = useStepperStyles()
	const labelClasses = useLabelStyles()

	const steps = ['Recibido', 'Alistamiento', 'En camino', 'Finalizado']

	const getActiveState = () => {
		if (status.finalized) {
			return 3
		} else if (status.dispatched) {
			return 2
		} else if (status.enlistment) {
			return 1
		} else if (status.received) {
			return 0
		}
		return -1
	}

	const activeStep = getActiveState()

	return (
		<>
			{
				// order cancelled
				status.cancelled ? (
					<span className='text-14px-400 color-gray-light'>
						{' '}
						Este pedido ha sido cancelado{' '}
					</span>
				) : // order not received
				activeStep < 0 ? (
					<span className='text-14px-400 color-gray-light'>
						{' '}
						Aún no hemos recibido tu pedido{' '}
					</span>
				) : (
					<Stepper
						className={stepperClasses.root}
						activeStep={activeStep}
						alternativeLabel
						connector={<ColorlibConnector />}
					>
						{steps.map((label, index) => (
							<Step key={index}>
								<StepLabel
									StepIconComponent={CustomStep}
									classes={{ label: labelClasses.label }}
								>
									<span
										className={`text-12px-400 color-gray-${
											index > activeStep ? 'light' : 'dark'
										}`}
									>
										{label}
									</span>
								</StepLabel>
							</Step>
						))}
					</Stepper>
				)
			}
		</>
	)
}

OrderSteps.propTypes = {
	status: PropTypes.shape({
		cancelled: PropTypes.object,
		received: PropTypes.object,
		enlistment: PropTypes.object,
		dispatched: PropTypes.object,
		finalized: PropTypes.object
	})
}

export default OrderSteps
