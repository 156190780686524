/* eslint-disable no-unreachable */
import { useCallback, useContext, useState } from 'react'
import { context } from '../../context/ContextProvider'
import * as authActions from '../../context/auth/authActions'
import { orderModel, productOrder, wholesalerProductOrder } from '../../context/models'
import * as shoppingCartActions from '../../context/shoppingCart/shoppingCartActions'
import * as cartSteps from './cartSteps'
// hooks
import useAuth from '../auth/useAuth'
import useConfigs from '../configs/useConfigs'
import useLayout from '../layout/useLayout'
// utils
import TotalToPay from '../../utils/TotalToPay'
import { getTotalValueOfProduct } from '../../utils/TotalToPay'

import { useEffect } from 'react'
import useProducts from '../products/useProducts'

//db
import { CREATED, PAYMENT } from '../../components/OrderStatusChip/orderStates'
import db from '../../firebase/firestore'

export default function useShoppingCart() {
  const { isLogged, showUserDeletedModal } = useAuth()
  const { showSnackbar } = useLayout()
  const { getProductData } = useProducts()
  const { loading, data, getBags, bag } = useConfigs()

  const { state, dispatch } = useContext(context)
  const { currentStep } = state.shoppingCartReducer.mobileLayout
  const { cupon, changedProducts, showCartValidation, observation } =
    state.shoppingCartReducer

  const { authValues } = state.authReducer

  const { shopping_cart } = authValues

  const [deliveryValue, setDeliveryValue] = useState(0)

  const [loadingOrder, setLoadingOrder] = useState(false)

  const categoryCollection = db.collection('categories')

  const setCurrentStep = (step) => {
    shoppingCartActions.setCurrentStep(dispatch, step)
  }
  const setObservation = (value) => {
    shoppingCartActions.setObservation(dispatch, value)
  }

  const restartSteps = () => {
    shoppingCartActions.setCurrentStep(dispatch, cartSteps.PRODUCTOS)
  }

  const mapStepToNumber = {
    [cartSteps.DIRECCIONES]: 1,
    [cartSteps.PAGOS]: 2,
    [cartSteps.RESUMEN]: 3,
    [cartSteps.FINALIZADO]: 4
  }

  const mapNumberToStep = {}
  Object.entries(mapStepToNumber).forEach(([step, number]) => {
    mapNumberToStep[number] = step
  })

  /**
   * Updates delivery value
   */
  useEffect(() => {
    // console.log("Updates delivery value"); 
    
    if (data && isLogged) {
      const { addresses, selected_address } = authValues
      let address = addresses[selected_address]
      let delivery = {
        value:0
      }
      if (address) {
        delivery = data.deliveries.find(
          (delivery) =>
            delivery.city.toLowerCase() === address.city.toLowerCase()
        )
      }

      if (delivery) {
        setDeliveryValue(delivery.value)
      }
    }
  }, [data, authValues, isLogged])

  /**
   * Adds products to product cart
   * @param {object} product product to be added
   * @param {{
   *      notify?: boolean
   *      notificationMessage?: string
   * }} options additional options
   */
  const addProductsToCart = (products, options = {}) => {
    const {
      notify = true,
      notificationMessage = 'Añadiste los productos al carrito'
    } = options

    let updatedSC = [...shopping_cart, ...products]
    authActions.updateShoppingCartProducts(
      dispatch,
      authValues.ID,
      updatedSC,
      isLogged,
      () => {
        if (notify) showSnackbar(notificationMessage)
      },
      showUserDeletedModal
    )
  }

  /**
   * Adds a product to product cart
   * @param {object} product product to be added
   * @param {{
   *      notify?: boolean
   *      notificationMessage?: string
   * }} options additional options
   */
  const addProductToCart = (product, options = {}) => {

    options.notificationMessage = 'Añadiste el producto al carrito'
    addProductsToCart([product], options)
  }

  /**
   * Removes a product from cart
   * @param {string} productId id of product to me removed
   * @param {{
   *      notify?: boolean,
   *      asWholesaler?: boolean
   * }} options additional options
   */
  const removeProductFromCart = async (productId, options = {}) => {
    const { notify = true, asWholesaler = false } = options

    let updatedSC = shopping_cart.filter(
      (product) =>
        !(
          product.id_item === productId && !!product.wholesaler === asWholesaler
        )
    )
    await authActions.updateShoppingCartProducts(
      dispatch,
      authValues.ID,
      updatedSC,
      isLogged,
      () => {
        if (notify) showSnackbar('Removiste el producto del carrito')
      },
      showUserDeletedModal
    )
  }

  /**
   * Changes the count of a producto in cart
   * @param {number} count updated product count
   * @param {string} productId id of the product to be updated
   * @param {{
   *      notify?: boolean,
   *      asWholesaler?: boolean
   * }} options additional options
   */
  const changeProductCount = (count, productId, options = {}) => {
    const { notify = true, asWholesaler = false } = options;
  
    let updatedSC = shopping_cart.map((product) => {
      if (!(product.id_item === productId && !!product.wholesaler === asWholesaler)) {
        return product;
      }

      const updatedProduct = {
        ...product,
        count: count
      };

      /* if(product.isWholesaler && product.wholesaler && newProduct.count >= product.wholesaler.factor ){
        newProduct.price = product.wholesaler.price
        newProduct.price_neto = product.wholesaler.price_neto
        newProduct.pum = product.wholesaler.pum
        newProduct.factor = product.wholesaler.factor
        newProduct.subtotal =  getTotalValueOfProduct(newProduct, true)
      } else {
        newProduct.price = branch.price
        newProduct.price_neto = branch.price_neto
        newProduct.pum = branch.pum
  
        newProduct.subtotal =  getTotalValueOfProduct(newProduct)
      } */
     //console.log(product.wholesaler)

      updatedProduct.subtotal = getTotalValueOfProduct(updatedProduct, asWholesaler);
  
      return updatedProduct;
    });
  
    authActions.updateShoppingCartProducts(
      dispatch,
      authValues.ID,
      updatedSC,
      isLogged,
      () => {
        if (notify) showSnackbar('Carrito modificado');
      },
      showUserDeletedModal
    );
  };

  const changeProductObservation = (observation, productId, options = {}) => {
    const { notify = true } = options

    let updatedSC = shopping_cart.map((product) => {
      if (product.id_item !== productId )
        return product
      return {
        ...product,
        observation: observation
      }
    })

    authActions.updateShoppingCartProducts(
      dispatch,
      authValues.ID,
      updatedSC,
      isLogged,
      () => {
        if (notify) showSnackbar('Observación actualizada')
      },
      showUserDeletedModal
    )
  }

  /**
   * Changes product maturity
   * @param {string} maturity new maturity value
   * @param {string} productId id of the product to be updated
   * @param {{
   *      notify?: boolean,
   *      asWholesaler?: boolean
   * }} options additional options
   */
  const changeProductMaturity = (maturity, productId, options = {}) => {
    const { notify = true, asWholesaler = false } = options
    let updatedSC = shopping_cart.map((product) => {
      if (
        product.id_item === productId &&
        product.wholesaler === asWholesaler
      ) {
        return { ...product, maturity: maturity }
      }
      return product
    })
    authActions.updateShoppingCartProducts(
      dispatch,
      authValues.ID,
      updatedSC,
      isLogged,
      () => {
        if (notify) showSnackbar('Carrito modificado')
      },
      showUserDeletedModal
    )
  }

  /**
   * Changes product cut type
   * @param {string} cutType new cut type value
   * @param {string} productId id of the product to be updated
   * @param {{}} options additional options
   */
  const changeProductCutType = (cutType, productId, options = {}) => {
    const { notify = true, asWholesaler = false } = options

    let updatedSC = shopping_cart.map((product) => {
      if (
        product.id_item === productId &&
        product.wholesaler === asWholesaler
      ) {
        return { ...product, cut_type: cutType }
      }
      return product
    })
    authActions.updateShoppingCartProducts(
      dispatch,
      authValues.ID,
      updatedSC,
      isLogged,
      () => {
        if (notify) showSnackbar('Carrito modificado')
      },
      showUserDeletedModal
    )
  }

  const emptyCart = (notify = true) => {
    authActions.emptyCart(
      dispatch,
      authValues.ID,
      isLogged,
      () => {
        if (notify) showSnackbar('Carrito de compras vaciado')
      },
      showUserDeletedModal
    )
  }

  /**
   * Check if the product with productId as id is in the card
   * @param {string} productId product identifier
   * @param {{ asWholesaler?: boolean}} options additional options
   * @returns
   */
  const isProductInCart = (productId, options = {}) => {
    const { asWholesaler = false } = options
    return shopping_cart.some(
      (product) =>
        product.id_item === productId && !!product.wholesaler === asWholesaler
    )
  }
  const findProductInCart = useCallback((productId) => {

    return shopping_cart.find((product) => product.id_item === productId) || null
  }, [shopping_cart])

  /**
   * Returns product order template
   * @param {boolean} asWholesaler if true, returns the wholesaler product template
   * @returns product template
   */
  const getProductTemplateCopy = (asWholesaler = false) => {
    if (asWholesaler) return { ...wholesalerProductOrder }
    return { ...productOrder }
  }

  // productos mayoristas
  const wholesalerProducts = () => {
    return shopping_cart.filter((product) => product.wholesaler === true);
  }
  const wholesalerTotal = () => {
    return TotalToPay(wholesalerProducts())
  }

  // productos generales
  const generalProducts = () => {
    return shopping_cart.filter((product) => !product.wholesaler)
  }

  const generalTotal = () => {
    return TotalToPay(generalProducts())
  }

  const cartTotal = () => {
    return generalTotal() + wholesalerTotal()
  }

  /**
   * Actualiza el cupón actual en el carrito de compras con el cupón proporcionado.
   *
   * @param {string} coupon - El código del cupón que se aplicará al carrito de compras.
   *
   * @returns {void} - Esta función no devuelve nada directamente. En cambio, llama a una función que actualiza el carrito de compras con el nuevo cupón.
   */
  const setCupon = (coupon) => {
    shoppingCartActions.setCupon(dispatch, coupon)
  }

  /**
   * Compara los precios y el stock de los productos en dos sucursales diferentes y guarda los cambios en una variable.
   *
   * @param {string} prevBranch - La ciudad de la sucursal anterior.
   * @param {string} currentBranch - La ciudad de la sucursal actual.
   *
   * @returns {void} - Esta función no devuelve nada directamente. En cambio, si hay cambios en el stock o el precio de un producto, llama a una función que actualiza el carrito de compras con los productos modificados.
   */
  const compareStocks = async (prevBranch, currentBranch) => {
    let changed = []

    for (let i = 0; i < shopping_cart.length; i++) {
      const product = await getProductData(
        shopping_cart[i].main_category_id,
        shopping_cart[i].id_item
      )
      const productPrevious = product.price_branch_offices.find(
        (branch) => branch.city === prevBranch
      )

      const productActualPrices = product.price_branch_offices.find(
        (item) => item.city === currentBranch
      )

      if (
        (typeof productPrevious.stock !== "undefined" && typeof product.stock !== "undefined" && productPrevious.stock < product.stock) ||
        productActualPrices.price_neto === 0
      ) {
        changed = [...changed, { ...product, validation: 'Sin stock' }]
      } else if (
        productActualPrices.price_neto !== (productPrevious.price_neto || 0)
      ) {
        changed = [
          ...changed,
          {
            ...product,
            validation: 'Cambió precio',
            actualPrices: {
              price: productActualPrices.price,
              price_neto: productActualPrices.price_neto,
              pum: productActualPrices.pum
            }
          }
        ]
      }
    }
    if (changed.length > 0) {
      //dispatch({ type: shoppingCartActionsTypes.SET_VALIDATION, value: true, products: changed })
      shoppingCartActions.setChangedProducts(dispatch, changed)
    }
  }

  const clearChangedProducts = () => {
    shoppingCartActions.clearChangedProducts(dispatch)
  }

  const isCategoryActive = async (product) => {
    const categories = await categoryCollection
      .where('id', '==', product.main_category_id)
      .get()
    const response = categories.docs[0].data().isActive
    return response
  }

  const getInactiveProducts = async () => {
    const inactiveProducts = []
    for (let product of authValues.shopping_cart) {
      const response = await isCategoryActive(product)
      if (!response) {
        inactiveProducts.push(product)
      }
    }
    return inactiveProducts
  }

  /**
   * Envía una orden de compra al servidor con los datos proporcionados y vacía el carrito de compras si la orden se envía correctamente.
   *
   * @param {number} discountValue - El valor del descuento que se aplicará a la orden.
   * @param {Array} discounts - Los descuentos adicionales que se aplicarán a la orden.
   * @param {string} paymentMethod - El método de pago que se utilizará para la orden.
   * @param {function} onSucces - La función que se llamará después de que se envíe la orden con éxito.
   * @param {function} onError - La función que se llamará después de que se envíe la orden con error.
   * @returns {void} - Esta función no devuelve nada directamente. En cambio, llama a una acción que envía la orden al servidor y llama a otras funciones si la orden se envía correctamente.
   */
  const sendOrder = async (
    discountValue,
    discounts = [],
    paymentMethod,
    onSucces,
    onError = () => console.log('error al crear orden')
  ) => {
    setLoadingOrder(true);
    let order = { ...orderModel }
    // data
    order.order_status = CREATED;
    if (paymentMethod === 4) {
      order.order_status = PAYMENT;
    }
    order.status_created = new Date();
    order.status.created = new Date();
    order.email_user = authValues.email
    order.name_user = authValues.name + ' ' + authValues.lastname
    order.phone_user = authValues.phone
    order.user_id = authValues.ID
    order.address = authValues.addresses[authValues.selected_address]
    order.pay_method = paymentMethod
    order.observation = observation
    // products
    order.products_order = authValues.shopping_cart
    // prices

    order.delivery = deliveryValue
    order.price_total = generalTotal() + wholesalerTotal()
    //bags
    order.bags = {
      ...order.bags,
      count: getBags(order.price_total),
      price: bag.bag_price
    }

    //cupon
    if (discountValue) {
      order.discounts_total = discountValue
      order.final_price =
        order.price_total +
        order.delivery +
        (getBags(order.price_total) * bag.bag_price) -
        discountValue
      order.discounts = discounts.map((discount) => {
        if (discount.type === 'cupon') {
          return {
            effects: discount.effects,
            name: cupon.name,
            type: 'cupon'
          }
        }
        return discount
      })
    } else {
      order.final_price =
        order.price_total +
        order.delivery +
        (getBags(order.price_total) * bag.bag_price)
    }

    shoppingCartActions.sendOrder(
      order,
      async (orderResult) => {
        try {
          if (paymentMethod === 4) {
            const form = await shoppingCartActions.getCredibancoForm(orderResult);
            if (!form) {
              onError("No se logro generar el pago online.");
              return setLoadingOrder(false);
            }
            emptyCart(false)
            setLoadingOrder(false);
            return onSucces(form)
          }
          emptyCart(false)
          setLoadingOrder(false);
          onSucces()
        } catch (error) {
          onError("Algo ha salido mal y no se logro generar el pago online.");
          setLoadingOrder(false);
        }
      },
      (err) => {
        console.error("err", err);
        onError(err);
        setLoadingOrder(false);
      }
    )
  }

  const getProductCount = useCallback((productId = "") => {
    const productFind = shopping_cart.find(p => p.id_item === productId)
    if (productFind)
      return productFind.count
    return null
  }, [shopping_cart])


  return {
    //observation
    setObservation,
    observation,
    // maps
    mapStepToNumber,
    mapNumberToStep,
    // functions
    changeProductObservation,
    setCurrentStep,
    restartSteps,
    addProductToCart,
    addProductsToCart,
    removeProductFromCart,
    changeProductCount,
    changeProductMaturity,
    changeProductCutType,
    getProductTemplateCopy,
    isProductInCart,
    findProductInCart,
    emptyCart,
    sendOrder,
    getInactiveProducts,
    compareStocks,
    clearChangedProducts,
    // data
    currentStep,
    products: shopping_cart,
    getProductCount,
    wholesalerProducts: wholesalerProducts(),
    generalProducts: generalProducts(),
    generalTotal: generalTotal(),
    wholesalerTotal: wholesalerTotal(),
    cartTotal: cartTotal(),
    deliveryTotal: deliveryValue,
    setCupon,
    cupon,
    showCartValidation,
    changedProducts,
    deliveryValue,
    // flags
    loading,
    loadingOrder,
    setLoadingOrder
  }
}
