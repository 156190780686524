import React, { useEffect, useMemo, useState } from 'react'
import { isBrowser } from 'react-device-detect'
import HomeDesktop from './HomeDesktop'
import HomeMobile from './HomeMobile'
//hooks
import useAuth from '../../hooks/auth/useAuth'
import useConfigs from '../../hooks/configs/useConfigs'
import useProducts from '../../hooks/products/useProducts'

/**
 * Componente principal de la página de inicio.
 * @returns {React.ReactNode} Elemento React que contiene la página de inicio.
 */
const Home = () => {
	const {
		categories,
		loading,
		loadLimitedCategory
	} = useProducts()

	const {
		branches
	} = useConfigs()

	const {
		isLogged,
		authValues,
		getCityBranchOffice,
		getCityStorage,
		showLoginModal
	} = useAuth()

	const city = getCityStorage() || 'Cali'
	const branchOffice = getCityBranchOffice()
	const [branch, setBranch] = useState('')
  
	const getBranch = () => {
		if (isLogged === true) {
			setBranch(branchOffice)
		} else {
			branches.forEach((branch) => {
				if (city.toLowerCase() === branch.city.toLowerCase()) {
					setBranch(branch.name)
				}
			})
		}
	}

	useEffect(() => {
		if (!city) showLoginModal()
	}, [])

	useEffect(() => {
		if (categories.length > 0 && city && branchOffice) {
			if (categories[0].products.length == 0)
				loadLimitedCategory(categories[0].id, city, branchOffice)
		}
	}, [categories, city, branchOffice])

	useEffect(() => {
		getBranch()
		window.onunload = () => {
			if (!isLogged) {
				localStorage.removeItem('cityStorage')
				localStorage.removeItem('storeStorage')
				localStorage.removeItem('branchOffice')
			}
		}
	}, [authValues, isLogged, branches])

	const HomeLayout = isBrowser ? HomeDesktop : HomeMobile
	return useMemo(
		() => (
			<HomeLayout
				categories={categories}
				loading={loading}
				city={city}
				branchOffice={branch}
			/>
		),
		[categories, loading, city, branchOffice]
	)
}

export default Home
