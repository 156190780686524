import { Box, Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import CustomButton from '../atoms/buttons/CustomButton'
import EditInputText from '../atoms/inputs/EditInputText'
import EditSelect from '../atoms/selects/EditSelect'
import CustomSwitch from '../atoms/Switch/CustomSwitch'
// hooks
// import PaymentMethod from '../../components/PaymentMethod/PaymentMethod'
// import * as paymentTypes from '../../components/PaymentMethod/PaymentTypes'
// import usePaymentMethod from '../../components/PaymentMethod/usePaymentMethod'
import useAuth from '../../hooks/auth/useAuth'
import useMarkets from '../../hooks/markets/useMarkets'
import usePaymentCards from '../../hooks/paymentCards/usePaymentCards'
import useStringFormater from '../../hooks/stringFormater/useStringFormater'
import useValidator from '../../hooks/validator/useValidator'
import CircularLoader from '../loaders/CircularLoader'
import SelectCardModal from '../SelectCardModal/SelectCardModal'

/**
 * Formulario para crear un mercado.
 *
 * @param {object} market
 * @param {Function} onCreate
 * @param {Function} onEdit
 * @returns {JSX.Element}
 */
const CreateMarketForm = ({ market, onCreate, onEdit }) => {
	const editMode = Boolean(market)

	const { createMarket, editMarket, getMarketTemplate } = useMarkets()
	const { formatAddress } = useStringFormater()
	const { validateDate, isNotEmpty } = useValidator()

	const { authValues } = useAuth()
	const selected_address = authValues.selected_address
	const addresses = authValues.addresses

	const { cards, getUserPaymentCards } = usePaymentCards()
	// const { cardType } = usePaymentMethod()

	useEffect(() => {
		getUserPaymentCards()
	}, [authValues])

	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
		cards ? cards[0] : null
	)
	// select card modal
	const [openCM, setOpenCM] = useState(false)

	// Este código genera un arreglo de opciones de direcciones a partir de un arreglo de direcciones.
	// Se filtran las direcciones que corresponden a la ciudad de Tumaco y luego se mapean para crear un objeto
	// que contenga una etiqueta (label) y un valor (value) correspondientes a cada dirección.
	const addressesOptions = addresses.map((address) => ({
		label:
			address.city === 'Tumaco'
				? `${address.name} - Tumaco`
				: address.address
				? address.address
				: formatAddress(address),
		value: address
	}))

	//inicializa los valores de localMarket
	const initialLocalMarket = editMode
		? market
		: {
				...getMarketTemplate(),
				periodicity: new Date().getDate(),
				address: addresses[selected_address],
				card: cards ? cards[0] : []
		  }
	const [localMarket, setLocalMarket] = useState(initialLocalMarket)

	useEffect(() => {
		setLocalMarket(initialLocalMarket)
	}, [market])

	//Estado de periodicidad
	const [isPeriodic, setIsPeriodic] = useState(
		localMarket.periodicity === 0 ? false : true
	)
	// error messages
	const [nameError, setNameError] = useState('')
	const [periodicityError, setPeriodicityError] = useState('')

	/**
	 * Actualiza la propiedad 'name' o 'value' del objeto 'localMarket' utilizando la función 'setLocalMarket'.
	 *
	 * @param {string} name - El nombre de la propiedad a actualizar.
	 * @param {*} value - El valor de la propiedad a actualizar.
	 */
	const handleChange = (name, value) => {
		setLocalMarket((localMarket) => ({
			...localMarket,
			[name]: value
		}))
	}

	/**
	 * Busca el índice de la dirección proporcionada dentro del arreglo 'addresses'.
	 *
	 * @param {string} address - La dirección a buscar.
	 * @returns {number} El índice de la dirección proporcionada en el arreglo 'addresses', o -1 si no se encuentra.
	 */
	const findIndexOfAddress = (address) => {
		for (let i = 0; i < addresses.length; i++) {
			if (formatAddress(addresses[i]) === formatAddress(address)) {
				return i
			}
		}
		return -1
	}

	/**
	 * Obtiene el índice del método de pago seleccionado.
	 *
	 * @returns {number} El índice del método de pago seleccionado.
	 */ // eslint-disable-next-line
	const getSelectedIndex = () => {
		if (!editMode) return 0
		let index = findIndexOfAddress(localMarket.address)
		return index !== -1 ? index : 0
	}

	/**
	 * Limpia los mensajes de error estableciendo las propiedades de estado correspondientes en una cadena vacía.
	 */
	const cleanErrorMessages = () => {
		setPeriodicityError('')
		setNameError('')
	}

	/**
	 * Valida el nombre y la periodicidad del mercado local.
	 *
	 * @returns {boolean} Verdadero si el nombre y la periodicidad son válidos, de lo contrario falso.
	 */
	const validated = () => {
		let validDate = true
		if (isPeriodic) {
			validDate = validateDate({
				date: localMarket.periodicity,
				setDateError: setPeriodicityError
			})
		}
		let validName = isNotEmpty(localMarket.name, setNameError)
		return validDate && validName
	}

	/**
	 * Lleva a cabo la acción principal de la función.
	 * Limpia los mensajes de error, valida los campos de entrada y crea o edita un mercado local.
	 *
	 * @returns {null} Si la validación falla o no hay tarjetas de pago disponibles.
	 */
	const onClick = () => {
		cleanErrorMessages()
		if (!validated()) return null
		if (cards.length === 0) return null

		let market = {
			...localMarket,
			periodicity: isPeriodic ? localMarket.periodicity : 0,
			card: selectedPaymentMethod ? selectedPaymentMethod : cards[0]
		}

		if (editMode) {
			editMarket(market.id, market, false)
			onEdit()
		} else {
			market.created_date = new Date()
			createMarket({ ...market })
			onCreate()
		}
  }

	return !cards ? (
		<CircularLoader />
	) : (
		<div className='form-card'>
			<span className='text-16px-700 color-blue title'>
				{editMode ? 'Editar mercado' : 'Crear mercado'}
			</span>
			<Grid container spacing={2} className='body'>
				<Grid item xs={12}>
					<EditInputText
						label='Nombre'
						value={localMarket.name}
						onChange={(e) => handleChange('name', e.target.value)}
						errorMessage={nameError}
					/>
				</Grid>
				<Grid item xs={12}>
					<EditSelect
						label='Dirección'
						options={addressesOptions}
						selectedIndex={addressesOptions.findIndex(
							(option) => option.value === localMarket.address
						)}
						onSelect={(e) => handleChange('address', e.target.value)}
					/>
				</Grid>
				<Grid item xs={12}>
					<CustomSwitch
						label='Recibir este mercado periódicamente'
						checked={isPeriodic}
						name='periodicity'
						onChange={(e) => setIsPeriodic(e.target.checked)}
					/>
				</Grid>
				<Grid item xs={12}>
					<Box display={isPeriodic ? 'block' : 'none'}>
						<EditInputText
							label='Recibir los días'
							endAdornment='De cada més'
							name='periodicity'
							value={localMarket.periodicity}
							errorMessage={periodicityError}
							onChange={(e) => handleChange('periodicity', e.target.value)}
							inputProps={{
								disabled: !isPeriodic,
								maxLength: '2'
							}}
						/>
					</Box>
				</Grid>
				{/* <Grid item xs={12}>
					<span className='text-10px-400 color-gray label'>
						Tarjeta de crédito
					</span>
					{cards.length > 0 ? (
						<Grid item xs={12}>
							<PaymentMethod
								lastDigits={
									localMarket?.card
										? localMarket.card.mask
										: selectedPaymentMethod
										? selectedPaymentMethod.mask
										: cards[0].mask
								}
								type={paymentTypes.methods.CREDIT_CARD}
								cardType={
									localMarket?.card
										? cardType(localMarket.card.mask)
										: selectedPaymentMethod
										? cardType(selectedPaymentMethod.mask)
										: cardType(cards[0].mask)
								}
								layout={paymentTypes.layouts.CARD_SELECTED}
								onSecondaryAction={() => setOpenCM(true)}
							/>
						</Grid>
					) : (
						<span className='text-16px-400 color-gray-light label'>
							No has agregado tarjetas de crédito aún
						</span>
					)}
				</Grid> */}
				<SelectCardModal
					open={openCM}
					handleClose={() => setOpenCM(false)}
					acceptLabel='Aceptar'
					acceptAction={() => {
						setOpenCM(false)
						handleChange('card', selectedPaymentMethod)
					}}
					cards={cards}
					setSelectedPaymentMethod={setSelectedPaymentMethod}
				/>
			</Grid>
			<CustomButton
				label={editMode ? 'Editar' : 'Crear'}
				disable={cards.length === 0 ? true : false}
				onClick={onClick}
			/>
		</div>
	)
}

CreateMarketForm.propTypes = {
	market: PropTypes.object,
	onCreate: PropTypes.func,
	onEdit: PropTypes.func
}

CreateMarketForm.defaultProps = {
	onCreate: () => {},
	onEdit: () => {}
}

export default CreateMarketForm
