import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
//components
import { Skeleton } from '@material-ui/lab'
import LiquorValidationModal from '../../../Modals/LiquorValidationModal'
import NetworkImage from '../../../NetworkImage/NetworkImage'
import ProductCounter from '../../../ProductCounter/ProductCounter'
import ProductCounterWithGrams from '../../../ProductCounter/ProductCounterWithGrams'
import CustomButton from '../../buttons/CustomButton'
// hooks
import useAuth from '../../../../hooks/auth/useAuth'
import useProducts from '../../../../hooks/products/useProducts'
import useShoppingCart from '../../../../hooks/shoppingCart/useShoppingCart'
import useStringFormater from '../../../../hooks/stringFormater/useStringFormater'
import useFavProducts from '../../../../hooks/user/useFavProducts'
import useProductCounter from '../../../ProductCounter/useProductCounter'
import useProductCard from './useProductCard'
//import useProduct from "../../../../pages/Product/useProduct";
// consts
import { useRef } from 'react'
import mayorista from '../../../../assets/mayorista.svg'
import * as UnitTypes from '../../../../consts/ProductCountOptions'
import { productImageBaseUrl } from '../../../../utils/HasImage'
import { productPerKilogram } from '../../../../utils/productPerKilogram'
// eslint-disable-next-line no-unused-vars
import ProductModel from '../../../../models/ProductModel'
import ProductDiscountBadge from '../../../Products/ProductDiscountBadge'
import getUserAge from '../../../../utils/age'

/**
 * El componente RectSkeleton es un componente funcional de React que utiliza
 * el componente Skeleton de Material-UI para crear un esqueleto en forma de rectángulo animado.
 * Puede ser utilizado en cualquier lugar donde se requiera una animación
 * de carga de un rectángulo, como en la carga de imágenes.
 * @param {*} props
 * @returns {JSX.Element}
 */
const RectSkeleton = (props) => (
  <Skeleton variant='rect' animation='wave' {...props} />
)

/**
 * El componente LoadingContent es un componente funcional de React
 * que renderiza un esqueleto animado de carga en forma de tarjeta de producto.
 * @returns {JSX.Element}
 */
const LoadingContent = () => (
  <div className='productCardDesktop'>
    <div className='product-title'>
      <p>
        <RectSkeleton width={180} />
      </p>
    </div>
    <div className='price'>
      <p>
        <RectSkeleton width={100} />
      </p>
    </div>
    <p className='image'>
      <Skeleton variant='rect' animation='wave' width={120} height={120} />
    </p>
  </div>
)

const LoadedContent = ({
  categoryId,
  productId,
  product,
  branch,
  hasCompare,
  className = ''
}) => {
  // error handling
  if (!categoryId)
    throw new Error(
      'MercaZ Error: product is not loading but category id was not specified'
    )
  if (!productId)
    throw new Error(
      'MercaZ Error: product is not loading but product id was not specified'
    )
  if (!product)
    throw new Error(
      'MercaZ Error: product is not loading but product was not specified'
    )
  if (!branch)
    throw new Error(
      'MercaZ Error: product is not loading but branch was not specified'
    )


  // view
  const [isHover, setisHover] = useState(false)
  const [disable, setDisable] = useState(false)
  const { isLogged, authValues } = useAuth()
  const cardRef = useRef()

  const { changeProductCount, findProductInCart } = useShoppingCart()

  const { isFavorite, toggleFavorite } = useFavProducts()

  const { formatPrice } = useStringFormater()

  const { categories, toggleCompareModal, toggleMarketModal } = useProducts()

  const isFav = isFavorite(productId, categoryId)

  const {
    bar_code = '',
    name = '',
    unit_gram,
    quantity_pum
  } = product || {}
  // console.log("product", product);

  const [unitType, setUnitType] = useState(
    findProductInCart(productId)?.unit_type || product?.unit_type || UnitTypes.UNIT.value
  )

  const {
    counter,
    incrementCounter,
    decrementCounter,
    setCounter
  } = useProductCounter({
    initialValue: findProductInCart(productId)?.count ?? 1
  })

  const {
    isInCart,
    hasMinCountGrams,
    // flags
    canSelectUnitType,
    // actions
    addToCart,
    removeFromCart
  } = useProductCard({
    counter,
    branch,
    unitType,
    product,
    // actions
    incrementCounter,
  })

  useEffect(() => {
    setDisable(branch.stock === counter && counter !== 0)
  }, [counter])

  // liquour validation modal
  const [openLM, setOpenLM] = useState(false)
  const [errorLiquorValidation, setErrorLiquorValidation] = useState('')

  const birth_date = authValues.birth_date ? authValues.birth_date : undefined


  /** Determines whether the product category is restricted.
   * @function
   *@returns {boolean} - Whether the product category is restricted or not.
   */
  const isRestrictedCategorie = () => {
    const cat = categories.find((cat) => cat.id === categoryId)

    let isRestrictedCategorie = false
    if (categories.length > 0 && cat.isRestricted) {
      isRestrictedCategorie = true
    }
    return isRestrictedCategorie
  }


  /**
   * Returns a boolean value indicating whether a person is old enough to legally purchase alcohol, based on their birthdate.
   * @function
   * @returns {boolean} A boolean value indicating whether a person is old enough to purchase alcohol.
   *                    If birth_date is not defined or is not a valid date, the function returns false.
   */
  const getLiquorValidation = () => {
    if (birth_date) {
      const age = getUserAge(birth_date)
      return age >= 18;/*
      const today = new Date()
      let age = today.getFullYear() - birth_date.toDate().getFullYear()
      let month = today.getMonth() - birth_date.toDate().getMonth()
      console.log('age', age)
      console.log('month', month)
      if (
        month < 0 ||
        (month === 0 && today.getDate() < birth_date.toDate().getDate())
      ) {
        age--
      }

      let validation = false
      if (age >= 18) {
        validation = true
      }

      return validation*/
    } else return false
  }

  const confirmButtonText = useMemo(() => {
    if (!hasMinCountGrams) return `Minimo ${product.minimum_grams_sale}Gr`
    if (isInCart) {
      return 'Remover'
    }
    return 'Agregar'
  }, [isInCart, hasMinCountGrams, product])

  return (
    <div
      ref={cardRef}
      className={`productCardDesktop ${isHover ? 'productCard-hover' : ''} ${className}`}
      onMouseEnter={() => setisHover(true)}
      onMouseLeave={() => setisHover(false)}>
      <div className='main-content'>
        <div className='title_sku-container'>
          <div className='title_price'>
            <p title={name} className={`title ${isHover ? 'text-14px-600' : 'text-18px-600'} color-blue`}>
              {name}
            </p>
            <div className='price-hover'>
              <p className='text-21px-600 color-blue'>
                {formatPrice(branch?.discount? branch?.price_neto : branch.price)}
              </p>
              {branch?.discount > 0 && (
                <span className='text-13px-400 color-gray-light' style={{
                  textDecoration: 'line-through',
                  marginLeft: '5px'
                }}>
                  {formatPrice(branch?.price)}
                </span>
              )}
              {/* <span className='text-13px-400 color-gray-light'>Normal</span> */}
            </div>
          </div>
          <div className='sku_gramo'>
            <p className='text-12px-400 color-gray-light'>{`SKU:${bar_code}`}</p>
            <span className='text-12px-400 color-black'>
              {product.unit_measurement === 'UND'
                ? ''
                : `gramo a $${branch.pum.toString().substring(0, 4)}`}
            </span>
          </div>
        </div>
        <div className='price'>
          <p className='text-18px-600 color-blue'>
            {formatPrice(branch?.discount? branch?.price_neto : branch.price)}
            {Boolean(unit_gram) && quantity_pum === 1000 && (
              <span className='text-11px-600 color-gray-light'> x Kilo</span>
            )}
            {Boolean(unit_gram) && quantity_pum !== 1000 && (
              <span className='text-11px-600 color-gray-light'>x{quantity_pum}gr</span>
            )}
            {branch?.discount > 0 && (
              <span className='text-13px-400 color-gray-light' style={{
                textDecoration: 'line-through',
                marginLeft: '5px'
              }}>
                {formatPrice(branch?.price)}
              </span>
            )}
          </p>
        </div>
        <div className='image'>
          {branch?.discount > 0 && (
            <ProductDiscountBadge discount={branch?.discount} />
          )}
          {product.isWholesaler && branch?.city === 'Tumaco' && (
            <img
              src={mayorista}
              alt='mayorista'
              style={{
                position: 'absolute',
                width: '58px',
                height: '58px',
                left: '0',
                bottom: '-7px'
              }}
            />
          )}
          <Link to={`/product/${categoryId}/${productId}`}>
            <NetworkImage
              width={isHover ? '8rem' : '9rem'}
              url={
                productImageBaseUrl + productId}
              imageProps={{
                alt: `image-product-${productId}`
              }}
            />
          </Link>
        </div>
      </div>
      <div className='productCard-footer'>
        <div className='counter'>
          {productPerKilogram(canSelectUnitType, product) ? (
            <ProductCounterWithGrams
              big
              color='white'
              fontColor='white'
              product={product}
              unitType={unitType}
              setUnitType={setUnitType}
              selectDisabled={isInCart}
              count={counter}
              setCounter={setCounter}
              incrementCounter={() => {
                incrementCounter()
                if (isInCart) {
                  changeProductCount(counter + 1, productId)
                }
              }}
              decrementCounter={(_, posVal) => {
                if (posVal === 0) return removeFromCart();
                decrementCounter()
                if (isInCart) {
                  changeProductCount(counter - 1, productId)
                  if (counter === 1) removeFromCart()
                }
              }}
            />
          ) : (
            <ProductCounter
              big
              fontColor='white'
              count={counter}
              isDisable={disable}
              incrementCounter={(e) => {
                e.stopPropagation()
                incrementCounter()
                if (isInCart) {
                  changeProductCount(counter + 1, productId)
                }
              }}
              decrementCounter={(e) => {
                e.stopPropagation()
                decrementCounter()
                if (isInCart) {
                  changeProductCount(counter - 1, productId)
                  if (counter === 1) removeFromCart()
                }
              }}
            />
          )}
        </div>
        <div className='buttons'>
          <div className='buttons-content'>
            {isLogged && (
              <i
                className={`cursor-pointer heart-icon-circle -${!isFav ? 'un' : ''
                  }checked`}
                onClick={() => toggleFavorite(productId, categoryId)}
                title={`${isFav ? 'Remover de' : 'Añadir a'} favoritos`}
              />
            )}
            {isLogged && (
              <i
                className={`cursor-pointer market-icon-circle -unchecked`}
                onClick={() => toggleMarketModal(product)}
                title={`Agregar a mercado`}
              />
            )}
          </div>
          <div className='buttons-content'>
            {hasCompare && (
              <CustomButton
                label='Comparar'
                variant='outlined'
                color='white'
                width='6em'
                onClick={() => {
                  toggleCompareModal(product)
                }}
              />
            )}
            <CustomButton
              label={confirmButtonText}
              color='white'
              width='6em'
              disable={counter === 0 || !hasMinCountGrams}
              // disable={!hasMinCountGrams}
              onClick={(e) => {
                e.stopPropagation()
                if (isInCart) {
                  removeFromCart()
                } else if (isRestrictedCategorie()) {
                  setOpenLM(true)
                } else {
                  addToCart()
                }
              }}
            />
          </div>
          <LiquorValidationModal
            open={openLM}
            handleClose={() => setOpenLM(false)}
            onAccept={() => {
              if (!isLogged) {
                setErrorLiquorValidation(
                  'Inicia Sesion o Registrate para validar si eres mayor de edad'
                )
              } else if (isLogged && !getLiquorValidation()) {
                setErrorLiquorValidation('No cumples con la mayoria de edad')
              } else {
                addToCart()
                setOpenLM(false)
              }
            }}
            onDecline={() => setOpenLM(false)}
            setOpenLM={setOpenLM}
            errorMessage={errorLiquorValidation}
          />
        </div>
      </div>
    </div>
  )
}

/**
 * Renders a product card on desktop view. Displays either a loading state or the product details.
 * @function ProductCardDesktop
 * @param {object} props - The props object for the component.
 * @param {boolean} props.loading - A boolean value indicating whether the component should display a loading state.
 * @returns {JSX.Element} A JSX element representing the product card.
 */
const ProductCardDesktop = (props) => {
  const { loading } = props
  return loading ? <LoadingContent /> : <LoadedContent {...props} />
}

LoadedContent.propTypes = {
  product: PropTypes.object,
  branch: PropTypes.object,
  productId: PropTypes.string,
  categoryId: PropTypes.string,
  hasCompare: PropTypes.bool
}

LoadedContent.defaultProps = {
  hasCompare: true
}

ProductCardDesktop.propTypes = {
  loading: PropTypes.bool
}

export default ProductCardDesktop
