import * as actionsTypes from './authActionsTypes'

import firebase from 'firebase/app'
import db, { auth } from '../../firebase/firestore'
import { userModel } from '../models'

const userDB = db.collection('users')
const branchesCollection = db.collection('branch_offices')
let recaptchaVerifier = undefined
let confirmationResult = undefined

//modals
/**
 * Opens login modal
 * @param {Function} dispatch
 */
export const showLoginModal = (dispatch) => {
	dispatch({
		type: actionsTypes.SHOW_AUTH_MODAL
	})
}
/**
 * Hide login modal
 * @param {Function} dispatch
 */
export const hideLoginModal = (dispatch) => {
	dispatch({
		type: actionsTypes.HIDE_AUTH_MODAL
	})
}
/**
 * Opens a modal indicating that the user have been deleted
 * and session must be closed
 * @param {Function} dispatch
 */
export const showUserDeletedModal = (dispatch) => {
	dispatch({
		type: actionsTypes.SHOW_USER_DELETED_MODAL
	})
}
/**
 * Close user deleted modal
 * @param {Function} dispatch
 */
export const hideUserDeledModal = (dispatch) => {
	dispatch({
		type: actionsTypes.HIDE_USER_DELETED_MODAL
	})
}
/**
 * Changes users favorite products
 * @param {string} ID
 * @param {object[]} products
 * @param {() => void} onSucces
 * @param {(error: object) => void} onError
 */
export const changeFavoriteProducts = (userID, products, onSucces, onError) => {
	userDB
		.doc(userID)
		.update({
			['favorites.products']: products
		})
		.then(() => onSucces())
		.catch((error) => onError(error))
}

export const changeFavoriteMarkets = (ID, markets, onError) => {
	userDB
		.doc(ID)
		.update({
			['favorites.markets']: markets
		})
		.catch((error) => onError(error))
}

export const getUser = async (dispatch, ID, onError) => {
	if (ID === undefined) throw new Error('MercaZ Error: ID undefined')
	if (ID === '') throw new Error('MercaZ Error: ID empty')

	dispatch({
		type: actionsTypes.GET_CURRENT_USER
	})
	userDB.doc(ID).onSnapshot(
		(snapshot) => {
			const {
				ID,
				name,
				document,
				document_type,
				phone,
				email,
				photo,
				addresses,
				selected_address,
				uID
			} = snapshot.data()
			const userData = {
				ID,
				name,
				document,
				document_type,
				phone,
				email,
				photo,
				addresses,
				selected_address,
				uID
			}
			dispatch({
				type: actionsTypes.GET_CURRENT_USER_SUCCESS,
				authValues: userData
			})
		},
		(error) => {
			onError(error)
			dispatch({
				type: actionsTypes.GET_CURRENT_USER_FAILURE,
				error: error.message
			})
		}
	)
}

export const userGetData = (dispatch, userUID) => {
	dispatch({
		type: actionsTypes.SET_AUTH_VALUES
	})
	const userRef = userDB.where('uID', '==', userUID)

	userRef
		.get()
		.then((querySnapshot) => {
			if (!querySnapshot.empty) {
				userDB.doc(querySnapshot.docs[0].id).onSnapshot((snapshot) => {
					if (!snapshot.exists) return
					dispatch({
						type: actionsTypes.SET_AUTH_VALUES_SUCCESS,
						authValues: snapshot.data()
					})
				})
			} else {
				dispatch({
					type: actionsTypes.SET_AUTH_VALUES_SUCCESS,
					authValues: { ...userModel }
				})
			}
		})
		.catch((err) => {
			dispatch({
				type: actionsTypes.SET_AUTH_VALUES_FAILURE,
				error: err.message
			})
		})
}

export const updateUserData = async (dispatch, userData, ID) => {
	return new Promise((resolve, reject) => {
		dispatch({
			type: actionsTypes.UPDATE_USER_DATA
		})
		db.collection('users')
			.doc(ID)
			.set(userData, { merge: true })
			.then(() => {
				dispatch({
					type: actionsTypes.UPDATE_USER_DATA_SUCCESS,
					authValues: userData
				})
				resolve()
			})
			.catch((err) => {
				dispatch({
					type: actionsTypes.UPDATE_USER_DATA_FAILURE,
					error: err.message
				})
				reject(err.message)
			})
	})
}

export const signOut = async (dispatch) => {
	dispatch({ type: actionsTypes.REQUEST })
	try {
		await auth.signOut()
		await dispatch({ type: actionsTypes.SET_DEFAULTS })
	} catch (err) {
		dispatch({
			type: actionsTypes.ERROR,
			error: err.message
		})
	}
}

export const signInWithEmailAndPassword = async (
	dispatch,
	email,
	password,
	onSuccess,
	onFailure
) => {
	dispatch({
		type: actionsTypes.REQUEST
	})
	auth
		.signInWithEmailAndPassword(email, password)
		.then(async (res) => {
			const userRef = userDB.where('uID', '==', res.user.uid)
			try {
				const querySnapshot = await userRef.get()
				userDB
					.doc(querySnapshot.docs[0].id)
					.get()
					.then((userDoc) => {
						onSuccess(userDoc.data())
						dispatch({ type: actionsTypes.SUCCESS, authValues: userDoc.data() })
					})
			} catch (error) {
				dispatch({
					type: actionsTypes.ERROR,
					error: error.message
				})
				onFailure(error)
			}
		})
		.catch((err) => {
			dispatch({
				type: actionsTypes.ERROR,
				error: err.message
			})
			onFailure(err)
		})
}

export const signInWithGoogle = async (dispatch, onSuccess, onFailure) => {
	let provider = new firebase.auth.GoogleAuthProvider()
	provider.addScope('email')
	signInWithFirebaseProvider(provider, dispatch, onSuccess, onFailure)
}

export const initializesignInWithPhone = () => {
	if (!recaptchaVerifier)
		recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-in-phone', {
			size: 'invisible',
			// eslint-disable-next-line no-unused-vars
			callback: (response) => {}
		})
}
export const signInWithPhone = async (dispatch, phone, onSuccess) => {
	firebase
		.auth()
		.signInWithPhoneNumber(phone, recaptchaVerifier)
		.then((confirmationResultResponse) => {
			// SMS sent. Prompt user to type the code from the message, then sign the
			// user in with confirmationResult.confirm(code).
			confirmationResult = confirmationResultResponse
			onSuccess(confirmationResult)
			// ...
		})
		.catch((error) => {
			// Error; SMS not sent
			// ...
			console.log('error with phone', error)
		})
}
export const signInWithPhoneCodeVerification = async (
	dispatch,
	code,
	onSuccess,
	onFailure
) => {
	dispatch({
		type: actionsTypes.REQUEST
	})
	confirmationResult
		.confirm(code)
		.then((res) => {
			// User signed in successfully.
			const userRef = userDB.where('uID', '==', res.user.uid)
			userRef.get().then(async (querySnapshot) => {
				if (!querySnapshot.empty) {
					let documentId = querySnapshot.docs[0].id
					if (res.additionalUserInfo.providerId === 'phone') {
						userDB
							.doc(documentId)
							.get()
							.then((userDoc) => {
								dispatch({
									type: actionsTypes.SUCCESS,
									authValues: userDoc.data()
								})
								onSuccess(userDoc.data())
							})
					}
				} else {
					let city = localStorage.getItem('cityStorage')
					city = city[0].toUpperCase() + city.substr(1).toLowerCase()
					branchesCollection
						.where('city', '==', city)
						.get()
						.then(async (querySnapshot) => {
							branchesCollection
								.doc(querySnapshot.docs[0].id)
								.get()
								.then(async (branchOficce) => {
									// eslint-disable-next-line no-unused-vars
									let brach = branchOficce.data()
									let user = userModel
									const userData = {
										...user,
										uID: res.user.uid,
										email: res.user.email || ' ',
										name: res.user.displayName || '',
										phone: res.user.phoneNumber.substr(1),
										created_date: firebase.firestore.Timestamp.fromDate(
											new Date()
										),
										addresses: []
									}

									try {
										const docRef = await userDB.add(userData)
										await userDB.doc(docRef.id).update({ ID: docRef.id })
										userData.ID = docRef.id

										// user change listener
										userDB.doc(docRef.id).onSnapshot((snapshot) => {
											if (!snapshot.exists) return
											dispatch({
												type: actionsTypes.SET_AUTH_VALUES_SUCCESS,
												authValues: snapshot.data()
											})
										})

										onSuccess(userData)
										dispatch({
											type: actionsTypes.SUCCESS,
											authValues: userData
										})
									} catch (err) {
										console.log(err)
										dispatch({
											type: actionsTypes.ERROR,
											error: err.message
										})
									}
								})
						})
				}
			})
			// ...
		})
		.catch((error) => {
			// User couldn't sign in (bad verification code?)
			// ...
			onFailure(error.code)
			console.log('error code verification', error)
		})
}

export const signInWithFacebook = async (dispatch, onSuccess, onFailure) => {
	let provider = new firebase.auth.FacebookAuthProvider()
	signInWithFirebaseProvider(provider, dispatch, onSuccess, onFailure)
}

export const signInWithFirebaseProvider = (
	provider,
	dispatch,
	onSuccess,
	onFailure
) => {
	dispatch({ type: actionsTypes.REQUEST })

	auth
		.signInWithPopup(provider)
		.then(async (res) => {
			const userRef = userDB.where('uID', '==', res.user.uid)
			userRef.get().then(async (querySnapshot) => {
				if (!querySnapshot.empty) {
					let documentId = querySnapshot.docs[0].id
					if (res.additionalUserInfo.providerId === 'google.com') {
						userDB
							.doc(documentId)
							.get()
							.then((userDoc) => {
								if (userDoc.data().email === '') {
									userDB.doc(documentId).set(
										{
											email: res.additionalUserInfo.profile.email
												? res.additionalUserInfo.profile.email
												: res.user.email
												? res.user.email
												: '',
											ID: documentId
										},
										{ merge: true }
									)
								}
								dispatch({
									type: actionsTypes.SUCCESS,
									authValues: userDoc.data()
								})
								onSuccess(userDoc.data())
							})
					}
				} else {
					let city = localStorage.getItem('cityStorage')
					city = city[0].toUpperCase() + city.substr(1).toLowerCase()
					branchesCollection
						.where('city', '==', city)
						.get()
						.then(async (querySnapshot) => {
							branchesCollection
								.doc(querySnapshot.docs[0].id)
								.get()
								.then(async (branchOficce) => {
									// eslint-disable-next-line no-unused-vars
									let brach = branchOficce.data()
									let user = userModel
									const userData = {
										...user,
										uID: res.user.uid,
										email: res.user.email || ' ',
										name:
											res.additionalUserInfo.providerId === 'google.com'
												? res.additionalUserInfo.profile.given_name || ''
												: res.additionalUserInfo.profile.name || '',
										lastname:
											res.additionalUserInfo.providerId === 'google.com'
												? res.additionalUserInfo.profile.family_name || ''
												: '',
										photo: res.user.photoURL || '',
										created_date: firebase.firestore.Timestamp.fromDate(
											new Date()
										),
										addresses: []
									}

									try {
										const docRef = await userDB.add(userData)

										await userDB.doc(docRef.id).update({ ID: docRef.id })
										userData.ID = docRef.id

										// user change listener
										userDB.doc(docRef.id).onSnapshot((snapshot) => {
											if (!snapshot.exists) return
											dispatch({
												type: actionsTypes.SET_AUTH_VALUES_SUCCESS,
												authValues: snapshot.data()
											})
										})

										onSuccess(userData)
										dispatch({
											type: actionsTypes.SUCCESS,
											authValues: userData
										})
									} catch (err) {
										console.log(err)
										dispatch({
											type: actionsTypes.ERROR,
											error: err.message
										})
									}
								})
						})
				}
			})
		})
		.catch((err) => {
			dispatch({
				type: actionsTypes.ERROR,
				error: err.message
			})
			onFailure(err)
		})
}

export const createUserWithEmailAndPassword = async (
	password,
	dispatch,
	authValues,
	onSuccess,
	onFailure
) => {
	dispatch({
		type: actionsTypes.REQUEST
	})
	auth
		.createUserWithEmailAndPassword(authValues.email, password)
		.then(async (res) => {
			try {
				const userData = {
					...authValues,
					created_date: firebase.firestore.Timestamp.fromDate(new Date()),
					uID: res.user.uid,
					addresses: []
				}
				const doc = await userDB.add({ ...userData })
				await userDB.doc(doc.id).update({ ID: doc.id })

				dispatch({
					type: actionsTypes.SUCCESS,
					authValues: { ...userData, ID: doc.id }
				})
				//userGetData(dispatch, res.user.uid);
				onSuccess({ ...userData, ID: doc.id })

				window.location.reload()
			} catch (error) {
				dispatch({ type: actionsTypes.ERROR, error: error.message })
			}
		})
		.catch((err) => {
			dispatch({
				type: actionsTypes.ERROR,
				error: err.message
			})
			onFailure(err)
		})
}

export const handleAuthValues = (dispatch, id, value) => {
	dispatch({ type: actionsTypes.UPDATE_AUTH_VALUES, value: { id, value } })
}

export const passwordReset = async (dispatch, email) => {
	try {
		dispatch({ type: actionsTypes.REQUEST_PASSWORD_RESET })
		await auth.sendPasswordResetEmail(email)

		dispatch({
			type: actionsTypes.SUCCESS_PASSWORD_RESET,
			value: true
		})
		return true
	} catch (err) {
		console.error(err)
		dispatch({
			type: actionsTypes.ERROR_PASSWORD_RESET,
			error: err.message
		})
		return false
	}
}

export const newAddresses = async (
	dispatch,
	authvalues,
	addressValue,
	documentId
) => {
	dispatch({
		type: actionsTypes.UPDATE_USER_DATA
	})

	//const user = authvalues;
	const obj = { addresses: [...authvalues.addresses, addressValue] }

	userDB
		.doc(documentId)
		.set(obj, { merge: true })
		.then(() => {
			dispatch({
				authvalues: obj,
				type: actionsTypes.UPDATE_USER_DATA_SUCCESS
			})
		})
		.catch((err) => {
			dispatch({
				type: actionsTypes.UPDATE_USER_DATA_FAILURE,
				error: err.message
			})
		})
}

export const deleteAddress = (dispatch, authvalues, index) => {
	dispatch({
		type: actionsTypes.UPDATE_USER_DATA
	})

	let user = authvalues
	let addressesTemp = user?.addresses?.filter((item, i) => i !== index)

	userDB
		.doc(authvalues.ID)
		.set({ addresses: addressesTemp }, { merge: true })
		.then(() => {
			dispatch({
				type: actionsTypes.UPDATE_USER_DATA_SUCCESS
			})
		})
		.catch((err) => {
			dispatch({
				type: actionsTypes.UPDATE_USER_DATA_FAILURE,
				error: err.message
			})
		})
}

export const updateAddresses = (
	dispatch,
	authvalues,
	index,
	value,
	documentId
) => {
	dispatch({
		type: actionsTypes.UPDATE_USER_DATA
	})

	let user = authvalues
	let addressesTemp = user.addresses
	if (index !== undefined) {
		addressesTemp[index] = value
	}

	userDB
		.doc(documentId)
		.set(
			{
				addresses:
					index !== undefined ? addressesTemp : [...user.addresses, value]
			},
			{ merge: true }
		)
		.then(() => {
			dispatch({
				type: actionsTypes.UPDATE_USER_DATA_SUCCESS
			})
		})
		.catch((err) => {
			dispatch({
				type: actionsTypes.UPDATE_USER_DATA_FAILURE,
				error: err.message
			})
		})
}

// shopping cart

export const updateShoppingCartProducts = async (
	dispatch,
	ID,
	products,
	saveInUser = true,
	onSuccess,
	onError
) => {
	dispatch({
		type: actionsTypes.UPDATE_SHOPPING_CART,
		products: products
	})
	try {
		if (saveInUser) {
			await userDB.doc(ID).update({ shopping_cart: products })
			onSuccess()
		}
	} catch (error) {
		onError(error)
	}
}

export const emptyCart = (
	dispatch,
	ID,
	saveInUser = true,
	onSuccess,
	onError
) => {
	dispatch({
		type: actionsTypes.EMPTY_CART
	})
	if (saveInUser) {
		userDB
			.doc(ID)
			.update({ shopping_cart: [] })
			.then(() => onSuccess())
			.catch((error) => onError(error))
	}
}

export const setCurrentCity = (dispatch, city) => {
	dispatch({
		type: actionsTypes.USER_CURRENT_CITY,
		payload: city
	})
}
