import React, { useState } from 'react'
import PropTypes from 'prop-types'
//components
import CustomModal from '../CustomModal/CustomModal'
import CustomButton from '../atoms/buttons/CustomButton'
import PaymentMethod from '../PaymentMethod/PaymentMethod'
import * as paymentTypes from '../../components/PaymentMethod/PaymentTypes'
import CircularLoader from '../loaders/CircularLoader'
import { Grid } from '@material-ui/core'
//hooks
//import useAuth from '../../hooks/auth/useAuth'
//import usePaymentCards from '../../hooks/paymentCards/usePaymentCards'
import usePaymentMethod from '../PaymentMethod/usePaymentMethod'

const SelectCardModal = (props) => {
	const {
		acceptLabel,
		acceptAction,
		open,
		handleClose,
		cards,
		setSelectedPaymentMethod
	} = props

	// eslint-disable-next-line
	const [index, setIndex] = useState(0)
	const [selectedCard, setSelectedCard] = useState(0)
	// eslint-disable-next-line
	const [payment, setPayment] = useState(0)

	const { cardType } = usePaymentMethod()

	return (
		<CustomModal open={open} handleClose={handleClose} mobileSmall>
			<div className='select-card-modal'>
				<span className='text-18px-700 color-blue card-title'>
					Selecciona la tarjeta de crédito
				</span>
				<div className='content  custom-scrollbar'>
					<Grid container spacing={2} className='card-container'>
						{/* Cards del usuario */}
						{!cards ? (
							<CircularLoader />
						) : cards.length > 0 ? (
							cards.map((card, i) => (
								<Grid item xs={12} key={`card-${i}`}>
									<PaymentMethod
										key={card.id}
										lastDigits={card.mask}
										type={paymentTypes.methods.CREDIT_CARD}
										cardType={cardType(card.mask)}
										layout={paymentTypes.layouts.ORDER_SUMMARY}
										isSelected={selectedCard === i}
										onSelect={() => {
											setSelectedCard(i)
											setSelectedPaymentMethod(card)
										}}
										setPayment={setPayment}
									/>
								</Grid>
							))
						) : (
							<span className='text-16px-400 color-gray'>
								No has agregado tarjetas aún
							</span>
						)}
					</Grid>
				</div>
				<div className='btn-modal'>
					<CustomButton label={acceptLabel} onClick={acceptAction} />
				</div>
			</div>
		</CustomModal>
	)
}

SelectCardModal.propTypes = {
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	acceptLabel: PropTypes.string,
	acceptAction: PropTypes.func,
	cards: PropTypes.array.isRequired,
	setSelectedPaymentMethod: PropTypes.func
}

export default SelectCardModal
