import * as actionTypes from './configsActionsTypes'

/**
 * El estado inicial del reducer de configuraciones.
 * @type {{data: null, loading: boolean, error: null, branches: Array, bag: {}, bags: Array}}
 */
export const initialState = {
	data: null,
	loading: false,
	error: null,
	branches: [],
	bag: {},
	bags: []
}

/**
 * Reducer que maneja el estado de configuraciones de la aplicación.
 * @param {{data: null, loading: boolean, error: null, branches: Array, bag: {}, bags: Array}} prevState - Estado anterior del reducer.
 * @param {any} action - Acción enviada al reducer.
 * @returns {{data: null, loading: boolean, error: null, branches: Array, bag: {}, bags: Array}} - Nuevo estado del reducer.
 */
export const configsReducer = (prevState = initialState, action) => {
	switch (action.type) {
		case actionTypes.LOAD_DATA_CONFIGS:
			return {
				...prevState,
				loading: true
			}
		case actionTypes.LOAD_DATA_CONFIGS_SUCCESS:
			return {
				...prevState,
				data: action.data,
				loading: false,
				bag: action?.data?.bag || null,
				bags: [...action.data.bags],
				error: null
			}
		case actionTypes.LOAD_DATA_CONFIGS_FAILURE:
			return {
				...prevState,
				loading: false,
				error: action.error
			}
		case actionTypes.LOAD_BRANCHES:
			return {
				...prevState,
				loading: true
			}
		case actionTypes.LOAD_BRANCHES_SUCCESS:
			return {
				...prevState,
				loading: false,
				error: null,
				branches: action.branches
			}
		case actionTypes.LOAD_BRANCHES_FAILURE:
			return {
				...prevState,
				loading: false,
				error: action.error
			}
		default:
			return prevState
	}
}
