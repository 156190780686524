import PropTypes from 'prop-types'
import React from 'react'
import * as cardTypes from './cardDireccionTypes'
// hooks
import useStringFormater from '../../hooks/stringFormater/useStringFormater'
import useCardDireccion from './useCardDireccion'
// constants
import * as Cities from '../../consts/Cities'

/**
 * Componente para mostrar una tarjeta con información de una dirección
 * @param {Object} props - Propiedades del componente
 * @param {boolean} props.isSelected - Indica si la dirección está seleccionada
 * @param {string} props.nameDireccion - Nombre de la dirección
 * @param {Object} props.direccion - Objeto que contiene la información de la dirección
 * @param {string} props.timeEstimate - Estimación de tiempo de entrega
 * @param {string} props.dateOfDelivery - Fecha de entrega
 * @param {number} props.cantaddress - Cantidad de direcciones
 * @param {string} props.cardType - Tipo de tarjeta a mostrar
 * @param {function} props.onDelete - Función que se ejecuta al eliminar la dirección
 * @param {function} props.onUpdate - Función que se ejecuta al actualizar la dirección
 * @param {function} props.onClick - Función que se ejecuta al hacer clic en la tarjeta
 * @returns {JSX.Element} Componente CardDireccion
 */
const CardDireccion = (props) => {
	const { formatAddress } = useStringFormater()

	const {
		// flags
		isSelected,
		// info
		nameDireccion,
		direccion,
		timeEstimate,
		cantaddress,
		dateOfDelivery,
		// presentation
		cardType,
		// actions
		onDelete,
		onUpdate,
		onClick
	} = props

	const { cardIcon, actionName, footer } = useCardDireccion({
		cardType,
		isSelected,
		onDelete,
		timeEstimate,
		dateOfDelivery
	})

	return (
		<div
			className={
				isSelected
					? 'direccion-card direccion-card--selected'
					: 'direccion-card'
			}
			onClick={onClick}>
			<div className='body'>
				<div className='direccion-title'>
					{cantaddress > 1 && <>{cardIcon()}</>}
					<div>
						<span className='color-black-light text-10px-400'>
							{nameDireccion}
						</span>
						{direccion && (
							<span className='color-black-light text-12px-600'>
								{direccion.city.toUpperCase() === Cities.TUMACO
									? direccion.city
									: direccion.address
									? direccion.address
									: formatAddress(direccion)}
							</span>
						)}
					</div>
				</div>
				<span
					className='text-decoration-none color-blue text-12px-600'
					onClick={(e) => {
						e.stopPropagation()
						if (onUpdate) onUpdate()
					}}>
					{onUpdate ? actionName() : ''}
				</span>
			</div>
			<div className='card-direccion-footer'>{footer()}</div>
		</div>
	)
}

CardDireccion.propTypes = {
	// flags
	isSelected: PropTypes.bool,
	// info
	nameDireccion: PropTypes.string.isRequired,
	direccion: PropTypes.object,
	timeEstimate: PropTypes.string,
	dateOfDelivery: PropTypes.string,
	cantaddress: PropTypes.number,
	// presentation
	cardType: PropTypes.string,
	// actions
	onDelete: PropTypes.func.isRequired,
	onUpdate: PropTypes.func.isRequired,
	onClick: PropTypes.func.isRequired
}

CardDireccion.defaultProps = {
	onDelete: () => {},
	onUpdate: () => {},
	onClick: () => {},
	cardType: cardTypes.VISUALIZATION_MODE
}

export default CardDireccion
