import React from 'react'

import backArrowButton from '../../assets/arrow-back.svg'
import { Link } from 'react-router-dom'
import { routes } from '../../routes/routes'

/**
 * Renderiza el componente NotFound Desktop en función del tipo de dispositivo.
 * @returns {React.ReactNode} Elemento React que contiene el componente NotFound.
 */
const NotFoundDesktop = () => {
	return (
		<div className='desktop-page-wrapper notfound-page-desktop'>
			<h2 className='color-blue'>404</h2>
			<span className='text-20px-300 color-gray-light text-center'>
				Lo sentimos, no encontramos lo que buscabas...
			</span>
			<Link to={routes.home.path} className='goBack text-decoration-none'>
				<img src={backArrowButton} alt='goBack' />
				<span className='text-16px-400 color-blue'>ir al inicio</span>
			</Link>
		</div>
	)
}

export default NotFoundDesktop
