import React from 'react'
import { isBrowser } from 'react-device-detect'
import OrderFinishedDesktop from './OrderFinishedDesktop'
import OrderFinishedMobile from './OrderFinishedMobile'

/**
 * Componente que selecciona el diseño de la página de confirmación de pedido dependiendo del dispositivo del usuario.
 * @param {Object} props - Propiedades para el componente.
 * @returns {React.ReactNode} Elemento React que contiene la página de confirmación de pedido para dispositivos de escritorio o móviles.
 */
const OrderFinished = (props) => {
	const OrderFinishedLayout = isBrowser
		? OrderFinishedDesktop
		: OrderFinishedMobile
	return <OrderFinishedLayout {...props} />
}

export default OrderFinished
