import {
  Box,
  Menu,
  MenuItem,
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
// constants
import { useCallback } from 'react'
import * as UnitTypes from '../../consts/ProductCountOptions'
// eslint-disable-next-line no-unused-vars
import ProductModel from '../../models/ProductModel'
import { checkIfProductAppliPerKilogramOnly } from '../../utils/productPerKilogram'

const unitTypeOptions = [UnitTypes.UNIT, UnitTypes.WEIGHT]

/**
 *
 * @param {{
 * big?:boolean,
 * uneditable?:boolean,
 * loading?:boolean,
 * selectDisabled?:boolean,
 * color?:string,
 * fontColor?:"white"|"blue",
 * unitType?:string,
 * count?:number,
 * minValue?:number,
 * unitGram?:number,
 * incrementCounter?: ()=>void,
 * decrementCounter?: ()=>void,
 * setUnitType?:()=>void,
 * setCounter?:()=>void,
 * skeletonProps?:any
 * product?: ProductModel
 * }} props 
 * @returns 
 */
export const ProductCounterWithGrams = ({
  color = 'light-blue',
  fontColor = 'blue',
  incrementCounter = () => { },
  decrementCounter = () => { },
  setUnitType = () => { },
  count = 1,
  minValue = 0,
  big,
  uneditable,
  loading,
  selectDisabled,
  // data
  unitType,
  unitGram,
  setCounter,
  // additional props
  skeletonProps,
  product
}) => {
  const fontSize = big ? '17px' : '12px'
  const size = big ? 'big' : 'small'

  // select unit type modal variables
  const [anchorEl, setAnchorEl] = useState(null);

  const computeCount = useCallback((count = 0) => {
    if (count <= 0) return 0
    if (unitType !== UnitTypes.UNIT.value) {
      const quantity_pum = unitGram || product.unit_gram || product.quantity_pum || Number(process.env.process.env.REACT_APP_RANGE_QUANTITY)

      return count * quantity_pum
    } else {
      return count
    }
  }, [unitType])

  const countValue = useMemo(() => {
    return computeCount(count)
  }, [count, computeCount])

  const DisplayUnitTypes = useMemo(() => {
    if (checkIfProductAppliPerKilogramOnly(product)) {
      return [UnitTypes.WEIGHT]
    }
    return unitTypeOptions
  }, [unitTypeOptions, UnitTypes, unitType])

  useEffect(() => {
    if (checkIfProductAppliPerKilogramOnly(product)) {
      setUnitType(UnitTypes.WEIGHT.value)
    }
  }, []);

  return (
    <>
      {loading ? (
        <Skeleton
          variant='rect'
          animation='wave'
          width={180}
          height={big ? 36 : 26}
          style={{ borderRadius: 30 }}
          {...skeletonProps}
        />
      ) : (
        <div className={`product-counter -with-grams -${size}`}>
          {!uneditable && (
            <CounterButton
              size={size}
              color={color}
              fontSize={fontSize}
              onClick={() => decrementCounter(countValue, computeCount(count - 1))}
            >
              {countValue > minValue && "-"}
              {countValue <= minValue && selectDisabled && <i className='delete-icon-circle -blue'></i>}
              {countValue <= minValue && !selectDisabled && "-"}
            </CounterButton>
          )}
          <span
            className={`text-${fontSize}-700 color-blue counter-label -${size} -${color}`}
          >
            {countValue}
          </span>
          {!uneditable && (
            <CounterButton onClick={incrementCounter}>+</CounterButton>
          )}
          <Box
            style={{ gap: '.3rem' }}
            display='flex'
            alignItems='center'
            onClick={(e) => {
              if (selectDisabled) return
              setAnchorEl(e.target)
            }}
          >
            {uneditable ? (
              <>
                <span
                  className={`text-${fontSize}-500 color-${fontColor} unit-type`}
                >
                  {
                    DisplayUnitTypes?.find((option) => option.value === unitType)
                      ?.label?.long
                  }
                </span>
              </>
            ) : (
              <>
                <span
                  title='Cambiar unidad de medida'
                  className={`text-${fontSize}-500 color-${fontColor} unit-type -underline ${selectDisabled ? '-disabled' : 'cursor-pointer'
                    } `}
                >
                  {
                    DisplayUnitTypes?.find((option) => option.value === unitType)
                      ?.label?.short
                  }
                </span>
                {!selectDisabled && (
                  <i
                    className={`arrow -${fontColor} -down ${selectDisabled ? '' : 'cursor-pointer'
                      }`}
                  />
                )}
              </>
            )}
          </Box>
        </div>
      )}
      {/* Unit type modal select */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
      >
        {DisplayUnitTypes?.map((option) => (
          <MenuItem
            key={`radio-option-${option.value}`}
            onClick={() => {
              setUnitType(option.value)
              setAnchorEl(null)
              if (option.value !== unitType) setCounter(1)
            }}
          >
            <span className='text-12px-400 color-blue'>
              {option.label.long}
            </span>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

const CounterButton = ({ onClick, children, inputProps, size, color, fontSize }) => (
  <button
    className={`product-counter-button -${size} -${color}`}
    onClick={onClick}
    {...inputProps}
  >
    <span className={`text-${fontSize}-500 color-blue`}>{children}</span>
  </button>
)
CounterButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  inputProps: PropTypes.object
}


export default ProductCounterWithGrams
