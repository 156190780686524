import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
// hooks
import useMercadoCard from './useMercadoCard'

const MercadoCardMobile = (props) => {
	const {
		// actions
		onDelete,
		onEdit,
		// data
		marketId,
		address,
		name,
		products,
		city,
		// navigation
		to
	} = props


	//hooks
	const history = useHistory()
	const [totalItemsInCart, setTotalItemsInCart] = useState(0)
	const { getSubtotal, formatedAddress, isFav, toggleFavorite } =
		useMercadoCard({
			address,
			city,
			products,
			marketId
		})

	//redirecciona 
	const openMarket = () => {
		history.push(to)
	}


	useEffect(() => {
		const sumall = products // Calcula la cantidad de productos en el carrito,
			.map((product) => product.count)
			.reduce((prev, curr) => prev + curr, 0)
		setTotalItemsInCart(sumall) //setea a setTotalItemsInCart con el valos sumall
	}, [products]) //se ejecuta cada vez que la propiedad products cambie 

	return (
		<div className='mercado-card -unselected' onClick={openMarket}>
			<div className='card-info'>
				<span className='text-18px-700 color-gray-dark title'>{name}</span>
				<div className='body -unselected'>
					<div>
						<span className='color-gray-light text-11px-400'>
							{formatedAddress}
						</span>
						<span className='color-gray-light text-11px-400'>
							{totalItemsInCart} items
						</span>
					</div>
					<div>
						<span className='color-gray-light text-12px-500'>Subtotal</span>
						<span className='color-blue text-18px-700'>{getSubtotal()}</span>
					</div>
				</div>
			</div>
			<div className='card-actions'>
				<i
					className={`heart-icon-circle -${isFav ? '' : 'un'}checked -small`}
					onClick={(e) => {
						e.stopPropagation()
						toggleFavorite(marketId)
					}}
				/>
				<i className='edit-icon -blue' onClick={onEdit} />
				<i className='delete-icon-circle -blue' onClick={onDelete} />
			</div>
		</div>
	)
}

MercadoCardMobile.propTypes = {
	// data
	marketId: PropTypes.string.isRequired,
	address: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	products: PropTypes.array.isRequired,
	city: PropTypes.string.isRequired,
	// navigation
	to: PropTypes.string.isRequired,
	// actions
	onDelete: PropTypes.func,
	onEdit: PropTypes.func
}

MercadoCardMobile.defaultProps = {
	// actions
	onDelete: () => {},//prop callback para eliminar mercado
	onEdit: () => {} //prop callback para editar mercado
}

export default MercadoCardMobile
