import { useState } from 'react'
import * as cities from '../../consts/Cities'
import useAuth from '../../hooks/auth/useAuth'

const CALI_1 = 'CALI_1'
const CALI_2 = 'CALI_2'
const CALI_3 = 'CALI_3'
const MEDELLIN_1 = 'MEDELLIN_1'
const MEDELLIN_2 = 'MEDELLIN_2'
const MEDELLIN_3 = 'MEDELLIN_3'
const TUMACO_1 = 'TUMACO_1'

const stores = {
	[cities.CALI]: [
		{
			label: 'tienda 1 cali',
			value: CALI_1
		},
		{
			label: 'tienda 2 cali',
			value: CALI_2
		},
		{
			label: 'tienda 3 cali',
			value: CALI_3
		}
	],
	[cities.MEDELLIN]: [
		{
			label: 'tienda 1 medellin',
			value: MEDELLIN_1
		},
		{
			label: 'tienda 2 medellin',
			value: MEDELLIN_2
		},
		{
			label: 'tienda 3 medellin',
			value: MEDELLIN_3
		}
	],
	[cities.TUMACO]: [
		{
			label: 'tienda 1 tumaco',
			value: TUMACO_1
		}
	]
}

const useStoreSelector = () => {
	const { handleStore, getCityStorage } = useAuth()

	const [selectedIndex, setSelectedIndex] = useState(0)
	const [city] = useState(getCityStorage())

	//actualiza el estado selectedIndex
	const selectStore = (e) => {
		const index = e.target.selectedIndex
		setSelectedIndex(index)
	}
	// guarda  la tienda seleccionada por el usuario
	const saveStore = () => {
		handleStore(stores[city][selectedIndex].value)
	}

	return {
		stores: stores[city],
		selectedIndex,
		selectStore,
		saveStore
	}
}

export default useStoreSelector
