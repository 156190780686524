import InfoIcon from '@material-ui/icons/Info'
import { Skeleton } from '@material-ui/lab'
import React from 'react'
import { isMobile } from 'react-device-detect'
// hooks
import useConfigs from '../../hooks/configs/useConfigs'
import useStringFormater from '../../hooks/stringFormater/useStringFormater'

const MinimunValueMessage = () => {
	const { data = {}, loading } = useConfigs()
	const minimum_value = data?.minimum_value

	const { formatPrice } = useStringFormater()

	return (
		<div className={`card cart-message ${isMobile ? '-mobile' : ''}`}>
			<InfoIcon color='primary' fontSize='large' />
			{loading ? (
				<Skeleton variant='rect' animation='wave' width={'90%'} />
			) : (
				<span className='message'>
					<span className='text-15px-400 color-gray'>
						Debes realizar un pedido de mínimo&nbsp;
						<span className='message text-15px-600 color-blue'>
							{formatPrice(minimum_value)}
						</span>
						<br />
					</span>
					<span className='text-15px-400 color-gray'>
						para finalizar tu pedido
					</span>
				</span>
			)}
		</div>
	)
}

export default MinimunValueMessage
