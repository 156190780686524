import * as observationsActionsTypes from "./observationsActionsTypes";
export const initialState = {
  observations: JSON.parse(localStorage.getItem("observations")) || {}
}

export const observationsReducer = (prevState = initialState, action) => {
  switch (action.type) {
    case observationsActionsTypes.SET_OBSERVATION_PRODUCT: {
      const {productId, value} = action.payload;
      const newObservations = prevState.observations;
      newObservations[productId] = value
      localStorage.setItem("observations", JSON.stringify(newObservations))
      return {
        ...prevState,
        observations: newObservations
      }
    }
    case observationsActionsTypes.CLEAR_OBSERVATIONS: {
      localStorage.removeItem("observations")
      return {
        ...prevState,
        observations: {}
      }
    }
    default:
      return {
        ...prevState
      }
  }
}