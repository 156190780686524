import React, {useCallback, useContext, useMemo} from "react";
import {context} from "../../context/ContextProvider";
import {addObservation} from "../../context/observations/observationsActions";
import useShoppingCart from "./useShoppingCart";

const useObservations = (
 productId = null
) => {
  const { state, dispatch } = useContext(context);
  const [typingTimeout, setTypingTimeout] = React.useState(null);
  const [observation, setObservation] = React.useState("")
  const { observations } = state.observationsReducer
  const {
    isProductInCart,
    changeProductObservation
  } = useShoppingCart()

  const handleSetObservation = (e) => {
    setObservation(e.target.value)
    addObservation(
      dispatch,
      e.target.value,
      productId
    )
    // Limpiar el timeout existente si está configurado
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    // esperar un tiempo para actualizar la descripción
    const newTimeout = setTimeout(() => {
      if (!isProductInCart(productId)) return
      changeProductObservation(
        e.target.value,
        productId,
      )
    }, 500);

    // Guardar el nuevo timeout en el estado
    setTypingTimeout(newTimeout);
  }

  const clearObservation = () => {
    setObservation("")
    addObservation(
      dispatch,
      "",
      productId
    )
  }

  const getObservationsByID = useCallback((id) => {
    if (observations[id]) {
      return observations[id]
    }
    return ""
  }, [observations]);

  const productObservations = useMemo(() => {
    return getObservationsByID(productId)
  }, [productId, getObservationsByID])

  return {
    observation,
    observations,
    setObservation,
    clearObservation,
    handleSetObservation,
    getObservationsByID,
    productObservations
  }
}

export default useObservations;