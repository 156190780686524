const routes = {
	home: {
		path: '/',
		name: 'Historial de pedidos'
	},
	details: {
		path: '/:orderId',
		name: 'Detalles de pedidos'
	}
}

const routeFormatter = {
	toDetails: (orderId) => routes.details.path.replace(':orderId', orderId)
}

export { routes, routeFormatter }
