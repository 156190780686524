import React, { useMemo, useEffect } from 'react'
import { isMobile } from 'react-device-detect'

import SubcategoryDesktop from './SubcategoryDesktop'
import SubcategoryMobile from './SubcategoryMobile'
// hooks
import { useParams, Redirect } from 'react-router-dom'
import useProducts from '../../hooks/products/useProducts'
import useAuth from '../../hooks/auth/useAuth'

/**
 * Subcategory page component.
 *
 * @returns {JSX.Element} Subcategory page JSX element.
 */
const Subcategory = () => {
	const { categoryId, subcategoryId } = useParams()

	const { loading, categories, loadSubCategoryProducts, getCategoryById } =
		useProducts()

	const [category, subcategory] = useMemo(() => {
		// console.log('memo')
		let category = getCategoryById(categoryId)
		let subcategory = !category
			? undefined
			: category.subcategories_data.find(
					(subcat) => subcat.subcategory === subcategoryId
			  )
		return [category, subcategory]
	}, [categories])

	const { getCityStorage, getCityBranchOffice, showLoginModal } = useAuth()
	const city = getCityStorage()
	const branchOffice = getCityBranchOffice()

	useEffect(() => {
		if (!city) showLoginModal()
	}, [])

	useEffect(() => {
		loadSubCategoryProducts(categoryId, subcategoryId, city, branchOffice)
	}, [])

	useEffect(() => {
		loadSubCategoryProducts(categoryId, subcategoryId, city, branchOffice)
	}, [branchOffice])

	if (categories.length > 0 && (!category || !subcategory)) {
		// as the page "error_not_found" doesn't exists, router will redirect to 404 page as intended
		return <Redirect to='/error_not_found' />
	}

	const SubcategoryLayout = isMobile ? SubcategoryMobile : SubcategoryDesktop

	return (
		<SubcategoryLayout
			loading={loading}
			category={category}
			categoryId={categoryId}
			city={city}
			branchOffice={branchOffice}
			subcategory={subcategory}
			subcategoryId={subcategoryId}
		/>
	)
}

export default Subcategory
