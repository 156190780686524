import PropTypes from 'prop-types'
import React, {useEffect, useState} from 'react'
import useAuth from "../../../hooks/auth/useAuth";

// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

/**
 * Un componente de menú desplegable que se muestra en una caja azul oscuro.
 *
 * @param {Object} props - Propiedades del componente.
 * @param {string[]} props.menuItems - Una matriz de elementos que aparecerán en el menú desplegable.
 *
 * @returns {JSX.Element} Un componente de menú desplegable.
 */
const DarkBlueSelect = ({ menuItems }) => {
	// Obtiene las funciones y valores necesarios del hook useAuth
	const {
		handleCity,
		isLogged,
		currentCity,
	} = useAuth()

	const [val, setVal] = useState("")
	const [open, setOpen] = useState(false)
	const toggle = () => setOpen(!open)

	useEffect(() => {
		setCityValue(currentCity)
	}, [currentCity])

	const handleChange = (event) => {
		handleCity(event)
	}
	const setCityValue = (value) => {
		setVal(value)
	}

	if (isLogged) {
		return <></>
	}
	return (
		<div className='dark-blue-select'>
			<div
				className='select-input'
				role='button'
				onKeyPress={() => toggle(!open)}
				onClick={() => toggle(!open)}>
				<div className='select-container'>
					<p>{val}</p>
				</div>
				{open && (
					<ul>
						{menuItems.map((option, i) => (
							<li
								value={option}
								key={`option-${i}`}
								onClick={() => handleChange(option)}>
								{option}
							</li>
						))}
					</ul>
				)}
			</div>
		</div>
	)
}

DarkBlueSelect.propTypes = {
	menuItems: PropTypes.array
}

export default DarkBlueSelect
