// Esto se hace para medio tratar de arreglar esta cosa
export default function NormalizeBranch(branchOffice = '') {
	// if ((branchOffice.toLowerCase()) === "chipichape" || (branchOffice.toLowerCase()) === "cali")

	branchOffice = branchOffice.toLocaleLowerCase()
	if (['chipichape', 'cali', 'merca z cali'].includes(branchOffice))
		branchOffice = 'Chipichape'

	// poner la primera en mayuscula
	branchOffice = branchOffice
		.split(' ')
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
		.join(' ')

	if (['santa lucia', 'Santa Lucia'].includes(branchOffice))
		branchOffice = 'Santa Lucia'

	if (['guayabal'].includes(branchOffice)) branchOffice = 'Guayabal'

	return branchOffice
}
