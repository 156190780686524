import useAuth from '../../../hooks/auth/useAuth'
import useStringFormater from '../../../hooks/stringFormater/useStringFormater'

const useUserInfo = () => {
	const { loading, authValues } = useAuth()
	const { name, lastname, document, phone, email } = authValues

	const { formatDocument, formatPhone } = useStringFormater()

	return {
		name,
		lastname,
		document: formatDocument(document),
		phone: formatPhone(phone),
		email,
		loading: !authValues.ID || loading
	}
}

export default useUserInfo
