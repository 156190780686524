import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'

import SectionHeader from '../../components/SectionHeader/SectionHeader'
import ProductCardDesktop from '../../components/atoms/cards/ProductCard/ProductCardDesktop'
import { Box } from '@material-ui/core'
import { routeFormatter } from '../../routes/routes'
import getPriceByOffice from '../../utils/getPriceByOffice'
// hooks
import { Link } from 'react-router-dom'
import { Skeleton } from '@material-ui/lab'
import ValidateStock from '../../utils/ValidateStock'

/**
 * Componente para mostrar la página de subcategoría en la versión de escritorio.
 * @param {Object} props - Propiedades del componente.
 * @param {boolean} props.loading - Indica si la página está cargando.
 * @param {Object} props.category - Categoría a la que pertenece la subcategoría.
 * @param {string} props.categoryId - ID de la categoría.
 * @param {string} props.city - Ciudad en la que se encuentra la sucursal.
 * @param {string} props.branchOffice - Sucursal de la que se está solicitando el catálogo.
 * @param {Object} props.subcategory - Subcategoría a mostrar.
 * @param {string} props.subcategoryId - ID de la subcategoría.
 * @returns {JSX.Element} Componente de React que muestra la página de subcategoría en la versión de escritorio.
 */
const Subcategorydesktop = ({
	loading,
	category,
	categoryId,
	city,
	branchOffice,
	subcategory,
	subcategoryId
}) => {
	const [products, setProducts] = useState([])

	useEffect(() => {
		const data = category?.products?.filter((product) => product.sub_category_id === subcategoryId);
		setProducts(data)
	// eslint-disable-next-line
	}, [category])

	return (
		<div>
			<div className='desktop-page-wide homeDesktop'>
				{
					// validating the category avoids crashing when accesing by direct link
					loading || !category || category.products.length === 0 ? (
						<section className='category-section'>
							<p>
								<Skeleton animation='wave' variant='rect' width={200} />
							</p>
							<Box display='flex' flexWrap='wrap'>
								{Array(10)
									.fill(0)
									.map((_, i) => (
										<ProductCardDesktop loading key={`product-skltn-${i}`} />
									))}
							</Box>
						</section>
					) : (
						<>
							<SectionHeader goBack>
								<Link
									to={routeFormatter.toCategory(categoryId)}
									className='text-decoration-none text-capitalize'>
									<span className='text-21px-700 color-gray-light'>
										{category.name.toLowerCase()}
									</span>
								</Link>
								<span className='text-21px-700 color-blue'>
									/&nbsp;{subcategory.subCategoryName}
								</span>
							</SectionHeader>
							<div className='cards-desktop-container'>
								{products?.length === 0 ? (
									<>
										Por el momento no tenemos productos en esta categoría
									</>
								) : (
									products.map(
										(product) =>
											product.price_branch_offices.some((item) =>
												ValidateStock(item, city, branchOffice)
											) && (
												<ProductCardDesktop
													key={product.id_item}
													productId={product.id_item}
													categoryId={category.id}
													product={product}
													branch={product.price_branch_offices.find((item) =>
														getPriceByOffice(item, city, branchOffice)
													)}
												/>
											)
									)
								)}
							</div>
						</>
					)
				}
			</div>
		</div>
	)
}

Subcategorydesktop.propTypes = {
	loading: PropTypes.bool,
	category: PropTypes.object,
	categoryId: PropTypes.number,
	city: PropTypes.string,
	branchOffice: PropTypes.string,
	subcategory: PropTypes.object,
	subcategoryId: PropTypes.number
}
export default Subcategorydesktop
