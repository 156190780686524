import PropTypes from 'prop-types'
import React from 'react'
import SummaryButton from '../atoms/buttons/SummaryButton'
import CircularLoader from '../loaders/CircularLoader'
import SummarySection from './SummarySection'

const Summary = (props) => {
	const {
		// flags
		loading,
		visualizationMode,
		hidePrice,
		// data
		actionLabel,
		sections,
		total,
		loadingOrder = false,
		// actions
		onClick
	} = props

	return (
		<div className='order-summary'>
			{loading ? (
				<CircularLoader />
			) : (
				<>
					{!visualizationMode && (
						<span className='text-21px-700 color-blue title'>Resumen</span>
					)}
					{sections.map((section, i) => (
						<SummarySection
							key={`section-${i}`}
							title={section.title}
							subtitle={section.subtitle}
							priceTitle={section.priceTitle}
							price={section.price}
							message={section.message}
							withBorder={section.withBorder}
						/>
					))}
					{!visualizationMode && (
						<SummaryButton
							hidePrice={hidePrice}
							onClick={onClick}
							actionLabel={actionLabel}
							price={total}
							disabled={loadingOrder}
						/>
					)}
				</>
			)}
		</div>
	)
}

Summary.propTypes = {
	//flags
	visualizationMode: PropTypes.bool,
	hidePrice: PropTypes.bool,
	loading: PropTypes.bool,
	loadingOrder: PropTypes.bool,
	// actions
	actionLabel: PropTypes.string,
	onClick: PropTypes.func,
	// data
	total: PropTypes.number.isRequired,
	sections: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			subtitle: PropTypes.any.isRequired,
			price: PropTypes.number.isRequired,
			priceTitle: PropTypes.string,
			message: PropTypes.string,
			withBorder: PropTypes.bool
		})
	)
}

Summary.defaultProps = {
	actionLabel: 'Ir a pagar',
	onClick: () => {}
}

export default Summary
