import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import CustomModal from '../CustomModal/CustomModal'
import CustomButton from '../atoms/buttons/CustomButton'
import getUserAge from '../../utils/age'
import useAuth from '../../hooks/auth/useAuth'

const LiquorValidationModal = (props) => {
	const {
		open,
		handleClose,
		onAccept,
		onDecline,
		errorMessage,
		isMobile
	} = props
	const { isLogged, authValues } = useAuth()
	
	const age = useMemo(() => {
		if (!isLogged) return 0
		const birth_date = authValues.birth_date ? authValues.birth_date : undefined
		return birth_date ? getUserAge(birth_date) : 0
	}, [authValues, isLogged])

	return (
		<CustomModal open={open} handleClose={handleClose} mobileSmall>
			<div
				className={
					isMobile
						? 'liquor-validation-modal-mobile'
						: 'liquor-validation-modal'
				}
			>
				<span
					className={` ${
						isMobile ? 'text-20px-700' : 'text-25px-700'
					} color-blue validation-title`}
				>
					¿Eres mayor de edad?
				</span>
				<span
					className={`${
						isMobile ? 'text-15px-700' : 'text-18px-700'
					} color-blue validation-title`}
				>
					Debes tener más de 18 años para adquirir este producto
				</span>
				<div className='law-messagge-content'>
					{
						age < 18 ? (
							<span className='text-12px-400 color-gray-light'>
								No puedes continuar con la compra, ya que no cumples con la edad mínima requerida.
								{age === 0 ? '' : ` ( registras ${age} Años)`}
							</span>
						) : (
							<span className='text-12px-400 color-gray-light'>
								Al continuar con la compra, confirmas que eres mayor de edad.
								( registras {age} Años)
							</span>
						)
					}
					<span className='text-12px-400 color-gray-light'>
						El exceso de alcohol es perjudicial para la salud. Ley 30 de 1986.
					</span>
					<span className='text-12px-400 color-gray-light'>
						Prohíbase el expendio de bebidas embriagantes a menores de edad y
						mujeres embarazadas. Ley 124 de 1994.
					</span>
				</div>
				<div className='btn-modal-content'>
					<div className='btn-modal'>
						<CustomButton
							label='Si'
							onClick={onAccept}
							width={isMobile ? '100px' : '141px'}
						/>
					</div>
					<div className='btn-modal'>
						<CustomButton
							label='No'
							onClick={onDecline}
							width={isMobile ? '100px' : '141px'}
						/>
					</div>
				</div>
				<span className='text-12px-400 error-message'>{errorMessage}</span>
			</div>
		</CustomModal>
	)
}

LiquorValidationModal.propTypes = {
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,

	setOpenAM: PropTypes.func,
	onAccept: PropTypes.func,
	onDecline: PropTypes.func,
	errorMessage: PropTypes.string,
	isMobile: PropTypes.bool
}

export default LiquorValidationModal
