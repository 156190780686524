import React from 'react'
import SectionHeader from '../../components/SectionHeader/SectionHeader'
import CustomButton from '../../components/atoms/buttons/CustomButton'
// hooks
import useFavorites from './useFavorites'
// constants
import { PRODUCTS_SECTION, MARKETS_SECTION } from './useFavorites'
// components
import FavoriteProducts from './FavoriteProducts/FavoriteProducts'
import FavoriteMarkets from './FavoriteMarkets/FavoriteMarkets'

const FavoritesDesktop = () => {
	const { renderSection, changeSection, section } = useFavorites()

	return (
		<div className='desktop-page-wrapper favorites-pages-desktop'>
			<div className=' favorites-header'>
				<SectionHeader title='Mis favoritos' />
				<div className='favorites-actions'>
					<CustomButton
						label='Productos'
						variant={section !== PRODUCTS_SECTION ? 'outlined' : 'filled'}
						onClick={() => changeSection(PRODUCTS_SECTION)}
					/>
					<CustomButton
						label='Mis mercados'
						variant={section !== MARKETS_SECTION ? 'outlined' : 'filled'}
						onClick={() => changeSection(MARKETS_SECTION)}
					/>
				</div>
			</div>
			{renderSection({
				[PRODUCTS_SECTION]: FavoriteProducts,
				[MARKETS_SECTION]: FavoriteMarkets
			})}
		</div>
	)
}

export default FavoritesDesktop
