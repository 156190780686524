import * as actionsTypes from './paymentCardsTypes'

/**
 * El estado inicial del almacén de Redux para la colección de tarjetas de pago.
 *
 * @constant
 * @type {Object}
 * @property {boolean} loading - Indica si se está cargando alguna operación relacionada con la colección de tarjetas de pago.
 * @property {string} error - La descripción del error si ocurre algún error durante una operación relacionada con la colección de tarjetas de pago.
 * @property {Object[]} cards - La lista de tarjetas de pago del usuario.
 * @default { loading: false, error: '', cards: null }
 */
export const initialState = {
	loading: false,
	error: '',
	cards: null
}

/**
 * El reductor de la colección de tarjetas de pago del usuario.
 *
 * @function
 * @param {Object} prevState - El estado actual del almacén de Redux para la colección de tarjetas de pago.
 * @param {Object} action - La acción que se ha despachado para actualizar el estado del almacén de Redux para la colección de tarjetas de pago.
 * @returns {Object} - El nuevo estado del almacén de Redux para la colección de tarjetas de pago.
 */
export const paymentCardsReducer = (prevState = initialState, action) => {
	switch (action.type) {
		case actionsTypes.GET_PAYMENTCARD:
			return {
				...prevState,
				loading: true,
				error: null
			}
		case actionsTypes.GET_PAYMENTCARD_SUCCESS:
			return {
				...prevState,
				loading: false,
				error: null,
				cards: action.cards
			}
		case actionsTypes.GET_PAYMENTCARD_FAILURE:
			return {
				...prevState,
				loading: false,
				error: action.error
			}
		case actionsTypes.CREATE_PAYMENTCARD:
			return {
				...prevState,
				loading: true,
				error: null
			}
		case actionsTypes.CREATE_PAYMENTCARD_SUCCESS:
			return {
				...prevState,
				loading: false,
				error: null,
				cards: [...prevState.cards, action.card]
			}
		case actionsTypes.CREATE_PAYMENTCARD_FAILURE:
			return {
				...prevState,
				loading: false,
				error: action.error
			}
		case actionsTypes.DELETE_PAYMENTCARD:
			return {
				...prevState,
				loading: true,
				error: null
			}
		case actionsTypes.DELETE_PAYMENTCARD_SUCCESS:
			return {
				...prevState,
				loading: false,
				error: null,
				cards: prevState.cards.filter((card) => card.id !== action.cardId)
			}
		case actionsTypes.DELETE_PAYMENTCARD_FAILURE:
			return {
				...prevState,
				loading: false,
				error: action.error
			}
		case actionsTypes.EDIT_PAYMENTCARD:
			return {
				...prevState,
				loading: true,
				error: null
			}
		case actionsTypes.EDIT_PAYMENTCARD_SUCCESS:
			return {
				...prevState,
				loading: false,
				error: null,
				cards: prevState.cards.map((card) => {
					if (card.id === action.cardId) return { ...card, ...action.data }
					return card
				})
			}
		case actionsTypes.EDIT_PAYMENTCARD_FAILURE:
			return {
				...prevState,
				loading: false,
				error: action.error
			}
		default:
			return prevState
	}
}
