import firebase from 'firebase/app'
import PropTypes from 'prop-types'
import React, { createContext, useEffect, useReducer } from 'react'
import { rootReducer, states } from './RootReducer'

import { showLoginModal, userGetData } from '../context/auth/authActions'

const context = createContext()

/**
 * Component that provides a context with a state and a dispatch function.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The child elements to render.
 * @returns {React.ReactNode} A React node that provides a context with a state and a dispatch function.
 */
const ContextProvider = ({ children }) => {
	const [state, dispatch] = useReducer(rootReducer, states)

	useEffect(() => {
		firebase.auth().onAuthStateChanged((user) => {
			if (!user) {
				showLoginModal(dispatch)
				return
			}
			userGetData(dispatch, user.uid)
		})
	}, [])

	return (
		<context.Provider value={{ state, dispatch }}>{children}</context.Provider>
	)
}

ContextProvider.propTypes = {
	children: PropTypes.node
}

ContextProvider.defaultProps = {
	children: null
}

export { ContextProvider, context }
