import {
	authReducer,
	initialState as authInitialState
} from './auth/authReducer'
import {
	configsReducer,
	initialState as configsInitialState
} from './configs/configsReducer'
import {
	initialState as layoutInitialState,
	layoutReducer
} from './layout/layoutReducer'
import {
	initialState as marketsInitialState,
	marketsReducer
} from './markets/marketsReducer'
import {
	initialState as offersInitialState,
	offersReducer
} from './offers/offersReducer'
import {
	initialState as ordersInitialState,
	ordersReducer
} from './orders/ordersReducer'
import {
	initialState as paymentCardsInitialState,
	paymentCardsReducer
} from './paymentCards/paymentCardsReducer'
import {
	initialState as productsInitialState,
	productsReducer
} from './products/productsReducer'
import {
	initialState as shoppingCartInitialState,
	shoppingCartReducer
} from './shoppingCart/shoppingCartReducer'
import {
	initialState as observationsState,
	observationsReducer
} from './observations/observationsReducer'

/**
 * Combines multiple reducer functions into a single reducer that can handle multiple slices of the state.
 *
 * @param {Object} slices - An object containing the reducer functions for each state slice.
 * @returns {Function} A reducer function that can handle multiple slices of the state.
 */
const combineReducers = (slices) => (prevState, action) =>
	Object.keys(slices).reduce(
		(nextState, nextProp) => ({
			...nextState,
			[nextProp]: slices[nextProp](prevState[nextProp], action)
		}),
		prevState
	)

// Reducers
const rootReducer = combineReducers({
	authReducer,
	marketsReducer,
	productsReducer,
	offersReducer,
	shoppingCartReducer,
	ordersReducer,
	configsReducer,
	layoutReducer,
	paymentCardsReducer,
	observationsReducer,
})

// Reducers initial state
const states = {
	authReducer: authInitialState,
	marketsReducer: marketsInitialState,
	productsReducer: productsInitialState,
	offersReducer: offersInitialState,
	shoppingCartReducer: shoppingCartInitialState,
	ordersReducer: ordersInitialState,
	configsReducer: configsInitialState,
	layoutReducer: layoutInitialState,
	paymentCardsReducer: paymentCardsInitialState,
	observationsReducer: observationsState,
}

export { rootReducer, states }
