import React from 'react'
import { isBrowser } from 'react-device-detect'
import ResetPasswordPageDesktop from './ResetPasswordPageDesktop'
import ResetPasswordPageMobile from './ResetPasswordPageMobile'

/**
 * Componente que representa la página de restablecimiento de contraseña para dispositivos móviles y de escritorio.
 * @param {Object} props - Propiedades para el componente.
 * @param {Function} props.handleTransition - Función para cambiar la animación de transición.
 * @param {string} props.to - URL de la página de destino.
 * @returns {React.ReactNode} Elemento React que contiene la página de restablecimiento de contraseña para dispositivos móviles y de escritorio.
 */
const ResetPasswordPage = (props) => {
	const ResetPasswordPageLayout = isBrowser
		? ResetPasswordPageDesktop
		: ResetPasswordPageMobile
	return <ResetPasswordPageLayout {...props} />
}

export default ResetPasswordPage
