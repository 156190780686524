import * as layoutActions from './layoutActionsTypes'

/**
 * El estado inicial del reducer de layout.
 * @type {{snack: {isVisible: boolean, message: string, severity: string}}}
 */
export const initialState = {
	snack: {
		isVisible: false,
		message: 'Message undefined',
		severity: 'info'
	}
}

/**
 * Reducer que maneja el estado de layout de la aplicación.
 * @param {{snack: {isVisible: boolean, message: string, severity: string}}} prevState - Estado anterior del reducer.
 * @param {Object} action - Acción enviada al reducer.
 * @returns {{snack: {isVisible: boolean, message: string, severity: string}}} - Nuevo estado del reducer.
 */
export const layoutReducer = (prevState = initialState, action) => {
	switch (action.type) {
		case layoutActions.SHOW_SNACKBAR:
			return {
				...prevState,
				snack: {
					isVisible: true,
					message: action.message,
					severity: action.severity
				}
			}
		case layoutActions.HIDE_SNACKBAR:
			return {
				...prevState,
				snack: {
					...prevState.snack,
					isVisible: false
				}
			}
		default:
			return prevState
	}
}
