import { useParams } from 'react-router'
import Direcciones from './Direcciones/Direcciones'
import EditarDatos from './EditarDatos/EditarDatos'
import Historial from './Historial/Historial'
import Mercados from './Mercados/Mercados'
import Tarjetas from './Tarjetas/Tarjetas'
// constants
import * as constants from '../../consts/AccountSections'

export default function useAccountDesktop() {
	const { section } = useParams()

	//const [selectedOption, setSelectedOption] = useState(section);

	const renderOption = () => {
		switch (section) {
			case constants.OPTION_HISTORIAL_PEDIDOS:
				return Historial
			case constants.OPTION_MERCADOS:
				return Mercados
			case constants.OPTION_TARJETAS:
				return Tarjetas
			case constants.OPTION_DIRECCIONES:
				return Direcciones
			case constants.OPTION_EDITAR_DATOS:
				return EditarDatos
			default:
				return null
		}
	}

	const isSelected = (option) => {
		return option === section
	}

	return {
		RenderedOption: renderOption(),
		isSelected
	}
}
