import React from 'react'

import backArrowButton from '../../assets/arrow-back.svg'
import { Link } from 'react-router-dom'
import { routes } from '../../routes/routes'

/**
 * Renderiza el componente NotFound Mobile en función del tipo de dispositivo.
 * @returns {React.ReactNode} Elemento React que contiene el componente NotFound.
 */
const NotFoundMobile = () => {
	return (
		<div className='mobile-page-wrapper notfound-page-mobile'>
			<h2 className='color-blue'>404</h2>
			<span className='text-16px-300 color-gray-light text-center'>
				Lo sentimos,
				<br />
				no encontramos lo que buscabas...
			</span>
			<Link to={routes.home.path} className='goBack text-decoration-none'>
				<img src={backArrowButton} alt='goBack' />
				<span className='text-16px-400 color-blue'>ir al inicio</span>
			</Link>
		</div>
	)
}

export default NotFoundMobile
