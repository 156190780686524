import React, { useEffect } from 'react'
import CreateMarketForm from '../../../../components/CreateMarketForm/CreateMarketForm'
import CircularLoader from '../../../../components/loaders/CircularLoader'
// hooks
import useAuth from '../../../../hooks/auth/useAuth'
import { useHistory } from 'react-router'
import useMarkets from '../../../../hooks/markets/useMarkets'

const CreateMarket = () => {
	const { authValues } = useAuth()
	const addresses = authValues.addresses

	const history = useHistory()

	const { loading } = useMarkets()

	useEffect(() => {
		if (addresses.length === 0) {
			throw new Error(
				'MercaZ Error: You need at least one address to create a market'
			)
		}
	}, [addresses])

	return (
		<div>
			{loading ? (
				<CircularLoader />
			) : (
				<CreateMarketForm onCreate={() => history.goBack()} />
			)}
		</div>
	)
}

export default CreateMarket
