import React, { useEffect } from 'react'
import { isBrowser } from 'react-device-detect'
import MercadosDesktop from './MercadosDesktop'
import MercadosMobile from './MercadosMobile'
// hooks
import useAuth from '../../../hooks/auth/useAuth'

const Mercados = () => {
	const { getCityStorage, authValues, showLoginModal } = useAuth()
	const city = getCityStorage()
	const { addresses } = authValues

	const props = {
		city,
		addresses
	}

	useEffect(() => {
		if (!city) showLoginModal()
	}, [])

	const MercadosLayout = isBrowser ? MercadosDesktop : MercadosMobile
	return <MercadosLayout {...props} />
}

export default Mercados
