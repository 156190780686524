import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import CustomModal from '../CustomModal/CustomModal'

import CircularLoader from '../loaders/CircularLoader'

import CustomButton from '../atoms/buttons/CustomButton'
import CustomPhoneInput from '../atoms/inputs/CustomPhoneInput'
import EditInputText from '../atoms/inputs/EditInputText'

const SignInWithPhoneModal = (props) => {
	const {
		showModalPhone,
		toggleSignInPhoneModal,
		handleLoginWithPhone,
		signInWithPhoneCodeVerification,
		handleClose,
		handleAuthValues,
		initializesignInWithPhone
	} = props

	const [isSendingCode, setIsSendingCode] = useState(false)
	const [phone, setPhone] = useState()
	// eslint-disable-next-line
	const [phoneError, setphoneError] = useState()
	const [messageError, setMessageError] = useState('')
	const [showButtonSesion, setshowButtonSesion] = useState(false)

	//controla el comportamiento de un componente en respuesta a cambios en la variable showModalPhone, realizando ciertas acciones cuando se muestra u oculta el modal del teléfono
	useEffect(() => {
		if (showModalPhone) initializesignInWithPhone()
		else if (!showModalPhone) {
			setshowButtonSesion(false)
			setPhone('')
		}
	}, [showModalPhone])

	return (
		<CustomModal
			open={showModalPhone == undefined ? false : showModalPhone}
			handleClose={toggleSignInPhoneModal}>
			<div
				className={`signInWithPhoneModal add-product-to-market-${
					isMobile ? 'mobile' : 'desktop'
				}`}>
				<p className='text-21px-700' style={{ color: '#164194' }}>
					Iniciar sesión
				</p>

				{!showButtonSesion && (
					<>
						<CustomPhoneInput
							variant='white'
							value={phone}
							onChange={() => {}}
							onBlur={(e) => {
								setPhone(e.target.value)
								handleAuthValues('phone', e.target.value)
							}}
							errorMessage={phoneError}
						/>
						{isSendingCode ? (
							<CircularLoader />
						) : (
							<CustomButton
								label='Continuar'
								buttonProps={{ type: 'submit' }}
								width='100%'
								onClick={() => {
									setIsSendingCode(true)
									handleLoginWithPhone((result) => {
										if (result.verificationId != '')
											setshowButtonSesion(!showButtonSesion)
										setIsSendingCode(false)
									})
								}}
							/>
						)}
					</>
				)}
				{showButtonSesion && (
					<>
						<p className='text-18px-400' style={{ color: '#7d7d7e' }}>
							Hemos enviado un SMS con tu código de validación de 6 digitos al
							teléfono {phone}, ingresalo a continuación para continuar
						</p>
						<p className='text-15px-400' style={{ color: '#8d8d8d' }}>
							El mensaje se puede demorar en llegar un par de minutos
						</p>
						<EditInputText
							// errorMessage={emailError}
							inputProps={{
								placeholder: 'Código',
								autoComplete: 'code'
								// autoFocus: true
							}}
							onBlur={(e) => handleAuthValues('code', e.target.value)}
						/>
						{messageError != '' && (
							<div
								style={{
									background: '#ffe1e1',
									borderRadius: '10px',
									padding: '0em 2em',
									marginBottom: '1em',
									display: 'grid',
									gridTemplateColumns: '2em 24em'
								}}>
								<div
									style={{
										textAlign: 'center',
										display: 'flex',
										alignItems: 'center',
										color: 'red'
									}}>
									!
								</div>
								<p style={{ color: '#814848', textAlign: 'justify' }}>
									Se produjo un error desconocido: {messageError}
								</p>
							</div>
						)}
						<CustomButton
							label='Iniciar sesión'
							buttonProps={{ type: 'submit' }}
							width='100%'
							onClick={() =>
								signInWithPhoneCodeVerification(
									(success) => {
										handleClose(success)
									},
									(error) => {
										if (error != '') setMessageError(error)
									}
								)
							}
						/>
					</>
				)}
			</div>
		</CustomModal>
	)
}

SignInWithPhoneModal.propTypes = {
	// flags
	showModalPhone: PropTypes.bool,
	//functions
	toggleSignInPhoneModal: PropTypes.func,
	handleLoginWithPhone: PropTypes.func,
	signInWithPhoneCodeVerification: PropTypes.func,
	handleClose: PropTypes.func,
	handleAuthValues: PropTypes.func,
	initializesignInWithPhone: PropTypes.func
}

export default SignInWithPhoneModal
