import { address } from './common'

const bags = {
	count: 0,
	price: 0
}

const status = {
	cancelled: null,
	created: null,
	dispatched: null,
	dispatched_username: '',
	dispatched_useruid: '',
	enlistment: null,
	enlistment_username: '',
	enlistment_useruid: '',
	finalized: null,
	finalized_username: '',
	finalized_useruid: '',
	payment: null,
	payment_failed: null,
	received: null,
	received_username: '',
	received_useruid: ''
}

export const productOrder = {
	bar_code: '',
	main_category_id: '',
	sub_category_id: '',
	content: '',
	count: 0,
	cut_type: null,
	id_item: '',
	maturity: null,
	name: '',
	observation: '',
	price: 0,
	price_neto: 0,
	pum: 0,
	quantity_pum: 1,
	range_quantity: 0,
	unit_gram: 0,
	unit_type: '',
	wholesaler: false,
	brand: '',
  provider: '',
  minimum_grams_sale: 0,
	subtotal: 0,
}

export const wholesalerProductOrder = {
	...productOrder,
	generalPrice: 0,
	countOptions: []
}

export const orderModel = {
	address: { ...address },
	bags: { ...bags },
	rating: 0,
	cupon: '',
	delivery: 0,
	delivery_type: 0,
	discounts: [],
	email_user: '',
	payment_gateway_data: {},
	final_price: 0,
	franquicia: '',
	id_order: '',
	mask: '',
	name_domicilliary: '',
	name_enlist: '',
	name_user: '',
	observation: '',
	online_payment_method: {},
	pay_method: 0,
	phone_user: '',
	price_total: 0,
	products_order: [],
	id_user_domicilliary: '',
	id_user_enlist: '',
	status: { ...status },
	type_method_pay: '',
	user_id: ''
}
