import db from '../../firebase/firestore'
import * as actionTypes from './offersActionsTypes'

/**
 * Carga todas las ofertas desde la colección de Firestore y las envía al store a través de un dispatch.
 * @param {Function} dispatch - Función que envía una acción al store.
 */
export const loadAllOffers = (dispatch) => {
	// console.log("TRAER OFERTAS DE FIREBASE")
	try {
		db.collection('offers')
			.orderBy('position')
			.where('status', '==',true)
			.get()
			.then((querySnapshot) => {
				const data = querySnapshot.docs.map((document) => document.data())
				// console.log(data)
				dispatch({
					type: actionTypes.LOAD_ALL_OFFERS_SUCCESS,
					value: data
				})
			})
	} catch (e) {
		console.log(e)
	}
}
