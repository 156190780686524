/* eslint-disable react/prop-types */
import PropTypes from 'prop-types'
import React, { useState, useMemo, useCallback } from 'react'
import ProductCardDesktop from '../../components/atoms/cards/ProductCard/ProductCardDesktop'
import getPriceByOffice from '../../utils/getPriceByOffice'
//hooks
import clsx from 'clsx'
import { memo, useEffect, useRef } from 'react'
import ProductCardMobile from '../../components/atoms/cards/ProductCard/ProductCardMobile'
import useAuth from '../../hooks/auth/useAuth'
import SearchWacoHunt from '../../utils/SearchWacoHunt'
// import ValidateStock from '../../utils/ValidateStock'

/**
 * Screen search component
 * @param {{
 * isMobile?:boolean
 * query:string
 * }} props
 * @returns
 */
const SearchResultDesktopMobile = ({ query, isMobile }) => {
  const [loading, setLoading] = useState(false)
  const [found, setFound] = useState(0)
  const [hits, setHits] = useState([])
  const [fetchMore, setFetchMore] = useState(true)
  const lastSearch = useRef({
    query: '',
    page: 0
  })
  const { getCityStorage, getCityBranchOffice } = useAuth()
  const city = useMemo(() => getCityStorage() || 'CALI', [getCityStorage])
  const branchOffice = useMemo(
    () => getCityBranchOffice(),
    [getCityBranchOffice]
  )

  useEffect(() => {
    lastSearch.current.page = 0
    setHits([])
    setFetchMore(true)
  }, [branchOffice])

  const getMoreData = useCallback(() => {
    setLoading(true)
    SearchWacoHunt("", {
      isActive: true,
      limit: 20,
      city: city,
      branch: branchOffice,
      discount: true,
      page: lastSearch.current.page + 1
    }).then((res) => {
      setFound(res.found)
      if (res.page > lastSearch.current.page) {
        if (!fetchMore) {
          return
        }
        if (res.page === res.nbPages) {
          setFetchMore(false)
        } 
        lastSearch.current.page = res.page
        setHits((prev) => [...prev, ...res.hits])
      } else {
        setHits(res.hits)
      }
    }).finally(() => {
      setLoading(false)
    })
  }, [city, branchOffice, fetchMore, loading])

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          //console.log('El elemento está visible en pantalla')
          if (!loading && fetchMore) {
            getMoreData(query)
          }
        } else {
          // console.log('El elemento no está visible en pantalla');
        }
      })
    })

    const elem = document.querySelector(
      '.searchResultDesktop__scroll__intersect'
    )
    observer.observe(elem)
    return () => {
      observer.unobserve(elem)
    }
  }, [getMoreData, query])

  useEffect(() => {
    if (query !== lastSearch.current.query) {
      lastSearch.current.query = query
      getMoreData(query)
    }
  }, [query])
  return (
    <div
      className={clsx(
        !isMobile && 'searchResultDesktop',
        isMobile && 'searchResultMobile'
      )}>
      <section className='category-section'>
        <div className='title'>
          <p
            className={clsx(
              !isMobile && 'text-21px-700 color-blue',
              isMobile && 'text-16px-300 color-blue'
            )}>
            { found || 0 }
            { " "}
            Resultados de las ofertas
          </p>
        </div>
        {loading && <div>
          Cargando...
        </div>}
        <div className='searchResultDesktop__scroll'>
          <Hits
            isMobile={isMobile}
            hits={hits}
            branchOffice={branchOffice}
            city={city}
          />
          <div className='searchResultDesktop__scroll__intersect'></div>
        </div>
      </section>
    </div>
  )
}

const Hits = ({ hits, branchOffice, city, isMobile }) => {
  // console.log("hits", hits);

  return (
    <div className='containerProducts'>
      {!hits.length && (
        <div style={{ height: '200px' }}>No se encontraron resultados</div>
      )}
      {hits.length > 0 &&
        hits.map((product) => (
          <React.Fragment key={`ProductCardDesktop__${product.id_item}`}>
            {!isMobile && (
              <ProductCardDesktop
                productId={product.id_item}
                categoryId={product.main_category_id}
                product={product}
                branch={product.price_branch_offices.find((item) =>
                  getPriceByOffice(item, city, branchOffice)
                )}
              />
            )}
            {isMobile && (
              <ProductCardMobile
                productId={product.id_item}
                categoryId={product.main_category_id}
                product={product}
                branch={product.price_branch_offices.find((item) =>
                  getPriceByOffice(item, city, branchOffice)
                )}
              />
            )}
          </React.Fragment>
        ))}
    </div>
  )
}

Hits.propTypes = {
  hits: PropTypes.array.isRequired,
  branchOffice: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired
}

export default memo(SearchResultDesktopMobile, (prev, next) => {
  console.log('prev', prev, next)

  return prev.query === next.query
})
