import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import CardDireccion from '../../../../components/CardDireccion/CardDireccion'
import ShoppingCartSummary from '../../../../components/Summaries/ShoppingCartSummary'
import InputCupon from '../../../../components/atoms/inputs/InputCupon'

import useAuth from '../../../../hooks/auth/useAuth'
import useConfigs from '../../../../hooks/configs/useConfigs'
import * as cartSteps from '../../../../hooks/shoppingCart/cartSteps'
import useShoppingCart from '../../../../hooks/shoppingCart/useShoppingCart'
import useAddress from '../../../../hooks/user/useAddress'
/**
 * Componente que muestra un resumen del carrito de compras y permite seleccionar una dirección y aplicar un cupón de descuento.
 *
 * @param {Object} props - Las propiedades del componente.
 * @param {function} props.transitionTo - Una función que permite cambiar el estado del componente padre para avanzar al siguiente paso del proceso de compra.
 * @returns {JSX.Element} Elemento JSX que representa el resumen del carrito de compras.
 */
const CartResumen = ({ transitionTo }) => {
	const [addressSelected, SetAddressSelected] = useState()
	const [validCupon, setValidCupon] = useState(undefined)

	const { data } = useConfigs()

	const { setCupon } = useShoppingCart()

	const { authValues } = useAuth()
	const { addresses } = authValues

	const { getAddressTemplateCopy } = useAddress()

	const address = getAddressTemplateCopy()

	const handleSelectAddress = () => {
		addresses.forEach((address, index) => {
			if (authValues.selected_address == index) {
				SetAddressSelected(address)
			}
		})
	}

	useEffect(() => handleSelectAddress())

	//cupon
	useEffect(() => {
		setCupon(validCupon)
	}, [validCupon])

	const PageContent = () => (
		<div className='cart-resumen-mobile'>
			<span className='text-21px-700 color-blue resumen-title'>Tu pedido</span>
			{addressSelected && addresses.length > 0 ? (
				<CardDireccion
					nameDireccion={addressSelected.name}
					direccion={addressSelected}
					timeEstimate='26 - 41 min'
					onUpdate={() => transitionTo(cartSteps.DIRECCIONES)}
				/>
			) : (
				<CardDireccion nameDireccion='Cargando..' direccion={address} />
			)}
			{/* <div className='selected-payment-method'>
				<span className='text-21px-700 color-blue'>Método de pago</span>
			
				<PaymentMethod
					lastDigits='6784'
					type={paymentTypes.methods.CREDIT_CARD}
					cardType={paymentTypes.cards.MASTER_CARD}
					onSecondaryAction={() => transitionTo(cartSteps.PAGOS)}
				/>
			</div> */}
			<div className='selected-payment-method'>
				<span className='text-21px-700 color-blue'>Agregar Cupón</span>
				<InputCupon setCupon={setValidCupon} />
			</div>
			<div className='summary-section'>
				<span className='text-21px-700 color-blue'>Resumen</span>
				<ShoppingCartSummary
					visualizationMode
					loading={!data}
					validCupon={validCupon}
					isMobile
				/>
			</div>
		</div>
	)

	return (
		<>
			<PageContent />
		</>
	)
}

CartResumen.propTypes = {
	transitionTo: PropTypes.func.isRequired
}

export default CartResumen
