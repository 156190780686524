import React from 'react'
import PropTypes from 'prop-types'
import {
	FormControlLabel,
	makeStyles,
	Radio,
	RadioGroup
} from '@material-ui/core'
import { isMobile } from 'react-device-detect'
// consts
import * as meatCutTypes from '../../../consts/MeatCutsTypes'

const MeatCutTypes = ({ cutType, setCutType }) => {
	const useStyles = makeStyles({
		root: {
			flexDirection: 'row',
			gap: `${isMobile ? '0' : '2'}rem`
		}
	})
	const classes = useStyles()

	const handleChange = (e) => {
		setCutType(e.target.value)
	}

	return (
		<div className='maturity'>
			<span className='text-14px-400 color-gray-light'>Tipo de porcionado</span>
			<RadioGroup
				name='cutType'
				classes={classes}
				value={cutType}
				onChange={handleChange}
			>
				<FormControlLabel
					value={meatCutTypes.MARIPOSA.code}
					control={<Radio />}
					label={
						<span className='text-12px-400 color-gray-light'>
							{meatCutTypes.MARIPOSA.label}
						</span>
					}
				/>
				<FormControlLabel
					value={meatCutTypes.MOLIDA.code}
					control={<Radio />}
					label={
						<span className='text-12px-400 color-gray-light'>
							{meatCutTypes.MOLIDA.label}
						</span>
					}
				/>
				<FormControlLabel
					value={meatCutTypes.TAJADA.code}
					control={<Radio />}
					label={
						<span className='text-12px-400 color-gray-light'>
							{meatCutTypes.TAJADA.label}
						</span>
					}
				/>
				<FormControlLabel
					value={meatCutTypes.ENTERO.code}
					control={<Radio />}
					label={
						<span className='text-12px-400 color-gray-light'>
							{meatCutTypes.ENTERO.label}
						</span>
					}
				/>
			</RadioGroup>
		</div>
	)
}

MeatCutTypes.propTypes = {
	cutType: PropTypes.string.isRequired,
	setCutType: PropTypes.func.isRequired
}

export default MeatCutTypes
