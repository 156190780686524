import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'

/**
 * Componente que renderiza una flecha de navegación hacia la izquierda.
 *
 * @returns {JSX.Element} El componente a renderizar.
 */
function LeftArrow({ onClick }) {
  const [showLeftArrow, setShowLeftArrow] = useState(false)

  useEffect(() => {
    const timeoutRef = setTimeout(() => {
      setShowLeftArrow(true)
    }, 1000)

    return () => clearTimeout(timeoutRef)
  }, [])

  return (
    <div
      style={{
        position: 'absolute',
        left: 0,
        display: showLeftArrow ? 'block' : 'none'
      }}
      onClick={onClick}
      className={`arrows-container left-arrow`}>
      <i className='arrow -blue -left'></i>
    </div>
  )
}

/**
 * Componente que renderiza una flecha de navegación hacia la derecha.
 *
 * @returns {JSX.Element} El componente a renderizar.
 */
function RightArrow({ onClick }) {
  return (
    <div
      onClick={onClick}
      style={{
        position: 'absolute',
        right: 0
      }}
      className={`arrows-container right-arrow`}>
      <i className='arrow -blue -right'></i>
    </div>
  )
}
/**
 *
 * @param {{
 *   arrayItems: Array,
 *  hideArrows?: Boolean
 * }} props
 * @returns
 */
function CustomScrollMenu({
  children
  // hideArrows
}) {
  const menuRef = useRef(null)

  const scrollLeft = () => {
    if (menuRef.current) {
      menuRef.current.scrollTo({
        left: menuRef.current.scrollLeft - 300,
        behavior: 'smooth'
      })
    }
  }

  const scrollRight = () => {
    if (menuRef.current) {
      menuRef.current.scrollTo({
        left: menuRef.current.scrollLeft + 300,
        behavior: 'smooth'
      })
    }
  }

  return (
    <div
      style={{
        position: 'relative',
        overflow: 'hidden',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 20px',
        boxSizing: 'border-box',
        WebkitOverflowScrolling: 'touch'
      }}
      className='slider-products'
    >
      <LeftArrow onClick={scrollLeft} />
      <div ref={menuRef} style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
        {/* Replace this with your menu items */}
        {/* {arrayItems.map((item, index) => (
          <div key={index} style={{ display: 'inline-block', padding: '10px' }}>{item}</div>
        ))} */}
        {/* parse all children items and encapsulate */}
        {Array.isArray(children) &&
          children.map((child, index) => (
            <div
              key={index}
              style={{ display: 'inline-block', paddingLeft: '5px' }}>
              {child}
            </div>
          ))}
      </div>
      <RightArrow onClick={scrollRight} />
    </div>
  )
}

CustomScrollMenu.propTypes = {
  children: PropTypes.node,
  hideArrows: PropTypes.bool
}

export default CustomScrollMenu
