import useValidator from '../../hooks/validator/useValidator'
import useAuth from '../../hooks/auth/useAuth'
import { useState } from 'react'

const useResetPasswordPage = (handleOpen, openErrorModal) => {
	const [emailError, setEmailError] = useState('')

	const { validateEmail } = useValidator()
	const { authValues, handleAuthValues, handleResetPassword } = useAuth()

	const cleanErrorMessages = () => {
		setEmailError('')
	}

	const validated = () => {
		let emailValid = validateEmail({
			email: authValues.email,
			setEmailError: setEmailError
		})
		return emailValid
	}

	/* const validate = () => {
        console.log(authValues.email);
        return validateEmail(authValues.email, setEmailError);
    } */

	const reset = async (e) => {
		e.preventDefault()
		cleanErrorMessages()

		if (validated()) {
			const exists = await handleResetPassword()

			if (exists) handleOpen()
			else openErrorModal()
		}
	}

	return {
		emailError,
		reset,
		//authValues
		handleAuthValues
	}
}

export default useResetPasswordPage
