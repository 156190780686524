import { TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import PropTypes from 'prop-types'
import React from 'react'
// styles
import { makeStyles } from '@material-ui/core'

/**
 * Hook que define los estilos de un campo de autocompletado.
 * @function useAutoCompleteStyles
 * @param {Object} theme - El tema actual de Material UI.
 * @returns {Object} - Objeto con los estilos de un campo de autocompletado.
 */
const useAutoCompleteStyles = makeStyles((theme) => ({
	inputRoot: {
		backgroundColor: theme.palette.primary.light,
		height: '2.5rem',
		borderRadius: '30px'
	},
	input: {
		padding: '0px !important',
		fontFamily: 'Lexend',
		color: theme.palette.textColor.light
	}
}))

/**
 * Hook que define los estilos para un campo de entrada.
 * @function useInputStyles
 * @param {Object} theme - El tema actual de Material UI.
 * @returns {Object} - Objeto con los estilos para un campo de entrada.
 */
const useInputStyles = makeStyles((theme) => ({
	root: {
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: theme.palette.borderColor
			},
			'&:hover fieldset': {
				borderColor: theme.palette.borderColor
			},
			'&.Mui-focused fieldset': {
				border: `1px solid ${theme.palette.borderColor}`
			}
		}
	}
}))

/**
 * Componente que crea un campo de autocompletado personalizado utilizando la librería `Autocomplete` de Material-UI
 * @param {Object} props - Propiedades del componente
 * @param {Array} props.options - Opciones a mostrar en el campo de autocompletado
 * @param {string} props.label - Etiqueta del campo de autocompletado
 * @param {Object} props.value - Valor actual del campo de autocompletado
 * @param {Function} props.onChange - Función que se ejecuta cuando se cambia el valor del campo de autocompletado
 * @returns {JSX.Element} Componente de React
 */
const CustomAutoComplete = (props) => {
	const autoCompleteClasses = useAutoCompleteStyles()

	const inputClasses = useInputStyles()

	const { options, label, value, onChange } = props

	return (
		<div className='auto-complete'>
			<span className='text-10px-400 color-gray label'>{label}</span>
			<Autocomplete
				classes={{
					inputRoot: autoCompleteClasses.inputRoot,
					input: autoCompleteClasses.input
				}}
				noOptionsText='Sin opciones'
				fullWidth
				options={options}
				value={value}
				onChange={onChange}
				getOptionLabel={(option) => option.title}
				renderInput={(params) => (
					<TextField
						className={inputClasses.root}
						{...params}
						variant='outlined'
					/>
				)}
			/>
		</div>
	)
}

CustomAutoComplete.propTypes = {
	options: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			value: PropTypes.object.isRequired
		})
	),
	label: PropTypes.string.isRequired,
	value: PropTypes.object,
	onChange: PropTypes.func.isRequired
}

export default CustomAutoComplete
