import React from 'react'
import CartNavigation from './CartNavigation/CartNavigation'
import * as cartSteps from '../../../hooks/shoppingCart/cartSteps'

import useShoppingCart from '../../../hooks/shoppingCart/useShoppingCart'

//steps
import CartProducts from './Productos/CartProducts'
import OrderFinished from '../../OrderFinished/OrderFinished'
import CartDirecciones from './Direcciones/CartDirecciones'
import CartPagos from './Pagos/OrderPagos'
import CartResumen from './Resumen/CartResumen'
/**
 * Componente que representa la vista móvil del carrito de compras, que muestra diferentes pasos del proceso de compra.
 * Utiliza el hook `useShoppingCart` para obtener el estado actual y la función para cambiar el estado del carrito de compras.
 * 
 * @returns {JSX.Element} Elemento JSX que representa la vista móvil del carrito de compras.
 */
const CartMobile = () => {
	const { currentStep, setCurrentStep } = useShoppingCart()

	const renderStep = () => {
		switch (currentStep) {
			case cartSteps.PRODUCTOS:
				return <CartProducts transitionTo={setCurrentStep} />
			case cartSteps.DIRECCIONES:
				return <CartDirecciones transitionTo={setCurrentStep} />
			case cartSteps.PAGOS:
				return <CartPagos transitionTo={setCurrentStep} />
			case cartSteps.RESUMEN:
				return <CartResumen transitionTo={setCurrentStep} />
			case cartSteps.FINALIZADO:
				return <OrderFinished transitionTo={setCurrentStep} />
			default:
				return <CartProducts transitionTo={setCurrentStep} />
		}
	}

	return (
		<div className='mobile-page-wrapper'>
			{currentStep !== cartSteps.PRODUCTOS && <CartNavigation />}
			{renderStep()}
		</div>
	)
}

export default CartMobile
