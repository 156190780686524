import PropTypes from 'prop-types'
import React from 'react'
// material ui
import { FormControlLabel, Switch } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

// Define estilos personalizados del componente Switch
const useStyles = makeStyles((theme) => ({
	root: {
		width: 42,
		height: 26,
		padding: 0,
		margin: theme.spacing(1)
	},

	switchBase: {
		padding: 1,
		'&$checked': {
			transform: 'translateX(16px)',
			color: theme.palette.common.white,
			'& + $track': {
				backgroundColor: theme.palette.primary.main,
				opacity: 1,
				border: 'none'
			}
		},
		'&$focusVisible $thumb': {
			color: theme.palette.primary.main,
			border: '6px solid #fff'
		}
	},
	thumb: {
		width: 24,
		height: 24
	},
	track: {
		borderRadius: 26 / 2,
		boxSizing: 'border-box',
		border: `1px solid ${theme.palette.borderColor}`,
		backgroundColor: theme.palette.primary.light,
		opacity: 1,
		transition: theme.transitions.create(['background-color', 'border'])
	},
	checked: {},
	focusVisible: {}
}))

// Define estilos personalizados del componente Form
const useFormStyle = makeStyles({
	root: {
		marginLeft: 0
	}
})

/**
 * Un componente Switch personalizado con un label que se puede personalizar
 *
 * @param {Object} props - Propiedades del componente
 * @param {string} props.label - Label del switch
 * @param {boolean} props.checked - Estado actual del switch
 * @param {function} props.onChange - Función llamada cuando cambia el switch
 * @returns {JSX.Element} - El componente Switch personalizado
 */
const CustomSwitch = (props) => {
	const { label, checked, onChange } = props

	const classes = useStyles()
	const formControlClasses = useFormStyle()

	return (
		<FormControlLabel
			classes={formControlClasses}
			control={
				<Switch
					disableRipple
					checked={checked}
					onChange={onChange}
					classes={classes}
				/>
			}
			label={<span className='text-10px-400 color-gray'>{label}</span>}
		/>
	)
}

CustomSwitch.propTypes = {
	label: PropTypes.string.isRequired,
	checked: PropTypes.bool.isRequired,
	onChange: PropTypes.func.isRequired
}

export default CustomSwitch
