export const DOCUMENT_TYPE_CALLE = 'Calle'
export const DOCUMENT_TYPE_CARRERA = 'Carrera'
export const DOCUMENT_TYPE_AVENIDA = 'Avenida'
export const DOCUMENT_TYPE_AVENIDA_CARRERA = 'Avenida Carrera'
export const DOCUMENT_TYPE_AVENIDA_CALLE = 'Avenida Calle'
export const DOCUMENT_TYPE_CIRCULAR = 'Circular'
export const DOCUMENT_TYPE_CIRCUNVALAR = 'Circunvalar'
export const DOCUMENT_TYPE_DIAGONAL = 'Diagonal'
export const DOCUMENT_TYPE_MANZANA = 'Manzana'
export const DOCUMENT_TYPE_TRANSVERSAL = 'Transversal'
export const DOCUMENT_TYPE_VIA = 'Via'

export const DOCUMENT_TYPE_CITY_CALI = 'Cali'
export const DOCUMENT_TYPE_CITY_TUMACO = 'Tumaco'
export const DOCUMENT_TYPE_CITY_MEDELLIN = 'Medellin'

export const DOCUMENT_TYPE_COMP = ''
export const DOCUMENT_TYPE_COMP_A = 'a'
export const DOCUMENT_TYPE_COMP_B = 'b'
export const DOCUMENT_TYPE_COMP_C = 'c'
export const DOCUMENT_TYPE_COMP_D = 'd'
