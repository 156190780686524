import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { isBrowser } from 'react-device-detect'

/**
 * `SmallTransparentSelect` es un componente de React que muestra una lista de opciones
 * en un `select` con estilo personalizado.
 *
 * @param {Object} props - Propiedades del componente.
 * @param {Array} props.options - Las opciones a mostrar en el `select`.
 * @param {Object} props.selectProps - Otras propiedades para el `select`.
 * @returns {JSX.Element} - El componente `SmallTransparentSelect`.
 */
const SmallTransparentSelect = ({ options, selectProps }) => {
	const [open, setOpen] = useState(false)
	const toggleOpen = () => setOpen(!open)

	return (
		<div className='small-transparent-select'>
			<select
				{...selectProps}
				defaultValue={options[0].value}
				className={`arrow-${open && isBrowser ? 'up' : 'down'}`}
				onClick={toggleOpen}>
				{options.map((option, i) => (
					<option value={option.value} key={`option-${i}`}>
						{option.label}
					</option>
				))}
			</select>
		</div>
	)
}

SmallTransparentSelect.propTypes = {
	options: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.any,
			label: PropTypes.string
		})
	),
	selectProps: PropTypes.object
}

export default SmallTransparentSelect
