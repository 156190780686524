import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
// hooks
import useUserPhoto from './useUserPhoto'
// material ui
import EditIcon from '@material-ui/icons/Edit'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Skeleton } from '@material-ui/lab'

const useStyles = makeStyles(() => ({
	icon: {
		color: 'white'
	},
	spinner: {
		color: '#164194'
	}
}))

const UserPhoto = () => {
	const classes = useStyles()
	const {
		browseFileInput,
		browse,
		userImage,
		imageHandler,
		progress,
		loading
	} = useUserPhoto()

	return (
		<div
			className='user-photo'
			onClick={browse}
			aria-label='Editar foto de perfil'
			title='Editar foto de perfil'
		>
			{loading ? (
				<Skeleton variant='circle' width={108} height={108} animation='wave' />
			) : (
				<div className='imageContainer' style={{ backgroundImage: `url(${userImage})` }} />
			)}
			<input
				type='file'
				name='image-upload'
				accept='image/*'
				ref={browseFileInput}
				onChange={imageHandler}
			/>
			{progress ? (
				<CircularProgress
					variant='determinate'
					value={progress}
					className={`progress-indicator ${classes.spinner}`}
				/>
			) : (
				<EditIcon className={`edit-photo ${classes.icon}`} />
			)}
		</div>
	)
}

export default UserPhoto
