import React, { useEffect, useState } from 'react'
// hooks
import { useParams } from 'react-router'
import useAuth from '../../../../hooks/auth/useAuth'
import useMarkets from '../../../../hooks/markets/useMarkets'
// components
import ProductListMarket from '../../../../components/ProductListMarket/ProductListMarket'
import CircularLoader from '../../../../components/loaders/CircularLoader'
import CustomButton from '../../../../components/atoms/buttons/CustomButton'
import LoadMarketFloatingCard from './LoadMarketFloatingCard'
// modals
import AddProductToMarketModal from '../../../../components/Modals/AddProductToMarketModal'
// material
import { Box } from '@material-ui/core'

const MarketProducts = () => {
	// add product modal control variables
	const [openAddProduct, setOpenAddProduct] = useState(false)

	const { marketId } = useParams()

	const { getCityStorage, getCityBranchOffice, showLoginModal } = useAuth()
	const city = getCityStorage()
	const branchOffice = getCityBranchOffice()

	const { loading, findMarket, getFullMarketProducts } = useMarkets()

	const market = findMarket(marketId)

	const [fullProducts, setFullProducts] = useState(null)

	useEffect(() => {
		if (!city) showLoginModal()
		// load market products
		getFullMarketProducts(market.products, city, branchOffice).then(
			(result) => {
				setFullProducts(result)
			}
		)
	}, [market])

	return (
		<>
			{!market || !fullProducts || loading ? (
				<CircularLoader />
			) : (
				<>
					<ProductListMarket
						city={city}
						title='Productos'
						marketId={marketId}
						resumeLabel='Productos'
					/>
					<Box marginTop='2rem'>
						<AddProductToMarketModal
							marketId={market.id}
							open={openAddProduct}
							handleClose={() => setOpenAddProduct(false)}
						/>
						<CustomButton
							label='Agregar productos'
							variant='outlined'
							width='100%'
							onClick={() => setOpenAddProduct(true)}
						/>
					</Box>
					{market.products.length !== 0 && (
						<LoadMarketFloatingCard marketId={marketId} city={city} />
					)}
				</>
			)}
		</>
	)
}

export default MarketProducts
