import axios from 'axios';
/**
 *
 * @param {string} query
 * @param {{
 * isActive?:boolean,
 * limit?:number,
 * page?:number,
 * city?:string
 * }} options options to search into wacohunt
 * @returns {Promise<{
 *  hits:any[],
 *  nbPages:number,
 *  found:number, 
 *  limit:number,
 *  page:number
 * }>}
 */
export default async function SearchWacoHunt(query, options = {}) {
  // let branch = `${options.branch || ""}`
  // branch = branch.toLowerCase();
  // branch = branch.replaceAll(" ", "_")
  
  let filters = ''
  if (options.isActive) {
    filters = `(isActive:true) && (isHidden:false)`
  }
  if (options.city && filters)
    filters = `${filters} && (stock_city_${options.city.toLowerCase()}:>0)`
  if (options.city && !filters)
    filters = `(stock_city_${options.city.toLowerCase()}:>0)`
  if (options?.discount > 0 && options.branch) {
    let name = options.branch.trim().toLowerCase().replaceAll(" ", "_")
    if(name === "tumaco") {
      name = "sucre"
    } else if (name === "cali") {
      name = "chipichape"
    }
    
    if (name === "medellin") {
      // normalizar para medellin
      filters = `${filters ? filters + ' && ' : ""} (discount_branch_santa_lucia:>0||discount_branch_guayabal:>0)`
    } else {
      filters = `${filters ? filters + ' && ' : ""} (discount_branch_${name}:>0)`
    }
  }

  let params = {
    filter: filters
  }
  if (options.limit) params.limit = options.limit

  if (options.limit) params.limit = options.limit

  if (options.page) params.page = options.page

  if (query) params.search = query
  // console.log("waco hunt options", query, options);

  return axios
    .get(process.env.REACT_APP_SEARCH_HOST, {
      params,
      headers: {
        'Content-Type': 'application/json',
        'x-wacohunt-api-key': process.env.REACT_APP_SEARCH_HOST_API
      }
    })
    .then((res) => {
      // console.log("res", res.data.data);
      const { hits, found, limit, page } = res.data.data || []
      let nbPages = 0
      if (found && limit) nbPages = Math.ceil(found / limit)

      return {
        hits,
        nbPages,
        found,
        limit,
        page
      }
    })
    .catch((err) => {
      console.log('WacoHunt err', err)
      return []
    })
}
