export const LOAD_ALL_CATEGORIES = 'LOAD_ALL_CATEGORIES'
export const LOAD_ALL_CATEGORIES_SUCCESS =
	'PRODUCTS_LOAD_ALL_CATEGORIES_SUCCESS'
export const LOAD_ALL_CATEGORIES_FAILURE =
	'PRODUCTS_LOAD_ALL_CATEGORIES_FAILURE'

export const LOAD_CATEGORY_PROD = 'PRODUCTS_LOAD_CATEGORY_PROD'
export const LOAD_CATEGORY_PROD_SUCCESS = 'PRODUCTS_LOAD_CATEGORY_PROD_SUCCESS'
export const LOAD_CATEGORY_PROD_FAILURE = 'PRODUCTS_LOAD_CATEGORY_PROD_FAILURE'

export const ALL_CATEGORIES_FULL_LOADED = 'ALL_CATEGORIES_FULL_LOADED'

export const ADD_PRODUCT_TO_CATEGORY_CONTEXT = 'ADD_PRODUCT_TO_CATEGORY_CONTEXT'

//compare modal
export const OPEN_COMPARE_MODAL = 'OPEN_COMPARE_MODAL'
export const CLOSE_COMPARE_MODAL = 'CLOSE_COMPARE_MODAL'

//market modal
export const OPEN_MARKET_MODAL = 'OPEN_MARKET_MODAL'
export const CLOSE_MARKET_MODAL = 'CLOSE_MARKET_MODAL'

export const CLEAN_SUBCATEGORY_PRODUCTS = 'CLEAN_SUBCATEGORY_PRODUCTS'
