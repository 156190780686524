import React, { useEffect } from 'react'
// components
import CircularLoader from '../../../../components/loaders/CircularLoader'
import CreateMarketForm from '../../../../components/CreateMarketForm/CreateMarketForm'
// hooks
import useMarkets from '../../../../hooks/markets/useMarkets'
import { useHistory, useParams } from 'react-router'
import useAuth from '../../../../hooks/auth/useAuth'

const EditMarket = () => {
	const { authValues } = useAuth()
	const addresses = authValues.addresses

	const { marketId } = useParams()
	const history = useHistory()

	const { markets, loading } = useMarkets()

	const market = markets
		? markets.find((market) => market.id === marketId)
		: null

	useEffect(() => {
		if (addresses.length === 0) {
			throw new Error(
				'MercaZ Error: You need at least one address to edit a market'
			)
		}
	}, [addresses])

	return (
		<>
			{!market || loading ? (
				<CircularLoader />
			) : (
				<CreateMarketForm market={market} onEdit={() => history.goBack()} />
			)}
		</>
	)
}

export default EditMarket
