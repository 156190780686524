import React from 'react'
import PropTypes from 'prop-types'
import AlertModal from '../AlertModal/AlertModal'

const AddressValidationModal = ({ open, handleClose, actionToValidate }) => {
	return (
		<AlertModal
			open={open}
			handleClose={handleClose}
			message={`Debes registrar al menos una dirección antes de ${actionToValidate}`}
			acceptLabel='Aceptar'
			acceptAction={handleClose}
		/>
	)
}

AddressValidationModal.propTypes = {
	open: PropTypes.bool,
	handleClose: PropTypes.func.isRequired,
	actionToValidate: PropTypes.string
}

AddressValidationModal.defaultProps = {
	actionToValidate: 'realizar esta acción'
}

export default AddressValidationModal
