import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Summary from './Summary'
import sectionGenerator from './sectionGenerator'
// utils
import TotalToPay from '../../utils/TotalToPay'

const OrderSummary = (props) => {
	const { generalProducts, wholesalerProducts, delivery, total } = props

	const generalTotal = TotalToPay(generalProducts)
	const wholesalerTotal = TotalToPay(wholesalerProducts)

	const subTotal = generalTotal + wholesalerTotal

	const [totalWholesalerProducts, setTotalWholesalerProducts] = useState(0)

	useEffect(() => {
		const sumall = wholesalerProducts
			.map((product) => product.count)
			.reduce((prev, curr) => prev + curr, 0)//calcula la suma de la propiedad "count" de cada objeto en el arreglo 
		setTotalWholesalerProducts(sumall) //actualiza el estado de la variable 
	}, [wholesalerProducts]) //se ejecuta  cada vez que cambia el valor de "wholesalerProducts"

	const [totalGeneralProducts, setTotalGeneralProducts] = useState(0)

	useEffect(() => {
		const sumall = generalProducts
			.map((product) => product.count)
			.reduce((prev, curr) => prev + curr, 0) //se obtiene un arreglo con todas las propiedades count
		setTotalGeneralProducts(sumall) //actualiza el estado con el nuevo valor de la suma total
	}, [wholesalerProducts]) // se ejecutará cuando la variable wholesalerProducts cambie

	const sections = []
	sections.push(
		sectionGenerator({
			title: 'Generales',
			subtitle: `${totalGeneralProducts} item${totalGeneralProducts > 1 ? 's' : ''
				}`,
			price: generalTotal,
			withBorder: true
		})
	)
	sections.push(
		sectionGenerator({
			title: 'Mayorista',
			subtitle: `${totalWholesalerProducts} item${totalWholesalerProducts > 1 ? 's' : ''
				}`,
			price: wholesalerTotal,
			withBorder: true
		})
	)
	sections.push(
		sectionGenerator({
			title: 'Subtotal',
			price: subTotal,
			message: 'Incluye los productos generales y mayoristas',
			withBorder: true
		})
	)
	sections.push(
		sectionGenerator({
			title: 'Costo de envío',
			price: delivery,
			withBorder: true
		})
	)
	sections.push(
		sectionGenerator({
			title: 'Total',
			price: total
		})
	)

	return <Summary
		visualizationMode
		sections={sections} total={total} />
}

OrderSummary.propTypes = {
	generalProducts: PropTypes.array.isRequired,
	wholesalerProducts: PropTypes.array.isRequired,
	delivery: PropTypes.number.isRequired,
	total: PropTypes.number.isRequired
}

export default OrderSummary
