import React from 'react'
import LockIcon from '@material-ui/icons/Lock'
import CustomButton from '../../components/atoms/buttons/CustomButton'
import { isMobile } from 'react-device-detect'
// hooks
import useAuth from '../../hooks/auth/useAuth'

const LoginMessage = () => {
	const { showLoginModal } = useAuth()

	return (
		<div className={`card cart-message ${isMobile ? '-mobile' : ''}`}>
			<LockIcon color='primary' fontSize='large' />
			<span className='message text-15px-400 color-gray'>
				Debes iniciar sesión para continuar con tu compra
			</span>
			<CustomButton
				color='yellow'
				label='Iniciar sesión'
				onClick={() => {
					showLoginModal()
				}}
			/>
		</div>
	)
}

export default LoginMessage
