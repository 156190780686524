import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'
import FloatingSummary from '../../../../components/FloatingSummary/FloatingSummary'
// my components
import CardDireccion from '../../../../components/CardDireccion/CardDireccion'
//import FormAddress from '../../../../components/FormAddress/FormAddress'

import * as cardDireccionTypes from '../../../../components/CardDireccion/cardDireccionTypes'
import * as AccountSections from '../../../../consts/AccountSections'
import * as cartSteps from '../../../../hooks/shoppingCart/cartSteps'
import { routeFormatter } from '../../../../routes/routes'
// hooks
import { useHistory } from 'react-router'
import useAuth from '../../../../hooks/auth/useAuth'
import useShoppingCart from '../../../../hooks/shoppingCart/useShoppingCart'

const CartDirecciones = ({ transitionTo }) => {
	const [selectedAddress, setSelectedAddress] = useState(0)
	const { cartTotal } = useShoppingCart()

	const history = useHistory()

	const { authValues, updateUserData } = useAuth()
	const { addresses } = authValues

	const formRef = useRef(null)
	const scrollToForm = () => {
		formRef.current.scrollIntoView({ behavior: 'smooth' })
	}

	const PageContent = () => (
		<div className='cart-direcciones-mobile'>
			<span className='text-21px-700 color-blue direcciones-title'>
				Dirección de entrega
			</span>
			<div className='cart-direccion-content'>
				{/* Direcciones del usuario */}
				{addresses.length > 0 ? (
					addresses.map((address, index) => (
						<CardDireccion
							key={`CardDireccion-${index}`}
							cardType={cardDireccionTypes.CART_SELECTION_MODE}
							nameDireccion={address.name}
							direccion={address}
							timeEstimate='26 - 41 min'
							isSelected={selectedAddress === index}
							onClick={() => {
								setSelectedAddress(index)
								updateUserData({ selected_address: index })
							}}
							onUpdate={() => {
								history.push(
									routeFormatter.toAccount(AccountSections.OPTION_DIRECCIONES)
								)
							}}
						/>
					))
				) : (
					<CardDireccion
						cardType={cardDireccionTypes.ADD_MODE}
						nameDireccion='No tienes direciones de entrega registradas'
						onUpdate={() => {
							//history.push(routeFormatter.toAccount(AccountSections.OPTION_DIRECCIONES));
							scrollToForm()
						}}
					/>
				)}

				{/* Modificar direccion */}
				{/* <div ref={formRef}>
					<FormAddress isMobile />
				</div> */}
			</div>
		</div>
	)
	return (
		<>
			<PageContent />
			{/* Resumen de compra que redirige a pagos */}
			{addresses.length === 0 ? (
				<FloatingSummary
					price={cartTotal}
					disabled={true}
					buttonLabel='Método de pago'
					onClick={() => {}}
					message={
						<span className='text-11px-400 color-gray-light'>
							Para continuar debes registrar al menos una dirección
						</span>
					}
				/>
			) : (
				<FloatingSummary
					price={cartTotal}
					disabled={cartTotal === 0}
					buttonLabel='Método de pago'
					onClick={() => transitionTo(cartSteps.PAGOS)}
				/>
			)}
		</>
	)
}

CartDirecciones.propTypes = {
	transitionTo: PropTypes.func.isRequired
}

export default CartDirecciones
