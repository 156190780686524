import { WEIGHT } from "../consts/ProductCountOptions"

export const productPerKilogram = (canSelectUnitType,productData) => {
  if (canSelectUnitType) {
    return productData.unit_type === WEIGHT.value
  } else {
    return false
  }
}

// validar si el producto aplica medida por gramos y unidades
export const checkIfProductAppliPerKilogramOnly = (productData) => {
  return productData?.main_category_id === "03" 
    && productData?.sub_category_id === "0303"
    && productData?.unit_type === WEIGHT.value
}