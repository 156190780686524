import { useRef } from 'react'
// resources
import defaultImage from '../../../../assets/user-icon.svg'
// Hooks
import useStorage from '../../../../hooks/storage/useStorage'
import useAuth from '../../../../hooks/auth/useAuth'

const useUserPhoto = () => {
	const browseFileInput = useRef()

	const { updateProfileImage, progress } = useStorage()
	const { authValues, loading } = useAuth()
	const userImage = authValues.photo || defaultImage

	const browse = () => {
		if (progress) return
		browseFileInput.current.click()
	}

	const imageHandler = (e) => {
		const reader = new FileReader()
		// no se selecciona archivo
		if (!e.target.files.length) return

		const file = e.target.files[0]
		reader.onload = () => {
			if (reader.readyState === FileReader.DONE) {
				if (authValues.ID) {
					updateProfileImage(file, authValues.ID, file.type)
				}
			}
		}
		reader.readAsDataURL(file)
	}

	return {
		progress,
		browse,
		browseFileInput,
		userImage,
		imageHandler,
		loading: !authValues.ID || loading
	}
}

export default useUserPhoto
