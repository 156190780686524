import { useContext } from 'react'
import * as authActions from '../../context/auth/authActions'
import { context } from '../../context/ContextProvider'
// routing
import { useHistory } from 'react-router'
import { routes } from '../../routes/routes'

export default function useAuth() {
	//navigation
	const history = useHistory()

	const { state, dispatch } = useContext(context)

	const {
		authValues,
		askToLogin,
		loading,
		userDeletedModal,
		currentCity
	} = state.authReducer

	const isLogged = () => {
		return Boolean(authValues.ID)
	}

	/**
	 * Opens login modal
	 */
	const showLoginModal = () => {
		authActions.showLoginModal(dispatch)
	}
	/**
	 * Hide login modal
	 */
	const hideLoginModal = () => {
		authActions.hideLoginModal(dispatch)
	}
	/**
	 * Opens a modal indicating that the user have been deleted
	 * and session must be closed
	 */
	const showUserDeletedModal = () => {
		authActions.showUserDeletedModal(dispatch)
	}
	/**
	 * Close user deleted modal
	 */
	const hideUserDeledModal = () => {
		authActions.hideUserDeledModal(dispatch)
	}

	const handleAuthValues = (id, value) => {
		authActions.handleAuthValues(dispatch, id, value)
	}

	/**
 * Inicia sesión utilizando un correo electrónico y una contraseña, llama a onSuccess en caso de éxito o a onFailure en caso de fallo.
 * 
 * @param {string} password - Contraseña del usuario
 * @param {function} onSuccess - Función que se ejecuta en caso de éxito
 * @param {function} onFailure - Función que se ejecuta en caso de fallo
 */
	const handleLogin = (
		password,
		onSuccess = () => { },
		onFailure = () => { }
	) => {
		authActions.signInWithEmailAndPassword(
			dispatch,
			authValues.email,
			password,
			(document) => {
				const currentShoppingCart = authValues.shopping_cart
				if (currentShoppingCart.length > 0) {
					authActions.updateUserData(
						dispatch,
						{ shopping_cart: currentShoppingCart },
						document.ID
					)
				}
				onSuccess(document)
			},
			onFailure
		)
	}

	/**
 * Inicia sesión utilizando una cuenta de Google, llama a onSuccess en caso de éxito o a onFailure en caso de fallo.
 * 
 * @param {function} onSuccess - Función que se ejecuta en caso de éxito
 * @param {function} onFailure - Función que se ejecuta en caso de fallo
 */
	const handleLoginWithGoogle = (
		onSuccess = () => { },
		onFailure = () => { }
	) => {
		authActions.signInWithGoogle(
			dispatch,
			(document) => {
				const currentShoppingCart = authValues.shopping_cart
				if (currentShoppingCart.length > 0) {
					authActions.updateUserData(
						dispatch,
						{ shopping_cart: currentShoppingCart },
						document.ID
					)
				}
				onSuccess(document)
			},
			onFailure
		)
	}

	/**
 * Inicia sesión utilizando una cuenta de Facebook, llama a onSuccess en caso de éxito o a onFailure en caso de fallo.
 * 
 * @param {function} onSuccess - Función que se ejecuta en caso de éxito
 * @param {function} onFailure - Función que se ejecuta en caso de fallo
 */
	const handleLoginWithFacebook = (
		onSuccess = () => { },
		onFailure = () => { }
	) => {
		authActions.signInWithFacebook(
			dispatch,
			(document) => {
				const currentShoppingCart = authValues.shopping_cart
				if (currentShoppingCart.length > 0) {
					authActions.updateUserData(
						dispatch,
						{ shopping_cart: currentShoppingCart },
						document.ID
					)
				}
				onSuccess(document)
			},
			onFailure
		)
	}

	/**
 * Inicia sesión utilizando un número de teléfono, llama a onSuccess en caso de éxito o a onFailure en caso de fallo.
 * 
 * @param {function} onSuccess - Función que se ejecuta en caso de éxito
 * @param {function} onFailure - Función que se ejecuta en caso de fallo
 */
	const handleLoginWithPhone = (onSuccess = () => { }, onFailure = () => { }) => {
		authActions.signInWithPhone(
			dispatch,
			authValues.phone,
			(document) => {
				const currentShoppingCart = authValues.shopping_cart
				if (currentShoppingCart.length > 0) {
					authActions.updateUserData(
						dispatch,
						{ shopping_cart: currentShoppingCart },
						document.ID
					)
				}
				onSuccess(document)
			},
			onFailure
		)
	}

	const initializesignInWithPhone = () => {
		authActions.initializesignInWithPhone()
	}

	/**
 * Inicia sesión con verificación de código telefónico.
 *
 * @param {Function} onSuccess Función a ejecutar en caso de éxito.
 * @param {Function} onFailure Función a ejecutar en caso de fallo.
 */
	const signInWithPhoneCodeVerification = (
		onSuccess = () => { },
		onFailure = () => { }
	) => {
		authActions.signInWithPhoneCodeVerification(
			dispatch,
			authValues.code,
			(document) => {
				const currentShoppingCart = authValues.shopping_cart
				if (currentShoppingCart.length > 0) {
					authActions.updateUserData(
						dispatch,
						{ shopping_cart: currentShoppingCart },
						document.ID
					)
				}
				onSuccess(document)
			},
			(error) => {
				onFailure(error)
			}
		)
	}

	const handleRegister = (
		password,
		onSuccess = () => { },
		onFailure = () => { }
	) => {
		authActions.createUserWithEmailAndPassword(
			password,
			dispatch,
			authValues,
			onSuccess,
			onFailure
		)
	}

	const handleResetPassword = async () => {
		return await authActions.passwordReset(dispatch, authValues.email)
	}

	const handleCity = (city) => {
		localStorage.setItem('cityStorage', city)
		authActions.setCurrentCity(dispatch, city)
	}

	const handleStore = (store) => {
		localStorage.setItem('storeStorage', store)
	}

	const getCityStorage = () => {
		let city = localStorage.getItem('cityStorage')
		if (
			authValues.addresses.length > 0 &&
			authValues.addresses[authValues.selected_address]
		) {
			city = authValues.addresses[authValues.selected_address].city
		} else if (!city) {
			city = ""
		}
		localStorage.setItem('cityStorage', city)
		//
    return city
	}

	const getCityBranchOffice = () => {
		let branchOffice = localStorage.getItem('branchOffice')
		let city = localStorage.getItem('cityStorage')

		if (authValues.addresses.length > 0) {
			branchOffice = authValues.addresses[authValues.selected_address].branch
			localStorage.setItem('branchOffice', branchOffice)
		} else {
			if (!branchOffice) {
				if (city) {
					if (city.toLocaleLowerCase() === "cali") {
            localStorage.setItem('branchOffice', 'Chipichape')
					} else if (city.toLocaleLowerCase() === "tumaco") {
						localStorage.setItem('branchOffice', 'Tumaco')
					} else if (city.toLocaleLowerCase() === "medellin") {
						localStorage.setItem('branchOffice', 'Medellin')
					}
				} else {
					localStorage.setItem('branchOffice', '')
					return ''
				}
			}
		}
		return branchOffice
	}

	const getStoreStorage = () => {
		let store = localStorage.getItem('storeStorage')
		if (!store) {
			localStorage.setItem('storeStorage', '')
			return ''
		}
		return store
	}

	const updateUserData = (userData) => {
		return authActions.updateUserData(dispatch, userData, authValues.ID)
	}

	/**
	 * This function is used to log out an user
	 */
	const handleLogOut = async () => {
		history.push(routes.home.path)
		await authActions.signOut(dispatch)
		localStorage.removeItem('cityStorage')
		localStorage.removeItem('storeStorage')
		localStorage.removeItem('branchOffice')
		showLoginModal()
	}

	return {
		// flags
		isLogged: isLogged(),
		askToLogin,
		loading,
		userDeletedModal,
		// modals
		showLoginModal,
		hideLoginModal,
		showUserDeletedModal,
		hideUserDeledModal,
		// actions
		handleLogOut,
		handleAuthValues,
		handleLogin,
		handleLoginWithGoogle,
		handleLoginWithFacebook,
		handleLoginWithPhone,
		initializesignInWithPhone,
		signInWithPhoneCodeVerification,
		handleRegister,
		handleResetPassword,
		handleCity,
		getCityStorage,
		getCityBranchOffice,
		handleStore,
		getStoreStorage,
		updateUserData,
		// data
		authValues,
		currentCity
	}
}
