import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import { fb as firebase } from './firebase-config'

export const functions = firebase.functions("us-east1")
// functions.useEmulator("127.0.0.1", 5001)
export const auth = firebase.auth()
const db = firebase.firestore()
export default db
