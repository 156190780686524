import React from 'react'

//libraries and components
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Layout from './components/layout/Layout'
import { ContextProvider } from './context/ContextProvider'

//Hooks
import { useErrorBoundary } from 'use-error-boundary'

//Pages
import NotFound from './pages/404/NotFound'
import Account from './pages/Account/Account'
import Cart from './pages/Cart/Cart'
import Category from './pages/Category/Category'
import ErrorPage from './pages/ErrorPage/ErrorPage'
import Favorites from './pages/Favorites/Favorites'
import Home from './pages/Home/Home'
import LoginPage from './pages/LoginPage/LoginPage'
import OrderFinished from './pages/OrderFinished/OrderFinished'
import Product from './pages/Product/Product'
import RegisterPage from './pages/RegisterPage/RegisterPage'
import ResetPasswordPage from './pages/ResetPasswordPage/ResetPasswordPage'
import SearchResult from './pages/Search/SearchResult'
import Subcategory from './pages/Subcategory/Subcategory'

import { routes } from './routes/routes'
import Offers from './pages/Offers/Offers'

/**
 * Componente principal de la aplicación web.
 * Utiliza el hook `useErrorBoundary` para manejar errores y el componente `BrowserRouter` para el enrutamiento.
 * También utiliza el componente `ContextProvider` para proporcionar contexto a los componentes hijos.
 *
 * @returns {JSX.Element} Elemento JSX que representa la aplicación web.
 */
function App() {
	const { ErrorBoundary, didCatch, error } = useErrorBoundary()
	return (
		<BrowserRouter>
			<ContextProvider>
				{didCatch ? (
					<ErrorPage error={error} />
				) : (
					<ErrorBoundary>
						<Layout>
							<Switch>
								<Route exact path={routes.home.path} component={Home} />
								<Route exact path={routes.category.path} component={Category} />
								<Route exact path={routes.subcategory.path} component={Subcategory} />
								<Route exact path={routes.account.path} component={Account} />
								<Route path={routes.accountSection.path} component={Account} />
								<Route exact path={routes.login.path} component={LoginPage} />
								<Route exact path={routes.register.path} component={RegisterPage} />
								<Route exact path={routes.resetpassword.path} component={ResetPasswordPage} />
								<Route exact path={routes.cart.path} component={Cart} />
								<Route exact path={routes.orderFinished.path} component={OrderFinished} />
								<Route path={routes.product.path} component={Product} />
								<Route exact path={routes.favorites.path} component={Favorites} />
								<Route exact path={routes.searchResult.path} component={SearchResult} />
								<Route exact path={routes.offers.path} component={Offers} />
								<Route component={NotFound} />
							</Switch>
						</Layout>
					</ErrorBoundary>
				)}
			</ContextProvider>
		</BrowserRouter>
	)
}

export default App
