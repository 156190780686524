import React, { useEffect, useState } from 'react'

//components
import { Box, Grid } from '@material-ui/core'
import CustomButton from '../../components/atoms/buttons/CustomButton'
import ProductCardDesktop from '../../components/atoms/cards/ProductCard/ProductCardDesktop'
import CustomModal from '../../components/CustomModal/CustomModal'
import ProductoGeneral from '../ProductList/ProductoGeneral'

//resources

//hooks
import { useLocation } from 'react-router-dom'
import useAuth from '../../hooks/auth/useAuth'
import useProducts from '../../hooks/products/useProducts'
import getPriceByOffice from '../../utils/getPriceByOffice'
import { productImageBaseUrl } from '../../utils/HasImage'

const Compareproductmodal = () => {
	const { compareModal, toggleCompareModal, comparedProduct, getProductData } =
		useProducts()
	const { getCityStorage, getCityBranchOffice } = useAuth()
	const city = getCityStorage() || 'Cali'
	const branchOffice = getCityBranchOffice()
	const [substituteProds, setsubstituteProds] = useState([])
	const location = useLocation()


	//realiza una operación asíncrona para recopilar datos de varios productos y actualizar el estado del componente en función de esos datos.
	useEffect(async () => {
		if (compareModal && comparedProduct.substitutes) {
			let fullProducts = await Promise.all(
				comparedProduct.substitutes.map(
					async (product) =>
						await getProductData(product.categoryId, product.id)
				)
			)
			setsubstituteProds(fullProducts)
		}
	}, [comparedProduct])


	//se utiliza para controlar la visualización de un modal de comparación en función de los cambios en la ubicación. Si la ubicación cambia y compareModal es verdadero, se muestra el modal.
	useEffect(() => {
		if (compareModal) {
			toggleCompareModal()
		}
	}, [location])
	return (
		<>
			<CustomModal open={compareModal} handleClose={toggleCompareModal}>
				<div className='compareProductModal'>
					<h1 className='text-21px-700 color-blue title'>Comparar</h1>
					<Grid container spacing={1}>
						<Grid item md={6} style={{ width: '100%' }}>
							<Box display='flex' justifyContent='center'>
								{compareModal && (
									<ProductCardDesktop
										productId={comparedProduct.id_item}
										categoryId={comparedProduct.main_category_id}
										product={comparedProduct}
										branch={comparedProduct.price_branch_offices.find((item) =>
											getPriceByOffice(item, city, branchOffice)
										)}
										hasCompare={false}
									/>
								)}
							</Box>
						</Grid>
						<Grid item md={6} style={{ width: '100%' }}>
							<div className=' substitute-list custom-scrollbar'>
								{compareModal && comparedProduct.substitutes ? (
									<>
										{substituteProds.map((product) => {
											return (
												<div
													key={`pGeneral-${product.id_item}`}
													className='product'>
                          <ProductoGeneral
                            product={product}
														productId={product.id_item}
														categoryId={product.main_category_id}
														subcategoryId={product.sub_category_id}
														name={product.name}
														price={
															product.price_branch_offices.find((item) =>
																getPriceByOffice(item, city, branchOffice)
															).price
														}
														unitType={product.unit_type}
														img={
															productImageBaseUrl + product.id_item}
														uneditable={true}
														rangeQuantity={product.quantity_pum}
													/>
												</div>
											)
										})}
									</>
								) : (
									<div className='text-center'>
										No hay productos para comparar con este producto
									</div>
								)}
							</div>
						</Grid>
					</Grid>
				</div>
				<Box
					display='flex'
					justifyContent='center'
					alignItems='center'
					className='mt-3 mb-3'>
					<CustomButton label={'Cerrar'} onClick={toggleCompareModal} />
				</Box>
			</CustomModal>
		</>
	)
}

export default Compareproductmodal
