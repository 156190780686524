import React from 'react'
import PropTypes from 'prop-types'
import { useHistory, useParams } from 'react-router-dom'
// hooks
import useAuth from '../../hooks/auth/useAuth'
// my components
import UserInfo from './UserInfo/UserInfo'
import MenuOption from './MenuOption/MenuOption.js'
// constants
import * as options from '../../consts/AccountSections'
import { routes } from '../../routes/routes'
// account sections
import Direcciones from './Direcciones/Direcciones'
import EditarDatos from './EditarDatos/EditarDatos'
import Historial from './Historial/Historial'
import Mercados from './Mercados/Mercados'
import Tarjetas from './Tarjetas/Tarjetas'

/**
 * Creates a mobile version of the account component with the necessary user information and options.
 * @returns {JSX.Element} - Returns a React element representing the mobile version of the account component.
 */
const AccountMobile = () => {
	const { section } = useParams()

	return (
		<div className='account-container-mobile mobile-page-wrapper'>
			<div className='account-body'>
				{section ? <RenderedSection section={section} /> : <PrincipalMenu />}
			</div>
		</div>
	)
}

const PrincipalMenu = () => {
	const { handleLogOut } = useAuth()

	const history = useHistory()

	const logOut = () => {
		handleLogOut()
		history.push(routes.home.path)
	}

	return (
		<div className='account-menu'>
			<UserInfo />
			<div className='account-navigation'>
				<MenuOption
					label='Mis direcciones'
					option={options.OPTION_DIRECCIONES}
				/>
				{/* <MenuOption label='Mis tarjetas' option={options.OPTION_TARJETAS} /> */}
				<MenuOption label='Mis mercados' option={options.OPTION_MERCADOS} />
				<MenuOption
					label='Historial de pedidos'
					option={options.OPTION_HISTORIAL_PEDIDOS}
				/>
			</div>
			<button className='btn-account-logout' onClick={logOut}>
				<span>Cerrar sesión</span>
				<i className='arrow -white' />
			</button>
		</div>
	)
}

const RenderedSection = ({ section }) => {
	const componentToRender = () => {
		switch (section) {
			case options.OPTION_HISTORIAL_PEDIDOS:
				return Historial
			case options.OPTION_MERCADOS:
				return Mercados
			case options.OPTION_TARJETAS:
				return Tarjetas
			case options.OPTION_DIRECCIONES:
				return Direcciones
			case options.OPTION_EDITAR_DATOS:
				return EditarDatos
			default:
				return Historial
		}
	}

	const SelectedSection = componentToRender()

	return (
		<div className='account-component'>
			<SelectedSection />
		</div>
	)
}

RenderedSection.propTypes = {
	section: PropTypes.string.isRequired
}

export default AccountMobile
