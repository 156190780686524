import React, { useEffect } from 'react'
import { isBrowser } from 'react-device-detect'
import useAuth from '../../../hooks/auth/useAuth'
import useProducts from '../../../hooks/products/useProducts'
import AppBarDesktop from './AppBarDesktop'
import AppBarMobile from './AppBarMobile'

/**
 * La función AppBar es un componente que selecciona entre AppBarDesktop
 * y AppBarMobile según la variable isBrowser,
 * y luego renderiza el componente seleccionado.
 * @returns {JSX.Element}
 */
const AppBar = () => {
	const { /*categories,*/ loading, loadCategories } = useProducts()
	const { getCityBranchOffice } = useAuth()
	const branchOffice = getCityBranchOffice()

  useEffect(() => {
    if (!loading && branchOffice) {
			loadCategories(branchOffice)
		}
	}, [branchOffice])

	const AppBarLayout = isBrowser ? AppBarDesktop : AppBarMobile
	return <AppBarLayout />
}

export default AppBar
