import React, {useEffect, useMemo, useState} from 'react'
import { Link, useHistory } from 'react-router-dom'
// import domizLogo from '../../../assets/domiz.svg'
import domizLogo from '../../../assets/logomercaz1.png'
import { routeFormatter, routes } from '../../../routes/routes'
// hooks
import { useLocation } from 'react-router'
import useProducts from '../../../hooks/products/useProducts'
import useShoppingCart from '../../../hooks/shoppingCart/useShoppingCart'
import useStringFormater from '../../../hooks/stringFormater/useStringFormater'
import useAppBar from './useAppBar'
// my components
import CartMenu from '../../CartMenu/CartMenu'
import SelectAddressModal from '../../SelectAddressModal/SelectAddressModal'

import { Skeleton } from '@material-ui/lab'
import useAuth from '../../../hooks/auth/useAuth'
import CustomScrollMenu from '../../CustomScrollMenu/CustomScrollMenu'
import WacoHuntSearch from '../../WacoHuntSearch/WacoHuntSearch'
import CustomButton from '../../atoms/buttons/CustomButton'

/**
 * Componente de AppBar para Desktop
 *
 * @returns {JSX.Element} - AppBarDesktop
 */
const AppBarDesktop = () => {
	const {
		address,
		menuAnchor,
		closeMenu,
		openMenu,
		changeAddress,
		isLogged,
		showLoginModal,
		openAM,
		setOpenAM
	} = useAppBar()

	const { authValues } = useAuth()
	const history = useHistory()
	const location = useLocation()
	const { categories } = useProducts()
	const { formatPrice } = useStringFormater()
	const { products, cartTotal } = useShoppingCart()
	const [totalItemsInCart, setTotalItemsInCart] = useState(0)

	const [selected, setSelected] = useState([])

	/**
	 * Verifica si un elemento está seleccionado
	 * @param {string} id - El id del elemento a verificar
	 * @returns {boolean} - Verdadero si el elemento está seleccionado, falso en caso contrario
	 */
	const isItemSelected = (id) => !!selected.find((el) => el === id)
	/**
	 * Verifica si la ruta actual se encuentra en la misma categoría que la ruta de la categoría proporcionada
	 * @returns {boolean} - Verdadero si la ruta actual está en la misma categoría, falso en caso contrario
	 */
	const isInCategory =
		routes.category.path.split('/')[1] === location.pathname.split('/')[1]

	/**
	 * Genera una lista de categorías con información adicional para mostrar en la página
	 * @returns {Object[]} - Un arreglo de objetos con información sobre cada categoría
	 */
	const cats = useMemo(() => {
		return categories
			.filter((item) => item.name.trim() !== '')
			.map((cat) => {
				let firstLetter = cat.name[0].toUpperCase()
				return {
					id: cat.id,
					position: cat?.position || Number.MAX_SAFE_INTEGER,
					name: firstLetter + cat.name.toLowerCase().substring(1),
					subcategories_data: cat.subcategories_data,
					isSelected: !isInCategory
						? false
						: cat.id === location.pathname.split('/')[2]
				}
			}).sort((a, b) => a.position - b.position)
	}, [categories])

	/**
	 * Manejador para el evento de clic en un elemento
	 * @param {string} id - El id del elemento en el que se hizo clic
	 * @param {Object} functions - Objeto que contiene funciones útiles para el manejador
	 */
	const handleClick =
		(id) =>
			(/*{ getItemById, scrollToItem }*/) => {
				const itemSelected = isItemSelected(id)

				setSelected((currentSelected) =>
					itemSelected
						? currentSelected.filter((el) => el !== id)
						: currentSelected.concat(id)
				)
			}

	/**
	 * Redirecciona al usuario a la página de cuenta del sitio
	 */
	const goToAccount = () => {
		if (!isLogged) return showLoginModal()
		history.push(routeFormatter.toAccount())
	}

	/**
	 * Redirecciona al usuario a la página de favoritos del sitio
	 */
	const goToFavorites = () => {
		if (!isLogged) return showLoginModal()
		history.push(routes.favorites.path)
	}

	/**
	 * Componente de esqueleto para mostrar mientras se carga una categoría
	 * @returns {JSX.Element} - El componente de esqueleto
	 */
	const CategorySkeleton = () => (
		<div>
			<Skeleton
				variant='rect'
				animation='wave'
				width={'5rem'}
				style={{
					borderRadius: 30,
					marginLeft: 20
				}}
			/>
		</div>
	)

	/**
	 * Obtiene la posición izquierda de un elemento
	 * @param {string} classContent - La clase del elemento
	 * @returns {number} - La posición izquierda del elemento
	 */
	const getLeft = (classContent) => {
		let content = document.getElementsByClassName(classContent)
		if (content) {
			const windowWidth = document.body.clientWidth
			const linkDimentions = content[0] ? content[0].getBoundingClientRect() : null;
			if (!linkDimentions) return
			// left
			if (windowWidth - linkDimentions.left < 160) {
				return (
					linkDimentions.left -
					((linkDimentions.left - linkDimentions.width * 2) /
						linkDimentions.left) *
					150
				)
				// right
			} else if (linkDimentions.left < -30) {
				return linkDimentions.left + linkDimentions.width / 2
			} else return linkDimentions.left
		}
		return 0
	}

	useEffect(() => {
		const sumall = products
			.map((product) => product.count)
			.reduce((prev, curr) => prev + curr, 0)
		setTotalItemsInCart(sumall)
	}, [products])


	return (
		<>
			<SelectAddressModal
				open={openAM}
				handleClose={() => setOpenAM(false)}
				acceptLabel='Aceptar'
				setOpenAM={setOpenAM}
			/>
			<header className='appBarDesktop'>
				<div className='topBar'>
					<div className='logo'>
						<Link to={routes.home.path} className='logo'>
							<img src={domizLogo} alt='logo_merca_z' />
						</Link>
					</div>
					<WacoHuntSearch />
					<div className='select'>
						<CustomButton
							label='Ofertas'
							buttonProps={{ type: 'button' }}
							width='100%'
							iconClass={`pi pi-tags`}
							iconFirst={true}
							onClick={() =>
								history.push('/offers')
							}
						/>
					</div>
					<div className='menu'>
						<div className='actions'>
							<div className='address' onClick={changeAddress}>
								<i className='location-icon -white'></i>
								<span className='text-14px-600'>{address}</span>
							</div>
							<div className='divider'>
								<hr />
							</div>
							<div className='favorites' onClick={goToFavorites}>
								<i className='heart -white-outlined'></i>
								<span className='text-14px-600'>Favoritos</span>
							</div>
							<div className='divider'>
								<hr />
							</div>
							<div className='account' onClick={goToAccount}>
								<span className='text-14px-600'>
									{authValues.name || 'Mi Cuenta'}
								</span>
								<i className='person -white-outlined'></i>
							</div>
							<div className='cart' onClick={openMenu}>
								<div className='icon'>
									{totalItemsInCart !== 0 && (
										<span className='item-counter text-10px-500'>
											{totalItemsInCart}
										</span>
									)}
									<i className='cart-icon -blue'></i>
								</div>
								<div className='total'>
									<span className='text-12px-600 color-gray-light'>Subtotal</span>
									<span className='text-14px-600 color-blue'>
										{formatPrice(cartTotal)}
									</span>
								</div>
							</div>
							<CartMenu anchor={menuAnchor} onClose={closeMenu} />
						</div>
					</div>
				</div>
				<div className='categoriesBar'>
					{cats.length > 0 ? (
						<CustomScrollMenu>
							{cats
								.map((item, i) => (
									<div key={`CustomScrollMenu_${i}`}
										 className={` categoriesShowSubmenu`}
										 onMouseEnter={() => {
											 let posLeft = getLeft(`itemid-${i}`)
											 let subMenu = document.querySelector(`.subm-${i}`)
											 subMenu.style.left = posLeft + 'px'
											 subMenu.style.display = 'block'
											 subMenu.style.position = 'fixed'
										 }}
										 onMouseLeave={() => {
											 let subMenu = document.querySelector(`.subm-${i}`)
											 subMenu.style.display = 'none'
										 }}>
										<Link
											to={routeFormatter.toCategory(item.id)}
											key={`itemid-${i}`}
											// itemId={`itemid-${i}`}
											onClick={() => handleClick(i)}
											selected={isItemSelected(i)}
											className={`categories-item -${item.isSelected ? 'selected' : 'unselected'
											} text-decoration-none itemid-${i}`}>
											<img src={`/icons/navbar/${item.name}.svg`} alt='' />
											<span
												className={`text-14px-500 color-${item.isSelected ? 'white' : 'gray'
												} text-nowrap`}>
												{item.name}
											</span>
										</Link>
										<div className={`subMenuList subm-${i}`}>
											<ul>
												{item.subcategories_data
													.filter(subc =>
														(typeof subc.isActive !== "undefined" && subc.isActive) ||
														typeof subc.isActive === "undefined"
													)
													.filter((subC) => {
														return (`${subC.subCategoryName}`).trim()
													})
													.map((subcategory) =>  {
														return {
															...subcategory,
															position: subcategory?.position || Number.MAX_SAFE_INTEGER
														}
													})
													.sort((a, b) => a.position - b.position)
													// .sort((a, b) => a.subcategory - b.subcategory)
													.map((subC, index) => {
														return (
															<li
																key={index}
																onClick={() => {
																	isItemSelected(i)
																	history.push(
																		routeFormatter.toCategory(subC.category)
																	)
																	setTimeout(() => {
																		history.push(
																			routeFormatter.toSubcategory(
																				subC.category,
																				subC.subcategory
																			)
																		)
																	}, 100)
																}}>
																<p>{subC.subCategoryName}</p>
															</li>
														)
													})}
											</ul>
										</div>
									</div>
								))}
						</CustomScrollMenu>
					) : (
						<div className='skeletons'>
							{Array(20)
								.fill(0)
								.map((_, i) => (
									<CategorySkeleton key={`cat-skltn-${i}`} />
								))}
						</div>
					)}
				</div>
			</header>
		</>
	)
}

export default AppBarDesktop
