const useStringFormater = () => {
  /**
   * Formats date in format
   * @param {Date} date
   * @returns
   */
  const formatDate = (date) => {
    return new Date(date.toDate()).toLocaleString('es-CO')
  }

  /**
   * Formats price adding points and dollar symbol
   * @param {number} price
   */
  const formatPrice = (price) => {
    return '$' + parseInt(price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  }

  /**
   * Formats document adding point each max 3 digits
   * example 0000000000 -> 0.000.000.000
   * @param {string} document
   */
  const formatDocument = (document) => {
    if (document === '') return ''

    const stack = []
    while (document !== '') {
      stack.push('.' + document.match(/\d{1,3}$/)[0])
      document = document.replace(/\d{1,3}$/, '')
    }
    return stack
      .reverse()
      .reduce((acc, current) => acc + current)
      .slice(1)
  }

  /**
   * Formats phone adding spaces each 3 or 4 digits
   * example 0000000000 -> 000 000 0000
   * @param {string} phone
   * @returns
   */
  const formatPhone = (phone) => {
    if (phone === '') return ''
    if (!phone) return ''
    if (typeof phone !== "string") return ''
    if (phone.includes("+")) phone = phone.replace("+", "");
    const country = phone.substring(0, 2)
    let number = phone.substring(2)
    const stack = []

    while (number !== '') {
      var re = new RegExp(number.length === 10 ? /\d{4}$/ : /\d{3}$/)
      stack.push(' ' + number.match(re)[0])
      number = number.replace(re, '')
    }
    return `+${country} ${stack
      .reverse()
      .reduce((acc, current) => acc + current)
      .slice(1)}`
  }

  /**
   * Formats an address based on address object structure from db
   * @param {Object} address
   */
  const formatAddress = (address) => {
    return `${address.splitAddress?.street} ${address.splitAddress?.first}${address.splitAddress?.second} #${address.splitAddress?.third}${address.splitAddress?.fourth}-${address.house}`
  }

  return {
    formatPrice,
    formatDocument,
    formatPhone,
    formatAddress,
    formatDate
  }
}

export default useStringFormater
