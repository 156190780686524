import React from 'react'
import PropTypes from 'prop-types'

const UserField = ({ label, value }) => {
	return (
		<div className='userfield'>
			<span className='userfield-label'>{label}</span>
			<span className='userfield-value'>{value}</span>
		</div>
	)
}

UserField.propTypes = {
	label: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired
}

export default UserField
