import * as actionsTypes from './ordersActionsTypes'
import db from '../../firebase/firestore'

const ordersCollection = db.collection('orders')

/**
 * Retrieves user orders from Firebase and dispatches relevant Redux actions.
 * @param {function} dispatch - The Redux dispatch function.
 * @param {string} uID - The user ID to retrieve orders for.
 * @returns {void}
 */
export const getUserOrders = (dispatch, uID) => {
  console.log("getUserOrders", uID);
	dispatch({
		type: actionsTypes.GET_USER_ORDERS
	})

  ordersCollection.where('user_id', '==', uID).orderBy("status_created","desc").onSnapshot(
		(snapshot) => {
			dispatch({
				type: actionsTypes.GET_USER_ORDERS_SUCCESS,
				orders: snapshot.docs.map((doc) => (
					{
						...doc.data(),
						document_id: doc.id
					}
				))
			})
		},
    (error) => {
      console.log("error",error);
      
			dispatch({
				type: actionsTypes.GET_USER_ORDERS_FAILURE,
				error: error.message
			})
		}
	)
}

/**
 * Updates an order in Firebase.
 * @param {string} orderId - The ID of the order to update.
 * @param {Object} data - The updated order data.
 * @returns {void}
 */
export const updateOrder = (orderId, data) => {
	ordersCollection.doc(orderId).update({ ...data })
}
