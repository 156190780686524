import React, {useMemo} from "react";
import {Skeleton} from "@material-ui/lab";
import NetworkImage from "../../../components/NetworkImage/NetworkImage";

/**
 * Componente que muestra la imagen de un producto.
 * @param isLoading
 * @param productImageBaseUrl
 * @param productId
 * @returns {unknown}
 * @constructor
 */
const ProductImage = ({
  isLoading,
  productImageBaseUrl,
  productId
}) => {
  return useMemo(() => {
    if (isLoading) {
      return (
        <Skeleton
          variant='rect'
          animation='wave'
          width='80%'
          height='100%'
          style={{ marginLeft: '2rem' }}
        />
      )
    }

    return (
      <NetworkImage
        width='100%'
        url={productImageBaseUrl + productId}
        imageProps={{
          alt: `image-product-${productId}`,
          className: 'product-image'
        }}
      />
    )
  }, [isLoading, productImageBaseUrl, productId])
}

export default ProductImage;