import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import * as AccountSections from '../../../../consts/AccountSections'
import { routeFormatter } from '../../../../routes/routes'
// components
import { Box } from '@material-ui/core'
import CustomButton from '../../../../components/atoms/buttons/CustomButton'
import EditInputText from '../../../../components/atoms/inputs/EditInputText'
import FloatingSummary from '../../../../components/FloatingSummary/FloatingSummary'
import ProductList from '../../../../components/ProductList/ProductList'
import * as cartSteps from '../../../../hooks/shoppingCart/cartSteps'
import LoginMessage from '../../LoginMessage'
//hooks
import CircularLoader from '../../../../components/loaders/CircularLoader'
import useAuth from '../../../../hooks/auth/useAuth'
import useConfigs from '../../../../hooks/configs/useConfigs'
import useShoppingCart from '../../../../hooks/shoppingCart/useShoppingCart'
import useStringFormater from '../../../../hooks/stringFormater/useStringFormater'

const CartProducts = ({ transitionTo }) => {
	const { formatPrice } = useStringFormater()

	const { isLogged } = useAuth()
	const {
		wholesalerProducts,
		generalProducts,
		cartTotal,
		emptyCart,
		changeProductCount,
		removeProductFromCart
	} = useShoppingCart()

	const { data, loading } = useConfigs()
	const meetsMinimun = !data ? false : cartTotal >= data.minimum_value

	/**
	 * Returns a function to update product count
	 * @param {string} productId
	 * @param {{
	 *      asWholesaler?: boolean
	 * }} options
	 * @returns
	 */
	const onProductCountChange =
		(productId, options = {}) =>
		(count) =>
			changeProductCount(count, productId, options)

	/**
	 * Returns a function to delete product
	 * @param {string} productId
	 * @param {{
	 *      asWholesaler?: boolean
	 * }} options
	 * @returns
	 */
	const onProductDelete =
		(productId, options = {}) =>
		() =>
			removeProductFromCart(productId, options)

	// product filtering
	const [productFilter, setProductFilter] = useState('')

	return (
		<>
			{loading || !data ? (
				<CircularLoader />
			) : (
				<>
					<div className='cart-products-mobile'>
						{!isLogged ? (
							<Box marginBottom='1.198rem'>
								<LoginMessage />
							</Box>
						) : (
							<div className='direcciones-message'>
								<span className='text-16px-300 color-gray-light'>
									Antes de mercar,&nbsp;
								</span>
								<Link
									to={`${routeFormatter.toAccount(
										AccountSections.OPTION_DIRECCIONES
									)}`}
									className='direcciones-link'
								>
									<span className='text-16px-500 color-blue'>
										registra tu dirección
									</span>
								</Link>
								<span className='text-16px-300 color-gray-light'>
									&nbsp;para verificar si tienes cobertura.
								</span>
							</div>
						)}

						<div className='cart-products'>
							<EditInputText
								value={productFilter}
								onChange={(e) => setProductFilter(e.target.value)}
								inputProps={{
									placeholder: 'Buscar productos'
								}}
								endAdornment={
									productFilter ? (
										<i
											className='x-icon'
											onClick={() => setProductFilter('')}
											style={{ cursor: 'pointer' }}
										/>
									) : null
								}
							/>
							<div className='cart-lists'>
                {wholesalerProducts.length > 0 && <ProductList
									wholesaler
									title='Productos mayoristas'
									products={wholesalerProducts}
									resumeLabel='Mayorista'
									onProductCountChange={onProductCountChange}
									onProductDelete={onProductDelete}
									nameFilter={productFilter}
								/>}
								<ProductList
									title='Productos generales'
									products={generalProducts}
									resumeLabel='Generales'
									onProductCountChange={onProductCountChange}
									onProductDelete={onProductDelete}
									nameFilter={productFilter}
								/>
							</div>
							{wholesalerProducts.length > 0 || generalProducts.length > 0 ? (
								<div className='cart-button'>
									<CustomButton
										label='Vaciar carrito'
										iconClass='delete-icon -white'
										iconFirst
										width='170px'
										onClick={emptyCart}
									/>
								</div>
							) : (
								<div />
							)}
						</div>
					</div>
					{/* Resumen de compra que redirige a direcciones */}
					{isLogged && meetsMinimun ? (
						<FloatingSummary
							price={cartTotal}
							buttonLabel='Ir a pagar'
							disabled={cartTotal === 0}
							validateUserLoged
							onClick={() => transitionTo(cartSteps.DIRECCIONES)}
						/>
					) : (
						<FloatingSummary
							price={cartTotal}
							buttonLabel='Ir a pagar'
							disabled={true}
							onClick={() => {}}
							message={
								<>
									<span className='text-11px-400 color-gray-light'>
										Debes de realizar un pedido de mínimo&nbsp;
									</span>
									<span className='text-11px-500 color-blue'>
										{formatPrice(data.minimum_value)}
									</span>
									<br />
									<span className='text-11px-400 color-gray-light'>
										para finalizar tu pedido.
									</span>
								</>
							}
						/>
					)}
				</>
			)}
		</>
	)
}

CartProducts.propTypes = {
	transitionTo: PropTypes.func.isRequired
}

export default CartProducts
