import React from 'react'
import PropTypes from 'prop-types'
import { isBrowser } from 'react-device-detect'
// resources
import logo from '../../assets/logomercaz1.png'
// my componentes
import EditSelect from '../atoms/selects/EditSelect'
import CustomButton from '../atoms/buttons/CustomButton'
// hooks
import useStoreSelector from './useStoreSelector'

const StoreSelector = (props) => {
	const { handleTransition, to } = props
	const { stores, selectedIndex, selectStore, saveStore } = useStoreSelector()

	return (
		<div
			className={`store-selector-${isBrowser ? 'desktop' : 'mobile'} fade-in`}
		>
			<img src={logo} alt='MercaZ' className='logo' />
			<span className='text-36px-700 color-blue'>Bienvenido</span>
			<span
				className='text-16px-300 color-gray
                selector-message'
			>
				¿Qué sede deseas visitar?
			</span>
			<div className='controls'>
				<EditSelect
					label='Sede'
					options={stores}
					selectedIndex={selectedIndex}
					onSelect={selectStore}
				/>
				<CustomButton
					variant='filled'
					label='Continuar'
					onClick={() => {
						saveStore()
						handleTransition(to.login)
					}}
				/>
			</div>
		</div>
	)
}

StoreSelector.propTypes = {
	handleTransition: PropTypes.func,
	to: PropTypes.objectOf(PropTypes.string),
	handleClose: PropTypes.func.isRequired
}

export default StoreSelector
