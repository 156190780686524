import React from 'react'
import { isBrowser } from 'react-device-detect'
import MenuOptionDesktop from './MenuOptionDesktop'
import MenuOptionMobile from './MenuOptionMobile'

const MenuOption = (props) => {
	const MenuOptionLayout = isBrowser ? MenuOptionDesktop : MenuOptionMobile
	return <MenuOptionLayout {...props} />
}

export default MenuOption
