import { useContext } from 'react'
import { context } from '../../context/ContextProvider'
import * as authActions from '../../context/auth/authActions'
import { address } from '../../context/models'

/**
 * Hook que maneja las direcciones de envío de un usuario autenticado.
 * @returns {Object} Objeto con propiedades y métodos para manejar las direcciones de envío.
 * @property {Array} addresses - Arreglo de direcciones del usuario autenticado.
 * @property {Function} addNewAddresses - Método para agregar una nueva dirección al usuario autenticado.
 * @property {Function} updateAddresses - Método para actualizar una dirección existente del usuario autenticado.
 * @property {Function} deleteAddress - Método para eliminar una dirección existente del usuario autenticado.
 * @property {Object} selectedAddress - Dirección de envío seleccionada por el usuario autenticado.
 * @property {Function} getAddressTemplateCopy - Método para obtener una copia del modelo de dirección.
 */
const useAddress = () => {
	const { state, dispatch } = useContext(context)
	const { authValues } = state.authReducer

	const addresses = authValues && authValues.addresses
	const selectedAddress = authValues.selected_address

	const addNewAddresses = async (addressValue) => {
		if (authValues.ID) {
			await authActions.newAddresses(
				dispatch,
				authValues,
				{
					...addressValue
				},
				authValues.ID
			)
		}
	}

	const updateAddresses = (index, value) => {
		if (authValues.ID) {
			authActions.updateAddresses(
				dispatch,
				authValues,
				index,
				{
					...value
				},
				authValues.ID
			)
		}
	}

	const deleteAddress = (index) => {
		authActions.deleteAddress(dispatch, authValues, index, authValues.id)
	}

	const getAddressTemplateCopy = () => {
		return { ...address }
	}

	return {
		addresses,
		addNewAddresses,
		updateAddresses,
		deleteAddress,
		selectedAddress,
		getAddressTemplateCopy
	}
}

export default useAddress
