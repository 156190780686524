import * as paymentCardsTypes from './paymentCardsTypes'
import db from '../../firebase/firestore'

const userPaymentCardCollection = (ID) => {
	return db.collection('users').doc(ID).collection('cards')
}

/**
 * Retrieves the payment cards for a user from Firebase and dispatches relevant Redux actions.
 * @async
 * @param {function} dispatch - The Redux dispatch function.
 * @param {string} ID - The user ID to retrieve payment cards for.
 * @returns {Promise<Array>} A promise that resolves to an array of payment cards.
 * @throws An error if the retrieval of payment cards fails.
 */
export const getUserPaymentCards = async (dispatch, ID) => {
	return new Promise((resolve, reject) => {
		dispatch({ type: paymentCardsTypes.GET_PAYMENTCARD })

		const paymentCardCollection = userPaymentCardCollection(ID)

		paymentCardCollection
			.get()
			.then((querySnapshot) => {
				const cards = []
				if (!querySnapshot.empty) {
					querySnapshot.forEach((doc) => {
						cards.push({ ...doc.data(), id: doc.id })
					})
				}
				dispatch({
					type: paymentCardsTypes.GET_PAYMENTCARD_SUCCESS,
					cards: cards
				})
				resolve(cards)
			})
			.catch((error) => {
				dispatch({
					type: paymentCardsTypes.GET_PAYMENTCARD_FAILURE,
					error: error.message
				})
				reject(error)
			})
	})
}

/**
 * Crea una tarjeta de pago en la colección de tarjetas de pago del usuario.
 *
 * @async
 * @function
 * @param {function} dispatch - La función de despacho para enviar acciones al almacén de Redux.
 * @param {string} userId - El ID del usuario para quien se crea la tarjeta de pago.
 * @param {Object} card - El objeto de la tarjeta de pago que se va a crear.
 * @param {string} card.number - El número de la tarjeta de pago.
 * @param {string} card.expiry - La fecha de caducidad de la tarjeta de pago.
 * @param {string} card.cvc - El código CVC de la tarjeta de pago.
 * @returns {Promise<void>} - Una promesa que resuelve cuando se crea la tarjeta de pago correctamente y rechaza si hay algún error.
 */
export const createPaymentCard = (dispatch, userId, card) => {
	return new Promise((resolve, reject) => {
		dispatch({ type: paymentCardsTypes.CREATE_PAYMENTCARD })

		const paymentCardCollection = userPaymentCardCollection(userId)
		paymentCardCollection
			.add(card)
			.then((paymentCardReference) => {
				dispatch({
					type: paymentCardsTypes.CREATE_PAYMENTCARD_SUCCESS,
					card: { ...card, id: paymentCardReference.id }
				})
				paymentCardReference.update({ id: paymentCardReference.id })
				resolve()
			})
			.catch((error) => {
				dispatch({
					type: paymentCardsTypes.CREATE_PAYMENTCARD_FAILURE,
					error: error.message
				})
				reject(error)
			})
	})
}

/**
 * Edita una tarjeta de pago en la colección de tarjetas de pago del usuario.
 *
 * @async
 * @function
 * @param {function} dispatch - La función de despacho para enviar acciones al almacén de Redux.
 * @param {string} userId - El ID del usuario cuya tarjeta de pago se va a editar.
 * @param {string} cardId - El ID de la tarjeta de pago que se va a editar.
 * @param {Object} data - El objeto que contiene los datos actualizados de la tarjeta de pago.
 * @param {boolean} [loadFast=false] - Un indicador opcional que indica si se debe devolver una respuesta rápida sin esperar a que se complete la operación de edición.
 * @returns {Promise<void>} - Una promesa que resuelve cuando se edita la tarjeta de pago correctamente y rechaza si hay algún error.
 */
export const editPaymentCard = (
	dispatch,
	userId,
	cardId,
	data,
	loadFast = false
) => {
	return new Promise((resolve, reject) => {
		dispatch({ type: paymentCardsTypes.EDIT_PAYMENTCARD })

		if (loadFast) {
			dispatch({
				type: paymentCardsTypes.EDIT_PAYMENTCARD_SUCCESS,
				cardId: cardId,
				data: data
			})
		}

		userPaymentCardCollection(userId)
			.doc(cardId)
			.set(data, { merge: true })
			.then(() => {
				if (!loadFast) {
					dispatch({
						type: paymentCardsTypes.EDIT_PAYMENTCARD_SUCCESS,
						cardId: cardId,
						data: data
					})
				}
				resolve()
			})
			.catch((error) => {
				dispatch({
					type: paymentCardsTypes.EDIT_PAYMENTCARD_FAILURE,
					error: error.message
				})
				reject(error)
			})
	})
}

/**
 * Elimina una tarjeta de pago de la colección de tarjetas de pago del usuario.
 *
 * @async
 * @function
 * @param {function} dispatch - La función de despacho para enviar acciones al almacén de Redux.
 * @param {string} userId - El ID del usuario cuya tarjeta de pago se va a eliminar.
 * @param {string} cardId - El ID de la tarjeta de pago que se va a eliminar.
 * @returns {Promise<void>} - Una promesa que resuelve cuando se elimina la tarjeta de pago correctamente y rechaza si hay algún error.
 */
export const deletePaymentCard = async (dispatch, userId, cardId) => {
	dispatch({ type: paymentCardsTypes.DELETE_PAYMENTCARD })

	userPaymentCardCollection(userId)
		.doc(cardId)
		.delete()
		.then(() => {
			dispatch({
				type: paymentCardsTypes.DELETE_PAYMENTCARD_SUCCESS,
				cardId: cardId
			})
		})
		.catch((error) => {
			dispatch({
				type: paymentCardsTypes.DELETE_PAYMENTCARD_FAILURE,
				error: error.message
			})
		})
}
