import { useState } from 'react'
import useValidator from '../../hooks/validator/useValidator'
import useAuth from '../../hooks/auth/useAuth'

const useLoginPage = (handleClose) => {
	const errorTranslator = (error) => {
		switch (error.code) {
			case 'auth/wrong-password':
				return 'Contraseña incorrecta, inténtalo de nuevo'
			case 'auth/user-not-found':
				return 'Este correo no ha sido registrado'
			default: {
				console.log(error)
				return 'Ha ocurrido un error, inténtalo de nuevo'
			}
		}
	}

	const [email, setEmail] = useState('')
	const [emailError, setEmailError] = useState('')
	const [password, setPassword] = useState('')
	const [passwordError, setPasswordError] = useState('')

	const [firebaseError, setFirebaseError] = useState(false)
	const [fbErrorMessage, setfbErrorMessage] = useState('')

	const {
		authValues,
		handleAuthValues,
		handleLogin,
		handleLoginWithGoogle,
		handleLoginWithFacebook,
		handleLoginWithPhone,
		initializesignInWithPhone,
		signInWithPhoneCodeVerification,
		isLogged
	} = useAuth()

	const { validateEmail, isNotEmpty } = useValidator()

	const cleanErrorMessages = () => {
		setFirebaseError(false)
		setEmailError('')
		setPasswordError('')
	}

	const validated = () => {
		let emailValid = validateEmail({
			email: authValues.email,
			setEmailError: setEmailError
		})
		let passwordValid = isNotEmpty(password, setPasswordError)
		return emailValid && passwordValid
	}

	const login = (e) => {
		e.preventDefault()
		cleanErrorMessages()

		if (validated()) {
			handleLogin(password, handleClose, (error) => {
				setFirebaseError(true)
				setfbErrorMessage(errorTranslator(error))
			})
		}
	}

	return {
		firebaseError,
		fbErrorMessage,
		email,
		setEmail,
		emailError,
		setPassword,
		passwordError,
		//useAuth
		handleAuthValues,
		handleLoginWithGoogle,
		handleLoginWithFacebook,
		handleLoginWithPhone,
		initializesignInWithPhone,
		signInWithPhoneCodeVerification,
		isLogged,
		login
	}
}

export default useLoginPage
