import React from 'react'
import PropTypes from 'prop-types'
import usePaymentMethod from './usePaymentMethod'
import * as paymentTypes from '../../components/PaymentMethod/PaymentTypes'

const DebitCard = (props) => {
	const {
		isSelected,
		cardType,
		lastDigits,
		layout,
		onSelect,
		onDelete,
		onSecondaryAction,
		defaultCard
	} = props
	const { paymentTypeIcon, cardTitle, principalAction, secondaryAction } =
		usePaymentMethod()

	return (
		<div
			className={`payment-method 
            ${
							layout === paymentTypes.layouts.SELECTION_MODE
								? '-selectable'
								: ''
						}`}
			onClick={onSelect}
		>
			<div className='description'>
				<div>
					{principalAction(layout, { isSelected, onSelect, onDelete })}
					<i className={paymentTypeIcon(cardType)} />
					<div className='info'>
						<span className='type text-9px-400 color-gray-light'>
							Tarjeta débito
						</span>
						<span className='text-13px-700 color-black-light name'>
							{cardTitle(cardType, lastDigits)}
						</span>
					</div>
				</div>
				{secondaryAction(layout, onSecondaryAction)}
			</div>
			{layout === paymentTypes.layouts.USER_ACCOUNT && (
				<div className='shares'>
					<span className='text-9px-400 color-gray-light'>
						{defaultCard ? 'Predeterminado' : 'Tarjeta 1'}
					</span>
				</div>
			)}
		</div>
	)
}

DebitCard.propTypes = {
	isSelected: PropTypes.bool,
	cardType: PropTypes.string.isRequired,
	lastDigits: PropTypes.oneOfType([
		PropTypes.number.isRequired,
		PropTypes.string.isRequired
	]),
	layout: PropTypes.string.isRequired,
	onSelect: PropTypes.func,
	onDelete: PropTypes.func,
	onSecondaryAction: PropTypes.func,
	defaultCard: PropTypes.bool
}
DebitCard.defaultProps = {
	onSelect: () => {},
	onDelete: () => {},
	onSecondaryAction: () => {}
}

export default DebitCard
