import React from 'react'
import { routeFormatter } from '../../../routes/routes'
import * as AccountSections from '../../../consts/AccountSections'
// components
import UserField from './UserField'
import UserPhoto from './UserPhoto/UserPhoto'
import { Skeleton } from '@material-ui/lab'
// hooks
import useUserInfo from './useUserInfo'
import { useHistory } from 'react-router'

const UserInfoDesktop = () => {
	const { name, lastname, document, phone, email, loading } = useUserInfo()

	const history = useHistory()

	return (
		<div className='user-info'>
			<div className='user-header'>
				{loading ? (
					<Skeleton variant='text' width={200} animation='wave' />
				) : (
					<span className='user-name'>{name + ' ' + lastname}</span>
				)}
				{!loading && (
					<div
						className='edit-user-data'
						onClick={() => {
							history.push(
								routeFormatter.toAccount(AccountSections.OPTION_EDITAR_DATOS)
							)
						}}
					>
						<span>Editar mis datos</span>
						<i className='edit-data-icon' />
					</div>
				)}
			</div>
			{/* foto del usuario */}
			<UserPhoto />
			<div className='user-data'>
				{loading ? (
					<Skeleton variant='rect' width={250} animation='wave' />
				) : (
					<UserField label='Documento' value={`C.c ${document}`} />
				)}
				{loading ? (
					<Skeleton variant='rect' width={180} animation='wave' />
				) : (
					<UserField label='Celular' value={phone} />
				)}
				{loading ? (
					<Skeleton variant='rect' width={250} animation='wave' />
				) : (
					<UserField label='Correo electrónico' value={email} />
				)}
			</div>
		</div>
	)
}

export default UserInfoDesktop
