import { getDistance, isPointInPolygon } from 'geolib'
import Cali from '../assets/GeoJson/MercaZCali.geojson'
import MedellinGuayabal from '../assets/GeoJson/MercaZMedellinGuayabal.geojson'
import MedellinSantaLucia from '../assets/GeoJson/MercaZMedellinSantaLucia.geojson'
import Tumaco from '../assets/GeoJson/MercaZTumaco.geojson'
import NormalizeBranch from './BranchesNormalize'

/**
 * Calculates the proximity of a given point to the branches.
 * @param {Array} branches - The branches to compare to the point.
 * @param {object} point - The point to compare to the branches, with latitude and longitude properties.
 * @returns {object} An object with the branch that is closest to the point and the distance in meters between them.
 */
export const getBranchProximity = (branches, point) => {
	let distance = 0
	let selectedBranch = {}

	branches.forEach((branch) => {
		const currentDistance = getDistance(
			{
				latitude: branch.geojson.latitude,
				longitude: branch.geojson.longitude
			},
			point
		)
		if (!selectedBranch.name) {
			distance = currentDistance
			selectedBranch = branch
		} else if (currentDistance < distance) {
			distance = currentDistance
			selectedBranch = branch
		}
	})

	return { ...selectedBranch, distance }
}

/**
 * Calculates whether a point is covered by a branch, and if so, the neighborhood and zone where it is located.
 * @param {object} branch - The branch to compare to the point, with a name property.
 * @param {object} point - The point to compare to the branch, with latitude and longitude properties.
 * @returns {Promise<object>} A promise that resolves to an object with the coverage status, neighborhood, and zone if the point is covered by the branch.
 */
export const getLocationCoverageInBranches = async (branches, point, city) => {
	for (let i = 0; i < branches.length; i++) {
		if (branches[i].city === city) {
			const currentCoverage = await getLocationCoverageInBranch(
				branches[i],
				point
			)
			if (currentCoverage.hasCoverage) {
				return { ...currentCoverage, branch: branches[i] }
			}
		}
	}
	return { hasCoverage: false }
}

/**
 * Searches for a branch in a city that covers a given point, and if found, returns the coverage information and the branch.
 * @param {Array} branches - The branches to search through.
 * @param {object} point - The point to compare to the branches, with latitude and longitude properties.
 * @param {string} city - The city to search for the branch in.
 * @returns {Promise<object>} A promise that resolves to an object with the coverage status, neighborhood, zone, and branch if a branch in the city covers the point.
 */
export const getLocationCoverageInBranch = async (branch, point) => {
	let selectedGeoJson = undefined
	let coverage = false
	let neighborhood = undefined
	let zone = undefined

	switch (NormalizeBranch(branch.name)) {
		case 'Chipichape':
			await fetch(Cali)
				.then((res) => res.json())
				.then((data) => (selectedGeoJson = data))
			break
		case 'Guayabal':
			await fetch(MedellinGuayabal)
				.then((res) => res.json())
				.then((data) => (selectedGeoJson = data))
			break
		case 'Santa Lucia':
			await fetch(MedellinSantaLucia)
				.then((res) => res.json())
				.then((data) => (selectedGeoJson = data))
			break
		case 'Tumaco':
			await fetch(Tumaco)
				.then((res) => res.json())
				.then((data) => (selectedGeoJson = data))
			break
		default:
			selectedGeoJson = undefined
			coverage = false
			break
	}

	for (let i = 0; i < selectedGeoJson?.features?.length; i++) {
		let isInCoverage = false
		if (selectedGeoJson?.features[i].geometry.type === 'GeometryCollection') {
			for (
				let gI = 0;
				gI < selectedGeoJson?.features[i].geometry.geometries.length;
				gI++
			) {
				const polygon = selectedGeoJson.features[i].geometry.geometries[
					gI
				].coordinates[0].map((item) => {
					return { latitude: item[1], longitude: item[0] }
				})
				isInCoverage = isPointInPolygon(point, polygon)
				if (isInCoverage) break
			}
		} else if (selectedGeoJson.features[i].geometry.type === 'LineString') {
			const polygon = selectedGeoJson.features[i].geometry.coordinates.map(
				(item) => {
					return { latitude: item[1], longitude: item[0] }
				}
			)
			isInCoverage = isPointInPolygon(point, polygon)
		} else {
			const polygon = selectedGeoJson.features[i].geometry.coordinates[0].map(
				(item) => {
					return { latitude: item[1], longitude: item[0] }
				}
			)
			isInCoverage = isPointInPolygon(point, polygon)
		}

		if (isInCoverage) {
			coverage = true
			neighborhood = selectedGeoJson.features[i].properties.name
			zone = getZoneByStroke(selectedGeoJson.features[i].properties.stroke)
			break
		} else {
			coverage = false
		}
	}

	return { hasCoverage: coverage, neighborhood, zone }
}

/**
 * Get zone by stroke
 * @param {string} stroke
 * @returns {string} zone
 */
const getZoneByStroke = (stroke) => {
	switch (stroke) {
		case '#558b2f':
			return 'delivery_close'
		case '#01579b':
			return 'delivery_far'
		case '#880e4f':
			return 'delivery_far_away'
		default:
			return 'delivery_far_away'
	}
}
