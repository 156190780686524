import { useState } from 'react'

export const useProductCounter = ({ initialValue = 1, minValue = 0 }) => {
  const [counter, setCounter] = useState(initialValue)
  const [wholesalerCounter, setWholesalerCounter] = useState(initialValue)

	// La función actualiza el estado de counter sumando n al valor anterior utilizando la función setCounter.
	const incrementCounter = (n = 1) => setCounter(counter + n)
  const incrementWholesalerCounter = (n=1) => setWholesalerCounter(wholesalerCounter + n)
	//La función utiliza un condicional para verificar si el valor actual de counter es mayor que el valor mínimo.
  const decrementCounter = (n = 1) => {
		if (counter > minValue) setCounter(counter - n)
	}
  const decrementWholesalerCounter = (n = 1) => {
		if (wholesalerCounter > minValue) {
      let newCounter = wholesalerCounter - n
      if (newCounter < 0) {
        newCounter = 0
      }
      setWholesalerCounter(newCounter)
    }
	}

	const restartCounter = () => setCounter(initialValue) 
  const restartWholesalerCounter= () => setWholesalerCounter(initialValue)

  return {
    counter,
    incrementCounter,
    decrementCounter,
    restartCounter,
    setCounter,
    //wholesaler
    wholesalerCounter,
    setWholesalerCounter,
    incrementWholesalerCounter,
    decrementWholesalerCounter,
    restartWholesalerCounter,
  }
}

export default useProductCounter
