import React, { useContext, useEffect, useState } from 'react'
import { context } from '../../context/ContextProvider'
import * as modals from './modals'
// hooks
import useAuth from '../../hooks/auth/useAuth'
// modals
import LoginPage from '../../pages/LoginPage/LoginPage'
import RegisterPage from '../../pages/RegisterPage/RegisterPage'
import ResetPasswordPage from '../../pages/ResetPasswordPage/ResetPasswordPage'
import CitySelector from '../CitySelector/CitySelector'
import StoreSelector from '../StoreSelector/StoreSelector'

/**
 * Hook que maneja el estado del modal de autenticación de la aplicación.
 * @returns {{handleModal: function, open: boolean}} Un objeto con dos propiedades:
 * - `handleModal`: una función que devuelve el componente correspondiente al modal actual y que se encarga de cambiar de modal cuando es necesario.
 * - `open`: un booleano que indica si el modal debe estar abierto o cerrado.
 */
const useAuthModals = () => {
	const { getCityStorage } = useAuth()

	// Función que devuelve el modal inicial dependiendo de si se ha almacenado una ciudad en el almacenamiento local.
	const initialModal = () => {
		return !getCityStorage() ? modals.SELECT_CITY : modals.LOGIN
	}

	const [modal, setModal] = useState(initialModal())

	const { state } = useContext(context)
	const { askToLogin } = state.authReducer

	// Cambia al modal inicial si se ha recibido una petición de inicio de sesión.
	useEffect(() => {
		if (askToLogin) {
			setModal(initialModal())
		}
	}, [askToLogin])

	/**
	 * Devuelve el componente correspondiente al modal actual.
	 * @param {Object} modalProps Propiedades que se pasarán al componente del modal.
	 * @returns {ReactElement} El componente del modal correspondiente.
	 */
	const handleModal = (modalProps) => {
		const props = { handleTransition: setModal, ...modalProps }
		switch (modal) {
			case modals.SELECT_CITY:
				return (
					<CitySelector
						to={{
							login: modals.LOGIN
						}}
						{...props}
					/>
				)

			case modals.SELECT_STORE:
				return (
					<StoreSelector
						to={{
							login: modals.LOGIN
						}}
						{...props}
					/>
				)

			case modals.LOGIN:
				return (
					<LoginPage
						to={{
							register: modals.REGISTER,
							resetPassword: modals.PASSWORD_RECOVERY
						}}
						{...props}
					/>
				)

			case modals.REGISTER:
				return (
					<RegisterPage
						to={{
							login: modals.LOGIN
						}}
						{...props}
					/>
				)

			case modals.PASSWORD_RECOVERY:
				return (
					<ResetPasswordPage
						to={{
							register: modals.REGISTER,
							login: modals.LOGIN
						}}
						{...props}
					/>
				)

			default:
				return (
					<CitySelector
						to={{
							login: modals.LOGIN
						}}
						{...props}
					/>
				)
		}
	}
	return {
		handleModal,
		open: askToLogin || !getCityStorage()
	}
}

export default useAuthModals
