import React from 'react'
import PropTypes from 'prop-types'
import * as paymentTypes from './PaymentTypes'

// components
import Cash from './Cash'
import CreditCard from './CreditCard'
import DebitCard from './DebitCard'
import AddCard from './AddCard'
import Dataphone from './Dataphone'
import PSEpay from './PSEpay'

const PaymentMethod = (props) => {
	//Casos para los diferentes metodos de pago
	switch (props.type) {
		case paymentTypes.methods.CASH:
			return <Cash {...props} />
		case paymentTypes.methods.DATAPHONE:
			return <Dataphone {...props} />
		case paymentTypes.methods.CREDIT_CARD:
      return <CreditCard {...props} />
    case paymentTypes.methods.PSE:
      return <PSEpay {...props} />
		case paymentTypes.methods.DEBIT_CARD:
			return <DebitCard {...props} />
		case paymentTypes.methods.ADD_METHOD:
			return <AddCard {...props} />
		default:
			return <Cash {...props} />
	}
}
PaymentMethod.propTypes = {
	type: PropTypes.string.isRequired
}

export default PaymentMethod
