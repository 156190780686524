// hooks
import { useHistory } from 'react-router-dom'
import useShoppingCart from '../../../../hooks/shoppingCart/useShoppingCart'
// consts
import { useMemo } from 'react'
import * as CutsTypes from '../../../../consts/MeatCutsTypes'
import { UNIT } from '../../../../consts/ProductCountOptions'
import * as Maturities from '../../../../consts/ProductMaturities'
import SubcatsWithCutType from '../../../../consts/SubcatsWithCutType'
import SubcatsWithGrams from '../../../../consts/SubcatsWithGrams'
import SubcatsWithMaturity from '../../../../consts/SubcatsWithMaturity'
// eslint-disable-next-line no-unused-vars
import useObservations from '../../../../hooks/shoppingCart/useObservations'
//import ProductModel from '../../../../models/ProductModel'
import { getTotalValueOfProduct } from '../../../../utils/TotalToPay'

/**
 *
 * @param {{
 * product:ProductModel,
 * counter:number,
 * wholesalerCounter:number,
 * incrementCounter:()=>void,
 * branch:Record<any>,
 * setWholeSalerCounter:()=>void,
 * unitType:string
 * }} props
 * @returns
 */
const useProductCard = ({
	counter,
	wholesalerCounter,
	setWholeSalerCounter,
	branch,
	unitType,
	incrementCounter,
	product
}) => {
	const {
		name = '',
		sub_category_id = '',
		quantity_pum = 0,
		brand = '',
		provider = ''
	} = product || {}
	const productId = product?.id_item ?? ''
	const categoryId = product?.main_category_id ?? ''

	const {
		addProductToCart,
		removeProductFromCart,
		getProductTemplateCopy,
		isProductInCart
	} = useShoppingCart()

	const { observations } = useObservations()

	const history = useHistory()

	// optional data
	const canSelectUnitType = SubcatsWithGrams.includes(sub_category_id)
	const needsMaturity = SubcatsWithMaturity.includes(sub_category_id)
	const needsCutType = SubcatsWithCutType.includes(sub_category_id)

	const isInCart = useMemo(() => {
		return isProductInCart(productId)
	}, [productId, isProductInCart])
	/**
	 * Añade un producto al carrito de compras.
	 * @function addToCart
	 * @returns {void}
	 */
	const addToCart = (options = {}) => {
		const {
			asWholesaler = false,
			cutType = '',
			maturity = '',
		} = options

		// if products needs to specify cut type, redirect to product page
		const path = `/product/${categoryId}/${productId}`
		if (needsCutType && path !== history.location.pathname) {
			history.push(path)
			return
		}
		// cant add 0 items
		if (counter === 0) {
			incrementCounter()
		}
		if (asWholesaler && wholesalerCounter === 0)  {
			setWholeSalerCounter(wholesalerCounter + 25)
		}

		const newProduct = getProductTemplateCopy()
		// set object
		newProduct.bar_code = product?.bar_code || ''
		newProduct.main_category_id = categoryId
		newProduct.sub_category_id = sub_category_id
		newProduct.content = product?.content || ''

		// optional fields
		if (needsCutType) {
			newProduct.cut_type = (typeof cutType === 'string' && cutType !== '' ? cutType : CutsTypes.ENTERO.code) || ''
		}
		if (needsMaturity) {
			newProduct.maturity = (typeof maturity === 'string' && maturity !== '' ? maturity : Maturities.NORMAL.code) || ''
		}

		newProduct.id_item = productId
		newProduct.name = name
		newProduct.observation = observations[productId] || ''

		newProduct.quantity_pum = quantity_pum
		newProduct.range_quantity = product?.rangeQuantity || ''
		newProduct.unit_gram = product.unit_gram
		newProduct.unit_type = unitType
		newProduct.brand = brand
		newProduct.provider = provider
		newProduct.minimum_grams_sale = product.minimum_grams_sale || 0
		newProduct.discount = branch.discount
		//console.log('branch', branch) 
		if (
			asWholesaler &&
			product.isWholesaler &&
			product.wholesaler
		) {
			newProduct.count = wholesalerCounter || product.wholesaler.factor
			newProduct.unit_type = product.unit_type

			newProduct.wholesaler = product.isWholesaler
			newProduct.price = product.wholesaler.price
			newProduct.price_neto = product.wholesaler.price_neto
			newProduct.pum = product.wholesaler.pum
			newProduct.factor = product.wholesaler.factor
			newProduct.subtotal = getTotalValueOfProduct(newProduct, true)
		} else {
			newProduct.count = counter || 1
			newProduct.wholesaler = false
			newProduct.price = branch.price
			newProduct.price_neto = branch.price_neto
			newProduct.pum = branch.pum
			newProduct.subtotal = getTotalValueOfProduct(newProduct, false)
		}

		addProductToCart(newProduct)
	}

	/**
	 * Elimina un producto al carrito de compras.
	 * @function removeFromCart
	 * @returns {void}
	 */
	const removeFromCart = () => {
		removeProductFromCart(productId)
	}
	/**
	 * Verifica si el producto cumple con la cantidad mínima de gramos para la venta.
	 * @type {unknown}
	 */
	const hasMinCountGrams = useMemo(() => {
		const count = counter
		if (count === 0) return 0
		if (unitType !== UNIT.value) {
			const quantity_pum =
				product.unit_gram ||
				product.quantity_pum ||
				Number(process?.env?.REACT_APP_RANGE_QUANTITY)
			if (product) {
				if (product.minimum_grams_sale && !isNaN(product.minimum_grams_sale)) {
					if (count * quantity_pum < Number(product.minimum_grams_sale)) {
						return false
						// Number(product.minimum_grams_sale)
					}
				}
			}
			return true //count * quantity_pum
		} else {
			return true // count
		}
	}, [counter, product, unitType])

	return {
		isInCart,
		hasMinCountGrams,
		// flags
		canSelectUnitType,
		// actions
		addToCart,
		removeFromCart
	}
}

export default useProductCard
