import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Summary from './Summary'
import sectionGenerator from './sectionGenerator'
import CircularLoader from '../loaders/CircularLoader'
// hooks
import useMarkets from '../../hooks/markets/useMarkets'
//import { useHistory } from 'react-router'
import useAuth from '../../hooks/auth/useAuth'
import useShoppingCart from '../../hooks/shoppingCart/useShoppingCart'
// routes
// import { routes } from '../../routes/routes'
import useConfigs from '../../hooks/configs/useConfigs'

const MarketSummary = ({ marketId, city, branchOffice, addProduct }) => {
	// hooks
	//const history = useHistory()
	const { showLoginModal } = useAuth()
	const { getFullMarketProducts, addProductsToCart, totalGenerales, markets } =
		useMarkets()

	const { deliveryValue } = useShoppingCart()
	const { /*branches, bags,*/ bag, getBags } = useConfigs()
	const [totalBags, setTotalBags] = useState(0)

	// state
	const [fullProducts, setFullProducts] = useState(null)
	const market = markets.find((market) => market.id === marketId)

	const productsCount = fullProducts ? fullProducts.length : 0
	const productsTotal = fullProducts ? totalGenerales(fullProducts) : 0

	const isEmpty = productsCount === 0
	let total = productsTotal + deliveryValue

/* 	  este código asegura que los productos de un mercado específico se obtengan 
	  y se muestren en una lista de productos, siempre y cuando la ciudad sea válida
		y el mercado no haya sido eliminado */
	useEffect(() => {
		if (!city) showLoginModal() //si la ciudad no es válida, se invoca una función para mostrar un modal de inicio de sesión
		// early return if market has been deleted
		if (!market) return
		getFullMarketProducts(market.products, city, branchOffice).then(
			(result) => {
				setFullProducts(result)
			}
		)
	}, [markets, marketId])

	//agrega los productos al carrito de compras.
	const orderMarket = () => {
		addProductsToCart(fullProducts)
		//history.push(routes.cart.path)
	}

	//calcula el valor de las bolsas
	const getTotalBags = () => {
		setTotalBags(getBags(total) * bag.bag_price)
	}

	const sections = []
	sections.push(
		sectionGenerator({
			title: 'Productos',
			subtitle: `${productsCount} item${productsCount > 1 ? 's' : ''}`,
			price: productsTotal,
			withBorder: true
		})
	)
	sections.push(
		sectionGenerator({
			title: 'Costo de envío',
			price: deliveryValue,
			withBorder: true
		})
	)
	sections.push(
		sectionGenerator({
			title: 'Impuesto bolsa',
			price: totalBags,
			withBorder: true
		})
	)
	sections.push(
		sectionGenerator({
			title: 'Total',
			price: total
		})
	)

	//este código utiliza dos funciones de efecto para actualizar el valor total de la compra, incluyendo el costo total de las bolsas
	useEffect(() => {
		total += totalBags
	}, [totalBags])
	useEffect(() => {
		getTotalBags()
	}, [total])

	return (
		<>
			{
				// full products and cofig data needs to be loaded
				fullProducts && deliveryValue ? (
					<Summary
						actionLabel={isEmpty ? 'No hay productos' : 'Pedir ahora'}
						visualizationMode={false}
						hidePrice={isEmpty}
						onClick={
							!fullProducts ? () => {} : isEmpty ? addProduct : orderMarket
						}
						sections={sections}
						total={total}
					/>
				) : (
					<CircularLoader />
				)
			}
		</>
	)
}

MarketSummary.propTypes = {
	marketId: PropTypes.string.isRequired,
	city: PropTypes.string.isRequired,
	branchOffice: PropTypes.string,
	addProduct: PropTypes.func.isRequired
}

export default MarketSummary
