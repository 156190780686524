import React from "react";

import { Box } from "@material-ui/core";
import { useContext } from "react";
import { context } from "../../../context/ContextProvider";
import { setObservation } from "../../../context/shoppingCart/shoppingCartActions";
import EditInputText from "./EditInputText";
const ObservationsInCart = () => {

  const { state, dispatch } = useContext(context)
  const { observation } =
    state.shoppingCartReducer
  const handleSetObservation = (e) => {
    setObservation(dispatch, e.target.value)
  }
  // console.log("observation", observation);

  return <Box pt="2rem" width={"100%"}>
    <EditInputText placeholder="Si tienes observaciones para agregar al pedido escribelas aqui." isTextArea onChange={handleSetObservation} label='Observaciones en el pedido' value={observation}>
    </EditInputText>
  </Box>
}

export default ObservationsInCart;