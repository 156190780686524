import { useContext, useEffect, useRef } from 'react';
import { context } from '../../context/ContextProvider';

import * as configsActions from '../../context/configs/configsActions';

/**
 * A hook that provides access to the configsReducer state and related actions.
 *
 * @returns {Object} An object containing the configsReducer state and related actions.
 * @property {Object} data - The configs data.
 * @property {boolean} loading - A boolean indicating whether the configs data is currently being loaded.
 * @property {Array} branches - An array containing all the branches.
 * @property {Function} getBags - A function that returns the number of bags needed based on the total price of the shopping cart.
 * @property {Object} bag - The current bag configuration.
 * @property {Array} bags - An array containing all the available bag configurations.
 */
const useConfigs = (props = {}) => {
  const { autoLoadConfigsData = false } = props;
  const {
    state: { configsReducer },
    dispatch
  } = useContext(context)

  const { data, loading, branches, bag, bags } = configsReducer
  const isLoading = useRef(false);
  useEffect(() => {
    isLoading.current = loading;
  }, [loading])

  useEffect(() => {
    // console.log("loadConfigsData", data, autoLoadConfigsData);
    if (!data && autoLoadConfigsData) {
      configsActions.loadDataConfigs(dispatch)
      configsActions.branchesLoad(dispatch)
    }

  }, [data, autoLoadConfigsData])

  const getBags = (shoppingCartTotalPrice) => {
    // console.log(bags)
    if (bags.length > 0) {
      const bagRange = bags.find(
        (item) =>
          shoppingCartTotalPrice >= item.from &&
          shoppingCartTotalPrice <= item.to
      )

      if (bagRange) {
        return bagRange.quantity
      }

      const bagsSorted = bags.sort((a, b) => a.to - b.to)

      return bagsSorted[bagsSorted.length - 1].quantity
    }
  }

  return {
    data,
    loading,
    branches,
    getBags,
    bag,
    bags
  }
}

export default useConfigs
