import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import CircularLoader from '../loaders/CircularLoader'
import ProductoGeneral from './ProductoGeneral'
import ProductoMayorista from './ProductoMayorista'
// hooks
import useStringFormater from '../../hooks/stringFormater/useStringFormater'
// utils
import TotalToPay from '../../utils/TotalToPay'

const ProductList = (props) => {
  const {
    products,
    title,
    resumeLabel,
    wholesaler,
    uneditable,
    onProductCountChange,
    onProductUnitTypeChange,
    onProductDelete,
    contentLoading,
    emptyMessage,
    productTypeSelectEnable,
    // filters
    nameFilter
  } = props

  const { formatPrice } = useStringFormater()

  const [totalItemsInCart, setTotalItemsInCart] = useState(0)

  useEffect(() => {
    const sumall = products
      .map((product) => product.count) //recorre el array de products y crear un nuevo array que contenga solo la propiedad count de cada objeto.
      .reduce((prev, curr) => prev + curr, 0) // para sumar todos los valores del array creado.
    setTotalItemsInCart(sumall)//actualiza el valor del estado totalItemsInCart con el valor obtenido.
  }, [products]) //se  ejecuta cuando la variable products cambie


  // devuelve el valor total de una compra en un formato de precio formateado. 
  const getTotal = () => {
    return formatPrice(TotalToPay(products))
  }

  const nameFiltering = (product) => {
    // empty case
    if (nameFilter == '') return true
    return product.name.toLowerCase().startsWith(nameFilter.toLowerCase())
  }

    const filteredProducts = products.filter(nameFiltering)
  
    const List = () => (
        <div className='mproducts-group'>
            {filteredProducts.map((product, index) => {
                return wholesaler ? (
                    <ProductoMayorista
                        key={`pMayor-${index}`}
												product={product}
                        productId={product.id_item}
                        categoryId={product.main_category_id}
                        name={product.name}
                        generalPrice={product.generalPrice}
                        price={product.price}
                        count={product.count}
                        factor={product.factor}
                        countOptions={product.countOptions}
                        onCountChange={onProductCountChange(product.id_item, {
                            asWholesaler: true
                        })}
                        onDelete={onProductDelete(product.id_item, { asWholesaler: true })}
                        uneditable={uneditable}
												subtotal={product.subtotal}
                    />
                ) : (
                    <ProductoGeneral
                        key={`ProductoGeneral-${product.id_item}`}
                        product={product}
                        productId={product.id_item}
                        categoryId={product.main_category_id}
                        subcategoryId={product.sub_category_id}
                        name={product.name}
                        price={product.price}
                        count={product.count}
                        unitType={product.unit_type}
                        uneditable={uneditable}
                        onCountChange={onProductCountChange(product.id_item)}
                        onUnitTypeChange={onProductUnitTypeChange(product.id_item)}
                        onDelete={onProductDelete(product.id_item)}
                        typeSelectEnable={productTypeSelectEnable(product.id_item)}
                        rangeQuantity={product.quantity_pum}
												subtotal={product.subtotal}
                    />
                )
            })}
        </div>
    )
    const ListSummary = () => (
        <div className='mproducts-resume'>
            <div>
                <span className='text-14px-500 color-gray-light'>{resumeLabel}</span>
                <span className='text-11px-400 color-gray-light'>
                    {totalItemsInCart} items
                </span>
            </div>
            <div>
                <span className='text-12px-700 color-gray-light'>Subtotal</span>
                <span className='text-18px-700 color-blue'>{getTotal()}</span>
            </div>
        </div>
    )
  // console.log("wholesaler", wholesaler);
  
    return (
        <div className='product-list'>
            {title && <span className='color-blue text-15px-700'>{title}</span>}
            {products.length === 0 ? (
                <span className='text-15px-300 color-gray-light'>
                    {emptyMessage ||
                        `No has agregado productos ${wholesaler ? 'mayoristas ' : ' '} aún`}
                </span>
            ) : filteredProducts.length === 0 ? (
                <span className='text-15px-300 color-gray-light'>Sin resultados</span>
            ) : (
                <>
                    {contentLoading ? (
                        <CircularLoader />
                    ) : (
                        <>
                            <List />
                            <ListSummary />
                        </>
                    )}
                </>
            )}
        </div>
    )
}

ProductList.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id_item: PropTypes.string.isRequired,
      main_category_id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
      count: PropTypes.number.isRequired
    })
  ),
  productTypeSelectEnable: PropTypes.func,
  title: PropTypes.string,
  resumeLabel: PropTypes.string,
  wholesaler: PropTypes.bool,
  uneditable: PropTypes.bool,
  contentLoading: PropTypes.bool,
  onProductCountChange: PropTypes.func,
  onProductUnitTypeChange: PropTypes.func,
  onProductDelete: PropTypes.func,
  emptyMessage: PropTypes.string,
  // filters
  nameFilter: PropTypes.string
}

ProductList.defaultProps = {
  onProductCountChange: () => () => { },
  onProductUnitTypeChange: () => () => { },
  onProductDelete: () => { },
  productTypeSelectEnable: () => false,
  // filters
  nameFilter: ''
}

export default ProductList
