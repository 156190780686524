import React, { useState } from 'react'
import PropTypes from 'prop-types'
import CustomModal from '../CustomModal/CustomModal'
import CardDireccion from '../CardDireccion/CardDireccion'
import * as cardDireccionTypes from '../../components/CardDireccion/cardDireccionTypes'
// hooks
import useAuth from '../../hooks/auth/useAuth'
import useAddress from '../../hooks/user/useAddress'
import { useHistory } from 'react-router'
import CustomButton from '../atoms/buttons/CustomButton'
import { routeFormatter } from '../../routes/routes'

const SelectAddressModal = (props) => {
	const [selectedAddress, setSelectedAddress] = useState(null)
	const [indexAddress, setIndexAddress] = useState(null)

	const history = useHistory()

	const { acceptLabel, open, handleClose, setOpenAM } = props

	const { authValues, updateUserData } = useAuth()

	const { addresses } = authValues

	const { getAddressTemplateCopy } = useAddress()

	const address = getAddressTemplateCopy()

	//esta función se encarga de actualizar la información del usuario cuando se cierra el modal
	const closeModal = () => {
		if (indexAddress !== null) {
			updateUserData({ selected_address: indexAddress })
		}
		setOpenAM(false)
		setSelectedAddress(null)
		setIndexAddress(null)

	}

	//esta funcion actualiza o agrega la informacion del usuario.
	const goToAccountSettings = () => {
		history.push(routeFormatter.toAccount('addresses'))
		setOpenAM(false)
		setSelectedAddress(null)
		setIndexAddress(null)
	}

	return (
		<CustomModal open={open} handleClose={handleClose} mobileSmall>
			<div className='select-address-modal'>
				<span className='text-18px-700 color-blue direcciones-title'>
					Selecciona la dirección de entrega
				</span>
				<div className='cart-direccion-content  custom-scrollbar'>
					{/* Direcciones del usuario */}
					{addresses ? (
						addresses.map((address, index) => (
							<CardDireccion
								key={`CardDireccion-${index}`}
								cardType={cardDireccionTypes.CART_SELECTION_MODE}
								nameDireccion={address.name}
								direccion={address}
								timeEstimate='26 - 41 min'
								isSelected={selectedAddress === index}
								onClick={() => {
									setSelectedAddress(index)
									setIndexAddress(index)
								}}
								onUpdate={goToAccountSettings}
							/>
						))
					) : (
						<CardDireccion
							cardType={cardDireccionTypes.ADD_MODE}
							direccion={address}
							nameDireccion='No tienes direciones de entrega registradas'
							onUpdate={goToAccountSettings}
						/>
					)}
				</div>
				<div className='btn-modal'>
					<CustomButton label={acceptLabel} onClick={closeModal} />
				</div>
			</div>
		</CustomModal>
	)
}

SelectAddressModal.propTypes = {
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,

	acceptLabel: PropTypes.string,
	setOpenAM: PropTypes.func
}

export default SelectAddressModal
