import { createTheme } from '@material-ui/core/styles'

const theme = createTheme({
	palette: {
		primary: {
			main: '#164194',
			light: '#F4F6F9'
		},
		// custom colors
		borderColor: '#E3E8F0',

		textColor: {
			main: '#4A4A4A',
			light: '#858585',
			dark: '#212121'
		}
	},
	props: {
		MuiRadio: {
			color: 'primary',
			disableFocusRipple: true,
			disableRipple: true,
			disableTouchRipple: true
		}
	}
})

theme.overrides = {
	MuiSkeleton: {
		text: {
			borderRadius: 10
		},
		rect: {
			borderRadius: 10
		}
	},
	MuiRadio: {
		root: {
			'&:hover': {
				backgroundColor: 'transparent !important'
			}
		}
	},
	MuiMenu: {
		paper: {
			borderRadius: 10
		}
	},
	MuiAlert: {
		filledInfo: {
			backgroundColor: '#2359c2'
		}
	}
}

export { theme }
