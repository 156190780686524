import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import CardDireccion from '../../../components/CardDireccion/CardDireccion'
import * as cardDireccionTypes from '../../../components/CardDireccion/cardDireccionTypes'

import useAuth from '../../../hooks/auth/useAuth'

import useAddress from '../../../hooks/user/useAddress'
import useValidator from '../../../hooks/validator/useValidator'
import useDirecciones from './useDirecciones'

import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete'

import AlertModal from '../../../components/AlertModal/AlertModal'
import FormAddress from '../../../components/FormAddress/FormAddress'
import AddressCoverageValidationModal from '../../../components/Modals/AddressCoverageValidationModal'

const DireccionesDesktop = () => {
	const [isSelectAddress, setIsSelectAddress] = useState(false)
	const [indexAddress, setIndexAddress] = useState()

	const { updateUserData, authValues } = useAuth()
	const { addresses } = authValues
	const {
		updateAddresses,
		addNewAddresses,
		getAddressTemplateCopy,
		deleteAddress
	} = useAddress()
	const { cityOptions, dirOptions } = useDirecciones()


	const [openDelete, setOpenDelete] = useState(false)
	const closeDeleteModal = () => setOpenDelete(false)
	const openDeleteModal = () => setOpenDelete(true)

	const [openValidationModal, setOpenValidationModal] = useState(false)
	const closeVM = () => setOpenValidationModal(false)
	const openVM = () => setOpenValidationModal(true)

	// validation actions
	const cleanErrorMessages = () => {
		setNameError('')
		setNumberRangeError('')
		setObligatoryFliedsError('')
	}

	const { isNotEmpty, inRange } = useValidator()

	// error messages
	const [nameError, setNameError] = useState('')
	const [obligatoryFliedsError, setObligatoryFliedsError] = useState('')
	const [numberRangeError, setNumberRangeError] = useState('')

	const minNumValue = 1
	const maxNumValue = 999

	const addressObject = getAddressTemplateCopy()
	addressObject.splitAddress.street = dirOptions[0].value
	addressObject.city = cityOptions[0].value
	addressObject.branch = cityOptions[0].value

	const [addressValue, setAddressValue] = useState(addressObject)
	const [customAddress, setCustomAddress] = useState('')
	const tumacoSelected = addressValue.city === 'Tumaco'

	const handleDelete = () => {
		setIsSelectAddress(false)
		updateUserData({ selected_address: 0 })
		deleteAddress(indexAddress)
		closeDeleteModal()
	}

	const handleModified = (indexAddress, addressValue) => {
		setIsSelectAddress(false)
		updateAddresses(indexAddress, addressValue)
		setAddressValue(addressObject)
	}

	const handleChange = (name, value) => {
		setAddressValue((addressValue) => ({
			...addressValue,
			[name]: value
		}))
	}

	const validated = () => {
		let validName = isNotEmpty(
			addressValue.name,
			(errorMessage) => {
				setObligatoryFliedsError(errorMessage)
				setNameError(errorMessage)
			},
			'Faltan campos obligatorios. (*)'
		)

		let validAddressFirst =
			tumacoSelected ||
			isNotEmpty(
				addressValue.splitAddress.first,
				setObligatoryFliedsError,
				'Faltan campos obligatorios. (*)'
			)
		validAddressFirst =
			tumacoSelected && addressValue.splitAddress.first == ''
				? true
				: inRange(
					Number(addressValue.splitAddress.first),
					maxNumValue,
					minNumValue,
					setNumberRangeError,
					`Los datos numéricos deben estar entre ${minNumValue} y ${maxNumValue}`
				)

		let validAddressThird =
			tumacoSelected ||
			isNotEmpty(
				addressValue.splitAddress.third,
				setObligatoryFliedsError,
				'Faltan campos obligatorios. (*)'
			)
		validAddressThird =
			tumacoSelected && addressValue.splitAddress.first == ''
				? true
				: inRange(
					Number(addressValue.splitAddress.third),
					maxNumValue,
					minNumValue,
					setNumberRangeError,
					`Los datos numéricos deben estar entre ${minNumValue} y ${maxNumValue}`
				)

		let validHouse =
			tumacoSelected ||
			isNotEmpty(
				addressValue.house,
				setObligatoryFliedsError,
				'Faltan campos obligatorios. (*)'
			)
		validHouse =
			tumacoSelected && addressValue.splitAddress.first == ''
				? true
				: inRange(
					Number(addressValue.house),
					maxNumValue,
					minNumValue,
					setNumberRangeError,
					`Los datos numéricos deben estar entre ${minNumValue} y ${maxNumValue}`
				)

		let validObservation =
			!tumacoSelected ||
			isNotEmpty(
				addressValue.observation,
				setObligatoryFliedsError,
				'Falta el campo de indicaciones.'
			)

		return (
			validAddressFirst &&
			validAddressThird &&
			validHouse &&
			validName &&
			validObservation
		)
	}

	const onValidateClick = () => {
		cleanErrorMessages()
		if (!validated()) return null

		if (tumacoSelected) {
			if (isSelectAddress) {
				handleModified(indexAddress, addressValue)
			} else {
				const cAdress = `${addressValue.splitAddress.street} ${addressValue.splitAddress.first}${addressValue.splitAddress.second} #${addressValue.splitAddress.third}${addressValue.splitAddress.fourth}-${addressValue.house}, ${addressValue.city}, Colombia`

				const newAddressTumaco = {
					address: cAdress,
					branch: addressValue.branch,
					city: addressValue.city,
					name: addressValue.name,
					house: addressValue.house,
					neighbohood: addressValue.neighbohood,
					observation: addressValue.observation,
					splitAddress: {
						street: addressValue.splitAddress.street,
						first: addressValue.splitAddress.first,
						second: addressValue.splitAddress.second,
						third: addressValue.splitAddress.third,
						fourth: addressValue.splitAddress.fourth
					}
				}

				addNewAddresses(newAddressTumaco)
				setAddressValue(addressObject)
			}
		} else {
			getGeoPointFromAddress()
			openVM()
		}
	}

	const onCancelFormClick = () => {
		cleanErrorMessages()
		setIsSelectAddress(false)
		setAddressValue(addressObject)
		setIndexAddress(undefined)
	}

	const onSaveClick = () => {
		addNewAddresses(addressValue)
		setAddressValue(addressObject)
	}

	const getGeoPointFromAddress = (custom) => {
		const cAdress = `${addressValue.splitAddress.street} ${addressValue.splitAddress.first}${addressValue.splitAddress.second} #${addressValue.splitAddress.third}${addressValue.splitAddress.fourth}-${addressValue.house}, ${addressValue.city}, Colombia`
		if (!customAddress) {
			setCustomAddress(cAdress)
		}
		geocodeByAddress(custom ? custom : cAdress)
			.then((results) => {
				return getLatLng(results[0])
			})
			.catch((error) => {
				console.error(error)
			})
			.then(({ lat, lng }) => {
				setAddressValue({
					...addressValue,
					geopoint: { lat, lng },
					address: custom ? custom : cAdress
				})
			})
			.catch((error) => {
				console.error(error)
			})
	}

	return (
		<>
			<AlertModal
				open={openDelete}
				message='¿Seguro que deseas eliminar esta direccion?'
				acceptAction={handleDelete}
				cancelAction={() => {
					closeDeleteModal()
				}}
				cancelBtnRight
			/>
			<div className='addresses-desktop'>
				<span className='color-blue text-21px-700 section-title'>
					Mis direcciones
				</span>
				<Grid container className='addresses-container' spacing={2}>
					<Grid item xs={12} lg={6} className='addresses-cards'>
						{addresses.length > 0 ? (
							addresses.map((address, index) => (
								<CardDireccion
									key={`CardDireccion-${index}`}
									nameDireccion={address.name}
									direccion={address}
									timeEstimate='26 - 41 min'
									cantaddress={addresses.length}
									onDelete={() => {
										setIndexAddress(index)
										openDeleteModal()
									}}
									onUpdate={() => {
										setIsSelectAddress(true)
										setIndexAddress(index)
										setAddressValue(address)
									}}
									cardType={cardDireccionTypes.ACCOUNT_EDIT_MODE}
								/>
							))
						) : (
							<span className='text-14px-400 color-gray'>
								No has registrado direcciones aún
							</span>
						)}
					</Grid>
					<Grid item xs={12} lg={6} className='addresses-form custom-scrollbar'>
						<FormAddress
							isSelectAddress={isSelectAddress}
							addressValue={addressValue}
							setAddressValue={setAddressValue}
							handleChange={handleChange}
							nameError={nameError}
							obligatoryFliedsError={obligatoryFliedsError}
							numberRangeError={numberRangeError}
							tumacoSelected={tumacoSelected}
							onCancelFormClick={onCancelFormClick}
							onValidateClick={onValidateClick}
							onSaveClick={onSaveClick}
						/>
					</Grid>
				</Grid>
				<AddressCoverageValidationModal
					open={openValidationModal}
					acceptAction={(e) => {
						e.preventDefault()
						cleanErrorMessages()
						if (isSelectAddress) {
							handleModified(indexAddress, addressValue)
						} else {
							addNewAddresses(addressValue)
							setAddressValue(addressObject)
						}
						closeVM()
					}}
					cancelAction={() => {
						closeVM()
						setAddressValue(addressObject)
					}}
					setAddressValue={setAddressValue}
					addressValue={addressValue}
					setCustomAddress={setCustomAddress}
					customAddress={customAddress}
					getGeoPointFromAddress={getGeoPointFromAddress}
					acceptLabel='Guardar'
					isMobile={false}
				/>
			</div>
		</>
	)
}

DireccionesDesktop.propTypes = {
	addNewAddresses: PropTypes.func,
	updateAddresses: PropTypes.func,
	deleteAddress: PropTypes.func
}

export default DireccionesDesktop
