import { Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
// my componets
import CircularLoader from '../../../components/loaders/CircularLoader'
import OrderCard from './OrderCard/OrderCard'
import OrderDetails from './OrderDetails'
// hooks
import useOrders from '../../../hooks/orders/useOrders'

const HistorialDesktop = () => {
	const { loading, orders } = useOrders()

	const [selectedOrder, setSelectedOrder] = useState(null)

	const selectLastCreated = () => {
		let lastCreated = orders.reduce(
			(previous, current) => (
				previous.status.created > current.status.created ? previous : current,
				orders[0]
			)
		)
		setSelectedOrder(lastCreated)
	}

	const checkIfSelected = (orderId) => {
		return selectedOrder ? selectedOrder.id_order === orderId : false
	}

	useEffect(() => {
		if (!orders || orders.length <= 0) return
		if (!selectedOrder) {
			selectLastCreated()
		} else {
			setSelectedOrder((selected) =>
				orders.find((order) => order.id_order === selected.id_order)
			)
		}
	}, [orders])

	return (
		<div className='historial-desktop'>
			<span className='text-21px-700 color-blue title'>
				Historial de pedidos
			</span>
			<Grid
				container
				spacing={2}
				className='principal-container custom-scrollbar'
			>
				<Grid item xs={12} lg={6} className='order-container custom-scrollbar'>
					{!orders || loading ? (
						<CircularLoader />
					) : (
						<>
							{orders.length === 0 ? (
								<span className='text-14px-400 color-gray-dark'>
									No has realizado pedidos aún
								</span>
							) : (
								orders.map((order) => (
									<OrderCard
										key={order.id_order}
										orderNumber={
											order.id_order ? order.id_order : 'Cargando...'
										}
										address={order.address}
                    price={order.final_price}
                    numOfProducts={order.products_order.length}
                    numOfItems={order.products_order.reduce((a, item) => {
                      return a + item.count //order.products_order.count
                    },0)}
                    status={order.order_status}
										selected={checkIfSelected(order.id_order)}
										onClick={() => setSelectedOrder(order)}
									/>
								))
							)}
						</>
					)}
				</Grid>
				<Grid item xs={12} lg={6} className='custom-scrollbar order-details'>
					{selectedOrder && (
						<OrderDetails
							order={selectedOrder}
							setSelectedOrder={setSelectedOrder}
						/>
					)}
				</Grid>
			</Grid>
		</div>
	)
}

export default HistorialDesktop
