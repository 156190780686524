import { useContext } from 'react'
import { context } from '../../context/ContextProvider'

import * as layoutActions from '../../context/layout/layoutActions'

const useLayout = () => {
	const { state, dispatch } = useContext(context)
	const { snack } = state.layoutReducer

	/**
	 * Shows a snackbar message
	 * @param {string} message
	 * @param {'error' | 'info' | 'success' | 'warning'} severity
	 */
	const showSnackbar = (message, severity = 'info') => {
		layoutActions.showSnackbar(dispatch, message, severity)
	}

	/**
	 * Hides the opened snackbar
	 */
	const hideSnackbar = () => {
		layoutActions.hideSnackbar(dispatch)
	}

	return {
		snack,
		showSnackbar,
		hideSnackbar
	}
}

export default useLayout
