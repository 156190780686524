import React from 'react'
import { isBrowser } from 'react-device-detect'
import RegisterPageDesktop from './RegisterPageDesktop'
import RegisterPageMobile from './RegisterPageMobile'

/**
 * Componente que selecciona el diseño de la página de registro dependiendo del dispositivo del usuario.
 * @param {Object} props - Propiedades para el componente.
 * @param {Function} props.handleClose - Función para cerrar el componente.
 * @param {Function} props.handleTransition - Función para cambiar la animación de transición.
 * @param {string} props.to - URL de la página de destino.
 * @returns {React.ReactNode} Elemento React que contiene la página de registro para dispositivos de escritorio o móviles.
 */
const RegisterPage = (props) => {
	const RegisterPageLayout = isBrowser
		? RegisterPageDesktop
		: RegisterPageMobile
	return <RegisterPageLayout {...props} />
}

export default RegisterPage
