import PropTypes from 'prop-types'
import React from 'react'
import useStringFormater from '../../hooks/stringFormater/useStringFormater'
import CustomButton from '../atoms/buttons/CustomButton'
// hooks
import useAuth from '../../hooks/auth/useAuth'
import useShoppingCart from '../../hooks/shoppingCart/useShoppingCart'

/**
 * Renderiza un resumen flotante con el subtotal de los productos, un mensaje opcional y un botón para realizar alguna acción.
 * @param {Object} props - Las propiedades del componente.
 * @param {number} props.price - El precio total de los productos.
 * @param {string} props.buttonLabel - La etiqueta del botón.
 * @param {function} [props.onClick] - La función que se ejecuta cuando se hace clic en el botón.
 * @param {boolean} [props.disabled] - Si el botón debe estar desactivado.
 * @param {boolean} [props.validateUserLoged] - Si se debe validar si el usuario está autenticado para ejecutar la función al hacer clic en el botón.
 * @param {node} [props.message] - El mensaje opcional que se muestra debajo del subtotal.
 * @returns {JSX.Element} El componente FloatingSummary.
 */
const FloatingSummary = (props) => {
	const { price, buttonLabel, onClick, disabled, validateUserLoged, message } =
		props

	const { formatPrice } = useStringFormater()
	const { isLogged, showLoginModal } = useAuth()
	const { wholesalerProducts, generalProducts } = useShoppingCart()

	return (
		<div className='floating-summary floating-card'>
			<div className='subtotal'>
				<span className='text-13px-700 color-gray-dark'>SubTotal</span>
				<span className='text-18px-700 color-blue'>{formatPrice(price)}</span>
			</div>
			<span className='message'>{message}</span>
			{wholesalerProducts.length > 0 || generalProducts.length > 0 ? (
				<CustomButton
					label={buttonLabel}
					width='12.125em'
					buttonProps={{
						disabled: disabled
					}}
					onClick={() => {
						if (validateUserLoged && !isLogged) {
							return showLoginModal()
						}
						onClick()
					}}
				/>
			) : (
				<CustomButton
					label='Carrito vacio'
					width='12.125em'
					variant='outlined'
					buttonProps={{
						disabled: disabled
					}}
				/>
			)}
		</div>
	)
}

FloatingSummary.propTypes = {
	price: PropTypes.number.isRequired,
	buttonLabel: PropTypes.string.isRequired,
	onClick: PropTypes.func,
	disabled: PropTypes.bool,
	validateUserLoged: PropTypes.bool,
	message: PropTypes.node
}

FloatingSummary.defaultProps = {
	onClick: () => {},
	message: (
		<span className='text-11px-400 color-gray-light'>
			Incluye los productos mayoristas y generales
		</span>
	)
}

export default FloatingSummary
