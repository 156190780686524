import React from 'react'
import { Link } from 'react-router-dom'
import arrowBack from '../../../../assets/arrow-back.svg'
import Logo from '../../../../assets/logomercaz1.png'
import WacoHuntSearch from '../../../WacoHuntSearch/WacoHuntSearch'

import { useHistory } from 'react-router'
import { routes } from '../../../../routes/routes'

/**
 * La función AppBarMobileNav crea una barra de navegación
 * para la versión móvil de la aplicación.
 * La barra de navegación consta de un logotipo y un icono
 * de flecha hacia atrás, así como de un componente WacoHuntSearch.
 * @returns {JSX.Element}
 */
const AppBarMobileNav = () => {
  const history = useHistory()

  return (
    <header className='appBarMobile'>
      <div className='logo-navigation'>
        <div>
          <img
            className='back-arrow'
            src={arrowBack}
            alt='back'
            onClick={() => history.goBack()}
          />
          <Link to={routes.home.path} className='logo'>
            <img src={Logo} alt='logo_merca_z' />
          </Link>
        </div>
      </div>
      {/* <div className="search">
                <div className="searchInput -with-shadow full-width">
                    <i className="search-icon"></i>
                    <input type="text" className="" placeholder="Buscar producto" />
                    <div className="buscar">
                        <button >
                            <span className="text-12px-600 color-blue">
                                Buscar
                            </span>
                        </button>
                    </div>
                </div>
            </div> */}
      <WacoHuntSearch isMobile />
    </header>
  )
}

export default AppBarMobileNav
