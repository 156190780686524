import { useContext } from 'react'
import * as authActions from '../../context/auth/authActions'
import { context } from '../../context/ContextProvider'
import * as marketActions from '../../context/markets/marketActions'
import { marketModel, markets_product } from '../../context/models'
// hooks
import getPriceByOffice from '../../utils/getPriceByOffice'
import TotalToPay from '../../utils/TotalToPay'
import useProducts from '../products/useProducts'
import useShoppingCart from '../shoppingCart/useShoppingCart'

const useMarkets = () => {
	const { state, dispatch } = useContext(context)
	const { markets, loading } = state.marketsReducer

	const { authValues } = state.authReducer
	const { ID } = authValues

	const favoriteMarketsIds = authValues.favorites.markets || []

	const { getProductData } = useProducts()

	const { getProductTemplateCopy, addProductsToCart: addToCart } =
		useShoppingCart()

	// templates

	const getMarketTemplate = () => {
		return { ...marketModel }
	}

	const getProductReferenceTemplate = () => {
		return { ...markets_product }
	}

	// get user markets from db

	const getUserMarkets = () => {
		if (authValues.ID) {
			marketActions.getUserMarkets(dispatch, authValues.ID)
		}
	}

	// find market

	const findMarket = (marketId) => {
		return markets ? markets.find((market) => market.id === marketId) : null
	}

	/**
	 * Takes the minified version of product from a market and returns the full version of it,
	 * adding price, price_neto, pum, count and main_category_id properties according to the branch of the expecified city.
	 * @param {object} productRef
	 * @param {string} city
	 * @returns {object} product
	 */
	const getFullProductFromReference = async (
		productRef,
		city,
		branchOffice
	) => {
		let product = await getProductData(
			productRef.main_category_id,
			productRef.id_item
		)
		let branch = product.price_branch_offices.find((branch) =>
			getPriceByOffice(branch, city, branchOffice)
		)

		if (branch === undefined)
			throw new Error(
				'This product doesnt have a branch for the specified city'
			)

		// branch info
		product.price = branch.price
		product.price_neto = branch.price_neto
		product.pum = branch.pum

		product.quantity_pum = productRef.quantity_pum
		product.count = productRef.count
		product.main_category_id = productRef.main_category_id

		if (productRef.unit_type !== undefined) {
			product.unit_type = productRef.unit_type
		}
		return product
	}

	const getReferenceFromFullProduct = (product) => {
		let productReference = getProductReferenceTemplate()
		productReference.id_item = product.id_item
		productReference.count = product.count
		productReference.main_category_id = product.main_category_id

		return productReference
	}

	const totalGenerales = (products) => {
		return TotalToPay(products)
	}

	// get full markets

	const getFullMarkets = (city, branchOffice, markets) => {
		const fullMarkets = markets.map((market) => ({
			...market,
			products: market.products.map(
				async (productRef) =>
					await getFullProductFromReference(productRef, city, branchOffice)
			)
		}))
		return fullMarkets
	}

	const getFullMarketsFromDB = async (city, branchOffice) => {
		try {
			let markets = await marketActions.getUserMarkets(dispatch, ID)
			const fullMarkets = await getFullMarkets(city, branchOffice, markets)
			return fullMarkets
		} catch (error) {
			console.log(error)
			throw error
		}
	}

	const getFullMarketsFromContext = (city) => {
		if (!markets) return null
		const fullMarkets = getFullMarkets(city, markets)
		return fullMarkets
	}

	/**
	 * Takes the products references from a market and returns the full version of them,
	 * adding price, price_neto, pum, count and main_category_id properties to each one,
	 * according to the branch of the expecified city.
	 * @param {object} products
	 * @param {string} city
	 * @returns {Promise<object>}
	 */
	const getFullMarketProducts = async (products, city, branchOffice) => {
		return Promise.all(
			products.map(
				async (productRef) =>
					await getFullProductFromReference(productRef, city, branchOffice)
			)
		)
	}

	//CRUD

	const createMarket = async (market, onSuccess = () => {}) => {
		if (ID) {
			marketActions.createMarket(dispatch, ID, market).then(() => onSuccess())
		} else {
			throw new Error(
				'MercaZ Error: You tried to create a market with empty ID'
			)
		}
	}

	const editMarket = async (
		marketId,
		data,
		loadFast = false,
		onSuccess = () => {}
	) => {
		if (ID) {
			marketActions
				.editMarket(dispatch, ID, marketId, data, loadFast)
				.then(() => onSuccess())
		} else {
			throw new Error('MercaZ Error: You tried to edit a market with empty ID')
		}
	}

	const deleteMarket = (marketId) => {
		if (ID) {
			// remove from favorites
			removeFromFavorite(marketId)
			marketActions.deleteMarket(dispatch, ID, marketId)
		} else {
			throw new Error(
				'MercaZ Error: You tried to delete a market with empty ID'
			)
		}
	}

	const isFavorite = (marketId) => {
		return favoriteMarketsIds.includes(marketId)
	}

	// favorite markets

	const addToFavorite = (marketId) => {
		authActions.changeFavoriteMarkets(ID, [...favoriteMarketsIds, marketId])
	}

	const removeFromFavorite = (marketId) => {
		authActions.changeFavoriteMarkets(
			ID,
			favoriteMarketsIds.filter((market) => market !== marketId)
		)
	}

	const toggleFavorite = (marketId) => {
		isFavorite(marketId)
			? removeFromFavorite(marketId)
			: addToFavorite(marketId)
	}

	const getFavoriteMarkets = () => {
		return markets
			? markets.filter((market) => favoriteMarketsIds.includes(market.id))
			: undefined
	}

	// order market
	const addProductsToCart = (products) => {
		const cartProducts = products.map((product) => {
			let cartProduct = getProductTemplateCopy()
			// set object
			cartProduct.name = product.name
			cartProduct.count = product.count
			cartProduct.id_item = product.id_item
			cartProduct.main_category_id = product.main_category_id
			cartProduct.sub_category_id = product.sub_category_id
			cartProduct.price = product.price
			cartProduct.price_neto = product.price_neto
			cartProduct.pum = product.pum
			cartProduct.quantity_pum = product.quantity_pum
			cartProduct.unit_type = product.unit_type
			cartProduct.brand = product.brand
			cartProduct.provider = product.provider
			// check if product requires cut type
			if (product.cut_type) cartProduct.cut_type = product.cut_type
			// check if product requires maturity
			if (product.maturity) cartProduct.maturity = product.maturity
			return cartProduct
		})
		addToCart(cartProducts)
	}

	return {
		// data
		markets,
		loading,
		// functions
		getFullMarketsFromDB,
		getFullMarketsFromContext,
		findMarket,
		getUserMarkets,
		totalGenerales,
		// CRUD
		deleteMarket,
		createMarket,
		editMarket,
		// templates
		getMarketTemplate,
		getProductReferenceTemplate,
		// favorites
		isFavorite,
		addToFavorite,
		removeFromFavorite,
		toggleFavorite,
		getFavoriteMarkets,
		favoriteMarketsIds,
		// products mappings
		getReferenceFromFullProduct,
		getFullProductFromReference,
		getFullMarketProducts,
		// order market
		addProductsToCart
	}
}

export default useMarkets
