import React from 'react'
// components
import OrderDetails from '../OrderDetails'
// hooks
import { useParams } from 'react-router'
import useOrders from '../../../../hooks/orders/useOrders'

const OrderDetailsPage = () => {
	const { orderId } = useParams()

	const { getOrderFromContext } = useOrders()
	const order = getOrderFromContext(orderId)

	return (
		<div className='order-details'>
			{!order ? (
				<span className='text-14px-400 color-gray-dark'>
					Order no encontrada
				</span>
			) : (
				<OrderDetails order={order} />
			)}
		</div>
	)
}

export default OrderDetailsPage
