import React from 'react'
import PropTypes from 'prop-types'

/**
 * Renderiza pagina de error
 * @param {string} error
 * @returns
 */
const ErrorPage = ({ error }) => {
  return <div>Error Page {typeof error === "string" && error}{typeof error === "object" && JSON.stringify(error)}</div>
}

ErrorPage.propTypes = {
  error: PropTypes.object
}

export default ErrorPage
