import React from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
// subpages
import MercadosList from './MercadosMobile/MercadosList'
import CreateMarket from './MercadosMobile/CreateMarket'
import EditMarket from './MercadosMobile/EditMarket'
import MarketProducts from './MercadosMobile/MarketProducts'
// routes
import { routes } from './MercadosMobile/marketsRoutes'

const MercadosMobile = () => {
	const { path } = useRouteMatch()

	return (
		<Switch>
			<Route
				exact
				path={`${path}${routes.home.path}`}
				component={MercadosList}
			/>
			<Route
				exact
				path={`${path}${routes.create.path}`}
				component={CreateMarket}
			/>
			<Route exact path={`${path}${routes.edit.path}`} component={EditMarket} />
			<Route
				path={`${path}${routes.products.path}`}
				component={MarketProducts}
			/>
		</Switch>
	)
}

export default MercadosMobile
