import React from 'react'
import PropTypes from 'prop-types'

import SectionHeader from '../../components/SectionHeader/SectionHeader'
import ProductCardMobile from '../../components/atoms/cards/ProductCard/ProductCardMobile'
import { Box } from '@material-ui/core'
import { routeFormatter } from '../../routes/routes'
// hooks
import { Link } from 'react-router-dom'
import { Skeleton } from '@material-ui/lab'
import ValidateStock from '../../utils/ValidateStock'

/**
 * Renders the mobile version of the Subcategory page, which displays a list of products
 * belonging to a specific subcategory within a given category, along with some category information.
 * @param {Object} props - The props that are passed to the component.
 * @param {boolean} props.loading - Indicates if the component is still loading data or not.
 * @param {Object} props.category - The category object that the subcategory belongs to.
 * @param {string} props.categoryId - The id of the category that the subcategory belongs to.
 * @param {string} props.city - The name of the city where the user is located.
 * @param {Object} props.subcategory - The subcategory object being displayed.
 * @param {number} props.subcategoryId - The id of the subcategory being displayed.
 * @returns {JSX.Element} - The JSX code that will be rendered to the DOM.
 */
const Subcategorymobile = ({
	loading,
	category,
	categoryId,
	city,
	subcategory,
	subcategoryId
}) => {
	return (
		<div>
			<div className='desktop-page-wide homeMobile'>
				{
					// validating the category avoids crashing when accesing by direct link
					loading || !category ? (
						<section className='category-section'>
							<p>
								<Skeleton animation='wave' variant='rect' width={200} />
							</p>
							<Box display='flex' flexWrap='wrap'>
								{Array(10)
									.fill(0)
									.map((_, i) => (
										<ProductCardMobile loading key={`product-skltn-${i}`} />
									))}
							</Box>
						</section>
					) : (
						<>
							<SectionHeader goBack>
								<Box display='flex' flexWrap='wrap' justifyContent='center'>
									<Link
										to={routeFormatter.toCategory(categoryId)}
										className='text-decoration-none text-capitalize'>
										<span className='text-21px-700 color-gray-light'>
											{category.name.toLowerCase()}
										</span>
									</Link>
									<span className='text-21px-700 color-blue'>
										/&nbsp;{subcategory.subCategoryName.toLowerCase()}
									</span>
								</Box>
							</SectionHeader>
							<div className='cards-mobile-container'>
								{category.products
									.filter((product) => product.sub_category_id == subcategoryId)
									.map(
										(product) =>
											product.price_branch_offices.some((item) =>
												ValidateStock(item, city)
											) && (
												<ProductCardMobile
													key={product.id_item}
													productId={product.id_item}
													categoryId={category.id}
													product={product}
													branch={product.price_branch_offices.find(
														(item) =>
															item.city.toLowerCase() === city.toLowerCase()
													)}
												/>
											)
									)}
							</div>
						</>
					)
				}
			</div>
		</div>
	)
}

Subcategorymobile.propTypes = {
	loading: PropTypes.bool,
	category: PropTypes.object,
	categoryId: PropTypes.number,
	city: PropTypes.string,
	subcategory: PropTypes.object,
	subcategoryId: PropTypes.number
}

export default Subcategorymobile
