import { useEffect, useState } from 'react'
import useAuth from '../../hooks/auth/useAuth'
import useMarkets from '../../hooks/markets/useMarkets'
import useStringFormater from '../../hooks/stringFormater/useStringFormater'

const useMercadoCard = ({
	address,
	city,
	branchOffice,
	products,
	marketId
}) => {
   
	//hooks
	const { showLoginModal } = useAuth()
	const { formatPrice, formatAddress } = useStringFormater()
	const {
		isFavorite,
		toggleFavorite,
		getFullMarketProducts,
		markets,
		totalGenerales
	} = useMarkets()


	const isFav = isFavorite(marketId)
	const [fullProducts, setFullProducts] = useState([])

	// calcula el subtotal de los productos en el carrito
	const getSubtotal = () => {
		const result = totalGenerales(fullProducts)//  para calcular el subtotal 
		return formatPrice(result) // para formatear el resultado antes de devolverlo
	}



	useEffect(() => {
		if (!city) showLoginModal() //si el usuario no esta logeado muestra una modal de inicio de sesion
		getFullMarketProducts(products, city, branchOffice).then((result) => {
			setFullProducts(result) 
		}) // obtiene la lista de productos en funcion de la cuidad y sucursal seleccionadas 
	}, [markets])

	return {
		getSubtotal,
		formatedAddress: address.address ? address.address : formatAddress(address),
		isFav,
		toggleFavorite
	}
}

export default useMercadoCard
