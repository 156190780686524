import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
// import domizLogo from '../../../../assets/domiz.svg'
import domizLogo from '../../../../assets/logomercaz1.png'
import { routes } from '../../../../routes/routes'
import SelectAddressModal from '../../../SelectAddressModal/SelectAddressModal'
import WacoHuntSearch from '../../../WacoHuntSearch/WacoHuntSearch'
import DarkBlueSelect from '../../../atoms/selects/DarkBlueSelect'
// hooks
import useAppBar from '../useAppBar'

import * as cities from '../../../../consts/Cities'
/**
 * El componente `AppBarMobilePrincipal` representa la barra de navegación para dispositivos móviles
 * que se muestra en la parte superior de la pantalla en las páginas principales.
 *
 * Utiliza el hook `useAppBar` para obtener la dirección seleccionada, los elementos del menú,
 * el estado del inicio de sesión, los métodos para cambiar la dirección y abrir el modal de selección de dirección.
 *
 * Renderiza los siguientes elementos:
 *  - El logo de Merca Z y la selección de secciones de navegación
 *  - La dirección seleccionada y un ícono de ubicación
 *  - El componente `WacoHuntSearch` para buscar productos
 *  - El componente `SelectAddressModal` para seleccionar una dirección
 *
 * @returns {JSX.Element} El componente `AppBarMobilePrincipal`
 */
const AppBarMobilePrincipal = () => {
  const {
    // menuItems,
    address,
    isLogged,
    changeAddress,
    openAM,
    setOpenAM,
    haveAddresses
  } = useAppBar()
  const [menuItems, setMenuItems] = useState([])

  useEffect(() => {
    setMenuItems([
      cities.CALI,
      cities.MEDELLIN,
      cities.TUMACO,
    ])
  }, [])

  return (
    <>
      <SelectAddressModal
        open={openAM}
        handleClose={() => setOpenAM(false)}
        acceptLabel='Aceptar'
        setOpenAM={setOpenAM}
      />
      <header className='appBarMobile'>
        <div className='logo-and-sections'>
          <Link to={routes.home.path} className='logo'>
            <img src={domizLogo} alt='logo_merca_z' />
          </Link>
          <div className='sections'>
            <DarkBlueSelect menuItems={menuItems} />
          </div>
        </div>
        <div className='appbar-address' onClick={changeAddress}>
          <div>
            <i className='location-icon -blue' />
            <span className='text-14px-500 color-gray selected-address'>
              {address}
            </span>
            {isLogged && haveAddresses && <i className='arrow -blue -down' />}
          </div>
        </div>
        {/* <div className="search -qr">
                    <div className="searchInput -with-shadow full-width">
                        <i className="search-icon"></i>
                        <input type="text" className="" placeholder="Buscar producto" />
                        <div className="buscar">
                            <button >
                                <span className="text-12px-600 color-blue">
                                    Buscar
                                </span>
                            </button>
                        </div>
                    </div>
                    <div className="qr-code">
                        <div className="qr-code-container">
                            <i className="qr-code-icon"></i>
                        </div>
                    </div>
                </div> */}
        <WacoHuntSearch isMobile />
      </header>
    </>
  )
}

export default AppBarMobilePrincipal
