import React from 'react'
import { isBrowser } from 'react-device-detect'
import FavoriteMarketsDesktop from './FavoriteMarketsDesktop'
import FavoriteMarketsMobile from './FavoriteMarketsMobile/FavoriteMarketsMobile'

const FavoritesProducts = (props) => {
	const FavoriteProudctsLayout = isBrowser
		? FavoriteMarketsDesktop
		: FavoriteMarketsMobile
	return <FavoriteProudctsLayout {...props} />
}

export default FavoritesProducts
