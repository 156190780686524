import firebase from 'firebase/app'
import { useState } from 'react'
// hooks
import useAuth from '../../../hooks/auth/useAuth'
import useLayout from '../../../hooks/layout/useLayout'
import useValidator from '../../../hooks/validator/useValidator'
// constants
import * as documentTypes from './constants'
//import { auth } from '../../../firebase/firestore'

const documentOptions = [
	{
		label: 'CC',
		value: documentTypes.DOCUMENT_TYPE_CC
	},
	{
		label: 'TI',
		value: documentTypes.DOCUMENT_TYPE_TI
	}
]

export default function useEditarDatos() {
	const { showSnackbar } = useLayout()

	const { authValues, updateUserData } = useAuth()
	const {
		validateName,
		validateDocument,
		validateEmail,
		validatePhone,
		isNotEmpty
	} = useValidator()

	// state
	const [name, setName] = useState(authValues.name || '')
	const [nameError, setNameError] = useState('')

	const [lastname, setLastname] = useState(authValues.lastname || '')
	const [lastnameError, setLastnameError] = useState('')

	const [document, setDocument] = useState(authValues.document || '')
	const [documentError, setDocumentError] = useState('')

	const [phone, setPhone] = useState(authValues.phone || '')
	const [phoneError, setPhoneError] = useState('')

	const [email, setEmail] = useState(authValues.email || '')
	const [emailError, setEmailError] = useState('')

	const [birthDate, setbirthDate] = useState(
		authValues.birth_date ? authValues.birth_date.toDate() : null
	)
	const [birthError, setBirthError] = useState('')
	const birthDateFormatted = birthDate
		? `${birthDate.getFullYear()}-${(birthDate.getMonth() + 1)
				.toString()
				.padStart(2, '0')}-${birthDate.getDate().toString().padStart(2, '0')}`
		: ''

	const [selectedIndex, setSelectedIndex] = useState(
		documentOptions.findIndex(
			(option) => option.value === authValues.document_type
		) > 0 || 0
	)

	// handle state change
	const changeInput = (setInput) => (e) => {
		setInput(e.target.value)
	}

	const selectDocumentType = (e) => {
		setSelectedIndex(e.target.selectedIndex)
	}

	const changeDate = (e) => {
		if (!e.target.value) {
			setbirthDate(null)
			return
		}
		let value = e.target.value
		let year = value.split('-')[0]
		let month = value.split('-')[1]
		let date = value.split('-')[2]

		setbirthDate(new Date(year, month - 1, date))
	}

	const validated = () => {
		let nameValid = validateName({ name, setNameError })
		let lastnameValid = validateName({
			name: lastname,
			setNameError: setLastnameError
		})
		let docValid = validateDocument({ document, setDocumentError })
		let phoneValid = validatePhone({ phone, setPhoneError })
		let emailValid = validateEmail({ email, setEmailError })
		let birthValid = isNotEmpty(birthDateFormatted || '', setBirthError)

		return (
			nameValid &&
			lastnameValid &&
			docValid &&
			phoneValid &&
			emailValid &&
			birthValid
		)
	}
	const cleanErrorMessages = () => {
		setNameError('')
		setLastnameError('')
		setDocumentError('')
		setPhoneError('')
		setEmailError('')
		setBirthError('')
	}

	// change user data
	const updateData = (e) => {
		e.preventDefault()
		cleanErrorMessages()

		if (validated()) {
			updateUserData({
				name: name,
				lastname: lastname,
				document: document,
				document_type: documentOptions[selectedIndex]['value'],
				phone: phone,
				email: email,
				birth_date: firebase.firestore.Timestamp.fromDate(birthDate)
			})
				.then(() => {
					const user = firebase.auth().currentUser

					user.updateEmail(email).catch(() => {
						// console.log(error)
					})

					user
						.updateProfile({
							displayName: name
						})
						.then(() => {
							// console.log(response)
							showSnackbar('Se han guardado los datos con éxito', 'success')
						})
						.catch(() => {
							// console.log(error)
							showSnackbar('Ha ocurrido un error, inténtalo más tarde', 'error')
						})
				})
				.catch((error) => {
					console.log(error)
					showSnackbar('Ha ocurrido un error, inténtalo más tarde', 'error')
				})
		}
	}

	return {
		documentOptions,
		selectedIndex,
		selectDocumentType,
		name,
		changeName: changeInput(setName),
		nameError,
		lastname,
		changeLastname: changeInput(setLastname),
		lastnameError,
		document,
		changeDocument: changeInput(setDocument),
		documentError,
		phone,
		changePhone: setPhone,
		phoneError,
		email,
		changeEmail: changeInput(setEmail),
		emailError,
		birthDate,
		changeDate,
		birthDateFormatted,
		birthError,
		updateData
	}
}
