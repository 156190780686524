import React /*, { useContext } */ from 'react'
//import { VisibilityContext } from 'react-horizontal-scrolling-menu'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import useProducts from '../../hooks/products/useProducts'

const OfferCard = (props) => {

  const { /*title, itemId, */src, param, enrute } = props


  //hooks
  const history = useHistory()
  const { handleSearchQuery } = useProducts()
  //const visibility = useContext(VisibilityContext)

  //const visible = visibility.isItemVisible(itemId)

  // dependiendo el cao actualiza la url
  const getRute = (enrute, param) => {
    if (param != '') {
      switch (enrute) {
        case 'Categoria':
          return history.push(`category/${param}`)
        case 'Subcategoria':
          return history.push(`category/${param}`)
        case 'Producto':
          return history.push(`product/${param}`)
        case 'URL Externa': {
          let url = param;
          if (param.includes(window.location.origin)) {
            param = param.replace(window.location.origin, '');
            return history.push(url)
          }
          return window.location.replace(url);
        }
        default:
          break
      }
    }
  }

  const goToResults = () => {
    if (param) {
      handleSearchQuery(param) //Esta función  maneja la lógica relacionada con el término de búsqueda
      history.push(`/search/${param}`) //redirecciona
    }
  }

  return (
    <>
      {enrute === 'Resultado de busqueda' ||
        enrute === 'Marca' ||
        enrute === 'Proveedor' ? (
        <div
          role='button'
          tabIndex={0}
          className='offerCard'
          onClick={goToResults}
        >
          <img src={src} alt={"icon"} />
        </div>
      ) : (
        <div
          role='button'
          tabIndex={0}
          className='offerCard'
          onClick={() => {
            if (param != '') getRute(enrute, param)
          }}
        >
          <img src={src} />
        </div>
      )}
    </>
  )
}
//props
OfferCard.propTypes = {
  title: PropTypes.string,
  itemId: PropTypes.number,
  src: PropTypes.element,
  param: PropTypes.string,
  enrute: PropTypes.string
}

OfferCard.defaultProps = {
  param: ''
}

export default OfferCard
