import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Logo from '../../assets/logomercaz1.png'

// material ui
import { Box, Dialog, DialogContent } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import CustomButton from '../../components/atoms/buttons/CustomButton'
import EditInputText from '../../components/atoms/inputs/EditInputText'

import useResetPasswordPage from './useResetPasswordPage'

const useStyles = makeStyles({
	root: {
		'& .MuiBackdrop-root': {
			backgroundColor: 'rgba(244, 246, 249, 0.75)'
		}
	},
	paper: {
		borderRadius: '25px',
		boxShadow: '4.19689px 11.1917px 27.9793px rgba(74, 74, 74, 0.18)',
		textAlign: 'center',
		maxWidth: '16em',
		'& .MuiDialogContent-root': {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center'
		}
	}
})

/**
 * Componente que representa la página de restablecimiento de contraseña para dispositivos de mobile.
 * @param {Object} props - Propiedades para el componente.
 * @param {Function} props.handleTransition - Función para cambiar la animación de transición.
 * @param {string} props.to - URL de la página de destino.
 * @returns {React.ReactNode} Elemento React que contiene la página de restablecimiento de contraseña para dispositivos de mobile.
 */
const ResetPasswordPageMobile = (props) => {
	const { handleTransition, to } = props
	const classes = useStyles()

	const [open, setOpen] = useState(false)
	const handleClose = () => setOpen(false)
	const handleOpen = () => setOpen(true)

	const {
		emailError,
		reset,
		//authValues
		handleAuthValues
	} = useResetPasswordPage(handleOpen)

	return (
		<div className='o-login-container'>
			<div className='o-login-title-container'>
				<img src={Logo} alt='Logo Merca Z'></img>
				<p className='text-21px-700'>Recuperar contraseña</p>
			</div>
			<div className='o-text-container-resetpassword'>
				<p className='color-gray-light text-16px-300'>
					Escribe tu correo registrador en Merca Z, te enviaremos un correo de
					recuperacion
				</p>
			</div>
			<form className='o-form-resetpassword'>
				<EditInputText
					errorMessage={emailError}
					inputProps={{
						required: true,
						placeholder: 'Correo*',
						autoComplete: 'email',
						autoFocus: true
					}}
					onChange={(e) => handleAuthValues('email', e.target.value)}
				/>
				<Box marginTop='.5rem' width='260px'>
					<CustomButton width='100%' label='Enviar' onClick={reset} />
				</Box>
				<Box marginTop='.5rem' width='260px'>
					<CustomButton
						width='100%'
						variant='outlined'
						color='gray'
						label='Cancelar'
						onClick={() => handleTransition(to.login)}
					/>
				</Box>
			</form>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth='lg'
				classes={{
					root: classes.root,
					paper: classes.paper
				}}>
				<DialogContent>
					<p className='color-gray text-16px-300'>
						Te hemos enviado a tu correo las instrucciones para recuperar tu
						contraseña.
					</p>
					<button
						className='btn-filled button-direcciones'
						onClick={handleClose}>
						Finalizar
					</button>
				</DialogContent>
			</Dialog>
			<div className='o-line-horizontal'></div>
			<div className='o-reset-link-container o-reset-link-container-mobile'>
				<p className='color-gray-light text-16px-300'>¿No tienes cuenta aún?</p>
				{/* <Link className="link">Registrate aquí</Link> */}
				<a className='link' onClick={() => handleTransition(to.register)}>
					Registrate aquí
				</a>
			</div>
			<CustomButton
				color='yellow'
				width='260px'
				iconFirst
				iconClass='arrow -blue -left'
				label='Iniciar sesión'
				onClick={() => handleTransition(to.login)}
			/>
		</div>
	)
}

ResetPasswordPageMobile.propTypes = {
	handleTransition: PropTypes.func,
	to: PropTypes.objectOf(PropTypes.string),
	handleClose: PropTypes.func.isRequired
}

export default ResetPasswordPageMobile
