import React from 'react'
import { isBrowser } from 'react-device-detect'
import NotFoundDesktop from './NotFoundDesktop'
import NotFoundMobile from './NotFoundMobile'

/**
 * Renderiza el componente NotFound en función del tipo de dispositivo.
 * @returns {React.ReactNode} Elemento React que contiene el componente NotFound.
 */
const NotFound = () => {
	const NotFoundLayout = isBrowser ? NotFoundDesktop : NotFoundMobile
	return <NotFoundLayout />
}

export default NotFound
