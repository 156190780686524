import React,  { useMemo } from 'react'
import { Link } from 'react-router-dom'
// my components
import ProductCounter from '../../components/ProductCounter/ProductCounter'
import ProductCounterWithGrams from '../../components/ProductCounter/ProductCounterWithGrams'
// hooks
import useStringFormater from '../../hooks/stringFormater/useStringFormater'
import useProductCounter from '../ProductCounter/useProductCounter'
//resources
// consts
import * as UnitTypes from '../../consts/ProductCountOptions'
// import SubcatsWithGrams from '../../consts/SubcatsWithGrams'
import { productImageBaseUrl } from '../../utils/HasImage'
import { getTotalValueOfProduct } from '../../utils/TotalToPay'
import { productPerKilogram } from '../../utils/productPerKilogram'
// eslint-disable-next-line no-unused-vars
import clsx from 'clsx'
import useObservations from '../../hooks/shoppingCart/useObservations'
//import ProductModel from '../../models/ProductModel'
import NetworkImage from '../NetworkImage/NetworkImage'
import { Maturities } from '../../consts/ProductMaturities'
/**
 * 
 * @param {{
 * product?:ProductModel
 * }} props 
 * @returns 
 */
export const ProductoGeneral = ({
  name,
  price,
  uneditable,
  count,
  onCountChange,
  onUnitTypeChange,
  deleteOnZero,
  onDelete,
  productId,
  categoryId,
  // subcategoryId,
  typeSelectEnable,

  product,
  subtotal
}) => {

  const { formatPrice } = useStringFormater()
  const { counter, incrementCounter, decrementCounter } = useProductCounter({
    initialValue: count
  })

  const { productObservations } = useObservations(productId)

  /**
   * Calculates the maturity label for a product.
   * 
   * @returns {string} The maturity label of the product.
   */
  const maturityLabel = useMemo(() => {
    if (product?.maturity) {
      const maturity = Maturities.find((key) => product.maturity === key.code);
      return maturity?.label || '';
    }
    return '';
  }, [product, Maturities]);

  return (
    <div className={clsx(
      "producto-general",
      productObservations && "productObservations",
      "customScrollBar"
    )}>
      <Link to={`/product/${categoryId}/${productId}`} className='product-img' style={{
        flexDirection: 'column',
      }}>
        <NetworkImage
          width='4rem'
          url={productImageBaseUrl + productId}
          imageProps={{
            alt: `image-product-${productId}`
          }}
        />
        {product?.cut_type && (
          <span className='text-11px-400 color-gray-light'>
            {' ' + product?.cut_type}
          </span>
        )}
        {product?.maturity && (
          <span className='text-11px-400 color-gray-light'>
            {' ' + maturityLabel }
          </span>
        )}
      </Link>
      <div className='product-data'>
        <div className='product-info'>
          <Link
            to={`/product/${categoryId}/${productId}`}
            className='text-13px-700 color-gray-dark text-decoration-none'
          >
            {name}
          </Link>
          <div className='product-prices'>
            {<div className='price'>
              <span className='text-15px-700 color-blue'>
                {formatPrice(subtotal ? subtotal : getTotalValueOfProduct(product))}
              </span>
            </div>}
            <div className='price'>
              {product.unit_type === UnitTypes.WEIGHT.value &&
                <span className='text-9px-400 color-gray-light'>{product.quantity_pum}Gr a</span>
              }
              {(product.unit_type !== UnitTypes.WEIGHT.value && !(product.unit_gram)) &&
                <span className='text-9px-400 color-gray-light'>Unidad a</span>
              }
              {product.unit_type !== UnitTypes.WEIGHT.value && Boolean(product.unit_gram) &&
                <span className='text-9px-400 color-gray-light'> und {formatPrice(product.unit_gram * product.pum)} x {product.count}  </span>
              }
              <span className='text-12px-500 color-blue'>
                {formatPrice(product?.discount > 0 ? product?.price_neto : price)}
              </span>
            </div>

          </div>
        </div>
        <div className='product-actions'>
          {!onDelete ? (
            <i />
          ) : (
            <i className='delete-icon-circle -blue' onClick={onDelete} />
          )}
          {productPerKilogram(true, product) ? (
            <ProductCounterWithGrams
              product={product}
              count={counter}
              setCounter={onCountChange}
              setUnitType={onUnitTypeChange}
              uneditable={uneditable}
              // rangeQuantity={rangeQuantity}
              unitType={product.unit_type}
              unitGram={product.unit_gram}
              selectDisabled={!typeSelectEnable}
              minValue={product.unit_type === "weight" ? product.minimum_grams_sale : 1}
              incrementCounter={() => {
                incrementCounter()
                onCountChange(counter + 1)
              }}
              decrementCounter={(val) => {
                if(product.unit_type === "weight"){
                  if (val === product.minimum_grams_sale) return onDelete();
                } 

                if (deleteOnZero && counter === 1) return onDelete();
                decrementCounter()
                onCountChange(counter - 1)
              }}
            />
          ) : (
            <>
              {count && (
                <ProductCounter
                  count={counter}
                  uneditable={uneditable}
                  incrementCounter={() => {
                    incrementCounter()
                    onCountChange(counter + 1)
                  }}
                  decrementCounter={
                    deleteOnZero && counter === 1
                      ? onDelete
                      : () => {
                        decrementCounter()
                        onCountChange(counter - 1)
                      }
                  }
                />
              )}
            </>
          )}
        </div>
        <p className='product-description'>{productObservations}</p>
      </div>
    </div>
  )
}

// ProductoGeneral.propTypes = {
//   // data
//   product: PropTypes.object,
//   productId: PropTypes.string.isRequired,
//   categoryId: PropTypes.string.isRequired,
//   subcategoryId: PropTypes.string.isRequired,
//   name: PropTypes.string.isRequired,
//   price: PropTypes.number.isRequired,
//   count: PropTypes.number.isRequired,
//   unitType: PropTypes.string,
//   rangeQuantity: PropTypes.number,
//   unitGram: PropTypes.number,
//   // flags
//   uneditable: PropTypes.bool,
//   deleteOnZero: PropTypes.bool,
//   typeSelectEnable: PropTypes.bool,
//   // actions
//   onCountChange: PropTypes.func,
//   onUnitTypeChange: PropTypes.func,
//   onDelete: PropTypes.func
// }

// ProductoGeneral.defaultProps = {
//   product: null,
//   onCountChange: () => { },
//   onUnitTypeChange: () => { },
//   deleteOnZero: true,
//   unitType: UnitTypes.UNIT.value,
//   rangeQuantity: process.env.REACT_APP_RANGE_QUANTITY
// }

export default ProductoGeneral
