import React from 'react'
import { isBrowser } from 'react-device-detect'
import EditarDatosDesktop from './EditarDatosDesktop'
import EditarDatosMobile from './EditarDatosMobile'

const Account = () => {
	const EditarDatosLayout = isBrowser ? EditarDatosDesktop : EditarDatosMobile
	return <EditarDatosLayout />
}

export default Account
