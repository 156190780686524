export default function sectionGenerator({
	title,
	subtitle,
	price,
	priceTitle,
	message,
	withBorder
}) {
	return {
		title: title || 'Título',
		subtitle: subtitle || '',
		price: price || 0,
		priceTitle: priceTitle || '',
		message: message || '',
		withBorder: withBorder || false
	}
}
