import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ProductList from '../ProductList/ProductList'
import CircularLoader from '../loaders/CircularLoader'
// hooks
import useMarkets from '../../hooks/markets/useMarkets'
import useAuth from '../../hooks/auth/useAuth'

const ProductListMarket = (props) => {
	const { marketId, title, city, branchOffice, uneditable, resumeLabel } = props

	const { showLoginModal } = useAuth()

	const { getFullMarketProducts, markets, editMarket, loading } = useMarkets()

	const market = markets.find((market) => market.id === marketId)

	const [fullProducts, setFullProducts] = useState(null)

	// función actualiza el conteo de un producto en un mercado específico
	const handleProductCountChange = (productId) => (count) => {
		let data = {
			products: market.products.map((product) => {
				if (product.id_item !== productId) return product
				return { ...product, count: count }
			})
		}
		editMarket(marketId, data, true)
	}

	//funcion que saca de el mercado un producto eliminado
	const handleProductDelete = (productId) => () => {
		let updatedMarket = {
			...market,
			products: market.products.filter(
				(product) => product.id_item !== productId
			)
		}
		editMarket(market.id, updatedMarket)
	}

	useEffect(() => {
		if (!city) showLoginModal()
		// Early return if market has been deleted
		if (!market) return
		// load market products
		getFullMarketProducts(market.products, city, branchOffice).then(
			(result) => {
				setFullProducts(result)
			}
		)
	}, [markets, marketId])

	return (
		<>
			{
				// ensures markets have been loaded
				!markets || !fullProducts || loading ? (
					<CircularLoader />
				) : // ensures that there are markets
				markets.length === 0 ? (
					<p className='color-gray-dark'>No has creado mercados aún</p>
				) : // ensures target market exists
				!market ? (
					<p className='text-14px-400 color-gray-dark'>
						No se encuentra información de este mercado
					</p>
				) : (
					<ProductList
						contentLoading={loading}
						wholesaler={false}
						uneditable={uneditable}
						title={title}
						products={fullProducts}
						resumeLabel={resumeLabel}
						onProductDelete={handleProductDelete}
						onProductCountChange={handleProductCountChange}
					/>
				)
			}
		</>
	)
}

ProductListMarket.propTypes = {
	marketId: PropTypes.string.isRequired,
	title: PropTypes.string,
	resumeLabel: PropTypes.string,
	uneditable: PropTypes.bool,
	city: PropTypes.string.isRequired,
	branchOffice: PropTypes.string
}

ProductListMarket.defaultProps = {
	onProductDelete: () => {}
}

export default ProductListMarket
