// get paymentcards from db
export const GET_PAYMENTCARD = 'GET_PAYMENTCARD'
export const GET_PAYMENTCARD_SUCCESS = 'GET_PAYMENTCARD_SUCCESS'
export const GET_PAYMENTCARD_FAILURE = 'GET_PAYMENTCARD_FAILURE'
// create
export const CREATE_PAYMENTCARD = 'CREATE_PAYMENTCARD'
export const CREATE_PAYMENTCARD_SUCCESS = 'CREATE_PAYMENTCARD_SUCCESS'
export const CREATE_PAYMENTCARD_FAILURE = 'CREATE_PAYMENTCARD_FAILURE'
// delete
export const DELETE_PAYMENTCARD = 'DELETE_PAYMENTCARD'
export const DELETE_PAYMENTCARD_SUCCESS = 'DELETE_PAYMENTCARD_SUCCESS'
export const DELETE_PAYMENTCARD_FAILURE = 'DELETE_PAYMENTCARD_FAILURE'
// edit
export const EDIT_PAYMENTCARD = 'EDIT_PAYMENTCARD'
export const EDIT_PAYMENTCARD_SUCCESS = 'EDIT_PAYMENTCARD_SUCCESS'
export const EDIT_PAYMENTCARD_FAILURE = 'EDIT_PAYMENTCARD_FAILURE'
