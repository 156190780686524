import React, { useEffect, useState } from 'react'
// hooks
import { useHistory } from 'react-router'
import useAuth from '../../../../hooks/auth/useAuth'
import useMarkets from '../../../../hooks/markets/useMarkets'
// components
import CircularLoader from '../../../../components/loaders/CircularLoader'
import DeleteMarketModal from '../../../../components/MercadoCard/DeleteMarketModal'
import AddressValidationModal from '../../../../components/Modals/AddressValidationModal'
import MercadoCard from '../../../../components/MercadoCard/MercadoCard'
// routes
import { routeFormatter } from '../../../../routes/routes'
import * as AccountSections from '../../../../consts/AccountSections'
import { routeFormatter as marketsRouteFormatter } from '../../../Account/Mercados/MercadosMobile/marketsRoutes'

const FavoriteMarketsMobile = () => {
	const history = useHistory()

	const { getCityStorage, showLoginModal, authValues } = useAuth()
	const city = getCityStorage()

	const addresses = authValues.addresses

	// state
	const [favoriteMarkets, setFavoriteMarkets] = useState(null)
	// delete modal control variables
	const [openDM, setOpenDM] = useState(false)
	// addresses validation modal control variables
	const [openAV, setOpenAV] = useState(false)

	const [marketToDelete, setMarketToDelete] = useState('')

	const {
		deleteMarket,
		getFavoriteMarkets,
		getUserMarkets,
		markets,
		favoriteMarketsIds
	} = useMarkets()

	// effects
	useEffect(() => {
		if (!city) showLoginModal()
		getUserMarkets()
	}, [])

	useEffect(() => {
		if (markets) setFavoriteMarkets(getFavoriteMarkets())
	}, [markets, favoriteMarketsIds])

	// market actions

	const confirmationToDelete = (marketId) => (e) => {
		e.stopPropagation()
		setOpenDM(true)
		setMarketToDelete(marketId)
	}

	const goToEdit = (marketId) => (e) => {
		if (addresses.length === 0) {
			setOpenAV(true)
			return null
		}
		e.stopPropagation()
		history.push(
			`${routeFormatter.toAccount(
				AccountSections.OPTION_MERCADOS
			)}${marketsRouteFormatter.toEdit(marketId)}`
		)
	}

	const handleDelete = () => {
		setOpenDM(false)
		deleteMarket(marketToDelete)
	}

	return (
		<>
			{!favoriteMarkets || !city ? (
				<CircularLoader />
			) : (
				<div className='markets-list'>
					{favoriteMarkets.length > 0 ? (
						favoriteMarkets.map((market) => (
							<MercadoCard
								key={market.id}
								marketId={market.id}
								address={market.address}
								name={market.name}
								products={market.products}
								city={city}
								onEdit={goToEdit(market.id)}
								onDelete={confirmationToDelete(market.id)}
								to={`${routeFormatter.toAccount(
									AccountSections.OPTION_MERCADOS
								)}${marketsRouteFormatter.toProducts(market.id)}`}
							/>
						))
					) : (
						<p className='text-14px-400 color-gray-dark'>
							No tienes mercados favoritos
						</p>
					)}
				</div>
			)}

			<DeleteMarketModal
				open={openDM}
				handleClose={() => setOpenDM(false)}
				onDelete={handleDelete}
			/>
			<AddressValidationModal
				open={openAV}
				handleClose={() => setOpenAV(false)}
				actionToValidate='crear un mercado'
			/>
		</>
	)
}

export default FavoriteMarketsMobile
