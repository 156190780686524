import * as marketsTypes from './marketsActionsTypes'
import db from '../../firebase/firestore'

const userMarketsCollection = (ID) => {
	return db.collection('users').doc(ID).collection('markets')
}

/**
 * Obtiene los mercados de un usuario específico.
 * @async
 * @param {function} dispatch - Función de dispatch del store de Redux.
 * @param {string} ID - ID del usuario para obtener sus mercados.
 * @returns {Promise<Object[]>} - Promesa que se resuelve con un arreglo de objetos que representan los mercados del usuario.
 * @throws {Error} - Si ocurre algún error durante la obtención de los mercados.
 */
export const getUserMarkets = async (dispatch, ID) => {
	return new Promise((resolve, reject) => {
		dispatch({ type: marketsTypes.GET_MARKETS })

		const marketCollection = userMarketsCollection(ID)

		marketCollection
			.get()
			.then((querySnapshot) => {
				const markets = []
				if (!querySnapshot.empty) {
					querySnapshot.forEach((doc) => {
						markets.push({ ...doc.data(), id: doc.id })
					})
				}
				dispatch({ type: marketsTypes.GET_MARKETS_SUCCESS, markets: markets })
				resolve(markets)
			})
			.catch((error) => {
				dispatch({
					type: marketsTypes.GET_MARKETS_FAILURE,
					error: error.message
				})
				reject(error)
			})
	})
}

/**
 * Creates a new market for a user.
 *
 * @param {function} dispatch - The dispatch function from a Redux store.
 * @param {string} userId - The ID of the user to create the market for.
 * @param {Object} market - The market object to create.
 * @returns {Promise<void>} A promise that resolves if the market is created successfully, or rejects if there is an error.
 */
export const createMarket = (dispatch, userId, market) => {
	return new Promise((resolve, reject) => {
		dispatch({ type: marketsTypes.CREATE_MARKET })

		const marketCollection = userMarketsCollection(userId)
		marketCollection
			.add(market)
			.then((marketReference) => {
				dispatch({
					type: marketsTypes.CREATE_MARKET_SUCCESS,
					market: { ...market, id: marketReference.id }
				})
				marketReference.update({ id: marketReference.id })
				resolve()
			})
			.catch((error) => {
				dispatch({
					type: marketsTypes.CREATE_MARKET_FAILURE,
					error: error.message
				})
				reject(error)
			})
	})
}

/**
 * Edits an existing market for a user.
 *
 * @param {function} dispatch - The dispatch function from a Redux store.
 * @param {string} userId - The ID of the user that owns the market.
 * @param {string} marketId - The ID of the market to edit.
 * @param {Object} data - The new data for the market.
 * @param {boolean} [loadFast=false] - A flag indicating whether or not to update the local state immediately after the database update.
 * @returns {Promise<void>} A promise that resolves if the market is edited successfully, or rejects if there is an error.
 */
export const editMarket = (
	dispatch,
	userId,
	marketId,
	data,
	loadFast = false
) => {
	return new Promise((resolve, reject) => {
		dispatch({ type: marketsTypes.EDIT_MARKET })

		if (loadFast) {
			dispatch({
				type: marketsTypes.EDIT_MARKET_SUCCESS,
				marketId: marketId,
				data: data
			})
		}

		userMarketsCollection(userId)
			.doc(marketId)
			.set(data, { merge: true })
			.then(() => {
				if (!loadFast) {
					dispatch({
						type: marketsTypes.EDIT_MARKET_SUCCESS,
						marketId: marketId,
						data: data
					})
				}
				resolve()
			})
			.catch((error) => {
				dispatch({
					type: marketsTypes.EDIT_MARKET_FAILURE,
					error: error.message
				})
				reject(error)
			})
	})
}

/**
 * Elimina un mercado de un usuario.
 * @param {Function} dispatch - Función `dispatch` del store de Redux.
 * @param {string} userId - ID del usuario al que pertenece el mercado.
 * @param {string} marketId - ID del mercado a eliminar.
 * @returns {void}
 */
export const deleteMarket = async (dispatch, userId, marketId) => {
	dispatch({ type: marketsTypes.DELETE_MARKET })

	userMarketsCollection(userId)
		.doc(marketId)
		.delete()
		.then(() => {
			dispatch({ type: marketsTypes.DELETE_MARKET_SUCCESS, marketId: marketId })
		})
		.catch((error) => {
			dispatch({
				type: marketsTypes.DELETE_MARKET_FAILURE,
				error: error.message
			})
		})
}
