import { Fab } from "@material-ui/core";
import { WhatsApp } from "@material-ui/icons";
import React from "react";
import { isMobile } from "react-device-detect";
const WppFloating = () => {

  const handleWhatsAppClick = () => {
    // Aquí debes cambiar el enlace de whatsapp con el número o enlace específico que deseas utilizar
    const whatsappLink = "https://api.whatsapp.com/send?phone=573116702226";
    window.open(whatsappLink, "_blank");
  };
  return <Fab onClick={handleWhatsAppClick} style={{
    position: "fixed",
    right: "2rem",
    bottom: !isMobile ? "2rem" : "6rem",
    zIndex: 999,
    background: "green"
  }} variant="extended" color="primary" aria-label="edit">
    <WhatsApp style={{ marginRight: 2 }} />
    Soporte
  </Fab>
}

export default WppFloating;