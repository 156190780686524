// payment methods
export const methods = {
	CASH: 'CASH',
	CREDIT_CARD: 'CREDIT_CARD',
	DEBIT_CARD: 'DEBIT_CARD',
	ADD_METHOD: 'ADD_METHOD',
	DATAPHONE: 'DATAPHONE',
	PSE:"PSE"
}

// payment cards
export const cards = {
	MASTER_CARD: 'MASTER_CARD',
	VISA: 'VISA'
}

//cards layouts
export const layouts = {
	VISUALIZATION_MODE: 'VISUALIZATION_MODE',
	USER_ACCOUNT: 'USER_ACCOUNT',
	ORDER_SUMMARY: 'ORDER_SUMMARY',
	CARD_SELECTED: 'CARD_SELECTED'
}
