import { Skeleton } from '@material-ui/lab'
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useState } from 'react'
import { isBrowser } from 'react-device-detect'
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu'
import OfferCard from './OfferCard'
//hooks
import useOffers from '../../hooks/offers/useOffers'
import NormalizeBranch from '../../utils/BranchesNormalize'

const OffersSlider = ({ branch }) => {
  //hooks
  const { offers } = useOffers()

  const [offersActive, setOffersActive] = useState([])

  const handleOffersActive = () => {
    let branchOffice = NormalizeBranch(branch)
    if (offers.length > 0 && branchOffice) {
      let promoOffers
      promoOffers = offers.filter(
        (offer) => offer.status && offer.type === 'Promoción'
      ) // se filtran las ofertas que tienen el estado activo y el tipo promoción,

      let offersBranch
      offersBranch = promoOffers.filter((offer) => {
        const result = offer.branches.find(
          (branche) => {
            const same = NormalizeBranch(branche) === NormalizeBranch(branchOffice)
            return same
          })
        return result
      }) // se filtran las ofertas según la sucursal seleccionada
      const dateNow = new Date() //  contiene la fecha actual.
      let offersNow
      offersNow = offersBranch.filter(
        (offer) =>
          offer?.initialDate?.toDate() <= dateNow &&
          offer?.finalDate?.toDate() >= dateNow
      ) // se filtran las ofertas según la fecha actual,
      setOffersActive(offersNow) // se actualiza el estado offersActive con las ofertas que cumplen con los criterios establecidos.
    }
  }

  useEffect(() => {
    handleOffersActive() //es una función que se encarga de filtrar las ofertas disponibles para la sucursal.
  }, [offers, branch]) // se ejecuta cada vez que offers o branch cambien

  const loading = offers.length === 0;
  // console.log("offersActive", offers);

  const OfferCardSkeleton = () => (
    <Skeleton
      variant='rect'
      animation='wave'
      width={isBrowser ? '29em' : '18.69em'}
      height={isBrowser ? '12.5em' : '8.307em'}
      style={{ margin: '0.5rem' }}
    />
  )

  return (
    <div
      style={{
        display: !loading && offersActive.length === 0 ? 'none' : 'block'
      }}
      className={`offersSlider${isBrowser ? 'Desktop' : 'Mobile'}`}>
      {loading && (
        <Skeleton variant='rect' animation='wave' width={100} />
      )}
      <div className='offersBar'>
        <ScrollMenu
          LeftArrow={loading ? null : LeftArrow}
          RightArrow={loading ? null : RightArrow}
          onScroll={() => {
            /*
              const { visible } = e.getItemByIndex(0)
              console.log("visible",visible)
             */
          }}
        >

          {loading
            ? [...Array(6).keys()].map((_, i) => (
              <OfferCardSkeleton key={`offer-skltn-${i}`} />
            ))
            : offersActive.map((offer, i) => (
              <OfferCard
                title={offer.name}
                itemId={i}
                key={`Offercard_${offer.id}`}
                src={offer.image}
                param={offer.param}
                enrute={offer.enrute}
              />
            ))}
        </ScrollMenu>
      </div>
    </div>
  )
}

function LeftArrow() {
  const { isFirstItemVisible, scrollPrev } = useContext(VisibilityContext)

  return (
    <div
      onClick={() => scrollPrev()}
      className={`arrows-container -left ${isFirstItemVisible ? '-disabled' : ''}`}>
      <i
        className={`arrow ${isFirstItemVisible ? '-blue -left' : '-white -left'
          }`}></i>
    </div>
  )
}

function RightArrow() {
  const { isLastItemVisible, scrollNext } = useContext(VisibilityContext)

  return (
    <div
      onClick={() => scrollNext()}
      className={`arrows-container -right ${isLastItemVisible ? '-disabled' : ''}`}>
      <i
        className={`arrow ${isLastItemVisible ? '-blue -right' : '-white -right'
          }`}></i>
    </div>
  )
}

OffersSlider.propTypes = {
  branch: PropTypes.string
}

OffersSlider.defaultProps = {
  branch: ''
}

export default OffersSlider
