import React from 'react'
import { isBrowser } from 'react-device-detect'
import DireccionesDesktop from './DireccionesDesktop'
import DireccionesMobile from './DireccionesMobile'

const Direcciones = () => {
	const DireccionesLayout = isBrowser ? DireccionesDesktop : DireccionesMobile
	return <DireccionesLayout />
}
export default Direcciones
