import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import ProductDetails from './subcomponents/ProductDetails'
// resources
import mayorista from '../../assets/mayorista.svg'
// components
import { Box } from '@material-ui/core'
import { Alert, Skeleton } from '@material-ui/lab'
import { ScrollMenu } from 'react-horizontal-scrolling-menu'
import LiquorValidationModal from '../../components/Modals/LiquorValidationModal'
import NetworkImage from '../../components/NetworkImage/NetworkImage'
import ProductCounter from '../../components/ProductCounter/ProductCounter'
import ProductCounterWithGrams from '../../components/ProductCounter/ProductCounterWithGrams'
import CustomButton from '../../components/atoms/buttons/CustomButton'
import ProductCardMobile from '../../components/atoms/cards/ProductCard/ProductCardMobile'
import ProductMaturity from './subcomponents/ProductMaturity'
// hooks
import { Redirect } from 'react-router'
import { useParams } from 'react-router-dom'
import useAuth from '../../hooks/auth/useAuth'
import useStringFormater from '../../hooks/stringFormater/useStringFormater'
import useProduct from './useProduct'
// constants
import useProducts from '../../hooks/products/useProducts'
import { productImageBaseUrl } from '../../utils/HasImage'
import ValidateStock from '../../utils/ValidateStock'
import { productPerKilogram } from '../../utils/productPerKilogram'
import ProductObservation from './Components/ProductObservation'
import MeatCutTypes from './subcomponents/MeatCutTypes'
import ProductDiscountBadge from '../../components/Products/ProductDiscountBadge'
import getUserAge from '../../utils/age'

/**
 * Componente de diseño de la página de detalle de producto para dispositivos de mobile.
 * @param {Object} props - Propiedades para el componente.
 * @param {string} props.city - Ciudad del usuario.
 * @returns {React.ReactNode} Elemento React que contiene la página de detalle de producto para dispositivos de mobile.
 */
const ProductMobile = ({ city }) => {
	const { formatPrice } = useStringFormater()
	const { categoryId, productId } = useParams()
	const { isLogged, authValues } = useAuth()
	const [cantWholeSaler, setcantWholeSaler] = useState(false)

	const birth_date = authValues.birth_date ? authValues.birth_date : undefined

	const {
		// flags
		isFav,
		toggleFavorite,
		isInCart,
		isWholesalerInCart,
		isLoading,
		productNotFount,
		// product info
		branch,
		productData,
		bar_code,
		//is_wholesaler,
		pum,
		//price,
		description,
		name,
		quantity_pum,
		complementaries,
		unitType,
		unit_gram,
		setUnitType,
		// wholesaler info
		wholesalerInfo,
		// optional product info
		maturity,
		setMaturity,
		needsMaturity,
		cutType,
		setCutType,
		needsCutType,
		// actions
		addToCart,
		removeFromCart,
		changeProductCount,
		changeProductMaturity,
		changeProductCutType,
		removeProductFromCart,
		// validation message
		validationError,
		// counter
		// canSelectUnitType,
		counter,
		incrementCounter,
		decrementCounter,
		setCounter,
		disable,
		hasMinCountGrams,
		// wholesaler counter
		wholesalerCounter,
		incrementWholesalerCounter,
		decrementWholesalerCounter,

		//categories
		categories
	} = useProduct({
		categoryId: categoryId,
		productId: productId
	})

	const Tumaco = 'tumaco'

	const { toggleMarketModal, toggleCompareModal } = useProducts()

	// liquour validation modal
	const [openLM, setOpenLM] = useState(false)
	const [openModalMayorista, setOpenModalMayorista] = useState(false)
	const [errorLiquorValidation, setErrorLiquorValidation] = useState('')

	const isRestrictedCategorie = () => {
		const cat = categories.find((cat) => cat.id === categoryId)

		let isRestrictedCategorie = false
		if (categories.length > 0 && cat.isRestricted) {
			isRestrictedCategorie = true
		}

		return isRestrictedCategorie
	}

	const getLiquorValidation = () => {
		if (birth_date) {
			const age = getUserAge(birth_date)
			return age >= 18 /*
			const today = new Date()
			let age = today.getFullYear() - birth_date.toDate().getFullYear()
			let month = today.getMonth() - birth_date.toDate().getMonth()

			if (
				month < 0 ||
				(month === 0 && today.getDate() < birth_date.toDate().getDate())
			) {
				age--
			}

			let validation = false
			if (age >= 18) {
				validation = true
			}

			return validation*/
		} else return false
	}

	useEffect(() => {
		setcantWholeSaler(wholesalerCounter)
	}, [wholesalerCounter])

	const confirmButtonText = useMemo(() => {
		if (!hasMinCountGrams) return `Minimo ${productData.minimum_grams_sale}Gr`
		if (isInCart) {
			return 'Remover'
		}
		return 'Agregar'
	}, [isInCart, hasMinCountGrams, productData])

	if (productNotFount) {
		// as the page "error_not_found" doesn't exists, router will redirect to 404 page as intended
		return <Redirect to='/error_not_found' />
	}

	return (
		<>
			<div className='mobile-page-wrapper product-page-mobile'>
				{
					/* SKU */
					isLoading ? (
						<Skeleton
							variant='rect'
							animation='wave'
							width='6rem'
							style={{ marginBottom: 10 }}
						/>
					) : (
						<span className='text-16px-400 color-gray-light'>
							SKU: {bar_code}
						</span>
					)
				}
				{
					/* product name */
					isLoading ? (
						<Skeleton
							variant='rect'
							animation='wave'
							width='10rem'
							style={{ marginBottom: '1.453rem' }}
						/>
					) : (
						<span className='text-24px-600 color-gray-dark name'>{name}</span>
					)
				}
				{isLoading ? (
					<Box width='100%' display='flex' justifyContent='center'>
						<Skeleton
							variant='rect'
							animation='wave'
							width='15rem'
							height='15rem'
						/>
					</Box>
				) : (
					<div
						className='product-image'
						style={{
							width: '100%',
							display: 'flex',
							justifyContent: 'center',
							position: 'relative'
						}}>
						{branch?.discount > 0 && (
							<ProductDiscountBadge 
								discount={branch?.discount}
								size='large'
								position='left'
							/>
						)}
						<NetworkImage
							width='80vw'
							url={productImageBaseUrl + productId}
							imageProps={{
								alt: `image-product-${productId}`,
								className: 'image'
							}}
						/>
						{productData.isWholesaler && city === Tumaco && (
							<img className='mayorista' src={mayorista} alt='mayorista' />
						)}
						<div className='icons'>
							{isLogged && (
								<i
									className={`cursor-pointer heart-2 -${
										!isFav ? 'un' : ''
									}checked`}
									onClick={toggleFavorite}
								/>
							)}
							{isLogged && (
								<i
									style={{
										height: '1.8em',
										width: '1.8em'
									}}
									className={`cursor-pointer market-icon-circle`}
									onClick={() => toggleMarketModal(productData)}
									title={'Agregar a mercado'}
								/>
							)}
						</div>
					</div>
				)}
				<div className='details'>
					<div>
						{
							/* pum */
							isLoading ? (
								<Skeleton
									variant='text'
									animation='wave'
									width={80}
									style={{ marginBottom: 10 }}
								/>
							) : (
								<span className='text-13px-400 color-gray-light'>
									Gramo a $ {pum.toString().substring(0, 4)}
								</span>
							)
						}
						{
							/* price */
							isLoading ? (
								<Skeleton variant='rect' animation='wave' width={100} />
							) : (
								<>
									<p className='text-21px-600 color-blue'>
										{formatPrice(branch?.discount? branch?.price_neto : branch?.price)}
										{Boolean(unit_gram) && quantity_pum === 1000 && (
											<span className='text-11px-600 color-gray-light'>
												{' '}
												x Kilo
											</span>
										)}
										{Boolean(unit_gram) && quantity_pum !== 1000 && (
											<span className='text-11px-600 color-gray-light'>
												x{quantity_pum}gr
											</span>
										)}
										{branch?.discount > 0 && (
											<span className='text-13px-400 color-gray-light' style={{
												textDecoration: 'line-through',
												marginLeft: '5px'
											}}>
												{formatPrice(branch?.price)}
											</span>
										)}
									</p>
								</>
							)
						}
					</div>
					{
						/* detalles de un producto mayorista */
						!isLoading && productData?.isWholesaler && city === Tumaco && (
							<div>
								<span className='text-13px-400 color-gray-light'>
									$ {wholesalerInfo?.pum?.toFixed(2) || 'PUM Indefinido'}/g
								</span>
								<span className='text-21px-600 color-blue'>
									{formatPrice(
										wholesalerInfo ? 
										(branch?.discount > 0 ? 
											wholesalerInfo?.price_neto:
											wholesalerInfo?.price || 0)
										: 0
									) || 'Precio Indefindo'}{' '}
									c/u
								</span>
								{
									branch?.discount > 0 && (
										<span className='text-13px-400 color-gray-light' >
										Antes 
										<span style={{
											textDecoration: 'line-through',
											margin: '0 5px'
										}}>
											{formatPrice(wholesalerInfo?.price)}
										</span>
										c/u
										</span>
									)
								}
								<span className='text-13px-400 color-gray-light'>
									{`Mayor a ${productData.wholesaler?.factor} ${
										productData.unit_type === 'weight' ? 'Kg' : 'uds'
									}.`}
								</span>
							</div>
						)
					}
				</div>
				<>
					{!isLoading && needsMaturity && (
						<ProductMaturity
							maturity={maturity}
							setMaturity={(maturity) => {
								setMaturity(maturity)
								if (isInCart) changeProductMaturity(maturity, productId)
							}}
						/>
					)}
					{!isLoading && needsCutType && (
						<MeatCutTypes
							cutType={
								(typeof cutType === 'string' ? cutType : cutType?.cut_type) ||
								'entero'
							}
							setCutType={(maturity) => {
								setCutType(maturity)
								if (isInCart) {
									changeProductCutType(maturity, productId)
								}
							}}
						/>
					)}
					{validationError && (
						<Box margin='.5rem 0 1rem 0'>
							<span className='text-13px-400 color-error'>
								{validationError}
							</span>
						</Box>
					)}
				</>
				<div className='product-type-actions'>
					<div className='actions'>
						{productData?.isActive ? (
							<>
								{productData.isWholesaler && city === Tumaco && (
									<span className='text-14px-700'>
										Comprar como producto General
									</span>
								)}
								<div className='controls' style={{ alignItems: 'center' }}>
									{
										/* counter */
										productPerKilogram(true, productData) ? (
											<ProductCounterWithGrams
												product={productData}
												big
												color='blue'
												rangeQuantity={quantity_pum}
												unitType={unitType}
												unitGram={unit_gram}
												selectDisabled={isInCart}
												loading={isLoading}
												count={counter}
												minValue={
													unitType === 'weight'
														? productData.minimum_grams_sale
														: 1
												}
												setUnitType={setUnitType}
												setCounter={setCounter}
												incrementCounter={() => {
													incrementCounter()
													if (isInCart) {
														changeProductCount(counter + 1, productId)
													}
												}}
												decrementCounter={(val) => {
													if (unitType === 'weight' && isInCart) {
														if (val === productData.minimum_grams_sale)
															return removeFromCart()
													} else {
														if (val === 0) return removeFromCart()
													}
													decrementCounter()
													if (isInCart) {
														changeProductCount(counter - 1, productId)
														if (counter === 1) removeFromCart()
													}
												}}
											/>
										) : (
											<ProductCounter
												big
												color='blue'
												loading={isLoading}
												count={counter}
												isDisable={disable}
												incrementCounter={() => {
													incrementCounter()
													if (isInCart) {
														changeProductCount(counter + 1, productId)
													}
												}}
												decrementCounter={() => {
													decrementCounter()
													if (isInCart) {
														changeProductCount(counter - 1, productId)
														if (counter === 1) removeFromCart()
													}
												}}
											/>
										)
									}
									<CustomButton
										loading={isLoading}
										label={confirmButtonText}
										disable={counter === 0 || !hasMinCountGrams}
										iconClass='cart-icon-2 -white -menu'
										iconFirst
										width='111px'
										onClick={() => {
											if (isInCart) {
												removeFromCart()
											} else if (isRestrictedCategorie()) {
												setOpenLM(true)
											} else {
												addToCart({
													maturity: maturity,
													cutType: cutType
												})
											}
										}}
									/>
								</div>
								<div
									style={{
										width: '100%'
									}}>
									<CustomButton
										label='Comparar'
										variant='outlined'
										width='140px'
										loading={isLoading}
										onClick={() => toggleCompareModal(productData)}
									/>
								</div>
							</>
						) : (
							<>
								<Alert icon={false} variant={'standard'} severity={'info'}>
									Este producto se encuentra inactivo.
								</Alert>
							</>
						)}
					</div>
					{productData?.isWholesaler &&
						productData.wholesaler &&
						city === Tumaco && (
							<div className='actions'>
								<span className='text-14px-700'>
									Comprar como producto Mayorista
								</span>
								<div
									className='controls'
									style={{ marginTop: '0.5rem', alignItems: 'center' }}>
									<ProductCounter
										big
										color='blue'
										loading={false}
										count={cantWholeSaler}
										isDisable={disable}
										incrementCounter={() => {
											incrementWholesalerCounter(productData.wholesaler.factor)
											if (isWholesalerInCart) {
												changeProductCount(
													cantWholeSaler + productData.wholesaler.factor,
													productId,
													{ asWholesaler: true }
												)
											}
										}}
										decrementCounter={() => {
											decrementWholesalerCounter(productData.wholesaler.factor)
											if (isWholesalerInCart) {
												changeProductCount(
													cantWholeSaler - productData.wholesaler.factor,
													productId,
													{ asWholesaler: true }
												)
												if (cantWholeSaler === 25)
													removeProductFromCart(productId, {
														asWholesaler: true
													})
											}
										}}
									/>
									{Boolean(unit_gram) && (
										<span
											className={`text-17px-500 color-blue`}
											style={{ marginLeft: '-0.5rem' }}>
											Kg
										</span>
									)}
									<CustomButton
										loading={isLoading}
										disable={wholesalerCounter === 0}
										label={isWholesalerInCart ? 'Remover' : 'Agregar'}
										iconClass='cart-icon-2 -white -menu'
										iconFirst
										onClick={() => {
											if (isWholesalerInCart) {
												removeProductFromCart(productId, { asWholesaler: true })
											} else if (isRestrictedCategorie()) {
												setOpenModalMayorista(true)
											} else {
												addToCart({
													asWholesaler: true,
													maturity: maturity,
													cutType: cutType
												})
											}
										}}
									/>
								</div>
							</div>
						)}
				</div>
				<ProductObservation productId={productId} categoryId={categoryId} />
				{!isLoading && description ? (
					<ProductDetails description={description} />
				) : (
					<ProductDetails description={'Este producto no tiene descripción.'} />
				)}
			</div>
			{complementaries.length > 0 && (
				<div className='related-products'>
					<section className='products-section'>
						<div className='title'>
							<p className='text-21px-700 color-blue'>Productos relacionados</p>
						</div>
						<ScrollMenu>
							{complementaries.map(
								(product) =>
									// validates if products is available in selected city
									product.price_branch_offices.some((item) =>
										ValidateStock(item, city)
									) && (
										<ProductCardMobile
											key={product.id_item}
											productId={product.id_item}
											categoryId={product.main_category_id}
											product={product}
											branch={product.price_branch_offices.find(
												(item) => item.city.toLowerCase() === city.toLowerCase()
											)}
										/>
									)
							)}
						</ScrollMenu>
					</section>
				</div>
			)}
			<LiquorValidationModal
				isMobile
				open={openLM || openModalMayorista}
				handleClose={() => setOpenLM(false)}
				onAccept={() => {
					if (!isLogged) {
						setErrorLiquorValidation(
							'Inicia Sesion o Registrate para validar si eres mayor de edad'
						)
					} else if (isLogged && !getLiquorValidation()) {
						setErrorLiquorValidation('No cumples con la mayoria de edad')
					} else {
						addToCart({
							asWholesaler: openModalMayorista,
							cutType: cutType,
							maturity: maturity
						})
						setOpenLM(false)
						setOpenModalMayorista(false)
					}
				}}
				onDecline={() => {
					setOpenLM(false)
					setOpenModalMayorista(false)
				}}
				errorMessage={errorLiquorValidation}
			/>
		</>
	)
}

ProductMobile.propTypes = {
	city: PropTypes.string.isRequired
}

export default ProductMobile
