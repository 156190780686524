import React from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
// subpages
import { routes } from './HistorialMobile/historialRoutes'
import OrderDetailsPage from './HistorialMobile/OrderDetailsPage'
import OrdersListPage from './HistorialMobile/OrdersListPage'

const HistorialMobile = () => {
	const { path } = useRouteMatch()
	return (
		<Switch>
			<Route
				exact
				path={`${path}${routes.home.path}`}
				component={OrdersListPage}
			/>
			<Route
				path={`${path}${routes.details.path}`}
				component={OrderDetailsPage}
			/>
		</Switch>
	)
}

export default HistorialMobile
