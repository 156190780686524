import React from 'react'
import { useHistory } from 'react-router-dom'
// hooks
import useAccountDesktop from './useAccountDesktop'
import useAuth from '../../hooks/auth/useAuth'
// components
import SectionHeader from '../../components/SectionHeader/SectionHeader'
import UserInfo from './UserInfo/UserInfo'
import MenuOption from './MenuOption/MenuOption.js'
// constants
import * as options from '../../consts/AccountSections'
import { routes } from '../../routes/routes'

/**
 * Creates a desktop version of the account component with the necessary user information and options.
 * @returns {JSX.Element} - Returns a React element representing the desktop version of the account component.
 */
const AccountDesktop = () => {
	const { RenderedOption, isSelected, name, document, phone, email } =
		useAccountDesktop()

	const { handleLogOut } = useAuth()

	const history = useHistory()
	const logOut = () => {
		handleLogOut()
		history.push(routes.home.path)
	}

	return (
		<div className='account-container-desktop desktop-page-wrapper'>
			<SectionHeader title='Mi cuenta' />
			<div className='account-body'>
				<div className='account-menu'>
					<UserInfo
						name={name}
						document={document}
						phone={phone}
						email={email}
					/>
					<div className='account-navigation'>
						<MenuOption
							label='Mis direcciones'
							option={options.OPTION_DIRECCIONES}
							isSelected={isSelected}
						/>
						{/* <MenuOption
							label='Mis tarjetas'
							option={options.OPTION_TARJETAS}
							isSelected={isSelected}
						/> */}
						<MenuOption
							label='Mis mercados'
							option={options.OPTION_MERCADOS}
							isSelected={isSelected}
						/>

						<MenuOption
							label='Historial de pedidos'
							option={options.OPTION_HISTORIAL_PEDIDOS}
							isSelected={isSelected}
						/>
					</div>
					<button className='btn-account-logout' onClick={logOut}>
						<span>Cerrar sesión</span>
						<i className='arrow -white' />
					</button>
				</div>
				<div className='account-component custom-scrollbar'>
					<RenderedOption />
				</div>
			</div>
		</div>
	)
}

export default AccountDesktop
