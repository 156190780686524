import * as cartSteps from '../../hooks/shoppingCart/cartSteps'
import * as shoppingCartActionsTypes from './shoppingCartActionsTypes'

/**
 * The initial state for the shopping cart reducer.
 */
export const initialState = {
	error: null,
	loading: false,
	mobileLayout: {
		currentStep: cartSteps.PRODUCTOS
	},
	changedProducts: [],
	showCartValidation: false,
	cupon: null,
	observation: null
}

/**
 * The reducer function for the shopping cart state.
 *
 * @param {Object} prevState - The previous state of the shopping cart.
 * @param {Object} action - The action to be performed on the shopping cart state.
 * @returns {Object} The updated state of the shopping cart.
 */
export const shoppingCartReducer = (prevState = initialState, action) => {
	switch (action.type) {
		case shoppingCartActionsTypes.SET_CURRENT_STEP:
			return {
				...prevState,
				mobileLayout: {
					...prevState.mobileLayout,
					currentStep: action.step
				}
			}
		case shoppingCartActionsTypes.SET_CUPON:
			return {
				...prevState,
				cupon: action.value
			}
		case shoppingCartActionsTypes.SET_OBSERVATION:
			return {
				...prevState,
				observation: action.value
			}
		case shoppingCartActionsTypes.SET_CHANGED_PRODUCTS:
			return {
				...prevState,
				changedProducts: action.value,
				showCartValidation: true
			}
		//clear changed products
		case shoppingCartActionsTypes.CLEAR_CHANGED_PRODUCTS:
			return {
				...prevState,
				changedProducts: [],
				showCartValidation: false
			}
		default:
			return prevState
	}
}
