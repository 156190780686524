import React from 'react'
import { useHistory } from 'react-router-dom'
import { routeFormatter } from '../../routes/routes'
import * as AccountSections from '../../consts/AccountSections'

const AddCard = () => {
	const history = useHistory()

	return (
		<div className='payment-method'>
			<div className='body'>
				<div className='card-title'>
					<i className='card-icon -black' />
					<span className='color-black-light text-10px-400'>
						No tienes tarjetas de pago registradas
					</span>
				</div>
				<span
					className='text-decoration-none color-blue text-12px-600'
					onClick={(e) => {
						e.stopPropagation()
						history.push(
							routeFormatter.toAccount(AccountSections.OPTION_TARJETAS)
						)
					}}
				>
					Agregar
				</span>
			</div>
		</div>
	)
}

export default AddCard
