import * as UnitTypes from '../consts/ProductCountOptions';
import { isTypeUnits } from "./Helpers";
/**
 * This function computes the total amount to pay for a list of products
 * considering the type of unit (units or grams)
 * @param {*} products
 */
export default function (products) {
  return products.reduce((acc, product) => {
    return acc + parseFloat(product.subtotal)
  }, 0)
}

export const getProductUnitTypeName = (product) => {
  switch (product.unit_type) {
    case UnitTypes.UNIT.value:
      return UnitTypes.UNIT.label
    case UnitTypes.WEIGHT.value:
      return UnitTypes.WEIGHT.label
    default:
      return ""
  }
}

export const getTotalValueOfProducts = (products) => {

  return products.reduce((a, i) => {
    const quantity_pum = i.unit_gram || 250
    if (isTypeUnits(i.unit_type)) {
      return (
        a +
        (i.unit_gram ? i.count * i.unit_gram * i.pum : i.price_neto * i.count)
      )
    }
    return a + i.count * quantity_pum * i.pum
  }, 0)
}

export const getTotalValueOfProduct = (product, isWholesaler = false) => {
  const unit_gram = Number(product.unit_gram) || 250;
  const price = (
    product?.discount > 0 ?
    product.price_neto :
    product.price
  )
 // console.log(product)
  switch (product.unit_type) {
    case UnitTypes.UNIT.value:
      if (isWholesaler)
        return (product.count / product.factor) * price;
      if (product.unit_gram) {
        return product.count * unit_gram * product.pum
      } else {
        return product.count * price
      }
    case UnitTypes.WEIGHT.value:
      if (isWholesaler){
        return (product.count / product.factor) * price;
      }
      return (product.count * unit_gram * product.pum).toFixed(2)
    default:
      return 0
  }

}