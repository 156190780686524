import db from '../../firebase/firestore'
import NormalizeBranch from '../../utils/BranchesNormalize'
import ValidateStock from '../../utils/ValidateStock'
import * as actionTypes from './productsActionsTypes'

const categoriesDB = db.collection('categories')

/**
 * Loads all active categories from the database based on the given branch office.
 * @param {Function} dispatch - The dispatch function from the Redux store.
 * @param {string} branchOffice - The branch office to load categories from.
 * @returns {Promise} A promise that resolves with an array of categories or rejects with an error.
 */
export const loadAllCategories = (dispatch, branchOffice) => {
	return new Promise((resolve, reject) => {
		branchOffice = NormalizeBranch(branchOffice)
		dispatch({
			type: actionTypes.LOAD_ALL_CATEGORIES
		})
		if (branchOffice === 'Medellin') {
			categoriesDB
				.where('branches', 'array-contains-any', ['Santa Lucia', 'Guayabal'])
				.where('isActive', '==', true)
				.onSnapshot(
					(querySnapshot) => {
						const cats = querySnapshot.docs.map((document) => ({
							...document.data(),
							products: []
						}))
						dispatch({
							type: actionTypes.LOAD_ALL_CATEGORIES_SUCCESS,
							value: cats
						})
						resolve(cats)
					},
					(err) => {
						dispatch({
							type: actionTypes.LOAD_ALL_CATEGORIES_FAILURE,
							error: err.message
						})
						reject(err)
					}
				)
		} else {
			categoriesDB
				.where('branches', 'array-contains', branchOffice)
				.where('isActive', '==', true)
				.onSnapshot(
					(querySnapshot) => {
						const cats = querySnapshot.docs.map((document) => ({
							...document.data(),
							products: []
						}))
						dispatch({
							type: actionTypes.LOAD_ALL_CATEGORIES_SUCCESS,
							value: cats
						})
						resolve(cats)
					},
					(err) => {
						dispatch({
							type: actionTypes.LOAD_ALL_CATEGORIES_FAILURE,
							error: err.message
						})
						reject(err)
					}
				)
		}
	})
}

/**
 * Loads all products of a given category.
 * @param {function} dispatch - A function from Redux to dispatch actions.
 * @param {string} id - The ID of the category to load products from.
 * @returns {Promise<Array>} - A promise that resolves with an array of products once they are loaded.
 */
export const loadCategoryProducts = (dispatch, id) => {
	dispatch({ type: actionTypes.LOAD_CATEGORY_PROD })
	return new Promise((resolve, reject) => {
		categoriesDB
			.doc(id)
			.collection('products')
			.where('isActive', '==', true)
			.onSnapshot(
				(querySnapshot) => {
					const products = querySnapshot.docs.map((docSnapshot) =>
						docSnapshot.data()
					)
					dispatch({
						type: actionTypes.LOAD_CATEGORY_PROD_SUCCESS,
						value: products,
						categoryId: id,
						replace: true
					})
					resolve(products)
				},
				(err) => {
					dispatch({
						type: actionTypes.LOAD_CATEGORY_PROD_FAILURE,
						error: err.message
					})
					reject(err)
				}
			)
	})
}

/**
 * Load a limited number of products for a specific category based on stock availability at a specific branch office and city, and return a promise
 * @param {function} dispatch - Redux dispatch function
 * @param {string} id - ID of the category to load products from
 * @param {string} city - Name of the city where the branch office is located
 * @param {string} branchOffice - Name of the branch office to load products from
 * @param {number} limit - Maximum number of products to load
 * @returns {Promise} Promise that resolves to an array of products
 */
export const loadLimitedCategoryProducts = (
	dispatch,
	id,
	city,
	branchOffice,
	limit
) => {
	return new Promise((resolve, reject) => {
		categoriesDB
			.doc(id)
			.collection('products')
			.limit(limit)
			.where('isActive', '==', true)
			.onSnapshot(
				(querySnapshot) => {
					const products = querySnapshot.docs.map((docSnapshot) =>
						docSnapshot.data()
					)

					let productsfiltered = []

					products.map((m) => {
						let hasStock = m.price_branch_offices.some((item) =>
							ValidateStock(item, city, branchOffice)
						)
						if (hasStock && productsfiltered.length < 10) {
							productsfiltered.push(m)
						}
					})

					dispatch({
						type: actionTypes.LOAD_CATEGORY_PROD_SUCCESS,
						value: productsfiltered,
						categoryId: id,
						replace: true
					})
					resolve(productsfiltered)
				},
				(error) => {
					dispatch({
						type: actionTypes.LOAD_CATEGORY_PROD_FAILURE,
						error: error.message
					})
					reject(error)
				}
			)
	})
}

/**
 * Load products from a specific subcategory.
 * @param {function} dispatch - Redux dispatch function.
 * @param {string} categoryId - ID of the category containing the subcategory.
 * @param {string} subCategoryId - ID of the subcategory to load products from.
 * @param {string} city - Name of the city where the products should be available.
 * @param {string} branchOffice - Name of the branch office where the products should be available.
 * @param {boolean} limited - Flag indicating whether to limit the number of products loaded.
 * @returns {Promise} Promise that resolves with an array of products.
 */
export const loadSubCategoryProducts = (
	dispatch,
	categoryId,
	subCategoryId,
	city,
	branchOffice,
	limited
) => {
	return new Promise((resolve, reject) => {
		if (limited) {
			categoriesDB
				.doc(categoryId)
				.collection('products')
				.where('sub_category_id', '==', subCategoryId)
				.where('isActive', '==', true)
				.limit(100)
				.onSnapshot(
					(querySnapshot) => {
						const products = querySnapshot.docs.map((docSnapshot) =>
							docSnapshot.data()
						)
						let productsfiltered = []

						products.map((m) => {
							let hasStock = m.price_branch_offices.some((item) =>
								ValidateStock(item, city, branchOffice)
							)
							if (hasStock && productsfiltered.length < 10) {
								productsfiltered.push(m)
							}
						})

						// delete previous products from subcat
						dispatch({
							type: actionTypes.CLEAN_SUBCATEGORY_PRODUCTS,
							sub_category_id: subCategoryId,
							categoryId: categoryId
						})
						dispatch({
							type: actionTypes.LOAD_CATEGORY_PROD_SUCCESS,
							value: productsfiltered,
							categoryId: categoryId,
							replace: false
						})
						resolve(productsfiltered)
					},
					(err) => {
						dispatch({
							type: actionTypes.LOAD_CATEGORY_PROD_FAILURE,
							error: err.message
						})
						reject(err)
					}
				)
		} else {
			categoriesDB
				.doc(categoryId)
				.collection('products')
				.where('sub_category_id', '==', subCategoryId)
				.where('isActive', '==', true)
				.onSnapshot(
					(querySnapshot) => {
						const products = querySnapshot.docs.map((docSnapshot) =>
							docSnapshot.data()
						)
						dispatch({
							type: actionTypes.LOAD_CATEGORY_PROD_SUCCESS,
							value: products,
							categoryId: categoryId,
							replace: true
						})
						resolve(products)
					},
					(err) => {
						dispatch({
							type: actionTypes.LOAD_CATEGORY_PROD_FAILURE,
							error: err.message
						})
						reject(err)
					}
				)
		}
	})
}

/**
 * Dispatches an action to indicate that all categories have been fully loaded.
 *
 * @param {function} dispatch - The dispatch function from the Redux store.
 */
export const allCategoriesLoaded = (dispatch) => {
	dispatch({
		type: actionTypes.ALL_CATEGORIES_FULL_LOADED
	})
}

/**
 * Loads a product with the given ID and category ID from the database.
 *
 * @param {string} productId - The ID of the product to load.
 * @param {string} categoryId - The ID of the category that the product belongs to.
 * @returns {Promise<object>} A Promise that resolves to the data of the loaded product.
 */
export const loadProduct = async (productId, categoryId) => {
	return new Promise((resolve, reject) => {
		categoriesDB
			.doc(categoryId)
			.collection('products')
			.doc(productId)
			.onSnapshot(
				(docReference) => {
					resolve(docReference.data())
				},
				(error) => {
					reject(error)
				}
			)
	})
}

/**
 * Adds product data to its respective category in products context variable
 * @param {object} product
 * @param {string} categoryId
 * @param {function} dispatch
 */
export const addProductToCategory = (product, categoryId, dispatch) => {
	dispatch({
		type: actionTypes.ADD_PRODUCT_TO_CATEGORY_CONTEXT,
		product: product,
		categoryId: categoryId
	})
}
