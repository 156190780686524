/* eslint-disable react/prop-types */
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import errorImage from '../../../../assets/error.png'
import useAuth from '../../../../hooks/auth/useAuth'
import useStringFormater from '../../../../hooks/stringFormater/useStringFormater'
import { productImageBaseUrl } from '../../../../utils/HasImage'

import { Popover } from '@material-ui/core'
import { SearchOutlined } from '@material-ui/icons'
import { Link } from 'react-router-dom'
import SearchWacoHunt from '../../../../utils/SearchWacoHunt'
import CircularLoader from '../../../loaders/CircularLoader'
import useAsyncText from '../useAsyncText'
import './Search.scss'
/**
 *
 * @param {{
 * onChange:()=>void,
 * onEnter:()=>void,
 * placeholder:string
 * }} props
 * @returns
 */
const CustomInputAsync = ({ onChange, onEnter, placeholder }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const { asyncText, setAsyncText, setAsyncText_immediately } =
    useAsyncText(500)
  const [value, setValue] = useState('')
  const handleChange = (e) => {
    e.preventDefault()
    setAnchorEl(e.currentTarget)
    const newVal = e.target.value
    setValue(newVal)
    if (newVal === '') {
      setAsyncText_immediately('')
      setAsyncText('')
      e.focus()
      return
    }

    setAsyncText(newVal)
  }

  useEffect(() => {
    if (onChange) {
      onChange(asyncText, anchorEl)
      anchorEl && anchorEl.focus()
    }
    // eslint-disable-next-line
  }, [asyncText])

  const isSearch = value !== asyncText
  return (
    <div className='searchInput'>
      <i className='search-icon'></i>
      <input
        placeholder={placeholder || ''}
        onChange={handleChange}
        value={value}
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            onEnter && onEnter()
          }
        }}
      />
      <div className='buscar'>
        <button
          type='button'
          className='cursor-pointer'
          onClick={() => {
            onEnter && onEnter()
          }}>
          {!isSearch && (
            <span className='text-12px-600 color-blue'>Buscar</span>
          )}
          {isSearch && (
            <div style={{ zoom: '0.5' }}>
              <CircularLoader />
            </div>
          )}
        </button>
      </div>
    </div>
  )
}

/**
 * Función que devuelve una sucursal de la ciudad dada.
 * @param {Array} branches - Un array de objetos que representa las sucursales.
 * @returns {Object} Un objeto que representa la sucursal de la ciudad dada.
 */
const cityBranch = (branches, city) => {
  return (
    branches.find((item) => item.city.toLowerCase() === city.toLowerCase()) ||
    {}
  )
}

const Search = (props) => {
  const { isMobile } = props
  const { formatPrice } = useStringFormater()
  const { getCityStorage, showLoginModal, getCityBranchOffice } = useAuth()
  const [hits, setHits] = useState([])
  const [currentSearch, setCurrentSearch] = useState('')
  const [anchorEl, setAnchorEl] = React.useState(null)
  const branch = getCityBranchOffice()
  const city = getCityStorage()
  let history = useHistory()
  useEffect(() => {
    if (!city) showLoginModal()
  }, [])

  const onSuggestionSelected = (suggestion) => {
    // setValue('')
    setAnchorEl(null)
    history.push(
      `/product/${suggestion.main_category_id}/${suggestion.id_item}`
    )
  }
  /**
   * Función que renderiza cada sugerencia de búsqueda.
   * @param {Object} hit - El objeto que representa la sugerencia de búsqueda.
   * @returns {JSX.Element} El componente que representa la sugerencia de búsqueda.
   */
  const renderSuggestion = (hit) => {
    const { name, price_branch_offices, unit_measurement_pum } = hit

    const { price, pum } = cityBranch(price_branch_offices, city)
    let imagen = productImageBaseUrl + hit.id_item

    return (
      <div
        key={`hit_${hit.id_item}`}
        onClick={() => onSuggestionSelected(hit)}
        className={`search__suggestion${isMobile ? '_mobile' : ''
          } react-autosuggest__container`}>
        <div
          className='image-container'
          style={{
            backgroundImage: `url(${imagen})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}>
          <img
            style={{ display: 'none' }}
            src={imagen}
            onError={(e) => (
              (e.target.onerror = null), (e.target.src = errorImage)
            )}
          />
        </div>
        <div className='info'>
          <span className='text-15px-500 color-gray-dark name'>{name}</span>
          <span className='text-14px-400'>{formatPrice(price)}</span>
          {unit_measurement_pum && pum && <span className='text-12px-400'>
            {unit_measurement_pum} a {pum.toString().substring(0, 7)}
          </span>}
        </div>
      </div>
    )
  }

  const [loading, setLoading] = useState(false);

  /**
   * Recupera sugerencias de búsqueda según el valor proporcionado.
   *
   * @param {Object} value - El valor actual del campo de búsqueda.
   */
  const onSuggestionsFetchRequested = ({ value }) => {
    if (value) {
      setLoading(true)
      SearchWacoHunt(value, {
        isActive: true,
        limit: 5,
        city: city,
        branch
      }).then((res) => {
        setHits(res.hits)
      }).finally(() => {
        setLoading(false)
      })
    }
  }

  const handleChange = (newValue = '', anchorEl) => {
    if (typeof newValue !== "string") return
    setCurrentSearch(newValue)
    setAnchorEl(anchorEl)
    // setValue(newValue)
    onSuggestionsFetchRequested({ value: newValue })
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const gotoSearchScreen = () => {
    handleClose()
    if (currentSearch)
      history.push(`/search/${encodeURIComponent(currentSearch)}`)
  }

  return (
    <form
      className={`Search search${isMobile ? '-mobile' : ''}`}
      onSubmit={(event) => event.preventDefault()}>
      <CustomInputAsync
        placeholder={'Buscar productos'}
        onChange={handleChange}
        onEnter={gotoSearchScreen}
      />
      <Popover
        className='SearchPopover'
        PaperProps={{ className: 'Search__suggest_container' }}
        onClose={handleClose}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        disableAutoFocus={true}
        disableEnforceFocus={true}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}>
        {hits && !loading && (
          <div className='Search__suggest_container__internal'>
            {hits.map((hit) => {
              return renderSuggestion(hit)
            })}
            {hits.length > 0 && (
              <Link
                to={
                  currentSearch
                    ? `/search/${encodeURIComponent(currentSearch)}`
                    : '#'
                }
                onClick={handleChange}
                className='Search__suggest_container__viewmore'>
                VER MAS RESULTADOS
                <SearchOutlined />
              </Link>
            )}
            {hits.length === 0 && (
              <div className='Search__suggest_container__resultszero'>
                <b>Busqueda sin resultados</b>
              </div>
            )}
          </div>
        )}
        {loading && (<div className='Search__suggest_container__resultszero'>
          <b>Buscando...</b>
        </div>)}
      </Popover>
    </form>
  )
}

Search.propTypes = {
  isMobile: PropTypes.bool
}

Search.defaultProps = {
  isMobile: false
}

export default Search
