import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
//components
import { Box } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import LiquorValidationModal from '../../../Modals/LiquorValidationModal'
import NetworkImage from '../../../NetworkImage/NetworkImage'
import ProductCounter from '../../../ProductCounter/ProductCounter'
import ProductCounterWithGrams from '../../../ProductCounter/ProductCounterWithGrams'
import CustomButton from '../../buttons/CustomButton'
//hooks
import useAuth from '../../../../hooks/auth/useAuth'
import useProducts from '../../../../hooks/products/useProducts'
import useShoppingCart from '../../../../hooks/shoppingCart/useShoppingCart'
import useStringFormater from '../../../../hooks/stringFormater/useStringFormater'
import useFavProducts from '../../../../hooks/user/useFavProducts'
import useProductCounter from '../../../ProductCounter/useProductCounter'
import useProductCard from './useProductCard'
//consts
import * as UnitTypes from '../../../../consts/ProductCountOptions'

import { useMemo } from 'react'
import mayorista from '../../../../assets/mayorista.svg'
import { productImageBaseUrl } from '../../../../utils/HasImage'
import { productPerKilogram } from '../../../../utils/productPerKilogram'
import ProductDiscountBadge from '../../../Products/ProductDiscountBadge'
import getUserAge from '../../../../utils/age'

/** 
 * El componente RectSkeleton es un componente funcional de React que utiliza
 * el componente Skeleton de Material-UI para crear un esqueleto en forma de rectángulo animado.
 * Puede ser utilizado en cualquier lugar donde se requiera una animación
 * de carga de un rectángulo, como en la carga de imágenes.
 * @param {*} props
 * @returns {JSX.Element}
 */
const RectSkeleton = (props) => (
  <Skeleton variant='rect' animation='wave' {...props} />
)

/**
 * El componente LoadingContent es un componente funcional de React
 * que renderiza un esqueleto animado de carga en forma de tarjeta de producto.
 * @returns {JSX.Element}
 */
const LoadingContent = () => (
  <div className={`productCardMobile`}>
    <div className='product-name'>
      <p>
        <RectSkeleton width={200} />
      </p>
    </div>
    <div className='image'>
      <RectSkeleton
        width={120}
        height={120}
        style={{ marginBottom: '0.5rem' }}
      />
    </div>
    <div className='product-price'>
      <p>
        <RectSkeleton width={100} />
      </p>
    </div>
    <Box display='flex' justifyContent='center'>
      <CustomButton loading width={'4.5em'} height='2em' titleSmall={true} />
    </Box>
  </div>
)

/**
 * Componente de card product mobile cuando se haya cargado el contenido.
 * @param {*} param0
 * @returns
 */
const LoadedContent = ({ categoryId, productId, product, branch, isSmall }) => {
  // Error handling
  if (!categoryId)
    throw new Error(
      'MercaZ Error: product is not loading but category id was not specified'
    )
  if (!productId)
    throw new Error(
      'MercaZ Error: product is not loading but product id was not specified'
    )
  if (!product)
    throw new Error(
      'MercaZ Error: product is not loading but product was not specified'
    )
  if (!branch)
    throw new Error(
      'MercaZ Error: product is not loading but branch was not specified'
    )

  // utilities
  const { formatPrice } = useStringFormater()

  const { changeProductCount, isProductInCart, products, wholesalerProducts } =
    useShoppingCart()
  const { categories, toggleMarketModal } = useProducts()
  const { isFavorite, toggleFavorite } = useFavProducts()

  const isFav = isFavorite(productId, categoryId)
  const isInCart = isProductInCart(productId)
  const isWholesalerInCart = isProductInCart(productId, { asWholesaler: true })
  const { isLogged, authValues } = useAuth()

  const { counter, incrementCounter, decrementCounter, setCounter } =
    useProductCounter({
      initialValue: isInCart
        ? products.filter((product) => product.id_item === productId)[0].count
        : 1
    })

  const [wholesalerCounter, setWholesalerCounter] = useState(
    isWholesalerInCart
      ? wholesalerProducts.filter((product) => product.id_item === productId)[0]
        .count.count
      : 25
  )
  const {
    bar_code = '',
    name = '',
    sub_category_id = '',
    quantity_pum = product.quantity_pum,
    brand = product.brand,
    provider = product.provider,
    // unit_gram,
  } = product || {}

  // state
  const [disable, setDisable] = useState(false)

  const [unitType, setUnitType] = useState(
    isInCart
      ? products.find((product) => product.id_item === productId).unit_type
      : UnitTypes.UNIT.value
  )

  useEffect(() => {
    setDisable(branch.stock === counter && counter !== 0)
  }, [counter])

  const {
    hasMinCountGrams,
    // flags
    canSelectUnitType,
    // actions
    addToCart,
    removeFromCart
  } = useProductCard({
    // data
    name,
    sub_category_id,
    quantity_pum,
    counter,
    productId,
    branch,
    categoryId,
    product,
    unitType,
    brand,
    provider,
    // actions
    incrementCounter,
    wholesalerCounter,
    setWholesalerCounter
  })

  const birth_date = authValues.birth_date ? authValues.birth_date : undefined

  // liquour validation modal
  const [openLM, setOpenLM] = useState(false)
  const [errorLiquorValidation, setErrorLiquorValidation] = useState('')


  /** Determines whether the product category is restricted.
   * @function
   *@returns {boolean} - Whether the product category is restricted or not.
   */
  const isRestrictedCategorie = () => {
    const cat = categories.find((cat) => cat.id === categoryId)

    let isRestrictedCategorie = false
    if (cat.isRestricted) {
      isRestrictedCategorie = true
    }

    return isRestrictedCategorie
  }

  /**
   * Returns a boolean value indicating whether a person is old enough to legally purchase alcohol, based on their birthdate.
   * @function
   * @returns {boolean} A boolean value indicating whether a person is old enough to purchase alcohol.
   *                    If birth_date is not defined or is not a valid date, the function returns false.
   */
  const getLiquorValidation = () => {
    if (birth_date) {
      const age = getUserAge(birth_date)
      return age >= 18;/*
      const today = new Date()
      let age = today.getFullYear() - birth_date.toDate().getFullYear()
      let month = today.getMonth() - birth_date.toDate().getMonth()

      if (
        month < 0 ||
        (month === 0 && today.getDate() < birth_date.toDate().getDate())
      ) {
        age--
      }

      let validation = false
      if (age >= 18) {
        validation = true
      }

      return validation */
    } else return false
  }
  const confirmButtonText = useMemo(() => {
    if (!hasMinCountGrams) return `Minimo ${product.minimum_grams_sale}Gr`
    if (isInCart) {
      return 'Remover'
    }
    return 'Agregar'
  }, [isInCart, hasMinCountGrams, product])

  return (
    <div className={`productCardMobile ${isSmall ? '-small' : ''}`}>
      <div className='product-name'>
        <Link
          to={`/product/${categoryId}/${productId}`}
          className='text-15px-700 color-gray-dark text-decoration-none'>
          {name}
        </Link>
        <span className='text-9px-400 color-gray-light'>{`SKU:${bar_code}`}</span>
      </div>
      {JSON.parse(process.env.REACT_APP_FETCH_IMAGE) && (
        <div className='image' style={{ position: 'relative' }}>
          {branch?.discount > 0 && (
            <ProductDiscountBadge discount={branch.discount} />
          )}
          {product.isWholesaler && branch?.city === 'Tumaco' && (
            <img
              src={mayorista}
              alt='mayorista'
              style={{
                position: 'absolute',
                width: '55px',
                height: '55px',
                left: '0',
                bottom: '-7px'
              }}
            />
          )}
          <Link to={`/product/${categoryId}/${productId}`}>
            <NetworkImage
              width='8rem'
              url={
                productImageBaseUrl + productId}
              imageProps={{
                alt: `image-product-${productId}`
              }}
            />
          </Link>
        </div>
      )}
      <div className='product-count'>
        {
          /* counter */
          productPerKilogram(canSelectUnitType, product) ? (
            <ProductCounterWithGrams
              product={product}
              big={!isSmall}
              rangeQuantity={quantity_pum}
              unitType={unitType}
              setUnitType={setUnitType}
              selectDisabled={isInCart}
              count={counter}
              setCounter={setCounter}
              incrementCounter={(e) => {
                e.stopPropagation()
                incrementCounter()
                if (isInCart) {
                  changeProductCount(counter + 1, productId)
                }
              }}
              decrementCounter={(e) => {
                e.stopPropagation()
                decrementCounter()
                if (isInCart) {
                  changeProductCount(counter - 1, productId)
                  if (counter === 1) removeFromCart()
                }
              }}
            />
          ) : (
            <ProductCounter
              big={!isSmall}
              count={counter}
              isDisable={disable}
              incrementCounter={(e) => {
                e.stopPropagation()
                incrementCounter()
                if (isInCart) {
                  changeProductCount(counter + 1, productId)
                }
              }}
              decrementCounter={(e) => {
                e.stopPropagation()
                decrementCounter()
                if (isInCart) {
                  changeProductCount(counter - 1, productId)
                  if (counter === 1) removeFromCart()
                }
              }}
            />
          )
        }
      </div>
      <div className='product-price'>
        <div className='price'>
          <span className='text-9px-400 color-gray-light'>
            {product.unit_measurement === 'UND'
              ? ''
              : `gramo a $${branch.pum.toString().substring(0, 4)}`}
          </span>
          <span className='text-21px-700 color-blue'>
            {formatPrice(branch?.discount? branch?.price_neto : branch.price)}
            {Boolean(product.unit_gram) && quantity_pum === 1000 && (
              <span className='text-11px-600 color-gray-light'> x Kilo</span>
            )}
            {Boolean(product.unit_gram) && quantity_pum !== 1000 && (
              <span className='text-11px-600 color-gray-light'>x{quantity_pum}gr</span>
            )}
            {branch?.discount > 0 && (
              <span className='text-13px-400 color-gray-light' style={{
                textDecoration: 'line-through',
                marginLeft: '5px'
              }}>
                {formatPrice(branch?.price)}
              </span>
            )}
          </span>
        </div>
        <div className='mayorist'></div>
      </div>
      <div
        className='product-actions'
        style={{
          display: 'flex',
          alignItems: 'center'
        }}>
        {isLogged && (
          <i
            className={`cursor-pointer heart-2 -${!isFav ? 'un' : ''}checked -${isSmall ? 'small' : ''
              }`}
            onClick={() => toggleFavorite(productId, categoryId)}
          />
        )}
        {isLogged && (
          <i
            className={`cursor-pointer market-icon-circle -unchecked -small`}
            onClick={() => toggleMarketModal(product)}
            title={`Agregar a mercado`}
          />
        )}
        <CustomButton
          label={confirmButtonText}
          color='blue'
          iconClass={`cart-icon-2 -${isSmall ? 'small' : ''} -white -menu`}
          iconFirst={true}
          // width={isSmall ? '4.5em' : '7em'}
          disable={counter === 0 || !hasMinCountGrams}
          width={!isLogged ? '100%' : '7em'}
          height={isSmall ? '2em' : ''}
          onClick={(e) => {
            e.stopPropagation()
            if (isInCart) return removeFromCart()
            if (isRestrictedCategorie()) {
              setOpenLM(true)
            } else {
              addToCart()
            }
          }}
          titleSmall={isSmall ? true : false}
        />
        <LiquorValidationModal
          isMobile
          open={openLM}
          handleClose={() => setOpenLM(false)}
          onAccept={() => {
            if (!isLogged) {
              setErrorLiquorValidation(
                'Inicia Sesion o Registrate para validar si eres mayor de edad'
              )
            } else if (isLogged && !getLiquorValidation()) {
              setErrorLiquorValidation('No cumples con la mayoria de edad')
            } else {
              addToCart()
              setOpenLM(false)
            }
          }}
          onDecline={() => {
            setOpenLM(false)
          }}
          errorMessage={errorLiquorValidation}
        />
      </div>
    </div>
  )
}

/**
 * Renders a product card on mobile view. Displays either a loading state or the product details.
 * @function ProductCardMobile
 * @param {object} props - The props object for the component.
 * @param {boolean} props.loading - A boolean value indicating whether the component should display a loading state.
 * @returns {JSX.Element} A JSX element representing the product card.
 */
const ProductCardMobile = (props) => {
  const { loading } = props
  return loading ? <LoadingContent /> : <LoadedContent {...props} />
}

ProductCardMobile.propTypes = {
  loading: PropTypes.bool.isRequired
}

LoadedContent.propTypes = {
  product: PropTypes.object,
  branch: PropTypes.object,
  categoryId: PropTypes.string,
  productId: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.string.isRequired
  ]),
  isSmall: PropTypes.bool,
  loading: PropTypes.bool
}

export default ProductCardMobile
