import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// hooks
import useProducts from '../../hooks/products/useProducts'

import SectionHeader from '../../components/SectionHeader/SectionHeader'
import { Link } from 'react-router-dom'
import { Box } from '@material-ui/core'
import { routeFormatter } from '../../routes/routes'
import CustomScrollMenu from '../../components/CustomScrollMenu/CustomScrollMenu'
import ProductCardDesktop from '../../components/atoms/cards/ProductCard/ProductCardDesktop'
import { Skeleton } from '@material-ui/lab'
import ValidateStock from '../../utils/ValidateStock'
import getPriceByOffice from '../../utils/getPriceByOffice'

/**
 * Componente para mostrar una categoría de productos en la versión de escritorio.
 * @param {Object} props - Propiedades del componente.
 * @param {boolean} props.loading - Indica si los productos están cargando.
 * @param {string} props.category - Nombre de la categoría a mostrar.
 * @param {string} props.city - Nombre de la ciudad en la que se encuentran las tiendas.
 * @param {string} props.branchOffice - Nombre de la sucursal en la que se encuentran las tiendas.
 * @returns {React.ReactNode} Elemento React que contiene la categoría de productos en la versión de escritorio.
 */
const Categorydesktop = ({ loading, category, city, branchOffice }) => {
	return (
		<div className='desktop-page-wide homeDesktop'>
			{loading || !category ? (
				<section className='category-section'>
					<p>
						<Skeleton animation='wave' variant='rect' width={200} />
					</p>
					<CustomScrollMenu hideArrows>
						{Array(10)
							.fill(0)
							.map((_, i) => (
								<ProductCardDesktop loading key={`product-skltn-${i}`} />
							))}
					</CustomScrollMenu>
					<p>
						<Skeleton animation='wave' variant='rect' width={200} />
					</p>
					<CustomScrollMenu hideArrows>
						{Array(10)
							.fill(0)
							.map((_, i) => (
								<ProductCardDesktop loading key={`product-skltn-${i}`} />
							))}
					</CustomScrollMenu>
				</section>
			) : (
				<>
					<SectionHeader title={category.name.toLowerCase()} />
					{category.subcategories_data.filter((subC) => subC.subCategoryName)
						.length > 0 ? (
						category.subcategories_data
							.filter((subC) => subC.subCategoryName)
							.map((subcat, i) => (
								<section key={i} className='category-section'>
									<div className='title'>
										<p className='text-21px-700 color-blue'>
											{subcat.subCategoryName}
										</p>
										<Link
											to={routeFormatter.toSubcategory(
												category.id,
												subcat.subcategory
											)}
											className='text-decoration-none'>
											<Box display='flex' alignItems='center'>
												<span className='color-red text-14px-700'>
													Ver mas&nbsp;
												</span>
												<i className='arrow -red' />
											</Box>
										</Link>
									</div>
									<LazyCategory
										category={category}
										products={category.products.filter(
											(product) => product.sub_category_id == subcat.subcategory
										)}
										city={city}
										branchOffice={branchOffice}
										subcatId={subcat.subcategory}
										name={subcat.subCategoryName}
									/>
								</section>
							))
					) : (
						<div style={{ height: '200px' }}>
							Por el momento no tenemos productos en esta categoría
						</div>
					)}
				</>
			)}
		</div>
	)
}

const LazyCategory = ({ category, city, branchOffice, products, subcatId }) => {
	const { loadSubCategoryProducts } = useProducts()
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		const onChange = (entries, observer) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					loadSubCategoryProducts(
						category.id,
						subcatId,
						city,
						branchOffice,
						true,
						setLoading
					)
					observer.unobserve(entry.target)
				}
			})
		}

		const observer = new IntersectionObserver(onChange, {
			root: null,
			rootMargin: '200px',
			threshold: 0.3
		})
		observer.observe(document.getElementById(subcatId))

		return () => observer.disconnect()
	}, [category.id])

	return (
		<div id={subcatId}>
			{products.length > 0 && !loading && (
				<CustomScrollMenu>
					{
						// validates products is not empty
						products.map(
							(product) =>
								// validates if products is available in selected city
								product.price_branch_offices.some((item) =>
									ValidateStock(item, city, branchOffice)
								) && (
									<ProductCardDesktop
										className='product-card-margin'
										key={product.id_item}
										productId={product.id_item}
										itemId={product.id_item}
										categoryId={category.id}
										product={product}
										branch={product.price_branch_offices.find((item) =>
											getPriceByOffice(item, city, branchOffice)
										)}
									/>
								)
						)
					}
				</CustomScrollMenu>
			)}
			{loading && (
				<CustomScrollMenu hideArrows>
					{Array(10)
						.fill(0)
						.map((_, i) => (
							<ProductCardDesktop loading key={`product-skltn-${i}`} />
						))}
				</CustomScrollMenu>
			)}

			{!loading && products.length === 0 && (
				<div style={{ height: '200px' }}>
					Por el momento no tenemos productos en esta categoría
				</div>
			)}
		</div>
	)
}

LazyCategory.propTypes = {
	category: PropTypes.object,
	city: PropTypes.string,
	branchOffice: PropTypes.string,
	products: PropTypes.array,
	subcatId: PropTypes.string
}

Categorydesktop.propTypes = {
	loading: PropTypes.bool,
	category: PropTypes.object,
	city: PropTypes.string,
	branchOffice: PropTypes.string
}

export default Categorydesktop
