import React from 'react'
import { isBrowser } from 'react-device-detect'
import UserInfoDesktop from './UserInfoDesktop'
import UserInfoMobile from './UserInfoMobile'

const UserInfo = (props) => {
	const UserInfoLayout = isBrowser ? UserInfoDesktop : UserInfoMobile
	return <UserInfoLayout {...props} />
}

export default UserInfo
