import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import CustomButton from '../atoms/buttons/CustomButton'
import EditInputText from '../atoms/inputs/EditInputText'
import EditSelect from '../atoms/selects/EditSelect'

//hooks
import useAuth from '../../hooks/auth/useAuth'
import usePaymentCards from '../../hooks/paymentCards/usePaymentCards'
import useStringFormater from '../../hooks/stringFormater/useStringFormater'
import useValidator from '../../hooks/validator/useValidator'
import {
	formatCreditCardNumber,
	formatCVC,
	formatExpirationDate
} from '../../utils/CardsUtils'

import { usePaymentInputs } from 'react-payment-inputs'
import images from 'react-payment-inputs/images'

/**
 * Formulario para creacion de una PaymentCard
 *
 * @param {Function} handleClose
 * @param {object} card
 * @param {Function} onCreate
 * @param {Function} onEdit
 * @param {Function} prepareToCreate
 * @returns {JSX.Element}
 */
const CreateCard = ({ card, onCreate, onEdit, prepareToCreate }) => {
	const editMode = Boolean(card)

	const { createPaymentCard, getPaymentCardsTemplate, editPaymentCard } =
		usePaymentCards()
	const { isNotEmpty } = useValidator()

	const { getCardNumberProps, getCardImageProps } = usePaymentInputs()

	const initialLocalPaymentCard = editMode
		? card
		: {
				...getPaymentCardsTemplate()
		  }
	const [localPaymentCard, setLocalPaymentCard] = useState(
		initialLocalPaymentCard
	)

	useEffect(() => {
		setLocalPaymentCard(initialLocalPaymentCard)
	}, [card])

	//addressOptions
	const { authValues } = useAuth()
	const addresses = authValues.addresses

	const { formatAddress } = useStringFormater()

	// Este código genera un arreglo de opciones de direcciones a partir de un arreglo de direcciones.
	// Se filtran las direcciones que corresponden a la ciudad de Tumaco y luego se mapean para crear un objeto
	// que contenga una etiqueta (label) y un valor (value) correspondientes a cada dirección.
	const addressesOptions = addresses
		.filter((add) => add.city != 'Tumaco')
		.map((address) => ({
			label: address.address ? address.address : formatAddress(address),
			value: address
		}))

	// error messages
	const [maskError, setMaskError] = useState('')
	const [nameError, setNameError] = useState('')
	const [dateError, setDateError] = useState('')
	const [addressError, setAddressError] = useState('')

	// La función handleChange toma dos parámetros: name y value.
	const handleChange = (name, value) => {
		if (name === 'mask') {
			value = formatCreditCardNumber(value)
		} else if (name === 'date') {
			value = formatExpirationDate(value)
		} else if (name === 'token_card') {
			value = formatCVC(value)
		} else if (name === 'name') {
			value = value.toUpperCase()
		}
		setLocalPaymentCard((localPaymentCard) => ({
			...localPaymentCard,
			[name]: value
		}))
	}

	/**
	 * Limpia los mensajes de error estableciendo las propiedades de estado correspondientes en una cadena vacía.
	 */
	const cleanErrorMessages = () => {
		setMaskError('')
		setNameError('')
		setDateError('')
		setAddressError('')
	}

	/**
	 * Comprueba si los valores de la tarjeta de pago local son válidos.
	 * @returns {boolean} - Verdadero si todos los valores son válidos, de lo contrario falso.
	 */
	const validated = () => {
		let validMask = isNotEmpty(localPaymentCard.mask, setMaskError)
		let validName = isNotEmpty(localPaymentCard.name, setNameError)
		let validDate = isNotEmpty(localPaymentCard.date, setDateError)
		let validAddress = false
		if (addressesOptions.length <= 0) {
			validAddress = isNotEmpty(
				localPaymentCard.address.address,
				setAddressError
			)
		} else {
			validAddress = true
		}
		return validMask && validName && validDate && validAddress
	}

	/**
	 * Lleva a cabo la acción principal de la función.
	 * Limpia los mensajes de error, valida los campos de entrada y crea o edita una card.
	 *
	 * @returns {null} Si la validación falla o no hay tarjetas de pago disponibles.
	 */
	const onClick = () => {
		cleanErrorMessages()
		if (!validated()) return null

		let card = {
			...localPaymentCard
		}

		if (editMode) {
			editPaymentCard(card.id, card, false)
			prepareToCreate()
			onEdit()
		} else {
			card.created_date = new Date()
			createPaymentCard({ ...card })
			prepareToCreate()
			setLocalPaymentCard(initialLocalPaymentCard)
			onCreate()
		}
	}

	return (
		<div className='create-card form-card'>
			<span className='text-16px-700 color-blue title'>
				{editMode ? 'Editar tarjeta' : 'Agregar tarjeta'}
			</span>
			<Grid container spacing={2} className='body'>
				<Grid item xs={12}>
					<Grid container alignItems='center' justifyContent='space-between'>
						<Grid item xs={10}>
							<div className={`edit-data-input-text -blue`}>
								<span className='text-10px-400 color-gray label'>
									Número de tarjeta
								</span>
								<div className='input'>
									<input
										{...getCardNumberProps({
											onChange: (e) => handleChange('mask', e.target.value)
										})}
										value={localPaymentCard.mask}
										placeholder=''
									/>
								</div>
								<span className='text-12px-400 error-message'>{maskError}</span>
							</div>
						</Grid>
						<Grid item xs={2} className='icon'>
							<svg {...getCardImageProps({ images })} />
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={6}>
					<EditInputText
						label='Fecha'
						value={localPaymentCard.date}
						onChange={(e) => handleChange('date', e.target.value)}
						errorMessage={dateError}
					/>
				</Grid>
				<Grid item xs={6}>
					<EditInputText
						label='CVC'
						value={localPaymentCard.token_card}
						onChange={(e) => handleChange('token_card', e.target.value)}
						//errorMessage={dateError}
					/>
				</Grid>
				<Grid item xs={12}>
					<EditInputText
						label='Nombre del propietario'
						value={localPaymentCard.name}
						onChange={(e) => handleChange('name', e.target.value)}
						errorMessage={nameError}
					/>
				</Grid>
				<Grid item xs={12}>
					{addressesOptions.length > 0 ? (
						<EditSelect
							label='Dirección'
							options={addressesOptions}
							selectedIndex={addressesOptions.findIndex(
								(option) => option.value === localPaymentCard.address
							)}
							onSelect={(e) => handleChange('address', e.target.value)}
						/>
					) : (
						<EditInputText
							label='Dirección'
							value={
								typeof localPaymentCard.address === 'string'
									? localPaymentCard.address
									: localPaymentCard.address.address
							}
							onChange={(e) => handleChange('address', e.target.value)}
							errorMessage={addressError}
						/>
					)}
				</Grid>
			</Grid>
			<div className='btn-container'>
				{editMode && (
					<CustomButton
						label={'Cancelar'}
						variant='outlined'
						onClick={prepareToCreate}
					/>
				)}
				<CustomButton
					label={editMode ? 'Editar' : 'Agregar'}
					onClick={onClick}
				/>
			</div>
		</div>
	)
}

CreateCard.propTypes = {
	card: PropTypes.object,
	onCreate: PropTypes.func,
	onEdit: PropTypes.func,
	prepareToCreate: PropTypes.func
}

CreateCard.defaultProps = {
	onCreate: () => {},
	onEdit: () => {},
	prepareToCreate: () => {}
}

export default CreateCard
