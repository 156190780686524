import PropTypes from 'prop-types'
import React from 'react'

/**
 * Componente TextArea para campos de entrada de texto extenso.
 *
 * @param {object} props - Propiedades del componente.
 * @param {string} props.label - Etiqueta para identificar el campo.
 * @param {string} props.value - Valor del campo.
 * @param {function} props.onChange - Función que se ejecuta cuando cambia el valor del campo.
 * @param {object} props.inputProps - Propiedades adicionales para el elemento <textarea>.
 * @param {string} props.errorMessage - Mensaje de error a mostrar si el campo no es válido.
 * @param {string} props.variant - Variante de estilo del componente. Puede ser 'blue' o 'white'.
 *
 * @returns {JSX.Element} Componente TextArea.
 */
const TextArea = (props) => {
	const { label, value, onChange, inputProps, errorMessage, variant } = props

	return (
		<div className={`edit-data-text-area -${variant}`}>
			<span className='text-10px-400 color-gray label'>{label}</span>
			<div className='input'>
				<textarea {...inputProps} value={value} onChange={onChange}></textarea>
			</div>
			<span className='text-12px-400 error-message'>{errorMessage}</span>
		</div>
	)
}

TextArea.propTypes = {
	label: PropTypes.string,
	value: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	inputProps: PropTypes.object,
	errorMessage: PropTypes.string,
	variant: PropTypes.string
}

TextArea.defaultProps = {
	variant: 'blue',
	inputProps: {
		rows: 3,
		cols: 50
	}
}

export default TextArea
