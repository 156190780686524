import PropTypes from 'prop-types'
import React, { useState } from 'react'
// components
import { Paper } from '@material-ui/core'
import { Rating } from '@material-ui/lab'
import CardDireccion from '../../../components/CardDireccion/CardDireccion'
import ProductList from '../../../components/ProductList/ProductList'
import OrderSummary from '../../../components/Summaries/OrderSummary'
import CustomButton from '../../../components/atoms/buttons/CustomButton'
import OrderSteps from './OrderSteps'
// styles
import { makeStyles } from '@material-ui/styles'
// payment methods
import * as cardDireccionTypes from '../../../components/CardDireccion/cardDireccionTypes'
import PaymentMethod from '../../../components/PaymentMethod/PaymentMethod'
import * as paymentTypes from '../../../components/PaymentMethod/PaymentTypes'
// hooks
import useOrders from '../../../hooks/orders/useOrders'
import useStringFormater from '../../../hooks/stringFormater/useStringFormater'

const useRatingStyles = makeStyles({
	root: {
		padding: '0.5rem',
		display: 'flex',
		flexDirection: 'column',
		gap: '0.5rem',
		alignItems: 'center',
		borderRadius: 10,
		justifyContent: 'center',
		background: '#fff',
		boxShadow: '0px 5px 20px rgba(33, 74, 142, 0.15)'
	}
})

const OrderDetails = ({ order, setSelectedOrder }) => {
	const { formatDate } = useStringFormater()
	const { updateOrder } = useOrders()

	const classes = useRatingStyles()
	// eslint-disable-next-line no-unused-vars
	const [Payment, setPayment] = useState(0)
	const [rating, setRating] = useState(order.rating === 0 ? 1 : order.rating)
	const alreadyReated = order.rating !== 0

	const generalProducts = order.products_order.filter(
		(product) => !product.wholesaler
	)
	const wholesalerProducts = order.products_order.filter(
		(product) => !!product.wholesaler
	)

	return (
		<>
			<span className='text-21px-700 color-blue'>No. 1474512</span>
			<OrderSteps status={order.status} />
			{order.status.finalized && (
				<div className='historial-section'>
					<span className='text-21px-700 color-blue'>
						Calificación
					</span>
					<Paper className={classes.root}>
						<Rating
							readOnly={alreadyReated}
							value={rating}
							size='large'
							onChange={(_, newValue) => {
								if (!alreadyReated) setRating(newValue)
							}}
						/>
						{!alreadyReated && (
							<CustomButton
								variant='text'
								label='Enviar'
								onClick={() => {
									updateOrder(order.document_id || null, { rating: rating })
									setSelectedOrder({ ...order, rating: rating })
								}}
							/>
						)}
					</Paper>
				</div>
			)}
			<div className='historial-section'>
				<span className='text-21px-700 color-blue'>Dirección</span>
				<CardDireccion
					nameDireccion='Dirección de entrega'
					direccion={order.address}
					dateOfDelivery={
						order.status.finalized
							? formatDate(order.status.finalized)
							: 'Sin entregar'
					}
					cardType={cardDireccionTypes.HISTORIAL_ORDERS}
				/>
			</div>
			<div className='historial-section'>
				<span className='text-21px-700 color-blue'>Método de pago</span>
        {order.pay_method < 4 && <PaymentMethod
          type={paymentTypes.methods.CASH}
          setPayment={setPayment}
        />}
        {order.pay_method === 4 && <PaymentMethod
          type={paymentTypes.methods.PSE}
          setPayment={setPayment}
        />}
					
			</div>
			<div className='historial-section'>
				<span className='text-21px-700 color-blue'>Resumen</span>
				<OrderSummary
					generalProducts={generalProducts}
					wholesalerProducts={wholesalerProducts}
					total={order.final_price}
					delivery={order.delivery}
				/>
			</div>
			<div className='historial-section'>
				<ProductList
					wholesaler
					title='Productos mayoristas'
					products={wholesalerProducts}
					resumeLabel='Mayorista'
					uneditable
				/>
				<ProductList
					title='Productos generales'
					products={generalProducts}
					resumeLabel='Generales'
					uneditable
				/>
			</div>
		</>
	)
}

OrderDetails.propTypes = {
	order: PropTypes.shape({
		document_id: PropTypes.string,
		id_order: PropTypes.string.isRequired,
		address: PropTypes.object.isRequired,
		products_order: PropTypes.array.isRequired,
		delivery: PropTypes.number.isRequired,
		final_price: PropTypes.number.isRequired,
		status: PropTypes.object.isRequired,
		rating: PropTypes.number.isRequired,
		pay_method: PropTypes.number.isRequired
	}),
	setSelectedOrder: PropTypes.func
}

OrderDetails.defaultProps = {
	setSelectedOrder: () => { }
}

export default OrderDetails
