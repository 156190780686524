import { functions } from '../../firebase/firestore'
import * as shoppingCartActionsTypes from './shoppingCartActionsTypes'

/**
 * Dispatches an action to set the current step in the shopping cart process.
 *
 * @param {function} dispatch - The dispatch function from the Redux store.
 * @param {number} step - The new value for the current step.
 */
export const setCurrentStep = (dispatch, step) => {
  dispatch({
    type: shoppingCartActionsTypes.SET_CURRENT_STEP,
    step: step
  })
}
export const setObservation = (dispatch, observation) => {
  dispatch({
    type: shoppingCartActionsTypes.SET_OBSERVATION,
    value: observation
  })
}
export const getCredibancoForm = async (
  order,
) => {

  const getform = functions.httpsCallable("webhook_credibanco_getform")
  const result = await getform(order).catch((err) => {
    console.error("getform fn error", err);
    return null
  })
  return result?.data || null;
}
/**
 * Submits an order to the database.
 *
 * @param {Object} order - The order data to be submitted.
 * @param {(data:any)=>void} onSuccess - The function to be called upon successful submission.
 * @param {Function} onError - The function to be called upon failed submission.
 */
export const sendOrder = async (
  order,
  onSuccess = () => console.log('orden subida con exito'),
  onError = () => console.log('error al subir orden')
) => {
  const sendOrderFn = functions.httpsCallable("sendOrder")
  await sendOrderFn(order)
    .then((order) => {
      console.log("sendOrderFn", order);
      onSuccess(order.data)
    })
    .catch((err) => {
      console.log("err", err);
      onError("No se logró generar la orden.")
    })
}

/**
 * Dispatches an action to set the changed products in the shopping cart.
 *
 * @param {function} dispatch - The dispatch function from the Redux store.
 * @param {Array} products - An array of the changed products.
 */
export const setChangedProducts = (dispatch, products) => {
  dispatch({
    type: shoppingCartActionsTypes.SET_CHANGED_PRODUCTS,
    value: products
  })
}

/**
 * Dispatches an action to clear the changed products in the shopping cart.
 *
 * @param {function} dispatch - The dispatch function from the Redux store.
 */
export const clearChangedProducts = (dispatch) => {
  dispatch({ type: shoppingCartActionsTypes.CLEAR_CHANGED_PRODUCTS })
}

/**
 * Dispatches an action to set the coupon in the shopping cart.
 *
 * @param {function} dispatch - The dispatch function from the Redux store.
 * @param {string} coupon - The coupon code to set.
 */
export const setCupon = (dispatch, coupon) => {
  dispatch({ type: shoppingCartActionsTypes.SET_CUPON, value: coupon })
}
