import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
// components
import { Box } from '@material-ui/core'
import { Alert, Skeleton } from '@material-ui/lab'
import { Link } from 'react-router-dom'
import CustomScrollMenu from '../../components/CustomScrollMenu/CustomScrollMenu'
import OffersSlider from '../../components/OffersSlider/OffersSlider'
import ProductCardDesktop from '../../components/atoms/cards/ProductCard/ProductCardDesktop'
import { routeFormatter } from '../../routes/routes'
import getPriceByOffice from '../../utils/getPriceByOffice'
// hooks
import useProducts from '../../hooks/products/useProducts'
import ValidateStock from '../../utils/ValidateStock'

/**
 * Componente de diseño de la página de inicio para dispositivos de escritorio.
 * @param {Object} props - Propiedades para el componente.
 * @param {Array} props.categories - Categorías de productos a mostrar.
 * @param {boolean} props.loading - Indica si las categorías están cargando.
 * @param {string} props.city - Ciudad actual seleccionada.
 * @param {string} props.branchOffice - Sucursal actual seleccionada.
 * @returns {React.ReactNode} Elemento React que contiene la página de inicio para dispositivos de escritorio.
 */
const HomeDesktop = ({ categories, loading, city, branchOffice }) => {
  return (
    <div className='homeDesktop'>
      <OffersSlider branch={branchOffice} />
      <Box px={3}>
        <Alert severity='info'>
          Los precios de los productos solo aplican para esta plataforma y al usarla acepta los <a target='_blank' rel='noreferrer' href='https://firebasestorage.googleapis.com/v0/b/merca-z-prueba.appspot.com/o/Termns_and_conditions%2FPL-01%20Manual%20Interno%20de%20PolA%CC%83_ticas%20y%20Procedimientos.pdf?alt=media&token=04d964c0-d5f6-48fa-b90d-f183eef11a81'>Términos y Condiciones</a> de uso.
        </Alert>
      </Box>
      {loading ? (
        <section className='category-section'>
          <p>
            <Skeleton animation='wave' variant='rect' width={200} />
          </p>
          <CustomScrollMenu hideArrows>
            {Array(10)
              .fill(0)
              .map((_, i) => (
                <ProductCardDesktop
                  loading
                  key={`product-skltn-${i}`}
                />
              ))}
          </CustomScrollMenu>
        </section>
      ) : (
        <>
          {categories
            .filter((c) => c.name)
              .sort((a, b) => a.id - b.id)
              .sort((a, b) => a.position - b.position)
            .map((category) => (
              <section key={category.id} className='category-section'>
                <div
                  className={
                    category?.products?.length === 0 ? 'display-none' : 'title'
                  }>
                  <p className='text-21px-700 color-blue'>
                    {category.name ? category.name.toLowerCase() : 'Undefined'}
                  </p>
                  <Link
                    to={routeFormatter.toCategory(category.id)}
                    className='text-decoration-none'>
                    <Box display='flex' alignItems='center'>
                      <span className='color-red text-14px-700'>
                        Ver mas&nbsp;
                      </span>
                      <i className='arrow -red' />
                    </Box>
                  </Link>
                </div>
                <LazyCategory
                  category={category}
                  city={city}
                  branchOffice={branchOffice}
                />
              </section>
            ))}
        </>
      )}
    </div>
  )
}

const LazyCategory = ({ category, city, branchOffice }) => {
  const { loadLimitedCategory } = useProducts()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const onChange = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          loadLimitedCategory(
            category.id,
            city,
            branchOffice,
            100,
            setIsLoading
          )
          observer.unobserve(entry.target)
        }
      })
    }

    const observer = new IntersectionObserver(onChange, {
      root: null,
      rootMargin: '100px',
      threshold: 0.3
    })
    observer.observe(document.getElementById(category.id))

    return () => observer.disconnect()
  }, [])

  return (
    <div id={category.id}>
      {category.products.length > 0 && !isLoading && (
        <CustomScrollMenu>
          {
            // validates products is not empty
            category.products.map(
              (product) =>
                // validates if products is available in selected city
                product.price_branch_offices.some((item) =>
                  ValidateStock(item, city, branchOffice)
                ) && (
                  <ProductCardDesktop
                    key={product.id_item}
                    itemId={product.id_item ?? console.log(product)}
                    productId={product.id_item}
                    categoryId={category.id}
                    product={product}
                    branch={product.price_branch_offices.find((item) =>
                      getPriceByOffice(item, city, branchOffice)
                    )}
                  />
                )
            )
          }
        </CustomScrollMenu>
      )}

      {isLoading && (
        <CustomScrollMenu hideArrows>
          {Array(10)
            .fill(0)
            .map((_, i) => (
              <ProductCardDesktop loading key={`product-skltn-${i}`} />
            ))}
        </CustomScrollMenu>
      )}
    </div>
  )
}

LazyCategory.propTypes = {
  category: PropTypes.object.isRequired,
  city: PropTypes.string.isRequired,
  branchOffice: PropTypes.string
}

HomeDesktop.propTypes = {
  categories: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  city: PropTypes.string.isRequired,
  branchOffice: PropTypes.string
}

export default HomeDesktop
