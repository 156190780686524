import db from '../../firebase/firestore'
import * as configsTypes from './configsActionsTypes'

const configsCollection = db.collection('configs')
const branchesCollection = db.collection('branch_offices')

/**
 * Carga los datos de configuración de la aplicación desde Firestore y actualiza el estado.
 * @param {Function} dispatch - Función de envío de acciones al store de Redux.
 * @returns {void}
 */
export const loadDataConfigs = (dispatch) => {
  dispatch({
    type: configsTypes.LOAD_DATA_CONFIGS
  })
  // console.log("configsTypes.LOAD_DATA_CONFIGS_SUCCESS");

  configsCollection.doc('data').onSnapshot(
    (snapshot) => {
      const data = snapshot.data()
      // console.log("configsTypes.LOAD_DATA_CONFIGS_SUCCESS", data);
      dispatch({
        type: configsTypes.LOAD_DATA_CONFIGS_SUCCESS,
        data
      })
    },
    (error) => {
      dispatch({
        type: configsTypes.LOAD_DATA_CONFIGS_FAILURE,
        error: error.message
      })
    }
  )
}

/**
 * Carga la información de todas las sucursales desde Firestore y actualiza el estado.
 * @param {Function} dispatch - Función de envío de acciones al store de Redux.
 * @returns {void}
 */
export const branchesLoad = (dispatch) => {
  dispatch({
    type: configsTypes.LOAD_BRANCHES
  })

  branchesCollection
    .get()
    .then((querySnapshot) =>
      dispatch({
        type: configsTypes.LOAD_BRANCHES_SUCCESS,
        branches: querySnapshot.docs.map((documentSnapshot) =>
          documentSnapshot.data()
        )
      })
    )
    .catch((err) =>
      dispatch({
        type: configsTypes.LOAD_BRANCHES_FAILURE,
        error: err.message
      })
    )
}
