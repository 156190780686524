import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import CustomModal from '../CustomModal/CustomModal'
// components
import SummaryButton from '../atoms/buttons/SummaryButton'
import CustomAutoComplete from '../atoms/inputs/CustomAutoComplete'
import CircularLoader from '../loaders/CircularLoader'
import ProductList from '../ProductList/ProductList'
// hooks
import useAuth from '../../hooks/auth/useAuth'
import useMarkets from '../../hooks/markets/useMarkets'
import useProducts from '../../hooks/products/useProducts'
import useStringFormater from '../../hooks/stringFormater/useStringFormater'
// consts
import * as UnitTypes from '../../consts/ProductCountOptions'
// utils
import TotalToPay from '../../utils/TotalToPay'

const AddProductToMarketModal = ({ open, handleClose, marketId }) => {
	const { formatPrice } = useStringFormater()

	const { getCityStorage, showLoginModal } = useAuth()
	const city = getCityStorage()

	const { categories, loading } = useProducts()

	const { findMarket, getProductReferenceTemplate, editMarket, markets } =
		useMarkets()

	const market = findMarket(marketId)

	const [initialOptions, setInitialOptions] = useState([])

	const alreadyAdded = market
		? market.products.map((product) => product.id_item)
		: []

	const updateInitialOptions = () => {
		let options = []
		categories.forEach((category) => {  //Comienza un bucle de iteración de cada categoría en la lista de categorías.
			category.products.forEach((product) => {  //itera através de cada producto en la lista de productos de cada categoría
				//se valida si el producto ya ha sido agregado o no 
				if (alreadyAdded.includes(product.id_item)) return
				let branch = product.price_branch_offices.find(
					(branch) => branch.city.toLowerCase() === city.toLocaleLowerCase()
				)

				options.push({ //Se agrega un objeto nuevo al arreglo options, este objeto tiene 2 propiedades title,value
					title: `${product.name} (${formatPrice(branch.price)} c/u)`,
					value: {
						...product,
						price: branch.price,
						pum: branch.pum,
						quantity_pum: product.quantity_pum,
						unit_type: UnitTypes.UNIT.value,
						count: 1
					}
				})
			})
		})

		setInitialOptions(options) //se actualiza el estado
	}

	const [selectedProducts, setSelectedProducts] = useState([])
	const empty = selectedProducts.length === 0

	const [availableOptions, setAvailableOptions] = useState(initialOptions)

	const selectProduct = (product) => {
		setSelectedProducts([...selectedProducts, product])
	}

	const getTotal = () => {
		return TotalToPay(selectedProducts)
	}

	//esta función se utiliza para actualizar el valor de count para un producto específico en un conjunto de productos seleccionados.
	const handleProductCountChange = (productId) => (count) => {
		setSelectedProducts((products) =>
			products.map((product) => {
				if (productId !== product.id_item) return product
				return { ...product, count: count }
			})
		)
	}

	const handleProductUnitTypeChange = (productId) => (unitType) => {
		//La función devuelta se utiliza para cambiar el valor de la propiedad unit_type de un objeto producto con un id_item específico.
		setSelectedProducts((products) => //actualiza una variable de estado
			products.map((product) => {  
				if (productId !== product.id_item) return product
				return { ...product, unit_type: unitType }
			})  //itera los productos y devuelvo todos excepto el que tenga el id_item igual a product_id
		)
	}

	//elimina un producto del arreglo selectedProducts y agrega la opción correspondiente al arreglo availableOptions.
	const handleProductDelete = (productId) => () => {
		setSelectedProducts((products) =>
			products.filter((product) => product.id_item !== productId)
		)
		setAvailableOptions((options) => [
			...options,
			initialOptions.find((option) => option.value.id_item === productId)
		])
	}

/* 	agrega los productos seleccionados a un mercado y actualiza la lista de productos del mercado.
	 Además, vacía la lista de productos seleccionados y cierra una ventana modal. */
	const addProductsToMarket = () => {
		let newProducts = selectedProducts.map((product) => {
			let template = getProductReferenceTemplate()

			template.count = product.count
			template.id_item = product.id_item
			template.main_category_id = product.main_category_id
			template.unit_type = product.unit_type

			return template
		})
		let updatedProducts = [...market.products, ...newProducts]
		editMarket(marketId, { products: updatedProducts })
		setSelectedProducts([])
		handleClose()
	}

	// effects

	useEffect(() => {
		if (!city) showLoginModal()
	}, [])

	useEffect(() => {
		updateInitialOptions()
	}, [markets])

	useEffect(() => {
		setAvailableOptions(initialOptions)
	}, [initialOptions])

	return (
		<CustomModal open={open} handleClose={handleClose}>
			<div
				className={`add-product-to-market-${isMobile ? 'mobile' : 'desktop'}`}
			>
				{
					//!categoriesFullLoaded ||
					loading || !market ? (
						<CircularLoader />
					) : (
						<>
							<span className='text-21px-700 color-blue aptm-title'>
								Agregar productos
							</span>
							<div className='aptm-content'>
								<div className='first-segment'>
									<CustomAutoComplete
										label={'Productos'}
										options={availableOptions}
										onChange={(_, newValue) => {
											if (newValue) {
												selectProduct(newValue.value)
												setAvailableOptions((options) =>
													options.filter(
														(option) =>
															option.value.id_item !== newValue.value.id_item
													)
												)
											}
										}}
									/>
									{!loading && selectedProducts.length !== 0 && (
										<div className='aptm-products-list custom-scrollbar'>
											<ProductList
												title='Productos seleccionados'
												resumeLabel='Productos'
												products={selectedProducts}
												onProductCountChange={handleProductCountChange}
												onProductDelete={handleProductDelete}
												onProductUnitTypeChange={handleProductUnitTypeChange}
												productTypeSelectEnable={() => true}
											/>
										</div>
									)}
								</div>
								<div className='aptm-summary-btn'>
									<SummaryButton
										disabled={empty}
										hidePrice={empty}
										onClick={addProductsToMarket}
										actionLabel={
											empty ? 'No hay productos' : 'Agregar productos'
										}
										price={getTotal()}
									/>
								</div>
							</div>
						</>
					)
				}
			</div>
		</CustomModal>
	)
}

AddProductToMarketModal.propTypes = {
	open: PropTypes.bool,
	handleClose: PropTypes.func.isRequired,
	marketId: PropTypes.string.isRequired
}

export default AddProductToMarketModal
