import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
// hooks
import useMercadoCard from './useMercadoCard'

const MercadoCardDesktop = (props) => {
	const {
		// actions
		onDelete,
		onClick,
		onEdit,
		// flags
		selected,
		// data
		marketId,
		address,
		name,
		products,
		city,
		branchOffice
	} = props

	const [totalItemsInCart, setTotalItemsInCart] = useState(0) 

	//hooks
	const { getSubtotal, formatedAddress, isFav, toggleFavorite } =
		useMercadoCard({
			address,
			city,
			branchOffice,
			products,
			marketId
		})
		

	useEffect(() => {
		const sumall = products // Calcula la cantidad de productos en el carrito,
			.map((product) => product.count)
			.reduce((prev, curr) => prev + curr, 0)
		setTotalItemsInCart(sumall) //setea a setTotalItemsInCart con el valos sumall
	}, [products]) //se ejecuta cada vez que la propiedad products cambie 

	// styles
	const titleColor = selected ? 'yellow' : 'gray-dark'
	const textColor = selected ? 'white' : 'gray-light'
	const priceColor = selected ? 'yellow' : 'blue'
	const iconColor = selected ? 'white' : 'blue'

	return (
		<div
			className={`mercado-card -${selected ? '' : 'un'}selected`}
			onClick={onClick}
		>
			<div className='card-info'>
				<span className={`title text-18px-700 color-${titleColor}`}>
					{name}
				</span>
				<div className={`body -${selected ? '' : 'un'}selected`}>
					<div>
						<span className={`color-${textColor} text-11px-400`}>
							{formatedAddress}
						</span>
						<span className={`color-${textColor} text-11px-400`}>
							{totalItemsInCart} items
						</span>
					</div>
					<div>
						<span className={`color-${textColor} text-12px-500`}>Subtotal</span>
						<span className={`color-${priceColor} text-18px-700`}>
							{getSubtotal()}
						</span>
					</div>
				</div>
			</div>
			<div className='card-actions'>
				<i
					className={`heart-icon-circle -${isFav ? '' : 'un'}checked -small`}
					title={`${isFav ? 'Remover de' : 'Añadir a'} favoritos`}
					onClick={(e) => {
						e.stopPropagation()
						toggleFavorite(marketId)
					}}
				/>
				<i
					className={`edit-icon -${iconColor}`}
					onClick={onEdit}
					title='Editar mercado'
				/>
				<i
					className={`delete-icon-circle -${iconColor}`}
					onClick={onDelete}
					title='Eliminar mercado'
				/>
			</div>
		</div>
	)
}

MercadoCardDesktop.propTypes = {
	//data
	marketId: PropTypes.string.isRequired,
	address: PropTypes.object.isRequired,
	name: PropTypes.string.isRequired,
	products: PropTypes.array.isRequired,
	city: PropTypes.string.isRequired,
	branchOffice: PropTypes.string,
	// actions
	onDelete: PropTypes.func,
	onClick: PropTypes.func,
	onEdit: PropTypes.func,
	// flags
	selected: PropTypes.bool
}

MercadoCardDesktop.defaultProps = {
	// actions
	onDelete: () => {}, //prop callback para eliminar mercado
	onClick: () => {},
	onEdit: () => {} //prop callback para editar mercado
}

export default MercadoCardDesktop
