import PropTypes from 'prop-types'
import React from 'react'
import * as orderStates from './orderStates'

const OrderStatusChip = ({ status }) => {
  //estados de la orden
  const statusLabels = {
    [orderStates.CREATED]: 'Creado',
    [orderStates.RECEIVED]: 'Recibido',
    [orderStates.ENLISTMENT]: 'Alistamiento',
    [orderStates.DISPATCHED]: 'En camino',
    [orderStates.CANCELLED]: 'Cancelado',
    [orderStates.PAYMENT]: 'Online Pendiente',
    [orderStates.PAYMENT_SUCCESS]: 'Online exitoso',
    [orderStates.PAYMENT_ERROR]: 'Online Fallido',
    [orderStates.FINALIZED]: 'Finalizado'

  }
  const getStatus = (status) => {
    console.log("getStatus", status);

    let output = { label: 'Status indeterminado', code: orderStates.DISPATCHED }

    const acceptedStatus = Object.keys(statusLabels)
    for (const iterator of acceptedStatus) {
      if (iterator === status)
        output = {
          label: statusLabels[iterator],
          code: iterator
        }
    }

    return output
  }


  const orderStatus = getStatus(status)

  return (
    <div className={`order-status-chip -${orderStatus.code}`}>
      {orderStatus.label}
    </div>
  )
}

OrderStatusChip.propTypes = {
  status: PropTypes.shape({
    cancelled: PropTypes.object,
    received: PropTypes.object,
    enlistment: PropTypes.object,
    dispatched: PropTypes.object,
    finalized: PropTypes.object
  })
}

export default OrderStatusChip
