// get markets from db
export const GET_MARKETS = 'GET_MARKETS'
export const GET_MARKETS_SUCCESS = 'GET_MARKETS_SUCCESS'
export const GET_MARKETS_FAILURE = 'GET_MARKETS_FAILURE'
// create market
export const CREATE_MARKET = 'CREATE_MARKET'
export const CREATE_MARKET_SUCCESS = 'CREATE_MARKET_SUCCESS'
export const CREATE_MARKET_FAILURE = 'CREATE_MARKET_FAILURE'
// delete a market
export const DELETE_MARKET = 'DELETE_MARKET'
export const DELETE_MARKET_SUCCESS = 'DELETE_MARKET_SUCCESS'
export const DELETE_MARKET_FAILURE = 'DELETE_MARKET_FAILURE'
// edit a market
export const EDIT_MARKET = 'EDIT_MARKET'
export const EDIT_MARKET_SUCCESS = 'EDIT_MARKET_SUCCESS'
export const EDIT_MARKET_FAILURE = 'EDIT_MARKET_FAILURE'
