import { useEffect, useMemo, useState } from 'react'
import useProductCounter from '../../components/ProductCounter/useProductCounter'
import useAuth from '../../hooks/auth/useAuth'
import useProducts from '../../hooks/products/useProducts'
import useShoppingCart from '../../hooks/shoppingCart/useShoppingCart'
import useFavProducts from '../../hooks/user/useFavProducts'
// consts
import useProductCard from '../../components/atoms/cards/ProductCard/useProductCard'
import * as UnitTypes from '../../consts/ProductCountOptions'
import * as Maturities from '../../consts/ProductMaturities'
import SubcatsWithCutType from '../../consts/SubcatsWithCutType'
import SubcatsWithMaturity from '../../consts/SubcatsWithMaturity'
import getPriceByOffice from '../../utils/getPriceByOffice'
// eslint-disable-next-line no-unused-vars
import ProductModel from '../../models/ProductModel'

const REFERENCE_IMAGE_TEXT =
  '*Esta Imagen es de referencia y puede presentar variaciones. NO incluye otros bienes o productos exhibidos en la imagen.<br>*El peso es aproximado y puede presentar variaciones en la factura de Compra (No aplica para productos empacados).';
const ALCOHOL_RESTRICTION_TEXT =
  'PROHÍBASE EL EXPENDIO DE BEBIDAS EMBRIAGANTES A MENORES DE EDAD. LEY 124 DE 1994. EL EXCESO DE ALCOHOL ES PERJUDICIAL PARA LA SALUD. LEY 30 DE 1986.';

//service
const useProduct = ({ categoryId, productId }) => {

  /**
 * Product
 * @type {[ProductModel,(p:ProductModel)=>void]}
 */
  const [product, setProduct] = useState({});

  const {
    changeProductCount,
    findProductInCart,
    changeProductMaturity,
    changeProductCutType,
    isProductInCart,
    //wholesalerProducts,
    removeProductFromCart,
  } = useShoppingCart()

  const isWholesalerInCart = isProductInCart(productId, { asWholesaler: true })

  const { isFavorite, toggleFavorite } = useFavProducts()

  const isFav = isFavorite(productId, categoryId)

  const { getCityStorage, getCityBranchOffice } = useAuth()
  const city = getCityStorage() || 'CALI'
  const branchOffice = getCityBranchOffice()


  // const { changeProductCount, findProductInCart } = useShoppingCart()
  const { counter, incrementCounter, decrementCounter, setCounter } =
    useProductCounter({
      initialValue: findProductInCart(productId)?.count ?? 1
    })
  
  const {
    wholesalerCounter,
    incrementWholesalerCounter,
    decrementWholesalerCounter,
    setWholesalerCounter
  } =
    useProductCounter({
      initialValue: findProductInCart(productId)?.count ?? 0
    })

  const branch = product?.price_branch_offices?.find((item) =>
    getPriceByOffice(item, city, branchOffice)
  )

  const [unitType, setUnitType] = useState(
    findProductInCart(productId)?.unit_type || UnitTypes.UNIT.value
  )

  const {
    isInCart,
    hasMinCountGrams,
    // flags
    canSelectUnitType,
    // actions
    addToCart,
    removeFromCart
  } = useProductCard({
    counter,
    wholesalerCounter,
    branch,
    unitType,
    product,
    // actions
    incrementCounter,
  })

  // const isInCart = false
  // const hasMinCountGrams = false
  // const canSelectUnitType = () => { }
  // const addToCart = () => { }
  // const removeFromCart = () => { }

  const productInCart = useMemo(() => {
    // console.log("findProductInCart");

    return findProductInCart(productId) || {}
  }, [productId])
  // console.log("productInCart", productInCart);

  const { getProductData, categories, loading: productsLoading } = useProducts()

  const [disable, setDisable] = useState(false)
  const [wholeSalerDisable] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const [complementaries, setComplementaries] = useState([])
  // if user gives invalid cat id and product id
  const [productNotFount, setProductNotFount] = useState(false)

  useEffect(() => {
    if (productsLoading) return
    // console.log("productsLoading", productsLoading);

    const execute = async () => {
      // wait until useProducts finishes to load categories
      if (categories.length === 0) return

      setIsLoading(true)
      setComplementaries([])

      let productData = await getProductData(categoryId, productId)

      productData.description = `${productData.description ?? ''
        }<br />${productData.main_category_id === '07'
          ? ALCOHOL_RESTRICTION_TEXT
          : REFERENCE_IMAGE_TEXT
        }`;
      setIsLoading(false)
      // if the product doesn't exists, redirect to 404 page
      if (!productData) {
        setProductNotFount(true)
        return
      }
      setProduct(productData)

      // early return if productData doesnt have comlementary field or complementary array is empty
      if (
        !productData ||
        !productData.complementary ||
        productData.complementary.length === 0
      )
        return

      let fullProducts = await Promise.all(
        productData.complementary.map(
          async (product) => await getProductData(product.categoryId, product.id)
        )
      )
      setComplementaries(fullProducts)
    }
    execute()
  }, [
    productsLoading, categories,
    categoryId, productId])

  const {
    bar_code = '',
    name = '',
    description = '',
    sub_category_id = '',
    quantity_pum = product.quantity_pum,
    is_wholesaler = false,
    unit_gram
  } = product || {}
  // console.log("productData", productData);

  const needsMaturity = SubcatsWithMaturity.includes(sub_category_id)
  const needsCutType = SubcatsWithCutType.includes(sub_category_id)
  // const _canSelectUnitType = SubcatsWithGrams.includes(sub_category_id)


  // optional fields
  const [maturity, setMaturity] = useState(
    productInCart.maturity || Maturities.NORMAL.code
  )

  const [cutType, setCutType] = useState(productInCart.cut_type || '')



  useEffect(() => {
    setCutType(productInCart)
    setUnitType(productInCart.unit_type || UnitTypes.UNIT.value)
  }, [productInCart])



  // wholesaler info
  const wholesalerInfo = product.wholesaler

  const { pum = 0, price = 0, stock = 0 } = branch || {}



  // wholesaler product counter
  let wholesalerCounterOptions = [6, 12, 24].map((option) => ({
    label: option.toString(),
    value: option
  }))
  /* const [wholesalerCounter, setWholesalerCounter] = useState(
    isWholesalerInCart
      ? wholesalerProducts.filter((product) => product.id_item === productId)[0]
        .count
      : 0
  ) */

  // product stock update
  useEffect(() => {
    wholesalerCounter + counter === stock
      ? setDisable(true)
      : setDisable(false)
  }, [counter, wholesalerCounter])

  const [validationError, setValidationError] = useState('')

  /**
   * validates if info is correct before adding product to cart
   */
  const validate = () => {
    setValidationError('')
    // cant add 0 items
    if (counter === 0) incrementCounter()
    if (wholesalerCounter === 0) setWholesalerCounter(wholesalerCounter + 25)
    // early return if cut type is required and its not provided
    if (needsCutType && !cutType) {
      setValidationError(
        'Selecciona el tipo de porcionado antes de añadir el producto al carrito'
      )
      return false
    }
    return true
  }



  return {
    branch,
    // flags
    isFav,
    toggleFavorite: () => {
      toggleFavorite(productId, categoryId)
    },
    isInCart,
    isWholesalerInCart,
    isLoading,
    productNotFount,
    // product info,
    unit_type: product.unit_type,
    productData: product,
    hasMinCountGrams,
    bar_code,
    is_wholesaler,
    pum,
    price,
    description,
    unit_gram,
    name,
    quantity_pum,
    complementaries,
    unitType,
    setUnitType,
    validate,
    // wholesaler info
    wholesalerInfo,
    // optional product info
    maturity,
    setMaturity,
    needsMaturity,
    cutType,
    setCutType,
    needsCutType,
    // actions
    addToCart,
    removeFromCart,
    changeProductCount,
    changeProductMaturity,
    changeProductCutType,
    removeProductFromCart,
    // validation message
    validationError,
    // counter
    canSelectUnitType,
    counter,
    incrementCounter,
    decrementCounter,
    setCounter,
    disable,
    // wholesaler counter
    wholesalerCounter,
    setWholesalerCounter,
    incrementWholesalerCounter,
    decrementWholesalerCounter,
    wholesalerCounterOptions,
    wholeSalerDisable,
    //categories
    categories
  }
}

export default useProduct
