import React from "react";

/**
 * Renders a discount badge for a product.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {number|null} props.discount - The discount percentage. If null, the badge will not be rendered.
 * @param {string} [props.size='medium'] - The size of the badge. Possible values are 'small', 'medium', 'large'.
 * @param {string} [props.position='right'] - The position of the badge. Possible values are 'left', 'right'.
 * @returns {JSX.Element|null} The discount badge component.
 */
const ProductDiscountBadge = ({
    discount = null,
    size = 'medium',
    position = 'right',
}) => {
    if (!discount) return null;
    return (
        <span className={`text-${
            size === 'small' ? '14' : 
            size === 'medium' ? '19' :
            size === 'large' ? '28' : '10'
        }px-400 color-blue bg-yellow`} style={{
            position: 'absolute',
            top: '0',
            right: position === 'left' ? 'unset' : '0',
            left: position === 'left' ? '0' : 'unset',
            // transform: position === 'left' ? 'translate(-100%, 0)' : 'translate(100%, 0)',
            padding: '5px 10px',
            borderBottomLeftRadius: '0.5rem',
            borderTopRightRadius: '0.5rem',
        }}>
            {discount}%
        </span>
    );
}

export default ProductDiscountBadge;