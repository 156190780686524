import React, { useEffect, useState } from 'react'
import { auth } from '../../../firebase/firestore'
import useEditarDatos from './useEditarDatos'
// my componentes
import CustomButton from '../../../components/atoms/buttons/CustomButton'
import EditInputText from '../../../components/atoms/inputs/EditInputText'
import EditSelect from '../../../components/atoms/selects/EditSelect'
// material ui
import { Grid } from '@material-ui/core'
import CustomPhoneInput from '../../../components/atoms/inputs/CustomPhoneInput'

const EditarDatosDesktop = () => {
	const {
		documentOptions,
		selectedIndex,
		selectDocumentType,
		name,
		changeName,
		nameError,
		lastname,
		changeLastname,
		lastnameError,
		document,
		changeDocument,
		documentError,
		phone,
		changePhone,
		phoneError,
		email,
		changeEmail,
		emailError,
		changeDate,
		birthDateFormatted,
		birthError,
		updateData
	} = useEditarDatos()

	const [isLoggedWithGoogle, setIsLoggedWithGoogle] = useState(false)

	const LoggedWithGoogle = () => {
		const user = auth.currentUser
		if (user !== null) {
			user.providerData.forEach((profile) => {
				console.log('Sign-in provider: ' + profile.providerId)
				if (profile.providerId === 'google.com') setIsLoggedWithGoogle(true)
			})
		}
	}

	useEffect(() => {
		LoggedWithGoogle()
	}, [])

	// console.log(birthDateFormatted)

	return (
		<>
			<span className='color-blue text-21px-700 editar-datos-title'>
				Editar Datos
			</span>
			<form className='editar-datos-form'>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<EditInputText
							label='Nombre'
							value={name}
							onChange={changeName}
							errorMessage={nameError}
							variant='white'
						/>
					</Grid>
					<Grid item xs={6}>
						<EditInputText
							label='Apellido'
							value={lastname}
							onChange={changeLastname}
							errorMessage={lastnameError}
							variant='white'
						/>
					</Grid>
					<Grid item xs={6}>
						<EditSelect
							label='Tipo de documento'
							options={documentOptions}
							selectedIndex={selectedIndex}
							onSelect={selectDocumentType}
							variant='white'
						/>
					</Grid>
					<Grid item xs={6}>
						<EditInputText
							label='Número de documento'
							value={document}
							onChange={changeDocument}
							errorMessage={documentError}
							variant='white'
							inputProps={{
								type: 'number'
							}}
						/>
					</Grid>
					<Grid item xs={6}>
						<CustomPhoneInput
							variant='white'
							value={phone}
							onChange={changePhone}
							errorMessage={phoneError}
						/>
					</Grid>
					<Grid item xs={6}>
						<EditInputText
							label='Correo electrónico'
							value={email}
							onChange={changeEmail}
							errorMessage={emailError}
							variant='white'
							disabled={
								isLoggedWithGoogle === true && email != '' ? true : false
							}
						/>
					</Grid>
					<Grid item xs={6}>
						<EditInputText
							errorMessage={birthError}
							variant='white'
							value={birthDateFormatted}
							label='Fecha de nacimiento'
							inputProps={{
								type: 'date',
								max: new Date().toISOString().split('T')[0]
							}}
							onChange={changeDate}
						/>
					</Grid>
					<Grid
						container
						direction='row'
						justifyContent='center'
						className='editar-datos-button'>
						<CustomButton label='Guardar datos' onClick={updateData} />
					</Grid>
				</Grid>
			</form>
		</>
	)
}

export default EditarDatosDesktop
