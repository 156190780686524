import { useContext, useEffect } from 'react'
import { context } from '../../context/ContextProvider'
import * as ordersActions from '../../context/orders/ordersActions'

/**
 * Hook que permite acceder al estado y las acciones relacionadas con las órdenes de compra.
 * @returns {Object} Objeto con la información y acciones relacionadas con las órdenes.
 * @property {boolean} loading Indica si se está cargando información de las órdenes.
 * @property {Array} orders Array de órdenes de compra.
 * @property {Function} getUserOrders Función que carga las órdenes de compra del usuario actual.
 * @property {Function} getOrderFromContext Función que busca y retorna una orden de compra por su id.
 * @property {Function} updateOrder Función que actualiza una orden de compra con nuevos datos.
 */
const useOrders = () => {
	const { state, dispatch } = useContext(context)
	const { authValues } = state.authReducer

	const { loading, orders } = state.ordersReducer

	const getUserOrders = () => {
		ordersActions.getUserOrders(dispatch, authValues.ID)
	}

	const getOrderFromContext = (orderId) => {
		return orders.find((order) => order.id_order === orderId)
	}

	const updateOrder = (orderId, data) => {
		if (!orderId) {
			return
		}
		ordersActions.updateOrder(orderId, data)
	}

	useEffect(() => {
		getUserOrders()
	}, [authValues])

	return {
		// data
		loading,
		orders,
		// actions
		getUserOrders,
		getOrderFromContext,
		updateOrder
	}
}

export default useOrders
