import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import CustomButton from '../atoms/buttons/CustomButton'
import CustomModal from '../CustomModal/CustomModal'
// material ui
import { Box, CircularProgress } from '@material-ui/core'
// hooks
import useConfig from '../../hooks/configs/useConfigs'
// utils
import {
  GoogleMap,
  InfoWindow,
  Marker,
  useLoadScript
} from '@react-google-maps/api'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import { getLocationCoverageInBranches } from '../../utils/GeoJson'

import Geocode from 'react-geocode'

const containerStyle = {
	width: '100%',
	height: '40vh'
}

/**
 * Componente que muestra un modal para validar la cobertura dirección ingresada.
 *
 * @param {Object} props - Las propiedades del componente.
 * @param {string} props.acceptLabel - La etiqueta para el botón de aceptar.
 * @param {Function} props.acceptAction - La acción a realizar al presionar el botón de aceptar.
 * @param {string} props.cancelLabel - La etiqueta para el botón de cancelar.
 * @param {Function} props.cancelAction - La acción a realizar al presionar el botón de cancelar.
 * @param {boolean} props.open - Indica si el modal está abierto o cerrado.
 * @param {Function} props.handleClose - La función para cerrar el modal.
 * @param {Function} props.setAddressValue - La función para establecer el valor de la dirección.
 * @param {string} props.addressValue - El valor de la dirección actual.
 * @param {Function} props.setCustomAddress - La función para establecer la dirección personalizada.
 * @param {string} props.customAddress - La dirección personalizada actual.
 * @param {Function} props.getGeoPointFromAddress - La función para obtener las coordenadas geográficas de la dirección.
 * @param {boolean} props.isMobile - Indica si el dispositivo es móvil o no.
 * @returns {JSX.Element} - Retorna el modal AddressCoverageValidationModal.
 */
const AddressCoverageValidationModal = (props) => {
	const {
		acceptLabel,
		acceptAction,
		cancelLabel,
		cancelAction,
		open,
		handleClose,
		setAddressValue,
		addressValue,
		setCustomAddress,
		//customAddress,
		getGeoPointFromAddress,
		isMobile
	} = props

	/**
	 * Definir una constante "isLoaded" que indica si la API de Google Maps
	 * se ha cargado correctamente.
	 * Definir otra constante "loadError" que almacena un posible error durante
	 * la carga de la API.
	 * */
	const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ['places'],
	})

	/**
	 * Si hay un error al cargar la API de Google Maps, se lanza una excepción
	 * con el mensaje de error correspondiente.
	 */
	if (loadError) {
		throw new Error(`Merca Z error: google maps error - ${loadError}`)
	}

	const { branches } = useConfig()

	/**
	 * Definir varios estados utilizando el hook useState()
	 * showInfoWindow indica si se debe mostrar la ventana de información en el mapa.
	 * openInfo indica si la ventana de información está abierta.
	 * value es un estado que puede ser utilizado para almacenar un valor en cualquier momento.
	 * coverage indica si una dirección está dentro de la cobertura de entrega de la sucursal.
	 * coverageBranch es un objeto que contiene información de la sucursal dentro de la cobertura.
	 */
	// const [showInfoWindow, setShowInfoWindow] = useState(true)
	const [openInfo, setOpenInfo] = useState(false)
	// const [value, setValue] = useState(null)
	const [coverage, setCoverage] = useState(false)
	const [coverageBranch, setCoverageBranch] = useState({})

	/** Utilizar el hook useEffect() para verificar si la dirección proporcionada
	 * está dentro de la cobertura de entrega de alguna sucursal.
	 * Si la dirección tiene un geopunto, se utiliza el hook getLocationCoverageInBranches()
	 * para determinar si está dentro de la cobertura de alguna sucursal.
	 * Si la dirección está dentro de la cobertura de alguna sucursal, se actualizan
	 * los valores del estado de la dirección.
	 * */
	useEffect(() => {
		if (addressValue.geopoint) {
			const { lat, lng } = addressValue.geopoint
			if (lat && lng) {
				getLocationCoverageInBranches(
					branches,
					{ latitude: lat, longitude: lng },
					addressValue.city
				).then((coverage) => {
					setCoverageBranch(coverage.branch ? coverage.branch : '')
					setCoverage(coverage.hasCoverage)
					if (coverage.hasCoverage) {
						setAddressValue({
							...addressValue,
							branch: coverage.branch.name,
							neighbord: coverage.neighborhood
						})
					}
				})
			}
		} else {
			setCoverage(true)
		}
	}, [addressValue.geopoint])

	/**
	 * Crear una referencia a la instancia del mapa utilizando el hook useRef().
	 * La referencia se utiliza para acceder a la instancia del mapa.
	 */
	const mapRef = useRef()
	/**
	 * Utilizar el hook useCallback() para crear una función onMapLoad que establece
	 * la instancia del mapa en la referencia definida anteriormente.
	 */
	const onMapLoad = useCallback((map) => {
		mapRef.current = map
	}, [])

	/**
	 * Crear una función updateAddressValue() que recibe una dirección y actualiza el valor del estado de la dirección.
	 * @param {address} address
	 */
	const updateAddressValue = (address) => {
		setAddressValue({
			...addressValue,
			address: address
		})
	}

	/**
	 * Incializa las variables de Geocode.
	 */
	useEffect(() => {
		Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY)
		Geocode.setLanguage('es-419')
	}, [])
	//console.log("branches", branches);

	return (
    <CustomModal open={open} handleClose={() => {
      handleClose && handleClose()
    }} mobileSmall>
			<span
				className={
					isMobile ? 'text-20px-700 color-blue' : 'text-25px-700 color-blue'
				}>
				Valida tu dirección de entrega
			</span>
			<div
				className={
					isMobile ? 'address-coverage-modal-mobile' : 'address-coverage-modal'
				}>
				<GooglePlacesAutocomplete
					apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
					apiOptions={{ language: 'es', region: 'co' }}
					autocompletionRequest={{
						bounds: [
							//bounds Cali
							{ lat: -76.5209633, lng: 3.4970572 },
							{ lat: -76.5498024, lng: 3.4941444 },
							{ lat: -76.5757233, lng: 3.4614174 },
							{ lat: -76.5810448, lng: 3.388763 },
							{ lat: -76.5522057, lng: 3.3179883 },
							{ lat: -76.5147835, lng: 3.3193593 },
							{ lat: -76.508947, lng: 3.3587744 },
							{ lat: -76.4880043, lng: 3.396817 },
							{ lat: -76.4584786, lng: 3.4026432 },
							{ lat: -76.4454323, lng: 3.4324591 },
							{ lat: -76.4560753, lng: 3.4674146 },
							{ lat: -76.4945274, lng: 3.4914029 },
							{ lat: -76.5209633, lng: 3.4970572 }
						],
						componentRestrictions: {
							country: ['co']
						}
					}}
					minLengthAutocomplete={3}
					selectProps={{
						inputValue: addressValue.address,
						//onInputChange: updateAddressValue,
						onInputChange: (e) => {
							//console.log('e', e);
							//setValue(e)
							updateAddressValue(e)
						},
						//value,
						onChange: (e) => {
							if (e) {
								//setValue(e)
								updateAddressValue(e.label)
								getGeoPointFromAddress(e.label)
							}
						},
						placeholder: 'Dirección',
						noOptionsMessage: () => 'Sin resultados',
						loadingMessage: () => 'Cargando resultados'
					}}
					onLoadFailed={(e) => console.log('ERROOOOR' + e.message)}
				/>
				<Box marginBottom={isMobile ? '25px' : '15px'}>
					{isLoaded && addressValue.geopoint ? (
						<GoogleMap
							onLoad={onMapLoad}
							mapContainerStyle={containerStyle}
							center={{
								lat: addressValue.geopoint.lat,
								lng: addressValue.geopoint.lng
							}}
							zoom={17}
							options={{
								disableDefaultUI: true,
								zoomControl: true
							}}>
							<Marker
								position={{
									lat: addressValue.geopoint.lat,
									lng: addressValue.geopoint.lng
								}}
								onClick={() => {
									setOpenInfo(!openInfo)
								}}
								draggable={true}
								onDragEnd={(e) => {
									Geocode.fromLatLng(
										`${e.latLng.lat()}`,
										`${e.latLng.lng()}`
									).then((response) => {

										setAddressValue({
											...addressValue,
											address: response.results[0].formatted_address,
											geopoint: {
												lat: e.latLng.lat(),
												lng: e.latLng.lng()
											}
										})
										setCustomAddress(response.results[0].formatted_address)
									})
								}}>
								{openInfo && (
									<InfoWindow
										position={{
											lat: addressValue.geopoint.lat,
											lng: addressValue.geopoint.lng
										}}>
										<div>
											<span>
												<b>Dirección: {addressValue.address}</b>
											</span>
										</div>
									</InfoWindow>
								)}
							</Marker>
							{branches.map((branch) => {
								//console.log("branch.geopoint?.latitude", branch.geojson?.latitude);

								return <Marker
									icon="/logo24.png"
									key={`branch_marker_${branch.id}`}
									position={{
										lat: branch.geojson?.latitude,
										lng: branch.geojson?.longitude
									}}
								>
								</Marker>
							}
							)}
						</GoogleMap>
					) : (
						<Box
							display='flex'
							justifyContent='center'
							alignItems='center'
							minHeight='40vh'>
							<CircularProgress size='5rem' />
						</Box>
					)}
				</Box>
				{coverage ? (
					<p className='success-message text-13px-400'>
						La dirección actual tiene cobertura en{' '}
						<strong>{coverageBranch.name}</strong>
					</p>
				) : (
					<p className='error-message text-13px-400'>
						La dirección actual no cuenta con cobertura
					</p>
				)}
				<p className='text-13px-400 color-gray-light'>
					Verifica que la dirección{' '}
					{addressValue.address ? (
						<strong>{`${addressValue.address}`}</strong>
					) : (
						<strong>
							{' '}
							{`${addressValue.splitAddress.street} ${addressValue.splitAddress.first}${addressValue.splitAddress.second} #${addressValue.splitAddress.third}${addressValue.splitAddress.fourth}-${addressValue.house}, ${addressValue.city}, Colombia`}
						</strong>
					)}{' '}
					coincide con la <b>ubicación en el mapa</b> o selecciona en el mapa.
				</p>
				<div className='btn-modal-content'>
					<div className='btn-modal'>
						<CustomButton
							label={cancelLabel}
							variant='outlined'
							onClick={cancelAction}
							width={isMobile ? '100px' : '141px'}
						/>
					</div>
					<div className='btn-modal'>
						<CustomButton
							label={acceptLabel}
							onClick={acceptAction}
							disable={!coverage}
							width={isMobile ? '100px' : '141px'}
						/>
					</div>
				</div>
			</div>
		</CustomModal>
	)
}

AddressCoverageValidationModal.propTypes = {
	// control
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func,
	// labels
	acceptLabel: PropTypes.string,
	cancelLabel: PropTypes.string,
	// actions
	acceptAction: PropTypes.func.isRequired,
	cancelAction: PropTypes.func,
	//address
	setAddressValue: PropTypes.func,
	addressValue: PropTypes.object.isRequired,
	//custom address
	setCustomAddress: PropTypes.func,
	customAddress: PropTypes.string,
	getGeoPointFromAddress: PropTypes.func,
	//Mobile
	isMobile: PropTypes.bool
}

AddressCoverageValidationModal.defaultProps = {
	acceptLabel: 'Confirmar',
	cancelLabel: 'Cancelar'
}

export default AddressCoverageValidationModal
