import React from 'react'
import CustomButton from '../../components/atoms/buttons/CustomButton'
import { PRODUCTS_SECTION, MARKETS_SECTION } from './useFavorites'
// hooks
import useFavorites from './useFavorites'
// sub components
import FavoriteProducts from './FavoriteProducts/FavoriteProducts'
import FavoriteMarkets from './FavoriteMarkets/FavoriteMarkets'

const FavoritesMobile = () => {
	const { renderSection, changeSection, section } = useFavorites()

	return (
		<div className='mobile-page-wrapper favorites-pages-mobile'>
			<span className='text-21px-700 color-blue'>Mis favoritos</span>
			<div className='favorites-actions'>
				<CustomButton
					label='Productos'
					variant={section !== PRODUCTS_SECTION ? 'outlined' : 'filled'}
					onClick={() => changeSection(PRODUCTS_SECTION)}
				/>
				<CustomButton
					label='Mis mercados'
					variant={section !== MARKETS_SECTION ? 'outlined' : 'filled'}
					onClick={() => changeSection(MARKETS_SECTION)}
				/>
			</div>
			{renderSection({
				[PRODUCTS_SECTION]: FavoriteProducts,
				[MARKETS_SECTION]: FavoriteMarkets
			})}
		</div>
	)
}

export default FavoritesMobile
