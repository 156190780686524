import React from 'react'
import PropTypes from 'prop-types'
import { Skeleton } from '@material-ui/lab'

export const ProductCounter = (props) => {
	const {
		// flags
		big,
		uneditable,
		loading,
		// styles
		color,
		fontColor,
		// data
		count,
		minValue,
		// actions
		isDisable,
		incrementCounter,
		decrementCounter,
		// additional props
		skeletonProps
	} = props
	const fontSize = big ? '17px' : '12px'
	const size = big ? 'big' : 'small'

	const CounterButton = ({ onClick, children, inputProps }) => (
		<button
			className={`product-counter-button -${size} -${color}`}
			onClick={onClick}
			{...inputProps}
		>
			<span className={`text-${fontSize}-500 color-blue`}>{children}</span>
		</button>
	)
	CounterButton.propTypes = {
		onClick: PropTypes.func.isRequired,
		children: PropTypes.node.isRequired,
		inputProps: PropTypes.object
	}

	return (
		<>
			{loading ? (
				<Skeleton
					variant='rect'
					animation='wave'
					width={180}
					height={big ? 36 : 26}
					style={{ borderRadius: 30 }}
					{...skeletonProps}
				/>
			) : (
				<div className={`product-counter -without-grams -${size}`}>
					<span className={`text-${fontSize}-500 color-${fontColor}`}>
						Cant.
					</span>
					{!uneditable && (
						<CounterButton
							onClick={decrementCounter}
							inputProps={{ disabled: count <= minValue}}
						>
							-
						</CounterButton>
					)}
					<span
						className={`text-${fontSize}-700 color-blue counter-label -${size} -${color}`}
					>
						{count}
					</span>
					{!uneditable && (
						<CounterButton
							onClick={incrementCounter}
							inputProps={{ disabled: isDisable }}
						>
							+
						</CounterButton>
					)}
				</div>
			)}
		</>
	)
}

ProductCounter.propTypes = {
	// flags
	big: PropTypes.bool,
	uneditable: PropTypes.bool,
	loading: PropTypes.bool,
	// styles
	color: PropTypes.string,
	fontColor: PropTypes.oneOf(['white', 'blue']),
	// data
	count: PropTypes.number,
	minValue: PropTypes.number,
	isDisable: PropTypes.bool,
	// actions
	incrementCounter: PropTypes.func.isRequired,
	decrementCounter: PropTypes.func.isRequired,
	// additional props
	skeletonProps: PropTypes.object
}
ProductCounter.defaultProps = {
	color: 'light-blue',
	fontColor: 'blue',
	incrementCounter: () => {},
	decrementCounter: () => {},
	count: 1,
	minValue: 0
}

export default ProductCounter
