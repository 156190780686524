import React from 'react'
import AlertModal from '../AlertModal/AlertModal'
import useAuth from '../../hooks/auth/useAuth'

const UserDeletedModal = () => {
	const { handleLogOut, userDeletedModal, hideUserDeledModal } = useAuth()

	return (
		<AlertModal
			open={userDeletedModal}
			message='Tu usuario ha sido eliminado, así que cerraremos tu sesión.'
			acceptLabel='Aceptar'
			acceptAction={handleLogOut}
			handleClose={hideUserDeledModal}
		/>
	)
}

export default UserDeletedModal
