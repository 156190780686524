import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import CustomModal from '../CustomModal/CustomModal'
// components
import SummaryButton from '../atoms/buttons/SummaryButton'
import CustomAutoComplete from '../atoms/inputs/CustomAutoComplete'
import ProductoGeneral from '../ProductList/ProductoGeneral'
// hooks
import { useLocation } from 'react-router-dom'
import useAuth from '../../hooks/auth/useAuth'
import useLayout from '../../hooks/layout/useLayout'
import useMarkets from '../../hooks/markets/useMarkets'
import useProducts from '../../hooks/products/useProducts'
// consts
import * as UnitTypes from '../../consts/ProductCountOptions'
// utils
import getPriceByOffice from '../../utils/getPriceByOffice'
import TotalToPay from '../../utils/TotalToPay'

const SelectedProductToMarketModal = () => {
	const { marketModal, toggleMarketModal, marketProduct } = useProducts()

	const location = useLocation()
	const { getCityStorage, getCityBranchOffice } = useAuth()
	const { showSnackbar } = useLayout()
	const { getFullMarketsFromDB, editMarket, findMarket } = useMarkets()

	const city = getCityStorage()
	const branchOffice = getCityBranchOffice()
	const [unitT, setUnitT] = useState('unit')
	const [view, setView] = useState(false)
	const [marketSelected, setMarketSelected] = useState(undefined)
	const [marketList, setMarketList] = useState([])
	const [productOptions, setProductOptions] = useState(undefined)


//obtiene una lista de mercados, la almacena en el estado y establece algunas opciones de producto basadas en la información de un objeto de marketProduct.
	const updateInitialOptions = () => {
		let options = {}
		getFullMarketsFromDB(city, branchOffice).then((result) => {
			setMarketList(
				result.map((m) => {
					return {
						title: m.name,
						value: m
					}
				})
			)
		})
		if (marketProduct.id_item) {
			setUnitT(UnitTypes.UNIT.value)
			let branch = marketProduct.price_branch_offices?.find((branch) =>
				getPriceByOffice(branch, city, branchOffice)
			)
			options = {
				value: {
					...marketProduct,
					price: branch.price,
					pum: branch.pum,
					quantity_pum: marketProduct.quantity_pum,
					unit_type: UnitTypes.UNIT.value,
					count: 1
				}
			}
			setView(true)
		}
		setProductOptions(JSON.parse(JSON.stringify(options)))
	}

	//calcula un total de pago en función del valor seleccionado en productOptions.
	const getTotal = () => {
		if (productOptions.value) return TotalToPay([productOptions.value])
		else return 0
	}

	//maneja cambios en la cantidad de productos seleccionados y actualizar el objeto productOptions con la nueva cantidad y tipo de unidad.
	const handleProductCountChange = () => (count) => {
		setProductOptions({
			value: { ...productOptions.value, count: count, unit_type: unitT }
		})
	}

	//maneja los cambios en la unidad de un producto y la eliminación de un producto
	const handleProductUnitTypeChange = () => (unitType) => {
		setView(false)
		setUnitT(unitType)
		setTimeout(() => {
			setProductOptions({
				value: { ...productOptions.value, count: 1, unit_type: unitType }
			})
			setView(true)
		}, 500)
	}

	const handleProductDelete = () => () => {
		toggleMarketModal()
	}

	//funcion que agrega productos a una lista de mercado
	const addProductsToMarket = () => {
		if (marketSelected) {
			let markets = findMarket(marketSelected.id)
			let newMarket = markets.products
			newMarket.push(productOptions.value)
			console.log('markets per user', newMarket)
			editMarket(marketSelected.id, { products: newMarket })

			showSnackbar('agregado al mercado exitosamente', 'success')
			toggleMarketModal()
		} else {
			showSnackbar('Debe seleccionar un mercado', 'warning')
		}
	}

	useEffect(() => {
		if (marketProduct.id_item) {
			updateInitialOptions()
			setMarketSelected(undefined)
		}
	}, [marketProduct])

	useEffect(() => {
		if (marketModal) {
			toggleMarketModal()
		}
	}, [location])

	return (
		<CustomModal open={marketModal} handleClose={toggleMarketModal}>
			<div
				className={`selectedProductToMarketModal add-product-to-market-${
					isMobile ? 'mobile' : 'desktop'
				}`}>
				{isMobile && (
					<div className='aptm-go_back'>
						<img
							onClick={() => toggleMarketModal(false)}
							className='back-arrow'
							src='/static/media/arrow-back.92f5f911.svg'
							alt='go back'
						/>
					</div>
				)}
				<span className='text-21px-700 color-blue aptm-title'>
					Agregar producto a mercado
				</span>
				<div className='aptm-content'>
					<div className='first-segment'>
						<CustomAutoComplete
							label={'Mercados'}
							options={marketList}
							onChange={(_, newValue) => {
								if (newValue) {
									setMarketSelected(newValue.value)
								}
							}}
						/>
						{view && (
              <ProductoGeneral
                product={productOptions.value}
								productId={productOptions.value.id_item}
								categoryId={productOptions.value.main_category_id}
								subcategoryId={productOptions.value.sub_category_id}
								name={productOptions.value.name}
								price={productOptions.value.price}
								count={productOptions.value.count}
								unitType={productOptions.value.unit_type}
								uneditable={false}
								onCountChange={handleProductCountChange(
									productOptions?.value?.id_item
								)}
								onUnitTypeChange={handleProductUnitTypeChange(
									productOptions?.value?.id_item
								)}
								onDelete={handleProductDelete(productOptions.value.id_item)}
								typeSelectEnable={() => true}
								rangeQuantity={productOptions.value.quantity_pum}
							/>
						)}
						<div className='aptm-summary-btn'>
							{productOptions && (
								<SummaryButton
									disabled={false}
									hidePrice={false}
									onClick={addProductsToMarket}
									actionLabel={'Agregar producto'}
									price={getTotal()}
								/>
							)}
						</div>
					</div>
				</div>
			</div>
		</CustomModal>
	)
}

export default SelectedProductToMarketModal
