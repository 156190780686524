import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
// hooks
import OrderStatusChip from '../../../../components/OrderStatusChip/OrderStatusChip'
import useStringFormater from '../../../../hooks/stringFormater/useStringFormater'

const OrderCardMobile = (props) => {
	const { orderNumber, price, address, status, numOfItems, to } = props
	const { formatPrice, formatAddress } = useStringFormater()

	return (
		<Link className='order-card' to={to}>
			<div className='order-card-title'>
				<span className='text-18px-700 color color-gray-dark'>
					No. {orderNumber}
				</span>
				<OrderStatusChip status={status} />
			</div>
			<div className='details'>
				<div>
					<span className='text-11px-400 color-gray-light'>
						{address.address ? address.address : formatAddress(address)}
					</span>
					<span className='text-11px-400 color-gray-light'>
						{numOfItems} {numOfItems > 1 ? 'Items' : 'Item'}
					</span>
				</div>
				<div>
					<span className='text-12px-5 color-gray-light'>Subtotal</span>
					<span className='text-18px-700 color-blue'>{formatPrice(price)}</span>
				</div>
			</div>
		</Link>
	)
}

OrderCardMobile.propTypes = {
	orderNumber: PropTypes.string.isRequired,
	price: PropTypes.oneOfType([
		PropTypes.number.isRequired,
		PropTypes.string.isRequired
	]),
	address: PropTypes.object.isRequired,
	status: PropTypes.shape({
		cancelled: PropTypes.object.isRequired,
		received: PropTypes.object.isRequired,
		enlistment: PropTypes.object.isRequired,
		dispatched: PropTypes.object.isRequired,
		finalized: PropTypes.object.isRequired
	}),
	numOfItems: PropTypes.oneOfType([
		PropTypes.number.isRequired,
		PropTypes.string.isRequired
	]),
	to: PropTypes.string.isRequired
}

export default OrderCardMobile
