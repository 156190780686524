export const productImageBaseUrl = "https://res.cloudinary.com/merca-z/image/upload/dpr_auto,q_50,f_jpg,w_auto/PRODUCTS_V2/"
// Consulta imaghen de producto
export default function (product) {
  const imgage =
    productImageBaseUrl + 'productId.png'
  const url = imgage
    // .replace(
    // 	'categoryId',
    // 	parseInt(product.main_category_id) >= 10
    // 		? product.main_category_id.slice(1)
    // 		: product.main_category_id
    // )
    .replace('productId', product.id_item)
  console.log(url)

  fetch(url).then((response) => {
    if (!response.ok) {
      console.log('false')
      return false
    } else {
      console.log('true')
      return true
    }
  })
}
