import React, { useEffect, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import Logo from '../../../assets/logomercaz1.png'
import { routeFormatter, routes } from '../../../routes/routes'
import CartMenu from '../../CartMenu/CartMenu'
// hooks
import PropTypes from 'prop-types'
import pare from '../../../assets/pare.png'
import useAuth from '../../../hooks/auth/useAuth'
import useShoppingCart from '../../../hooks/shoppingCart/useShoppingCart'

/**
 * Componente FooterMobile que renderiza la sección de pie de página en la versión de movil.
 * @param {Object} information - Objeto con información de los enlaces del footer.
 * @returns {JSX.Element} Componente FooterMobile.
 */
const FooterMobile = ({ information }) => {
	/**
	 * Hook para obtener la ubicación actual del historial de navegación.
	 *
	 * @returns {object} Un objeto que contiene información sobre la ubicación actual.
	 */
	const location = useLocation()

	/**
	 * Función que hace scroll hacia la parte superior de la página con animación suave.
	 */
	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		})
	}

	/**
	 * Hook que maneja el estado del elemento ancla (anchor element) para el menú.
	 *
	 * @returns {array} Un array con el estado actual y una función para actualizarlo.
	 */
	const [anchorEl, setAnchorEl] = useState(null)

	/**
	 * Función que abre el menú y establece el elemento ancla.
	 *
	 * @param {object} event El objeto de evento que desencadenó la función.
	 */
	const openMenu = (event) => {
		setAnchorEl(event.currentTarget)
	}
	/**
	 * Función que cierra el menú y establece el elemento ancla en nulo.
	 */
	const closeMenu = () => setAnchorEl(null)

	const { isLogged, showLoginModal } = useAuth()
	const { products } = useShoppingCart()
	const history = useHistory()

	// const numOfItems = products.length
	const [totalItemsInCart, setTotalItemsInCart] = useState(0)

	/**
	 * Hook que se ejecuta cuando hay un cambio en los productos en el carrito de compras.
	 * Suma el número total de elementos en el carrito de compras y actualiza el estado correspondiente.
	 */
	useEffect(() => {
		const sumall = products
			.map((product) => product.count)
			.reduce((prev, curr) => prev + curr, 0)
		setTotalItemsInCart(sumall)
	}, [products])

	/**
	 * Función que redirige al usuario a su cuenta.
	 * Si el usuario no está autenticado, muestra el modal de inicio de sesión.
	 */
	const goToAccount = () => {
		if (!isLogged) return showLoginModal()
		history.push(routeFormatter.toAccount(''))
	}

	/**
	 * Función que redirige al usuario a su lista de favoritos.
	 * Si el usuario no está autenticado, muestra el modal de inicio de sesión.
	 */
	const goToFavorites = () => {
		if (!isLogged) return showLoginModal()
		history.push(routes.favorites.path)
	}

	/**
	 * Función que redirige al usuario a su historial.
	 * Si el usuario no está autenticado, muestra el modal de inicio de sesión.
	 */
	const goToHistory = () => {
		if (!isLogged) return showLoginModal()
		history.push(routes.wallet.path)
	}

	return (
		<>
			<footer className='footerMobile'>
				<div className='go-up' onClick={() => scrollToTop()}>
					<div className='icon-container'>
						<i className='arrow -white -up'></i>
					</div>
				</div>
				<Link to={routes.home.path} className='logo'>
					<img src={Logo} alt='logo_merca_z' />
				</Link>
				{information.map((item, i) => (
					<a
						key={i}
						href={item.link}
						target='_blank'
						rel='noreferrer'
						style={{ textDecoration: 'none' }}
						className='text-12px-400 information-item'>
						{item.description}
					</a>
				))}
				<p className='text-12px-400 color-blue'>
					Síguenos en nuestras redes sociales
				</p>
				<div className='social-networks'>
					<a
						href='https://www.facebook.com/supermercaz'
						target='_blank'
						rel='noopener noreferrer'>
						<i className='facebook-icon-blue'></i>
					</a>
					<a
						href='https://www.instagram.com/mercazsupermercados/'
						target='_blank'
						rel='noopener noreferrer'>
						<i className='instagram-icon'></i>
					</a>
					<a
						href='https://wa.link/ty1w8s'
						target='_blank'
						rel='noopener noreferrer'>
						<i className='whatsapp-icon'></i>
					</a>
				</div>
				<img src={pare} style={{ width: '80px' }} />
				<div className='navbar'>
					<div className='content'>
						<Link to={routes.home.path} className='item text-decoration-none'>
							{location.pathname === routes.home.path ? (
								<>
									<i className='home-icon -blue' />
									<p className='text-10px-500 color-blue'>{routes.home.name}</p>
								</>
							) : (
								<i className='home-icon -gray' />
							)}
						</Link>
						<span onClick={goToFavorites} className='item text-decoration-none'>
							{location.pathname === routes.favorites.path ? (
								<>
									<i className='heart -blue-outlined' />
									<p className='text-10px-500 color-blue'>
										{routes.favorites.name}
									</p>
								</>
							) : (
								<i className='heart -gray-outlined' />
							)}
						</span>
						<span onClick={goToHistory} className='item text-decoration-none'>
							{location.pathname === routes.wallet.path ? (
								<>
									<i className='wallet-icon -blue-outlined' />
									<p className='text-10px-500 color-blue'>
										{routes.wallet.name}
									</p>
								</>
							) : (
								<i className='wallet-icon -gray-outlined'></i>
							)}
						</span>
						<span onClick={goToAccount} className='item text-decoration-none'>
							{location.pathname === routes.account.path ? (
								<>
									<i className='person -blue-outlined' />
									<p className='text-10px-500 color-blue'>
										{routes.account.name}
									</p>
								</>
							) : (
								<i className='person -gray-outlined'></i>
							)}
						</span>
						<div className='cart' onClick={openMenu}>
							<span className='item text-decoration-none'>
								{totalItemsInCart !== 0 && (
									<span className='item-counter text-10px-500'>
										{totalItemsInCart}
									</span>
								)}
								<i className='cart-icon -white'></i>
							</span>
						</div>
					</div>
				</div>
			</footer>
			<CartMenu anchor={anchorEl} onClose={closeMenu} />
		</>
	)
}

FooterMobile.propTypes = {
	information: PropTypes.array.isRequired
}

export default FooterMobile
