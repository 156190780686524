import * as AccountSections from '../consts/AccountSections'
import * as appbarConsts from '../consts/AppBarMobileLayouts'
/**
 * Objeto con todas las rutas internas de la aplicacion
 */
const routes = {
	home: {
		path: '/',
		name: 'Inicio',
		mobileAppbarType: appbarConsts.PRINCIPAL
	},
	category: {
		path: '/category/:categoryId',
		name: 'Category',
		mobileAppbarType: appbarConsts.PRINCIPAL
	},
	subcategory: {
		path: '/category/:categoryId/:subcategoryId',
		name: 'Subcategory',
		mobileAppbarType: appbarConsts.PRINCIPAL
	},
	wallet: {
		path: '/account/history',
		name: 'Wallet',
		mobileAppbarType: appbarConsts.NAVIGATION
	},
	account: {
		path: '/account/',
		name: 'Cuenta',
		mobileAppbarType: appbarConsts.NAVIGATION
	},
	accountSection: {
		path: '/account/:section',
		name: 'Cuenta',
		mobileAppbarType: appbarConsts.NAVIGATION
	},
	login: {
		path: '/login',
		name: 'Login'
	},
	register: {
		path: '/register',
		name: 'Registro'
	},
	resetpassword: {
		path: '/resetpassword',
		name: 'Recuperar Contraseña'
	},
	cart: {
		path: '/cart',
		name: 'Carrito',
		mobileAppbarType: appbarConsts.NAVIGATION
	},
	orderFinished: {
		path: '/orderFinished',
		name: 'Pedido finalizado',
		mobileAppbarType: appbarConsts.PRINCIPAL
	},
	product: {
		path: '/product/:categoryId/:productId',
		name: 'Detalles de producto',
		mobileAppbarType: appbarConsts.NAVIGATION
	},
	favorites: {
		path: '/favorites/',
		name: 'Favoritos',
		mobileAppbarType: appbarConsts.PRINCIPAL
	},
	searchResult: {
		path: '/search/:query',
		name: 'Resultado Busqueda',
		mobileAppbarType: appbarConsts.NAVIGATION
	},
	offers: {
		path: '/offers',
		name: 'Ofertas',
		mobileAppbarType: appbarConsts.NAVIGATION
	}
}

const routeFormatter = {
	toCategory: (categoryId) => {
		return routes.category.path.replace(':categoryId', categoryId)
	},
	toSubcategory: (categoryId, subcategoryId) => {
		return routes.subcategory.path
			.replace(':categoryId', categoryId)
			.replace(':subcategoryId', subcategoryId)
	},
	toAccount: (section) => {
		return routes.accountSection.path.replace(
			':section',
			section === undefined ? AccountSections.OPTION_DIRECCIONES : section
		)
	},
	toSearch: (result) => {
		return routes.searchResult.path.replace(':result', result)
	}
}

export { routes, routeFormatter }
