import { useCallback, useRef, useState } from "react";
import SearchWacoHunt from "../../utils/SearchWacoHunt";
import useAuth from "../auth/useAuth";


/**
 * manejador de funciones auxiliares para las busquedas con Algolia
 * @returns {{
 * makeFilter: (function(*): string),
 * hits:any[],
 * search:(value:string)=>(any[]),
 * getMoreData:(value:string)=>void
 * }}
 */
const useSearch = () => {
  const [hits, setHits] = useState([]);
  const hitsRef = useRef(hits)
  const currPage = useRef(0)// storing current page number
  const wasLastListRef = useRef(false)
  const lastValueSearch = useRef(null)
  const lastFilter = useRef(null)
  const { getCityStorage } = useAuth()
  /**
   * Construir el filtro con base en stock de la sede, la ciudad y el estado de activación del producto
   * @param filter
   * @returns {string}
   */
  const makeFilter = useCallback(() => {/*(filter) => {
    let customFilter = filter ? filter : `stock_city_${cityName} > 5`
    let customFilterBranch = "";
    if (getStockAttributeName !== "") {
      customFilterBranch = `AND ${getStockAttributeName} > 0`
    }
    customFilter = `(${customFilter}) AND (isActive:true) ${customFilterBranch}`*/
    let customFilter = `isActive:true`

    return customFilter;
  }, [])
  const search = useCallback(async (
    value,
    filter = false,
    baseHits = [],
    limit = 5
  ) => {
    if (lastValueSearch.current === null) {
      lastValueSearch.current = value;
      wasLastListRef.current = false;
      currPage.current = 0;
    }
    if (wasLastListRef.current) {
      console.log("wasLastListRef true");
      return baseHits
    }

    if (lastValueSearch.current !== value) {
      currPage.current = 0;
    }
    lastValueSearch.current = value;
    lastFilter.current = filter;
    console.log("useSearch");

    const res = await SearchWacoHunt(value, {
      limit,
      page: currPage.current,
      isActive: true,
      city: getCityStorage()
    }).then(async (res) => {
      let hits = res.hits;
      const hasResults = hits.length > 0;

      currPage.current += 1;
      const resHits = [...baseHits, ...hits]
      if (res.nbPages === (res.page + 1)) {
        wasLastListRef.current = true
      } else if (hits.length === 0 && hasResults) {
        return await search(value, filter, resHits)
        // } else if ((res.page + 1) !== res.nbPages && hits.length < 20) {
        //   return await search(value, filter, resHits)
      }

      hitsRef.current = resHits;
      setHits(resHits)
      return resHits;
    }).catch(err => {
      console.log(err)
      return []
    })
    return res

  }, [makeFilter])

  const getMoreData = useCallback(async (value,
    filter = false,
    baseHits = []) => {
    let nh = baseHits ? baseHits : hitsRef.current;
    if (nh.length === 0) nh = hitsRef.current;
    search(value || lastValueSearch.current, filter || lastFilter.current, nh, 20)
  }, [])

  return {
    getMoreData,
    search,
    hits
  }
}

export default useSearch