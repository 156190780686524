import { useContext, useState } from 'react'
import { context } from '../../context/ContextProvider'
import { cardsModel } from '../../context/models'
import * as paymentCardsActions from '../../context/paymentCards/paymentCardsActions'

const usePaymentCards = () => {
	const { state, dispatch } = useContext(context)
	const { cards, loading } = state.paymentCardsReducer

	const { authValues } = state.authReducer
	const { ID } = authValues

	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(0)

	// templates
	const getPaymentCardsTemplate = () => {
		return { ...cardsModel }
	}

	// get user Payment Cards from db
	const getUserPaymentCards = () => {
		if (authValues.ID) {
			paymentCardsActions.getUserPaymentCards(dispatch, authValues.ID)
		}
	}

	//CRUD
	const createPaymentCard = async (card, onSuccess = () => {}) => {
		if (ID) {
			paymentCardsActions
				.createPaymentCard(dispatch, ID, card)
				.then(() => onSuccess())
		} else {
			throw new Error(
				'MercaZ Error: You tried to create a market with empty ID'
			)
		}
	}

	const editPaymentCard = async (
		cardId,
		data,
		loadFast = false,
		onSuccess = () => {}
	) => {
		if (ID) {
			paymentCardsActions
				.editPaymentCard(dispatch, ID, cardId, data, loadFast)
				.then(() => onSuccess())
		} else {
			throw new Error('MercaZ Error: You tried to edit a market with empty ID')
		}
	}

	const deletePaymentCard = (cardId) => {
		if (ID) {
			paymentCardsActions.deletePaymentCard(dispatch, ID, cardId)
		} else {
			throw new Error(
				'MercaZ Error: You tried to delete a market with empty ID'
			)
		}
	}

	return {
		cards,
		loading,
		//functions
		getUserPaymentCards,
		// CRUD
		createPaymentCard,
		editPaymentCard,
		deletePaymentCard,
		// templates
		getPaymentCardsTemplate,
		//set payment method
		selectedPaymentMethod,
		setSelectedPaymentMethod,
	}
}

export default usePaymentCards
