/**
 * 
 * @module ProductMaturities
 * @description Constants for product maturities.
 */

/**
 * Green maturity constant.
 * @constant {Object}
 * @property {string} label - The label for green maturity.
 * @property {string} code - The code for green maturity.
 */
export const GREEN = { label: 'Verde', code: 'green' }

/**
 * Normal maturity constant.
 * @constant {Object}
 * @property {string} label - The label for normal maturity.
 * @property {string} code - The code for normal maturity.
 */
export const NORMAL = { label: 'Normal', code: 'normal' }

/**
 * Mature maturity constant.
 * @constant {Object}
 * @property {string} label - The label for mature maturity.
 * @property {string} code - The code for mature maturity.
 */
export const MATURE = { label: 'Maduro', code: 'mature' }

/**
 * Array of all maturities.
 * @constant {Array}
 */
export const Maturities = [GREEN, NORMAL, MATURE]