import React from 'react'

import { makeStyles, Snackbar } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'

import { isMobile } from 'react-device-detect'
import useLayout from '../../hooks/layout/useLayout'

/**
 * Hook de Material UI que define los estilos para la alerta.
 *
 * @returns {Object} Los estilos a aplicar en la alerta.
 */
const useAlertStyles = makeStyles(() => ({
	root: {
		borderRadius: 30
	}
}))

/**
 * Hook de Material UI que define los estilos para la alerta en dispositivos móviles.
 *
 * @returns {Object} Los estilos a aplicar en la alerta.
 */
const useSnackStylesMobile = makeStyles(() => ({
	root: {
		marginBottom: 90
	}
}))

/**
 * Componente que renderiza una alerta con un mensaje en la parte inferior de la pantalla.
 *
 * @returns {JSX.Element} El componente a renderizar.
 */
const CustomSnackbar = () => {
	const alertClasses = useAlertStyles()
	const snackClassesMobile = useSnackStylesMobile()

	const { snack, hideSnackbar } = useLayout()
	const { isVisible, message, severity } = snack

	return (
		<>
			{
				<Snackbar
					open={isVisible}
					autoHideDuration={3000}
					onClose={(_, reason) => {
						if (reason === 'clickaway') return
						hideSnackbar()
					}}
					classes={isMobile ? snackClassesMobile : undefined}>
					<MuiAlert
						className={alertClasses.root}
						elevation={6}
						variant='filled'
						severity={severity}
						onClose={hideSnackbar}>
						{message}
					</MuiAlert>
				</Snackbar>
			}
		</>
	)
}

export default CustomSnackbar
