import * as observationsActionsTypes from "./observationsActionsTypes";

export const addObservation = (dispatch, value, productId) => {
  dispatch({
    type: observationsActionsTypes.SET_OBSERVATION_PRODUCT,
    payload: {
      productId: productId,
      value: value
    }
  })
}

export const clearObservations = (dispatch) => {
    dispatch({
        type: observationsActionsTypes.CLEAR_OBSERVATIONS
    })
}