import React from 'react'
import PropTypes from 'prop-types'
import { routeFormatter } from '../../../routes/routes'
import * as AccountSections from '../../../consts/AccountSections'
// components
import UserField from './UserField'
import UserPhoto from './UserPhoto/UserPhoto'
import { Skeleton } from '@material-ui/lab'
// hooks
import useUserInfo from './useUserInfo'
import { useHistory } from 'react-router'

const UserInfo = ({ to }) => {
	const { name, lastname, document, phone, email, loading } = useUserInfo()

	const history = useHistory()

	return (
		<div className='user-info'>
			<div className='user-header'>
				{loading ? (
					<Skeleton variant='text' width={130} animation='wave' />
				) : (
					<span className='user-name'>{name + ' ' + lastname}</span>
				)}
				<div
					className='edit-user-data'
					to={to}
					onClick={() =>
						history.push(
							routeFormatter.toAccount(AccountSections.OPTION_EDITAR_DATOS)
						)
					}
				>
					<span>Editar mis datos</span>
					<i className='edit-data-icon' />
				</div>
			</div>
			{/* foto del usuario */}
			<UserPhoto />
			<div className='user-data'>
				{loading ? (
					<Skeleton variant='rect' width={200} />
				) : (
					<UserField label='Documento' value={`C.c ${document}`} />
				)}
				{loading ? (
					<Skeleton variant='rect' width={130} />
				) : (
					<UserField label='Celular' value={phone} />
				)}
				{loading ? (
					<Skeleton variant='rect' width={200} />
				) : (
					<UserField label='Correo electrónico' value={email} />
				)}
			</div>
		</div>
	)
}

UserInfo.propTypes = {
	to: PropTypes.string.isRequired
}

export default UserInfo
