import { userModel } from '../models'
import * as authTypes from './authActionsTypes'
/**
 * Objeto que contiene los valores iniciales del estado de autenticación.
 * @typedef {Object} AuthInitialState
 * @property {UserModel} authValues - Objeto que representa los valores de autenticación del usuario.
 * @property {boolean} loading - Indica si la aplicación está en un estado de carga o no.
 * @property {string} error - Cadena que representa un mensaje de error relacionado con la autenticación.
 * @property {boolean} showPassword - Indica si se deben mostrar o no los caracteres de la contraseña en la interfaz de usuario.
 * @property {boolean} askToLogin - Indica si se debe solicitar al usuario que inicie sesión o no.
 * @property {boolean} userDeletedModal - Indica si se debe mostrar o no un modal que confirme la eliminación del usuario.
 */
export const initialState = {
	authValues: userModel,
	loading: false,
	error: '',
	showPassword: false,
	askToLogin: false,
	userDeletedModal: false,
	currentCity: ''
}
/**
 * Función reductora para el estado de autenticación.
 * @param {AuthInitialState} prevState - Estado anterior de la autenticación.
 * @param {Object} action - Acción a ejecutar sobre el estado.
 * @returns {AuthInitialState} Nuevo estado de autenticación.
 */
export const authReducer = (prevState = initialState, action) => {
	switch (action.type) {
		// MODALS
		case authTypes.SHOW_AUTH_MODAL:
			return {
				...prevState,
				askToLogin: true
			}
		case authTypes.HIDE_AUTH_MODAL:
			return {
				...prevState,
				askToLogin: false
			}
		case authTypes.SHOW_USER_DELETED_MODAL:
			return {
				...prevState,
				userDeletedModal: true
			}
		case authTypes.HIDE_USER_DELETED_MODAL:
			return {
				...prevState,
				userDeletedModal: false
			}
		case authTypes.SHOW_PASSWORD:
			return {
				...prevState,
				showPassword: action.value
			}
		case authTypes.REQUEST:
			return {
				...prevState,
				error: null,
				loading: true,
				showPassword: false
			}
		case authTypes.SUCCESS:
			return {
				...prevState,
				error: null,
				loading: false,
				authValues: action.authValues
			}
		case authTypes.ERROR:
			return {
				...prevState,
				loading: false,
				error: null
			}
		case authTypes.REQUEST_PASSWORD_RESET:
			return {
				...prevState,
				error: null,
				loading: true
			}
		case authTypes.SUCCESS_PASSWORD_RESET:
			return {
				...prevState,
				error: null,
				loading: false
			}
		case authTypes.ERROR_PASSWORD_RESET:
			return {
				...prevState,
				loading: false,
				error: null
			}
		case authTypes.UPDATE_AUTH_VALUES:
			return {
				...prevState,
				authValues: {
					...prevState.authValues,
					[action.value.id]: action.value.value
				}
			}
		case authTypes.SET_AUTH_VALUES:
			return {
				...prevState,
				loading: true
			}
		case authTypes.SET_AUTH_VALUES_SUCCESS:
			return {
				...prevState,
				loading: false,
				error: '',
				authValues: action.authValues
			}
		case authTypes.SET_AUTH_VALUES_FAILURE:
			return {
				...prevState,
				loading: false,
				error: action.error
			}
		case authTypes.SET_DEFAULTS:
			return initialState
		case authTypes.GET_CURRENT_USER:
			return {
				...prevState,
				loading: true
			}
		case authTypes.GET_CURRENT_USER_SUCCESS:
			return {
				...prevState,
				loading: false,
				error: '',
				authValues: {
					...prevState.authValues,
					...action.authValues
				}
			}
		case authTypes.GET_CURRENT_USER_FAILURE:
			return {
				...prevState,
				loading: false,
				error: action.error
			}
		case authTypes.UPDATE_USER_DATA:
			return {
				...prevState,
				loading: true
			}
		case authTypes.UPDATE_USER_DATA_SUCCESS:
			return {
				...prevState,
				loading: false,
				error: '',
				authValues: {
					...prevState.authValues,
					...action.authValues
				}
			}
		case authTypes.UPDATE_USER_DATA_FAILURE:
			return {
				...prevState,
				loading: false,
				error: action.error
			}
		case authTypes.UPDATE_SHOPPING_CART:
			return {
				...prevState,
				authValues: {
					...prevState.authValues,
					shopping_cart: action.products
				}
			}
		case authTypes.EMPTY_CART:
			return {
				...prevState,
				authValues: {
					...prevState.authValues,
					shopping_cart: []
				}
			}
		case authTypes.USER_CURRENT_CITY:
			return {
				...prevState,
				currentCity: action.payload,
			}
		default:
			return prevState
	}
}
