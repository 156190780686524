import PropTypes from 'prop-types'
import React from 'react'

import PhoneInput from 'react-phone-input-2'
import es from 'react-phone-input-2/lang/es.json'
import 'react-phone-input-2/lib/style.css'

/**
 * La función CustomPhoneInput es un componente React que renderiza
 * un campo de entrada para números de teléfono que utiliza el paquete react-phone-input-2.
 * @param {string} props.value - valor del input del teléfono
 * @param {function} props.onChange - función que se ejecuta al cambiar el valor del input del teléfono
 * @param {function} props.onBlur - función que se ejecuta al perder el foco el input del teléfono.
 * @param {string} props.errorMessage - mensaje de error para mostrar debajo del input del teléfono (obligatorio).
 * @param {string} props.variant - variante del estilo de la caja del teléfono.
 * @param {string} props.label - etiqueta del input del teléfono (obligatorio).
 * @param {boolean} props.hideLabel - booleano para mostrar o esconder la etiqueta del input del teléfono.
 * @param {object} props.inputProps - otras propiedades del input
 * @returns {JSX.Element}
 */
const CustomPhoneInput = ({
	value,
	onChange,
	onBlur,
	errorMessage,
	variant,
	label,
	hideLabel,
	inputProps
}) => {
	return (
		<div className={`phone-field -${variant}`}>
			{!hideLabel && (
				<span className='text-10px-400 color-gray label'>{label}</span>
			)}
			<PhoneInput
				value={value}
				onChange={onChange}
				onBlur={onBlur}
				localization={es}
				enableSearch
				searchPlaceholder='Buscar'
				countryCodeEditable={false}
				country={'co'}
				masks={{ co: '- ... ... ....' }}
				// classes
				containerClass='phone-field-container'
				inputClass='phone-field-input'
				buttonClass='phone-field-button'
				inputProps={inputProps}
			/>
			{errorMessage && <span className='text-12px-400 error-message'>{errorMessage}</span>}
		</div>
	)
}

CustomPhoneInput.propTypes = {
	value: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	onBlur: PropTypes.func,
	errorMessage: PropTypes.string.isRequired,
	variant: PropTypes.string,
	label: PropTypes.string.isRequired,
	hideLabel: PropTypes.bool,
	inputProps: PropTypes.object
}

CustomPhoneInput.defaultProps = {
	variant: 'blue',
	label: 'Número de contacto',
	errorMessage: ''
}

export default CustomPhoneInput
