import React, { useState, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import PaymentMethod from '../../../components/PaymentMethod/PaymentMethod'
import * as paymentTypes from '../../../components/PaymentMethod/PaymentTypes'
import CreateCard from '../../../components/CreateCard/CreateCard'
import CircularLoader from '../../../components/loaders/CircularLoader'
import DeleteCardModal from '../../../components/CreateCard/DeleteCardModal'
//hooks
import usePaymentCards from '../../../hooks/paymentCards/usePaymentCards'
import usePaymentMethod from '../../../components/PaymentMethod/usePaymentMethod'
import useAuth from '../../../hooks/auth/useAuth'

const TarjetasMobile = () => {
	const { authValues } = useAuth()
	const { cards, getUserPaymentCards, deletePaymentCard } = usePaymentCards()
	const { cardType } = usePaymentMethod()

	// delete modal control variables
	const [openDM, setOpenDM] = useState(false)

	// state
	// eslint-disable-next-line no-unused-vars
	const [selectedPaymentCard, setSelectedPaymentCard] = useState(null)
	const [paymentCardToEdit, setPaymentCardToEdit] = useState(null)
	const [paymentCardToDelete, setPaymentCardToDelete] = useState('')

	const prepareToEditPaymentCard = (paymentCard) => (e) => {
		e.stopPropagation()

		let paymentCardToEdit = { ...paymentCard }

		setPaymentCardToEdit(paymentCardToEdit)
		setSelectedPaymentCard(null)
	}

	const prepareToCreatePaymentCard = () => {
		setPaymentCardToEdit(null)
		setSelectedPaymentCard(null)
	}

	const confirmationToDelete = (cardId) => (e) => {
		e.stopPropagation()
		setOpenDM(true)
		setPaymentCardToDelete(cardId)
	}

	// data actions
	const handleDelete = () => {
		if (paymentCardToDelete) {
			setOpenDM(false)
			deletePaymentCard(paymentCardToDelete)
		}
	}

	useEffect(() => {
		getUserPaymentCards()
	}, [authValues])

	return !cards ? (
		<CircularLoader />
	) : (
		<div className='tarjetas-mobile'>
			<span className='text-21px-700 color-blue title'>Mis tarjetas</span>
			<Grid container className='content' spacing={2}>
				<Grid item xs={12} className='cards-container'>
					{cards.length > 0 ? (
						cards.map((card) => (
							<PaymentMethod
								key={card.id}
								lastDigits={card.mask}
								type={paymentTypes.methods.CREDIT_CARD}
								cardType={cardType(card.mask)}
								layout={paymentTypes.layouts.USER_ACCOUNT}
								onDelete={confirmationToDelete(card.id)}
								onSecondaryAction={prepareToEditPaymentCard(card)}
							/>
						))
					) : (
						<span className='text-16px-400 color-gray'>
							No has agregado tarjetas aún
						</span>
					)}
				</Grid>
				<DeleteCardModal
					open={openDM}
					handleClose={() => setOpenDM(false)}
					onDelete={handleDelete}
				/>
				<Grid item xs={12} lg={6} className='custom-scrollbar'>
					<CreateCard
						card={paymentCardToEdit}
						prepareToCreate={prepareToCreatePaymentCard}
					/>
				</Grid>
			</Grid>
		</div>
	)
}

export default TarjetasMobile
