import React from 'react'
import * as paymentTypes from './PaymentTypes'
import { formatCreditCardNumberToShow } from '../../utils/CardsUtils'

const cardType = (lastDigits) => {
	//verificar si el primer dígito de "lastDigits" es igual a 4
	if (lastDigits[0] == 4) { 
		return paymentTypes.cards.VISA //la función devuelve otra constante definida en el mismo objeto, que representa una tarjeta Visa
	} else {
		return paymentTypes.cards.MASTER_CARD //la función devuelve otra constante definida en el mismo objeto, que representa una tarjeta MasterCard
	}
}

const paymentTypeIcon = (type) => {
	//switch statement para determinar el icono de pago a devolver
	switch (type) {
		case paymentTypes.cards.MASTER_CARD:
			return 'mastercard-icon'
		case paymentTypes.cards.VISA:
			return 'visa-icon'
		default:
			return 'mastercard-icon'
	}
}

const cardTitle = (type, lastDigits) => {
	//esta función se utiliza para generar el título de la tarjeta.
	switch (type) {
		case paymentTypes.cards.MASTER_CARD:
			return `Mastercard * ${formatCreditCardNumberToShow(lastDigits)}`
		case paymentTypes.cards.VISA:
			return `Visa * ${formatCreditCardNumberToShow(lastDigits)}`
		default:
			return 'mastercard-icon'
	}
}

const principalAction = (layout, { isSelected, onSelect, onDelete }) => {
	// switch statement  según el valor de layout seleccionar, eliminar
	switch (layout) {
		case paymentTypes.layouts.ORDER_SUMMARY:
			return (
				<i
					className={`round-check-icon -${
						isSelected ? 'checked' : 'unchecked'
					} action`}
					onClick={onSelect}
				/>
			)
		case paymentTypes.layouts.USER_ACCOUNT:
			return (
				<i className='delete-icon-circle -blue action' onClick={onDelete} />
			)
		case paymentTypes.layouts.CARD_SELECTED:
			return null
		case paymentTypes.layouts.VISUALIZATION_MODE:
			return null
	}
}

const secondaryAction = (layout, onAction) => {
	//  switch statement segun el layout, modificar o cambiar
	switch (layout) {
		case paymentTypes.layouts.USER_ACCOUNT:
			return (
				<span className='text-12px-600 color-blue action' onClick={onAction}>
					Modificar
				</span>
			)
		case paymentTypes.layouts.CARD_SELECTED:
			return (
				<span className='text-12px-600 color-blue action' onClick={onAction}>
					Cambiar
				</span>
			)
		case paymentTypes.layouts.ORDER_SUMMARY:
			return null
		case paymentTypes.layouts.VISUALIZATION_MODE:
			return null
		default:
			return (
				<span className='text-12px-600 color-blue action' onClick={onAction}>
					Cambiar
				</span>
			)
	}
}

/**
 *
 * @param {number} number
 * @returns {array}
 */
const sharesOptions = (number) => {
	const options = []
	for (let i = 0; i < number; i++) { 
		options.push({
			label: (i + 1).toString(),
			value: i + 1
		})
	} // En cada iteración del bucle, se agrega un objeto a la matriz options.
	return options
}

const usePaymentMethod = () => {
	return {
		cardType,
		paymentTypeIcon,
		cardTitle,
		sharesOptions,
		principalAction,
		secondaryAction
	}
}

export default usePaymentMethod
