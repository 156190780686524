import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
// hooks
import useMarkets from '../../../../hooks/markets/useMarkets'
import CircularLoader from '../../../../components/loaders/CircularLoader'
import { useHistory } from 'react-router'
// routes
import { routes } from '../../../../routes/routes'
// components
import SummaryButton from '../../../../components/atoms/buttons/SummaryButton'

const LoadMarketFloatingCard = ({ marketId, city }) => {
	const {
		findMarket,
		getFullMarketProducts,
		totalGenerales,
		addProductsToCart
	} = useMarkets()

	const market = findMarket(marketId)

	const [fullProducts, setFullProducts] = useState(null)

	useEffect(() => {
		getFullMarketProducts(market.products, city).then((result) => {
			setFullProducts(result)
		})
	}, [market])

	const history = useHistory()

	const orderMarket = () => {
		addProductsToCart(fullProducts)
		history.push(routes.cart.path)
	}

	return (
		<div className='floating-card floating-finish-order'>
			{!market || !fullProducts ? (
				<CircularLoader />
			) : (
				<>
					<span className='text-11px-400 color-gray-light message'>
						Tienes 0 productos mayoristas &nbsp;y {fullProducts.length}{' '}
						{fullProducts.length === 1
							? 'producto general'
							: 'productos generales'}
					</span>
					<SummaryButton
						onClick={orderMarket}
						actionLabel='Pedir ahora'
						price={totalGenerales(fullProducts)}
					/>
				</>
			)}
		</div>
	)
}

LoadMarketFloatingCard.propTypes = {
	marketId: PropTypes.string.isRequired,
	city: PropTypes.string.isRequired
}

export default LoadMarketFloatingCard
