import React from 'react'
import { isBrowser } from 'react-device-detect'
// material ui
import { Fade, Slide } from '@material-ui/core'
// hooks
import useAuth from '../../hooks/auth/useAuth'
import useAuthModals from './useAuthModals'
// components
import CustomModal from '../CustomModal/CustomModal'
import CircularLoader from '../loaders/CircularLoader'

/**
 * La función Transition es un componente de transición que
 * se utiliza para animar la entrada y salida de un elemento.
 */
const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='down' ref={ref} {...props} />
})

/**
 * AuthModals es un componente React que muestra un modal para la autenticación de usuarios.
 * Este componente utiliza el hook useAuthModals para manejar el estado de apertura y
 * cierre del modal, y el hook useAuth para realizar la autenticación del usuario.
 * @returns {JSX.Element}
 */
const AuthModals = () => {
	const { handleModal, open } = useAuthModals()
	const { hideLoginModal, loading } = useAuth()

	const handleClose = () => hideLoginModal()

	return (
		<CustomModal
			open={open}
			onClose={handleClose}
			TransitionComponent={isBrowser ? Transition : Fade}
			transitionDuration={300}>
			{loading && (
				<div className='loading-screen'>
					<CircularLoader />
				</div>
			)}
			{handleModal({ handleClose })}
		</CustomModal>
	)
}

export default AuthModals
