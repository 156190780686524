/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
// components
import { Grid } from '@material-ui/core'
import AlertModal from '../../components/AlertModal/AlertModal'
import CardDireccion from '../../components/CardDireccion/CardDireccion'
import * as cardDireccionTypes from '../../components/CardDireccion/cardDireccionTypes'
import PaymentMethod from '../../components/PaymentMethod/PaymentMethod'
import * as paymentTypes from '../../components/PaymentMethod/PaymentTypes'
import ProductList from '../../components/ProductList/ProductList'
import SectionHeader from '../../components/SectionHeader/SectionHeader'
import SelectAddressModal from '../../components/SelectAddressModal/SelectAddressModal'
import ShoppingCartSummary from '../../components/Summaries/ShoppingCartSummary'
import CustomButton from '../../components/atoms/buttons/CustomButton'
import EditInputText from '../../components/atoms/inputs/EditInputText'
import InputCupon from '../../components/atoms/inputs/InputCupon'
import * as AccountSections from '../../consts/AccountSections'
import { routeFormatter } from '../../routes/routes'
// messages
import LoginMessage from './LoginMessage'
import MinimunValueMessage from './MinimunValueMessage'
// hooks
import PropTypes from 'prop-types'
import { useHistory } from 'react-router'
import useAuth from '../../hooks/auth/useAuth'
import useConfigs from '../../hooks/configs/useConfigs'
import useShoppingCart from '../../hooks/shoppingCart/useShoppingCart'
import ObservationsInCart from "../../components/atoms/inputs/ObservationsInCart";
/**
 *
 * @param {{
 * onlyProductList:boolean
 * }} props
 * @returns
 */
const CartDesktop = ({
						onlyProductList=false
					}) => {
	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(0)
	const [addressSelected, setAddressSelected] = useState(null)
	const [validCupon, setValidCupon] = useState(undefined)
	// const [Payment, setPayment] = useState(0)
	const setPayment = () => {}

	// product filtering
	const [productFilter, setProductFilter] = useState('')

	// select addresses modal
	const [openAM, setOpenAM] = useState(false)

	//Open modal if inactiveProducts exists
	const [showModal, setShowModal] = useState(false)

	const [listElement, setListElement] = useState([])

	const [messageModal, setMessageModal] = useState('')

	const history = useHistory()

	const {
		wholesalerProducts,
		generalProducts,
		emptyCart,
		changeProductCount,
		removeProductFromCart,
		setCupon,
		cartTotal
	} = useShoppingCart()

	const { authValues, isLogged } = useAuth()

	const { addresses } = authValues

	const { data } = useConfigs()

	const meetsMinimun = !data ? false : data.minimum_value <= cartTotal

	/**
	 * Returns a function to update product count
	 * @param {string} productId
	 * @param {{
	 *      asWholesaler?: boolean
	 * }} options
	 * @returns
	 */
	const onProductCountChange =
		(productId, options = {}) =>
			(count) =>
				changeProductCount(count, productId, options)

	/**
	 * Returns a function to delete product
	 * @param {string} productId
	 * @param {{
	 *      asWholesaler?: boolean
	 * }} options
	 * @returns
	 */
	const onProductDelete =
		(productId, options = {}) =>
			() =>
				removeProductFromCart(productId, options)

	const handleSelectAddress = () => {
		addresses.forEach((address, index) => {
			if (authValues.selected_address == index) {
				setAddressSelected(address)
			}
		})
	}

	const valideInactiveProducts = (products) => {
		if (products.length > 0) {
			let newMessage =
				'Los siguientes productos están inactivos, por favor eliminelos del carro de compras: \n'
			setMessageModal(newMessage)
			setListElement(products)
			setShowModal(true)
		}
	}

	useEffect(() => handleSelectAddress(), [addresses])
	useEffect(() => handleSelectAddress(), [authValues.selected_address])
	useEffect(() => {
		if (addressSelected && addressSelected?.city !== 'Tumaco') {
			wholesalerProducts.forEach((el) => {
				removeProductFromCart(el.id_item, { asWholesaler: true })
			})
		}
	}, [addressSelected])

	//cupon
	useEffect(() => {
		setCupon(validCupon)
	}, [validCupon])

	if (onlyProductList)
		return (
			<div
				className='cart-lists custom-scrollbar'
				style={{ marginTop: '1rem', width: "100%" }}>
				<ProductList
					title='Productos generales'
					products={generalProducts}
					resumeLabel='Generales'
					onProductCountChange={onProductCountChange}
					onProductDelete={onProductDelete}
					nameFilter={productFilter}
				/>
				{wholesalerProducts.length > 0 && (
					<div style={{ marginTop: '2rem' }}>
						<ProductList
							wholesaler
							title='Productos mayoristas'
							products={wholesalerProducts}
							resumeLabel='Mayorista'
							onProductCountChange={onProductCountChange}
							onProductDelete={onProductDelete}
							nameFilter={productFilter}
						/>
					</div>
				)}
			</div>
		)
	return (
		<div className='cart-page-desktop desktop-page-wrapper'>
			<SectionHeader title='Carrito' />
			<Grid container spacing={2} className='cart-body'>
				<Grid item xs={12} sm={6} md={4} className='cart-products'>
					<EditInputText
						value={productFilter}
						onChange={(e) => setProductFilter(e.target.value)}
						inputProps={{
							placeholder: 'Buscar productos'
						}}
						endAdornment={
							productFilter ? (
								<i
									className='x-icon'
									onClick={() => setProductFilter('')}
									style={{ cursor: 'pointer' }}
								/>
							) : null
						}
					/>
					<div
						className='cart-lists custom-scrollbar'
						style={{ marginTop: '1rem', width: "100%"  }}>
						<ProductList
							title='Productos generales'
							products={generalProducts}
							resumeLabel='Generales'
							onProductCountChange={onProductCountChange}
							onProductDelete={onProductDelete}
							nameFilter={productFilter}
						/>
						{wholesalerProducts.length > 0 && (
							<ProductList
								wholesaler
								title='Productos mayoristas'
								products={wholesalerProducts}
								resumeLabel='Mayorista'
								onProductCountChange={onProductCountChange}
								onProductDelete={onProductDelete}
								nameFilter={productFilter}
							/>
						)}
					</div>
					{wholesalerProducts.length + generalProducts.length > 0 && (
						<>
							<div className='cart-button'>
								<CustomButton
									label='Vaciar carrito'
									iconClass='delete-icon -white'
									iconFirst
									width='170px'
									onClick={emptyCart}
								/>
							</div>
							<div className='cart-button'>
								<CustomButton
									variant='outlined'
									label='Seguir comprando'
									iconClass='home-icon -white'
									iconFirst
									width='200px'
									onClick={() => history.push('/')}
								/>
							</div>
						</>
					)}
				</Grid>
				<Grid item xs={12} sm={6} md={8}>
					<Grid container spacing={2}>
						<Grid item xs={12} md={6} className='payment-methods-container'>
							{!isLogged ? (
								<LoginMessage />
							) : !meetsMinimun ? (
								<MinimunValueMessage />
							) : (
								<>
									{addressSelected && addresses.length > 0 ? (
										<CardDireccion
											cardType={cardDireccionTypes.VISUALIZATION_MODE}
											nameDireccion={addressSelected.name}
											direccion={addressSelected}
											timeEstimate='26 - 41 min'
											onUpdate={() => {
												setOpenAM(true)
											}}
										/>
									) : (
										<CardDireccion
											cardType={cardDireccionTypes.ADD_MODE}
											nameDireccion='No tienes direciones de entrega registradas'
											onUpdate={() => {
												history.push(
													routeFormatter.toAccount(
														AccountSections.OPTION_DIRECCIONES
													)
												)
											}}
										/>
									)}
									<SelectAddressModal
										open={openAM}
										handleClose={() => setOpenAM(false)}
										acceptLabel='Aceptar'
										//acceptAction={() => setOpenAM(false)}
										setOpenAM={setOpenAM}
									/>
									<InputCupon setCupon={setValidCupon} />
									{/* metodos de pago */}
									<PaymentMethod
										type={paymentTypes.methods.CASH}
										isSelected={selectedPaymentMethod === 0}
										onSelect={() => setSelectedPaymentMethod(0)}
										setPayment={setPayment}
									/>
									<PaymentMethod
										type={paymentTypes.methods.DATAPHONE}
										isSelected={selectedPaymentMethod === 3}
										onSelect={() => setSelectedPaymentMethod(3)}
										setPayment={setPayment}
									/>
									<PaymentMethod
										type={paymentTypes.methods.PSE}
										isSelected={selectedPaymentMethod === 4}
										onSelect={() => setSelectedPaymentMethod(4)}
										setPayment={setPayment}
									/>
									<ObservationsInCart />
									{/*                                         {authValues.cards ? //prueba de validacion provicional
                                            <>
                                                <PaymentMethod
                                                    lastDigits="4459"
                                                    type={paymentTypes.methods.DEBIT_CARD}
                                                    cardType={paymentTypes.cards.VISA}
                                                    isSelected={selectedPaymentMethod === 1}
                                                    onSelect={() => setSelectedPaymentMethod(1)}
                                                    layout={paymentTypes.layouts.ORDER_SUMMARY}
                                                />
                                                <PaymentMethod
                                                    lastDigits="6784"
                                                    type={paymentTypes.methods.CREDIT_CARD}
                                                    cardType={paymentTypes.cards.MASTER_CARD}
                                                    isSelected={selectedPaymentMethod === 2}
                                                    onSelect={() => setSelectedPaymentMethod(2)}
                                                    layout={paymentTypes.layouts.ORDER_SUMMARY}
                                                />
                                            </> :
                                            <PaymentMethod
                                                type={paymentTypes.methods.ADD_METHOD}
                                            />
                                        } */}
								</>
							)}
						</Grid>
						<Grid item xs={12} md={6}>
							<ShoppingCartSummary
								loading={!data}
								visualizationMode={!(meetsMinimun && isLogged)}
								validCupon={validCupon}
								paymentMethod={selectedPaymentMethod}
								setShowModal={valideInactiveProducts}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			{/*<div>
				wasa
			</div>*/}
			<AlertModal
				message={messageModal}
				open={showModal}
				acceptAction={() => setShowModal(false)}
				acceptLabel='Cerrar'
				listElement={listElement}></AlertModal>
		</div>
	)
}
CartDesktop.prototype = {
	onlyProductList: PropTypes.bool
}
export default CartDesktop
