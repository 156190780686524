import React from 'react'
import { isBrowser } from 'react-device-detect'
import FavoritesDesktop from './FavoritesDesktop'
import FavoritesMobile from './FavoritesMobile'

const Favorites = (props) => {
	const FavoritesLayout = isBrowser ? FavoritesDesktop : FavoritesMobile
	return <FavoritesLayout {...props} />
}

export default Favorites
