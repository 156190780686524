import React from 'react'
import { isBrowser } from 'react-device-detect'
import FooterDesktop from './FooterDesktop'
import FooterMobile from './FooterMobile'

/**
 * Componente Footer que renderiza la sección de pie de página en la aplicación.
 * @returns {JSX.Element} Componente Footer.
 */
const Footer = () => {
	const information = [
		{
			description: 'Nosotros',
			link: ''
		},
		{
			description: 'Contáctenos',
			link: ''
		},
		{
			description: 'Plan de premios',
			link: ''
		},
		{
			description: 'Términos y condiciones',
			link: 'https://firebasestorage.googleapis.com/v0/b/merca-z-prueba.appspot.com/o/Termns_and_conditions%2FPL-01%20Manual%20Interno%20de%20PolA%CC%83_ticas%20y%20Procedimientos.pdf?alt=media&token=04d964c0-d5f6-48fa-b90d-f183eef11a81'
		},
		{
			description: 'Sedes',
			link: ''
		},
		{
			description: 'Tratamiento de datos',
			link: 'https://firebasestorage.googleapis.com/v0/b/merca-z-prueba.appspot.com/o/Termns_and_conditions%2FPL-01%20Manual%20Interno%20de%20PolA%CC%83_ticas%20y%20Procedimientos.pdf?alt=media&token=04d964c0-d5f6-48fa-b90d-f183eef11a81'
		},
		{
			description: 'PQRS',
			link: 'https://www.mercaz.com.co/pqrs'
		},
		{
			description: 'Superintendencia de Industria y Comercio',
			link: 'https://www.sic.gov.co/'
		}
	]

	const FooterLayout = isBrowser ? FooterDesktop : FooterMobile
	return <FooterLayout information={information} />
}

export default Footer
