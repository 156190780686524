import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import SearchResultDesktopMobile from './SearchResultDesktopMobile'

const SearchResultLayout = SearchResultDesktopMobile
/**
 * Componente que representa la página de resultados de búsqueda.
 * @param {Object} props - Propiedades para el componente.
 * @returns {React.ReactNode} Elemento React que contiene la página de resultados de búsqueda.
 */
const SearchResult = (props) => {
  const { query } = useParams()
  const [realQuery, setRealQuery] = useState("")
  useEffect(() => {
    if (query && typeof query === "string") setRealQuery(query)
  }, [query]
  )

  // console.log("query", query);

  return useMemo(() => <SearchResultLayout
    key={`searchResult_${realQuery}`}
    query={realQuery}
    {...props} />, [realQuery])
}

export default SearchResult
