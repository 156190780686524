import React, { useMemo, useEffect } from 'react'
import { isBrowser } from 'react-device-detect'
//Project components
import CategoryDesktop from './CategoryDesktop'
import CategoryMobile from './CategoryMobile'

// hooks
import { useParams, Redirect } from 'react-router-dom'
import useAuth from '../../hooks/auth/useAuth'
import useProducts from '../../hooks/products/useProducts'

const Category = () => {
	const { categoryId } = useParams()
	const { loading, categories, getCategoryById } = useProducts()

	const category = useMemo(() => {
		return getCategoryById(categoryId)
	}, [categories, categoryId])

	const { getCityStorage, getCityBranchOffice, showLoginModal } = useAuth()
	const city = getCityStorage()
	const branchOffice = getCityBranchOffice()

	useEffect(() => {
		if (!city) showLoginModal()
	}, [])

	if (categories.length > 0 && !category) {
		// as the page "error_not_found" doesn't exists, router will redirect to 404 page as intended
		return <Redirect to='/error_not_found' />
	}

	const CategoryLayout = isBrowser ? CategoryDesktop : CategoryMobile
	return (
		<CategoryLayout
			loading={loading}
			category={category}
			city={city}
			branchOffice={branchOffice}
		/>
	)
}

export default Category
