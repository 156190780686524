import { useContext, useEffect } from 'react'
import { context } from '../../context/ContextProvider'
import * as offersActions from '../../context/offers/offersActions'
import db from '../../firebase/firestore'
import { getTotalValueOfProduct, getTotalValueOfProducts } from '../../utils/TotalToPay'
import NormalizeBranch from '../../utils/BranchesNormalize'

const cuponModel = (name, code, effects, error, id) => ({
  name,
  code,
  effects,
  error,
  id
})
const cuponsDb = db.collection('offers')
const usersDb = db.collection('users')

/**
 * Valida la offerta de acuerdo a su estatus, branch activa, la fecha de actividad y devuelve un array con las ofertas
 * que se pueden aplicar a el carrito de compras.
 *
 * @param {Array} promoOffers
 * @param {Array} products
 * @param {String} currentBranch
 * @returns {{
 * name,
 * effects,
 * productsIds:string[]
 * }[]}
 */
export const validateOffers = (promoOffers, products, currentBranch) => {
  // Array of aplicable promotions to return
  let applicables = []
  //console.log("validateOffers");
  currentBranch = NormalizeBranch(currentBranch)
  /**
   * Verifica status de una matriz de ofertas y si es asi la almacena en Array con offertas activas.
   */
  let activeOffers = [] // Array con offertas validas
  // console.log("VALIDAR PROMOS:")
  promoOffers.forEach((promo) => {
    const { status, conditions, effects, type, isInformative } = promo
    //Verify if promo offer is active and has conditions to validate.
    if (
      status &&
      !isInformative &&
      (conditions || []).length > 0 &&
      (effects || []).length > 0 &&
      type != 'cupon'
    ) {
      activeOffers.push(promo)
    }
  })
  // console.log("TOTAL VALIDADAS: " , activeOffers.length)
  // console.log(activeOffers)
  /**
   * Filtra una matriz de ofertas por branch activa.
   * @param {Array} promoOffers - Matriz de ofertas.
   * @returns {Array} - Nueva matriz de ofertas que cumplen la condición de branch activa.
   */
  const offersByActiveBranches = activeOffers.filter((p) => {
    return p.branches.map(item=>NormalizeBranch(item)).includes(currentBranch)
  })
  // console.log("CUENTA PARA MI SEDE: ", offersByActiveBranches.length)
  // console.log(offersByActiveBranches)

  /**
   * Filtra una matriz de ofertas por fecha activa.
   * @param {Array} offersByActiveBranches - Matriz de ofertas.
   * @returns {Array} - Nueva matriz de ofertas que cumplen la condición de fecha activa.
   */
  const offersByActiveDate = offersByActiveBranches.filter((p) => {
    const actualDate = new Date()
    const initial = p.initialDate.toDate()
    const final = p.finalDate.toDate()
    return actualDate >= initial && actualDate <= final
  })

  // console.log("DISPONIBLES POR MI FECHA ", offersByActiveDate.length)
  // console.log(offersByActiveDate)
  // console.log("applicables offersByActiveDate ", offersByActiveDate)

  offersByActiveDate.forEach((promo) => {
    const applicablesPreview = validOffertsDiscount(promo, products)
    if (applicablesPreview.productsIds.length > 0) {
      applicables = [
        ...applicables,
        applicablesPreview
      ]
    }
  })
  
  console.log("applicables ", applicables)

  return applicables
}

const validateByType = (promCondition, products, type) => { 
	// console.log("validateByType")
	const {
		modifier_type,
		modifier_value,
		condition,
		condition_value
	} = promCondition

  const validProducts = validateProductsModifier(
    modifier_type,
    modifier_value,
    products,
    type
  )
  // console.log(validProducts)
  const result = {
    value: false,
    products: validProducts.products
  }
  //console.log("modifier_type ", modifier_type)
  if (modifier_type === 'Producto') {
    const parts = modifier_value.split('/')
    const id = parts[parts.length - 1]
    const validProduct = products.find((p) => p.id_item === id)
    if (validProduct) {
      result.value = validateCondition(
        condition,
        validProducts.value,
        condition_value
      )
    } else {
      result.value = false
    }
  } else if (modifier_type === 'Categoria') {
    result.value = validateCondition(
      condition,
      validProducts.value,
      condition_value
    )
  } else if (modifier_type === 'Subcategoria') {
    result.value = validateCondition(
      condition,
      validProducts.value,
      condition_value
    )
  } else {
    if (validProducts.products.length > 0) {
      result.value = validateCondition(
        condition,
        validProducts.value,
        condition_value
      )
    }
  }
  return result
}

export const validateDate = (promCondition, productsO = [], offerData) => {
  const { condition, condition_value } = promCondition
  let productsValidated = []
  if (offerData?.enrute && productsO.length > 0) {
    if (offerData.enrute === "Subcategoria") {
      const [main_category_id, sub_category_id] = (offerData?.param || "").split("/")
      if (main_category_id && sub_category_id) {
        productsValidated = productsO.filter(item => {
          return item.main_category_id === main_category_id && item.sub_category_id === sub_category_id
        })
      }
    } else if (offerData?.enrute === "Categoria") {
      const main_category_id = (offerData?.param || "")
      if (main_category_id) {
        productsValidated = productsO.filter(item => {
          return item.main_category_id === main_category_id;
        })
      }
    }
  }
  const result = validateCondition(
    condition,
    new Date().getTime(),
    condition_value.toDate().getTime()
  )
  return {
    result,
    products: productsValidated
  }
}

export const validateCondition = (condition, value1, value2) => {
  switch (condition) {
    case 'Mayor igual que':
      return value1 >= value2
    case 'Menor igual que':
      return value1 <= value2
    case 'Igual que':
      return value1 === value2
    default:
      return false
  }
}

/*      Modifiers get products      */
const validateProductsModifier = (
  modifier_type,
  modifier_value,
  products,
  type
) => {
  //console.log("validateProductsModifier ", modifier_type, type)
  //Type can be: count or price
  const result = {
    value: 0,
    products: []
  }
  switch (modifier_type) {
    case 'Proveedor': {
      const currentProducts = products.filter(
        (product) => product.provider === modifier_value
      )
      if (currentProducts) {
        if (type === 'count') {
          const totalCount = currentProducts.reduce(
            (acumulado, p) => acumulado + p.count,
            0
          )
          result.value = totalCount
        } else if (type === 'price') {
          result.value = getTotalValueOfProducts(currentProducts)
        }
      } else {
        result.value = currentProducts.length
      }
      break
    }
    case 'Marca': {
      const currentProducts = products.filter(
        (product) => product.brand === modifier_value
      )
      if (currentProducts) {
        if (type === 'count') {
          const totalCount = currentProducts.reduce(
            (acumulado, p) => acumulado + p.count,
            0
          )
          result.value = totalCount
        } else if (type === 'price') {
          result.value = getTotalValueOfProducts(currentProducts)
        }
      } else {
        result.value = currentProducts.length
      }
      break
    }
    case 'Categoria': {
      const currentProducts = products.filter(
        (product) => product.main_category_id === modifier_value
      )
      if (currentProducts) {
        if (type === 'count') {
          const totalCount = currentProducts.reduce(
            (acumulado, p) => acumulado + p.count,
            0
          )
          result.value = totalCount
          result.products = currentProducts
        } else if (type === 'price') {
          result.value = getTotalValueOfProducts(currentProducts)
          result.products = currentProducts
        }
      } else {
        result.value = currentProducts.length
      }
      //console.log("RESULTADO PARA ESTE: " ,result)
      break
    }
    case 'Subcategoria': {
      const currentProducts = products.filter((product) => {
        let subcat = product.main_category_id + '/' + product.sub_category_id
        return modifier_value === subcat
      })
      if (currentProducts) {
        if (type === 'count') {
          const totalCount = currentProducts.reduce(
            (acumulado, p) => acumulado + p.count,
            0
          )
          result.value = totalCount
        } else if (type === 'price') {
          result.value = getTotalValueOfProducts(currentProducts)
        }
        result.products = currentProducts;
      } else {
        result.value = currentProducts.length
      }
      break
    }
    case 'Carrito de compras': {
      if (type === 'price') {
        result.value = getTotalValueOfProducts(products)

      } else result.value = products
      break
    }
    case 'Producto': {
      const parts = modifier_value.split('/')
      const id = parts[parts.length - 1]
      const product = products.find((product) => product.id_item === id)

      if (product) {
        // result.products = [product]
        if (type === 'count') {
          result.value = product.count
        } else if (type === 'price') {
          result.value = getTotalValueOfProducts(products)
        }
      } else {
        result.value = 0
      }
    }
      break
  }
  return result
}



/**
 *
 * @param {{
 * name,
 * effects:any[],
 * productsIds:string[],
 * type: "cupon" | undefined
 * }[]}  validOffers
 * @param {*} subTotal
 * @param {*} deliveryValue
 * @param {any[]} fullProducts
 * @returns
 */
export const getTotalDiscountValue = (
  validOffers,
  subTotal,
  deliveryValue,
  //	fullProducts
) => {
  // console.log("getTotalDiscountValue")
  let totalDiscount = 0
  if (!validOffers) {
    return 0
  }
  // console.log("RECORRER OFERTAS")
  validOffers.forEach((offer) => {
    const effect = offer.effects[0]
    const productsApply = offer.productsIds || [];
    if (!effect) return
    // console.log("VALIDAR SI ES CUPON")
    if (offer && offer.type === 'cupon') {
      // console.log("ES CUPON")
      switch (effect.type) {
        case '% de la orden':
          effect.calc = (subTotal * effect.value) / 100
          totalDiscount += effect.calc
          break
        case 'Valor de la orden':
          effect.calc = effect.value
          totalDiscount += effect.value
          break
        default:
          break
      }
      return
    }
    let totalEffectsCalc = 0

    switch (effect.type) {
      case '% de la orden': {
        let subTotalProductsApply = 0;
        if (productsApply.length > 0) {
          productsApply.forEach((product) => {
            const pVal = getTotalValueOfProduct(product);
            subTotalProductsApply += pVal;
          })
        } else subTotalProductsApply = subTotal;
        const calc = (subTotalProductsApply * effect.value) / 100;
        totalEffectsCalc += calc;
        totalDiscount += calc;
        break
      }
      case 'Valor de la orden': {
        // console.log("DESCUENTO EN EL TOTAL ? ")
        // console.log(effect, offer)
        effect.calc = effect.value
        totalEffectsCalc += effect.calc
        totalDiscount += effect.value
        break
      }
      default:
        break
    }
    //}
    // console.log("CALCULO FINAL ", totalEffectsCalc)
    effect.calc = totalEffectsCalc;
  })

  const total = totalDiscount < subTotal + deliveryValue
    ? totalDiscount
    : subTotal + deliveryValue

  return total
}

const useOffers = () => {
  const { state, dispatch } = useContext(context)
  const { offers } = state.offersReducer

  useEffect(() => {
    if (offers.length <= 0) {
      loadAllOffers()
    }
  }, [])

  const loadAllOffers = () => {
    offersActions.loadAllOffers(dispatch)
  }

  const consumeCupon = async (cuponCode, cuponId, userDocId, authValues) => {
    //Insert cupon on user cupons
    // ----------------ESTO ESTA INCOMPLETO
    // const docSnapshots = await cuponsDb.where('code', '==', cuponCode).get()
    // let cupon
    // if (!docSnapshots.empty) {
    // 	const data = docSnapshots.docs[0].data()
    // 	cupon = data
    // }

    const cupons = authValues.cupons
      ? [...authValues.cupons, cuponCode]
      : [cuponCode]
    await usersDb
      .doc(userDocId)
      .set({ cupons }, { merge: true })
      .catch((err) => console.log(err))

    //Add cupon usage
    /* const incrementCupon = firebase.firestore.FieldValue.increment(1);
        cuponsDb.doc(cuponId).update({ usages: incrementCupon })
            .catch((err) => console.log(err)); */
  }

  const validateCupon = async (cuponCode, currentBranchName, documentId) => {
    //Validate if cupon exists
    const docSnapshots = await cuponsDb.where('code', '==', cuponCode).get()
    if (docSnapshots.empty) {
      return cuponModel('', cuponCode, '', `El cupón ${cuponCode} no es válido`)
    }

    //Set cupon data
    const data = docSnapshots.docs[0].data()
    const cupon = cuponModel(data.name, data.code, data.effects, false, data.id)

    //Validate active
    if (!data.status) {
      return cuponModel('', cuponCode, '', `El cupón ${cuponCode} no es válido`)
    }

    //Validate branch
    if (data.branches && data.branches.length > 0) {
      if (!data.branches.find((branch) => NormalizeBranch(branch) === NormalizeBranch(currentBranchName))) {
        return cuponModel(
          '',
          cuponCode,
          '',
          `El cupón ${cuponCode} no es válido para esta cobertura`
        )
      }
    }

    //Validate conditions
    if (data.conditions) {
      let validations = []
      data.conditions.forEach((cuponCondition) => {
        const { condition_value, type } = cuponCondition

        let validationResult = false

        switch (type) {
          case 'Fecha':
            validationResult = validateDate(cuponCondition).result
            break
          case 'Cantidad de usos':
            validationResult = validateCondition(
              cuponCondition.condition,
              data.usages || 0,
              parseInt(condition_value)
            )
            break
          default:
            break
        }

        if (validationResult) {
          validations = [...validations, validationResult]
        }
      })

      // Verify if validations is equal to conditions
      if (validations.length !== data.conditions.length) {
        return cuponModel(
          '',
          cuponCode,
          '',
          `El cupón ${cuponCode} no es válido`
        )
      }
    }

    //Validate if user has used the cupon
    const userDoc = await usersDb.doc(documentId).get()
    const userData = userDoc.data()

    if (userData.cupons && userData.cupons.find((code) => code === cuponCode)) {
      return cuponModel('', cuponCode, '', 'Este cupón ya lo has usado')
    }

    return cupon
  }

  return {
    offers,
    loadAllOffers,
    consumeCupon,
    validateCupon
  }
}

export default useOffers


const validOffertsDiscount = (offertData, products) => {
  const { conditions, effects, name } = offertData
  let data = {
    name,
    effects,
    productsIds: products
  }
  console.log("validOffertsDiscount");
  
  for (const condition of conditions) {
    const { type } = condition
    let validationResult = {};
    // validar tipo de oferta:
    switch (type) {
      case 'Cantidad items':
        // validar si el total de items aplica para la oferta
        // console.log("CANTIDAD DE ITEMS")
        validationResult = validateByType(condition, products, 'count', offertData)
        break
      case 'Valor total':
        // validar condiciones para aplicar al valor total
        // console.log("VALOR TOTAL")
        validationResult = validateByType(condition, products, 'price', offertData)
        if (validationResult.value) {
          data.productsIds = validationResult.products
          continue
        }
        break
      case 'Fecha': {
        const resultByDate = validateDate(condition, products, offertData);
        validationResult.value = resultByDate.result;
        if (resultByDate.products) {
          data.productsIds = resultByDate.products
          continue
        }
        break
      }
      default:
        break
    }
    if (!validationResult.value) {
      data = null;
    }
  }

  return data;
}
