import { useState } from 'react'
import { useHistory } from 'react-router'
import * as Cities from '../../../consts/Cities'
import useAuth from '../../../hooks/auth/useAuth'
import useStringFormater from '../../../hooks/stringFormater/useStringFormater'
import { routeFormatter } from '../../../routes/routes'

/**
 * Hook personalizado para manejar la barra de aplicaciones.
 * Devuelve varios valores y funciones útiles para manejar la barra de aplicaciones.
 *
 * @returns {Object} Un objeto con las siguientes propiedades:
 *  - openMenu: Función para abrir el menú de opciones.
 *  - closeMenu: Función para cerrar el menú de opciones.
 *  - menuAnchor: El elemento que actúa como ancla para el menú de opciones.
 *  - menuItems: Un arreglo de cadenas que representa las opciones del menú.
 *  - address: La dirección actual del usuario.
 *  - haveAddresses: Un valor booleano que indica si el usuario tiene direcciones registradas.
 *  - isLogged: Un valor booleano que indica si el usuario ha iniciado sesión.
 *  - changeAddress: Función para cambiar la dirección actual del usuario.
 *  - openAM: Un valor booleano que indica si el modal de cambio de dirección está abierto.
 *  - setOpenAM: Función para actualizar el valor de openAM.
 *  - showLoginModal: Función para mostrar el modal de inicio de sesión.
 */
const useAppBar = () => {
	const { isLogged, authValues, loading, showLoginModal } = useAuth()

	const history = useHistory()

	const { formatAddress } = useStringFormater()

	const [anchorEl, setAnchorEl] = useState(null)

	/**
	 * Función para abrir el menú de opciones.
	 *
	 * @param {Object} event - El objeto de evento que desencadenó esta función.
	 */
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget)
	}

	/**
	 * Función para cerrar el menú de opciones.
	 */
	const handleClose = () => {
		setAnchorEl(null)
	}

	// Las opciones del menú.
	const menuItems = ['Mayorista', 'Mercado', 'Bonos de regalo']
	// Verifica si el usuario tiene direcciones registradas.
	const haveAddresses = authValues.addresses.length > 0

	// Define la dirección actual del usuario.
	const address = loading
		? 'Cargando...'
		: isLogged
		? haveAddresses
			? authValues.addresses[authValues.selected_address].city.toUpperCase() ===
			  Cities.TUMACO
				? authValues.addresses[authValues.selected_address].city
				: authValues.addresses[authValues.selected_address].address != ''
				? authValues.addresses[authValues.selected_address].address
				: formatAddress(authValues.addresses[authValues.selected_address])
			: 'Sin direcciones registradas'
		: 'Inicia sesión antes de pedir'

	const [openAM, setOpenAM] = useState(false)

	/**
	 * Función para cambiar la dirección actual del usuario.
	 * Si el usuario no ha iniciado sesión, se muestra el modal de inicio de sesión.
	 * Si el usuario tiene direcciones registradas, se muestra el modal de cambio de dirección.
	 * Si el usuario no tiene direcciones registradas, se redirige a la página de direcciones.
	 */
	const changeAddress = () => {
		if (!isLogged) {
			showLoginModal()
			return
		}
		if (haveAddresses) {
			setOpenAM(true)
			return
		}
		history.push(routeFormatter.toAccount('addresses'))
	}

	return {
		openMenu: handleClick,
		closeMenu: handleClose,
		menuAnchor: anchorEl,
		menuItems,
		address,
		haveAddresses,
		isLogged,
		changeAddress,
		openAM,
		setOpenAM,
		showLoginModal
	}
}

export default useAppBar
