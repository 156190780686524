import PropTypes from 'prop-types'
import React from 'react'
import Logo from '../../assets/logomercaz1.png'
import useRegisterPage from './useRegisterPage'

import { Box } from '@material-ui/core'
import CustomButton from '../../components/atoms/buttons/CustomButton'
import CustomPhoneInput from '../../components/atoms/inputs/CustomPhoneInput'
import EditInputText from '../../components/atoms/inputs/EditInputText'
import InputCheckbox from '../../components/atoms/inputs/InputCheckbox'
import EditSelect from '../../components/atoms/selects/EditSelect'

/**
 * Componente de diseño de la página de registro para dispositivos de escritorio.
 * @param {Object} props - Propiedades para el componente.
 * @param {Function} props.handleClose - Función para cerrar el componente.
 * @param {Function} props.handleTransition - Función para cambiar la animación de transición.
 * @param {string} props.to - URL de la página de destino.
 * @returns {React.ReactNode} Elemento React que contiene la página de registro para dispositivos de escritorio.
 */
const RegisterPageDesktop = (props) => {
	const { handleClose, handleTransition, to } = props

	const {
		cityOptions,
		register,
		setPassword,
		// errors
		nameError,
		lastnameError,
		emailError,
		passwordError,
		documentError,
		phoneError,
		birthError,
		termsError,
		changeDate,
		acceptTerm,
		fbErrorMessage,
		firebaseError,
		// auth
		authValues,
		handleAuthValues
	} = useRegisterPage(handleClose)

	return (
		<div className='register-modal fade-in'>
			<div className='o-register-container'>
				<div className='o-register-title-container'>
					<img src={Logo} alt='Logo Merca Z'></img>
					<p className='text-21px-700'>Registro de nuevo usuario</p>
				</div>
				<form className='o-register-form'>
					<EditInputText
						placeholder='Nombre*'
						errorMessage={nameError}
						inputProps={{
							required: true,
							placeholder: 'Nombre* ',
							autoComplete: 'name',
							autoFocus: true
						}}
						onBlur={(e) => handleAuthValues('name', e.target.value)}
					/>
					<EditInputText
						placeholder='Apellido*'
						errorMessage={lastnameError}
						inputProps={{
							required: true,
							placeholder: 'Apellido*',
							autoComplete: 'name',
							autoFocus: true
						}}
						onBlur={(e) => handleAuthValues('lastname', e.target.value)}
					/>
					<EditInputText
						placeholder='Correo*'
						errorMessage={emailError}
						inputProps={{
							required: true,
							placeholder: 'Correo*',
							autoComplete: 'email',
							autoFocus: true
						}}
						onBlur={(e) => handleAuthValues('email', e.target.value)}
					/>
					<EditInputText
						placeholder='Contraseña*'
						errorMessage={passwordError}
						inputProps={{
							required: true,
							placeholder: 'Contraseña*',
							autoComplete: 'current-password',
							autoFocus: true,
							type: 'password'
						}}
						onBlur={(e) => setPassword(e.target.value)}
					/>
					<EditInputText
						placeholder='Cédula'
						errorMessage={documentError}
						inputProps={{
							required: true,
							placeholder: 'Cédula',
							autoFocus: true
						}}
						onBlur={(e) => handleAuthValues('document', e.target.value)}
					/>
					<Box width='100%' marginBottom='1rem'>
						<EditSelect
							selectedIndex={cityOptions.findIndex(
								(city) => city.value === authValues.city
							)}
							options={cityOptions}
							onSelect={(e) => handleAuthValues('city', e.target.value)}
						/>
					</Box>
					<CustomPhoneInput
						errorMessage={phoneError}
						inputProps={{
							required: true
						}}
						onChange={(value) =>
							setTimeout(() => {
								handleAuthValues('phone', value)
							}, 3000)
						}
					/>
					<EditInputText
						errorMessage={birthError}
						label='Fecha de nacimiento*'
						inputProps={{
							type: 'date',
							autoFocus: true,
							max: new Date().toISOString().split('T')[0]
						}}
						onChange={changeDate}
					/>
					<InputCheckbox errorMessage={termsError} onChange={acceptTerm} />

					<Box marginTop='2rem' width='100%'>
						<CustomButton
							label='Registrarse'
							onClick={register}
							buttonProps={{ type: 'submit' }}
							width='100%'
						/>
						{firebaseError && (
							<Box marginTop='1rem'>
								<span className='color-error text-12px-400'>
									{fbErrorMessage}
								</span>
							</Box>
						)}
					</Box>
				</form>
				<div className='o-line-horizontal'></div>
				<CustomButton
					iconFirst
					color='yellow'
					iconClass='arrow -blue -left'
					label='Iniciar sesion'
					onClick={() => handleTransition(to.login)}
					buttonProps={{ type: 'submit' }}
					width='260px'
				/>
			</div>
		</div>
	)
}

RegisterPageDesktop.propTypes = {
	handleTransition: PropTypes.func,
	to: PropTypes.objectOf(PropTypes.string),
	handleClose: PropTypes.func.isRequired
}

export default RegisterPageDesktop
