import React from 'react'
import { isBrowser } from 'react-device-detect'
import CartDesktop from './CartDesktop'
import CartMobile from './CartMobile/CartMobile'

const Cart = (props) => {
	const CartLayout = isBrowser ? CartDesktop : CartMobile
	return <CartLayout {...props} />
}

export default Cart
