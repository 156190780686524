import React from 'react'
// components
import { Link } from 'react-router-dom'
import NetworkImage from '../NetworkImage/NetworkImage'
import ProductCounter from '../ProductCounter/ProductCounter'
// resources
import pMayoristaImg from '../../assets/mayorista.svg'
// hooks
import useStringFormater from '../../hooks/stringFormater/useStringFormater'
//import useProduct from '../../pages/Product/useProduct'
import { productImageBaseUrl } from '../../utils/HasImage'

export const ProductoMayorista = (props) => {
	const {
		name,
		productId,
		categoryId,
		//generalPrice,
		price,
		uneditable,
		count,
		//countOptions,
		onCountChange,
		onDelete,
		factor,

		product,
		subtotal
	} = props

	const { formatPrice } = useStringFormater()

	/* const { productData } = useProduct({
		categoryId: categoryId,
		productId: productId
	}) */


	return (
		<div className='producto-mayorista'>
			<Link to={`/product/${categoryId}/${productId}`} className='product-img'>
				<NetworkImage
					url={productImageBaseUrl + productId}
					imageProps={{
						alt: `image-product-${productId}`
					}}
				/>
				<img className='mayorista-logo' src={pMayoristaImg} />
			</Link>
			<div className='product-data'>
				<div className='product-info'>
					<span className='text-13px-700 color-gray-dark'>{name}</span>
					<div className='product-prices'>
						<div className='price'>
							<span className='text-15px-700 color-blue'>
								{formatPrice(subtotal)}
							</span>
						</div>
						<div className='price'>
							<span className='text-15px-700 color-blue'>
								{formatPrice(product?.discount > 0 ? product?.price_neto : price)}
							</span>
							<span className='text-9px-400 color-gray-light'>
								{`Mayor a ${factor} ${product.unit_type === "weight" ? "Kg" : "uds"}.`}
							</span>
						</div>
					</div>
				</div>
				<div className='product-actions'>
					<i className='delete-icon-circle -blue' onClick={onDelete} />
					{/* <WholesalerProductCounter
                        uneditable={uneditable}
                        count={count}
                        options={countOptions}
                        onSelect={(e) => {onCountChange(e.target.value)}}
                    /> */}
					<div style={{display: "flex", alignItems: "center", gap: "0.3rem"}}>
						<ProductCounter
							color='blue'
							loading={false}
							count={count}
							isDisable={uneditable}
							incrementCounter={() => {
								//productData.wholesaler.rango =+ productData.wholesaler.rango + range

								// console.log(count + range)
								// setWholesalerCounter(count + range)
								// if (isWholesalerInCart) {
								//     changeProductCount(count, productId, { asWholesaler: true })
								// }

								onCountChange(count + factor)
							}}
							decrementCounter={() => {
								// setWholesalerCounter(count - productData.wholesaler.range)
								// if (isWholesalerInCart) {
								//     changeProductCount(count, productId, { asWholesaler: true })
								// }
								onCountChange(count - factor)
							}}
						/>
						{product.unit_type === "weight" &&
							<span className='text-9px-400 color-gray-light'>
								Kg
							</span>
						}
					</div>
				</div>
			</div>
		</div>
	)
}

/* ProductoMayorista.propTypes = {
	productId: PropTypes.string.isRequired,
	categoryId: PropTypes.string.isRequired,
	factor: PropTypes.number,
	name: PropTypes.string.isRequired,
	generalPrice: PropTypes.number,
	price: PropTypes.number.isRequired,
	count: PropTypes.number.isRequired,
	range: PropTypes.number,
	countOptions: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.number.isRequired,
			label: PropTypes.string.isRequired
		})
	),
	onCountChange: PropTypes.func,
	onDelete: PropTypes.func,
	uneditable: PropTypes.bool,
	subtotal: PropTypes.number,
}

ProductoMayorista.defaultProps = {
	onCountChange: () => { },
	onDelete: () => { }
} */

export default ProductoMayorista
