import PropTypes from 'prop-types'
import React, { useState } from 'react'
//hooks
import useAuth from '../../../hooks/auth/useAuth'
import useOffers from '../../../hooks/offers/useOffers'

/**
 * Componente para introducir un código de cupón y validarlo.
 * @param {object} props - Propiedades del componente.
 * @param {function} props.setCupon - Función para establecer el cupón en la página.
 * @returns {JSX.Element} - Elemento JSX que muestra el input del código de cupón y los mensajes de error o éxito.
 */
const InputCupon = (props) => {
	const { setCupon } = props

	const [message, setMessage] = useState('')
	const [success, setSuccess] = useState(false)
	const [inputCupon, setInputCupon] = useState(undefined)

	const { offers, validateCupon } = useOffers()
	const { authValues, getCityBranchOffice } = useAuth()

	const branch = getCityBranchOffice()

	const documentId = authValues.ID

	return (
		<div className='input-cupon'>
			<div className='body'>
				<input
					placeholder='Código de Cupón'
					value={inputCupon}
					onChange={(e) => {
						setInputCupon(e.target.value.toUpperCase())
					}}
				/>
				<span
					className='text-decoration-none color-blue text-12px-600'
					onClick={() => {
						if (inputCupon) {
							validateCupon(inputCupon, branch, documentId).then((resCupon) => {
								if (resCupon.error) {
									setSuccess(false)
									setMessage(resCupon.error)
								} else {
									setCupon(offers.find((cupon) => cupon.code == resCupon.code))
									setSuccess(true)
									setMessage('Cupón Válido')
								}
							})
						} else {
							setSuccess(false)
							setMessage('Por favor, introduce un código de cupón')
						}
					}}>
					Aplicar cupón
				</span>
			</div>
			<span
				className={`text-12px-400 ${
					success ? 'success-message' : 'error-message'
				}`}>
				{message}
			</span>
		</div>
	)
}

InputCupon.propTypes = {
	setCupon: PropTypes.func
}

export default InputCupon
