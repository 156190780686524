import * as layoutActions from './layoutActionsTypes'

/**
 * Shows a snackbar message
 * @param {Function} dispatch
 * @param {string} message
 * @param {'error' | 'info' | 'success' | 'warning'} severity
 */
export const showSnackbar = (dispatch, message, severity) => {
	dispatch({
		type: layoutActions.SHOW_SNACKBAR,
		message: message,
		severity: severity
	})
}

/**
 * Hides the opened snackbar
 * @param {Function} dispatch
 */
export const hideSnackbar = (dispatch) => {
	dispatch({ type: layoutActions.HIDE_SNACKBAR })
}
