import PropTypes from 'prop-types'
import React from 'react'
// hooks
import useStringFormater from '../../../hooks/stringFormater/useStringFormater'

/**
 * El componente SummaryButton renderiza un botón con una etiqueta y un precio opcional.
 *
 * @param {object} props - Las props para el componente.
 * @param {string} props.actionLabel - La etiqueta del botón (obligatorio).
 * @param {boolean} [props.hidePrice=false] - Si se debe ocultar el precio (opcional).
 * @param {boolean} [props.disabled=false] - Si el botón debe estar desactivado (opcional).
 * @param {function} props.onClick - La función que se llama cuando se hace clic en el botón (obligatorio).
 * @param {number} props.price - El precio que se mostrará en el botón (obligatorio).
 *
 * @returns {JSX.Element} - El botón con la etiqueta y el precio (si no está oculto).
 */
const SummaryButton = (props) => {
	const { formatPrice } = useStringFormater()

	const {
		// flags
		hidePrice,
		disabled,
		// actions
		onClick,
		// data
		actionLabel,
    price,
    asLink,
    href=""
	} = props
  if (asLink) return <a target="_blank" rel="noreferrer" className={`btn-filled summary-btn -${hidePrice ? 'simple' : 'double'
    } -blue`} href={href}
    onClick={onClick}
  >
    <span>{actionLabel}</span>
    {!hidePrice && (
      <span className='text-18px-700'>{formatPrice(price)}</span>
    )}
    </a>
	return (
		<button
			className={`btn-filled summary-btn -${
				hidePrice ? 'simple' : 'double'
			} -blue`}
			onClick={onClick}
			disabled={disabled}>
			<span>{actionLabel}</span>
			{!hidePrice && (
				<span className='text-18px-700'>{formatPrice(price)}</span>
			)}
		</button>
	)
}

SummaryButton.propTypes = {
	actionLabel: PropTypes.string.isRequired,
	hidePrice: PropTypes.bool,
	disabled: PropTypes.bool,
	onClick: PropTypes.func.isRequired,
	price: PropTypes.number.isRequired
}

export default SummaryButton
