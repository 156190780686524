import PropTypes from 'prop-types'
import React from 'react'
//hooks
import { Checkbox } from '@material-ui/core'

/**
 * Componente para un input de tipo checkbox
 * @param {Object} props - Propiedades del componente
 * @param {function} props.onChange - Función que se ejecuta cuando cambia el valor del checkbox
 * @param {string} props.errorMessage - Mensaje de error a mostrar
 * @returns {JSX.Element} Componente de React que muestra un input de tipo checkbox
 */
const InputCheckbox = (props) => {
	const { onChange, errorMessage } = props

	return (
		<div style={{ width: '19em' }}>
			<div className='input-checkbox'>
				<Checkbox
					color='primary'
					inputProps={{ 'aria-label': 'secondary checkbox' }}
					onChange={onChange}
				/>
				<div style={{ display: 'grid' }}>
					<a
						className='color-blue text-14px-300 text-decoration-none link'
						style={{ marginTop: '12px' }}
						href='https://firebasestorage.googleapis.com/v0/b/merca-z-prueba.appspot.com/o/Termns_and_conditions%2FPL-01%20Manual%20Interno%20de%20PolA%CC%83_ticas%20y%20Procedimientos.pdf?alt=media&token=04d964c0-d5f6-48fa-b90d-f183eef11a81'
						target='blank'>
						Aceptas los términos y condiciones
					</a>
					<span
						className='text-12px-400 error-message'
						style={{ color: '#ce6868' }}>
						{errorMessage}
					</span>
				</div>
			</div>
		</div>
	)
}

InputCheckbox.propTypes = {
	onChange: PropTypes.func,
	errorMessage: PropTypes.string
}

export default InputCheckbox
