const routes = {
	home: {
		path: '/',
		name: 'Mis mercados'
	},
	create: {
		path: '/create',
		name: 'Crear mercado'
	},
	edit: {
		path: '/edit/:marketId',
		name: 'Editar mercado'
	},
	products: {
		path: '/details/:marketId',
		name: 'Productos de mercado'
	}
}

const routeFormatter = {
	toProducts: (marketId) => routes.products.path.replace(':marketId', marketId),
	toEdit: (marketId) => routes.edit.path.replace(':marketId', marketId)
}

export { routes, routeFormatter }
