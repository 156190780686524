import { useState } from 'react'
import * as documentTypes from './constants'

const dirOptions = [
	{
		label: 'Avenida',
		value: documentTypes.DOCUMENT_TYPE_AVENIDA
	},
	{
		label: 'Avenida Carrera',
		value: documentTypes.DOCUMENT_TYPE_AVENIDA_CARRERA
	},
	{
		label: 'Avenida Calle',
		value: documentTypes.DOCUMENT_TYPE_AVENIDA_CALLE
	},
	{
		label: 'Calle',
		value: documentTypes.DOCUMENT_TYPE_CALLE
	},
	{
		label: 'Carrera',
		value: documentTypes.DOCUMENT_TYPE_CARRERA
	},
	{
		label: 'Circular',
		value: documentTypes.DOCUMENT_TYPE_CIRCULAR
	},
	{
		label: 'Circunvalar',
		value: documentTypes.DOCUMENT_TYPE_CIRCUNVALAR
	},
	{
		label: 'Diagonal',
		value: documentTypes.DOCUMENT_TYPE_DIAGONAL
	},
	{
		label: 'Manzana',
		value: documentTypes.DOCUMENT_TYPE_MANZANA
	},
	{
		label: 'Transversal',
		value: documentTypes.DOCUMENT_TYPE_TRANSVERSAL
	},
	{
		label: 'Via',
		value: documentTypes.DOCUMENT_TYPE_VIA
	}
]

const cityOptions = [
	{
		label: 'Cali',
		value: documentTypes.DOCUMENT_TYPE_CITY_CALI
	},
	{
		label: 'Tumaco',
		value: documentTypes.DOCUMENT_TYPE_CITY_TUMACO
	},
	{
		label: 'Medellin',
		value: documentTypes.DOCUMENT_TYPE_CITY_MEDELLIN
	}
]

export default function useDirecciones() {
	// state

	const [selectedCityIndex, setSelectedCityIndex] = useState(0)
	const [selectedDirIndex, setSelectedDirIndex] = useState(0)
	const [selectedCompIndex, setSelectedCompIndex] = useState(0)
	const [selectedCompSecondIndex, setSelectedCompSecondIndex] = useState(0)

	const selectCityType = (e) => {
		setSelectedCityIndex(e.target.selectedCityIndex)
	}
	const selectDireccionType = (e) => {
		setSelectedDirIndex(e.target.selectedDirIndex)
	}
	const selectCompType = (e) => {
		setSelectedCompIndex(e.target.selectedCompIndex)
	}
	const selectCompSecondType = (e) => {
		setSelectedCompSecondIndex(e.target.selectedCompSecondIndex)
	}

	return {
		cityOptions,
		selectedCityIndex,
		selectCityType,
		dirOptions,
		selectedDirIndex,
		selectDireccionType,
		selectedCompIndex,
		selectCompType,
		selectedCompSecondIndex,
		selectCompSecondType
	}
}
