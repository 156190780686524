import { address } from './common'
import * as UnitTypes from '../../consts/ProductCountOptions'

const role = {
	administrator: false,
	client: true,
	mayorista: false,
	domicilliary: false,
	enlist: false
}

const favorites = {
	products: [],
	markets: []
}

export const userModel = {
	accept_terms: false,
	addresses: [],
	birth_date: null,
	city: '',
	created_date: null,
	document: '',
	document_type: 'CC',
	email: '',
	favorites: { ...favorites },
	lastname: '',
	name: '',
	phone: '',
	photo: '',
	role: { ...role },
	selected_address: 0,
	shopping_cart: [],
	uID: null,
	ID: ''
}

export const cardsModel = {
	mask: '',
	date: '',
	created_date: null,
	name: '',
	token_card: '',
	type: '',
	address: { ...address },
	id: ''
}

export const marketModel = {
	address: { ...address },
	card: { ...cardsModel },
	created_date: null,
	id: '',
	id_card: '',
	name: '',
	pay_method: 0,
	periodicity: 0,
	products: [],
	validity: null
}

export const markets_product = {
	count: 0,
	id_item: '',
	main_category_id: '',
	unit_type: UnitTypes.UNIT.value
}
