import { useCallback, useRef, useState } from "react";

const useAsyncText = (timeOut = 500) => {
  // const current = useRef("");
  const timeOutRef = useRef(null);
  const [value, setValue] = useState("");
  const setAsyncText = useCallback(
    (newValue) => {
      if (timeOutRef.current) {
        clearTimeout(timeOutRef.current);
      }
      const timeoutId = setTimeout(() => {
        setValue(newValue);
      }, timeOut);

      timeOutRef.current = timeoutId;
    },
    [timeOut]
  );
  const setAsyncText_immediately = useCallback((value) => {
    setValue(value);
  }, []);
  return {
    asyncText: value,
    setAsyncText,
    setAsyncText_immediately,
  };
};
export default useAsyncText;