import { Skeleton } from '@material-ui/lab'
import PropTypes from 'prop-types'
import React from 'react'

/**
 * CustomButton es un componente de botón personalizado que se utiliza para crear botones con diferentes estilos, iconos y comportamientos.
 *
 * @param {object} props - Las propiedades del componente.
 * @param {string} [props.id] - El identificador del botón.
 * @param {string} [props.label] - La etiqueta del botón.
 * @param {string} [props.iconClass] - La clase CSS del icono del botón.
 * @param {function} [props.onClick] - El controlador de eventos click del botón.
 * @param {string} [props.variant='filled'] - La variante del botón, puede ser 'filled', 'outline' o 'text'.
 * @param {boolean} [props.iconFirst] - Si es verdadero, el icono se mostrará antes del texto.
 * @param {string} [props.width='141px'] - El ancho del botón.
 * @param {string} [props.height='40px'] - La altura del botón.
 * @param {string} [props.color='blue'] - El color del botón, puede ser 'blue', 'green', 'red', 'yellow', 'gray' o cualquier otro valor válido de color CSS.
 * @param {boolean} [props.loading] - Si es verdadero, se muestra un indicador de carga en lugar del botón.
 * @param {boolean} [props.titleSmall] - Si es verdadero, se utiliza un tamaño de fuente más pequeño para la etiqueta del botón.
 * @param {object} [props.buttonProps] - Las propiedades adicionales del botón.
 * @param {object} [props.skeletonProps] - Las propiedades adicionales del componente Skeleton utilizado para mostrar un indicador de carga.
 * @param {boolean} [props.disable] - Si es verdadero, el botón estará deshabilitado.
 * @returns {JSX.Element} - Un componente de botón personalizado.
 */
const CustomButton = (props) => {
	const {
		// data
		id,
		label,
		iconClass,
		// actions
		onClick,
		// styles
		variant,
		iconFirst,
		width,
		height,
		color,
		// flags
		titleSmall,
		loading,
		// additional props
		buttonProps,
		skeletonProps,
		disable
	} = props

	const buttonStyles = {
		width: width,
		height: height
	}

	return (
		<>
			{loading ? (
				<Skeleton
					variant='rect'
					animation='wave'
					width={width}
					height={height}
					{...skeletonProps}
					style={{ borderRadius: 30 }}
				/>
			) : (
				<button
					id={id}
					disabled={disable}
					className={`btn-${variant} ${iconFirst ? ' -revert' : ''} -${
						disable ? 'gray' : color
					}`}
					style={buttonStyles}
					onClick={onClick}
					{...buttonProps}>
					<span className={titleSmall ? 'text-8px-500' : 'text-14px-500'}>
						{label}
					</span>
					{iconClass && <i className={iconClass} />}
				</button>
			)}
		</>
	)
}

CustomButton.propTypes = {
	// data
	id: PropTypes.string,
	label: PropTypes.string,
	iconClass: PropTypes.string,
	// actions
	onClick: PropTypes.func,
	// styles
	variant: PropTypes.string,
	iconFirst: PropTypes.bool,
	width: PropTypes.string,
	height: PropTypes.string,
	color: PropTypes.string,
	// flags
	loading: PropTypes.bool,
	titleSmall: PropTypes.bool,
	// additional props
	buttonProps: PropTypes.object,
	skeletonProps: PropTypes.object,
	disable: PropTypes.bool
}

CustomButton.defaultProps = {
	variant: 'filled',
	width: '141px',
	height: '40px',
	color: 'blue',
	onClick: () => {}
}

export default CustomButton
