import React, {useEffect} from "react";

import { Box } from "@material-ui/core";
import EditInputText from "../../../components/atoms/inputs/EditInputText";
import useObservations from "../../../hooks/shoppingCart/useObservations";

const ObservationsInCart = ({
    productId
}) => {
  const {
    observation,
    observations,
    setObservation,
    handleSetObservation,
  } = useObservations(productId)

  useEffect(() => {
    if (observations[productId]) {
      setObservation(observations[productId])
    }
  }, []);

  return <Box pt="2rem" width={"100%"}>
    <EditInputText
      inputProps={{
        placeholder: "Si tienes alguna observación del producto, escríbela aquí."
      }}
      isTextArea
      onChange={handleSetObservation}
      label='Observaciones del producto'
      value={observation}
    ></EditInputText>
  </Box>
}

export default ObservationsInCart;