import React from 'react'
import { useLocation } from 'react-router'
import { routes } from '../../../routes/routes'
// layouts
import AppBarMobileNav from './AppBarMobile/AppBarMobileNav'
import AppBarMobilePrincipal from './AppBarMobile/AppBarMobilePrincipal'

import * as appbarConsts from '../../../consts/AppBarMobileLayouts'

/**
 * Componente que devuelve una barra de navegación para dispositivos móviles
 * La barra de navegación que se renderiza depende del tipo de página en la que se encuentra el usuario
 *
 * @returns Componente de barra de navegación para dispositivos móviles
 */
const AppBarMobile = () => {
	// Obtiene la ubicación actual del usuario utilizando el hook useLocation de react-router-dom
	const location = useLocation()
	// Busca el tipo de barra de navegación que se debe mostrar según la página actual
	const layoutType = Object.values(routes).find(
		(route) => location.pathname.split('/')[1] == route.path.split('/')[1]
	) || { mobileAppbarType: appbarConsts.PRINCIPAL }

	// Obtiene el componente de barra de navegación que se debe renderizar según el tipo de página
	const getAppBarLayout = () => {
		switch (layoutType.mobileAppbarType) {
			case appbarConsts.PRINCIPAL:
				return AppBarMobilePrincipal
			case appbarConsts.NAVIGATION:
				return AppBarMobileNav
			default:
				return AppBarMobilePrincipal
		}
	}

	// Obtiene el componente de barra de navegación que se debe renderizar
	const AppBarMobileLayout = getAppBarLayout()

	// Renderiza el componente de barra de navegación
	return <AppBarMobileLayout />
}

export default AppBarMobile
