import * as ordersTypes from './ordersActionsTypes'

/**
 * The initial state of the orders reducer.
 * @typedef {Object} OrdersInitialState
 * @property {Array} orders - The orders data.
 * @property {boolean} loading - Whether the orders are currently being loaded or not.
 * @property {string|null} error - The error message in case of failure, null otherwise.
 */
export const initialState = {
	orders: null,
	loading: false,
	error: null
}

/**
 * The orders reducer function for updating the Redux store state.
 * @param {OrdersInitialState} prevState - The previous state of the orders reducer.
 * @param {Object} action - The action to be performed on the state.
 * @returns {OrdersInitialState} The new state of the orders reducer.
 */
export const ordersReducer = (prevState = initialState, action) => {
	switch (action.type) {
		case ordersTypes.GET_USER_ORDERS:
			return {
				...prevState,
				error: null,
				loading: true
			}
		case ordersTypes.GET_USER_ORDERS_SUCCESS:
			return {
				...prevState,
				error: null,
				loading: false,
				orders: action.orders
			}
		case ordersTypes.GET_USER_ORDERS_FAILURE:
			return {
				...prevState,
				error: action.error,
				loading: false
			}
		default:
			return prevState
	}
}
