import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import { isMobile } from 'react-device-detect'
// consts
import * as Maturities from '../../../consts/ProductMaturities.js'

const ProductMaturity = ({ maturity, setMaturity }) => {
	const useStyles = makeStyles({
		root: {
			flexDirection: 'row',
			gap: `${isMobile ? '0' : '2'}rem`
		}
	})
	const classes = useStyles()

	const handleChange = (e) => {
		setMaturity(e.target.value)
	}

	return (
		<div className='maturity'>
			<span className='text-14px-400 color-gray-light'>
				Madurez del producto
			</span>
			<RadioGroup
				name='maturity'
				classes={classes}
				value={maturity}
				onChange={handleChange}
			>
				<FormControlLabel
					value={Maturities.GREEN.code}
					control={<Radio />}
					label={
						<span className='text-12px-400 color-gray-light'>
							{Maturities.GREEN.label}
						</span>
					}
				/>
				<FormControlLabel
					value={Maturities.NORMAL.code}
					control={<Radio />}
					label={
						<span className='text-12px-400 color-gray-light'>
							{Maturities.NORMAL.label}
						</span>
					}
				/>
				<FormControlLabel
					value={Maturities.MATURE.code}
					control={<Radio />}
					label={
						<span className='text-12px-400 color-gray-light'>
							{Maturities.MATURE.label}
						</span>
					}
				/>
			</RadioGroup>
		</div>
	)
}

ProductMaturity.propTypes = {
	maturity: PropTypes.string.isRequired,
	setMaturity: PropTypes.func.isRequired
}

export default ProductMaturity
