import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import FloatingSummary from '../../../../components/FloatingSummary/FloatingSummary'
import PaymentMethod from '../../../../components/PaymentMethod/PaymentMethod'

import * as paymentTypes from '../../../../components/PaymentMethod/PaymentTypes'
import * as cartSteps from '../../../../hooks/shoppingCart/cartSteps'
// hooks
import useAuth from '../../../../hooks/auth/useAuth'
import usePaymentCards from '../../../../hooks/paymentCards/usePaymentCards'
import useShoppingCart from '../../../../hooks/shoppingCart/useShoppingCart'


const CartPagos = ({ transitionTo }) => {
  const { cartTotal } = useShoppingCart()

  const { authValues } = useAuth()
  const { getUserPaymentCards, selectedPaymentMethod, setSelectedPaymentMethod } = usePaymentCards()

  useEffect(() => {
    getUserPaymentCards()
  }, [authValues])

  return (
    <>
      <div className='cart-pagos-mobile'>
        <span className='text-21px-700 color-blue'>Método de pago</span>
        <div className='payment-methods'>
          <PaymentMethod
            key={"a0CASH"}
            type={paymentTypes.methods.CASH}
            isSelected={selectedPaymentMethod === 0}
            onSelect={() => setSelectedPaymentMethod(0)}
          />
          <PaymentMethod
            key={"a0DATAPHONE"}
            type={paymentTypes.methods.DATAPHONE}
            isSelected={selectedPaymentMethod === 1}
            onSelect={() => setSelectedPaymentMethod(1)}
          />
          <PaymentMethod
            key={"a0PSE"}
            type={paymentTypes.methods.PSE}
            isSelected={selectedPaymentMethod === 4}
            onSelect={() => setSelectedPaymentMethod(4)}
          />
          {/* {cards ? (
						cards.map((card, i) => (
							<PaymentMethod
										key={card.id}
										lastDigits={card.mask}
										type={paymentTypes.methods.CREDIT_CARD}
										cardType={cardType(card.mask)}
										layout={paymentTypes.layouts.ORDER_SUMMARY}
										isSelected={selectedPaymentMethod === i+2}
										onSelect={() => {
											setSelectedPaymentMethod(i+2)
										}}
									/>
						))
				) : (
					<PaymentMethod type={paymentTypes.methods.ADD_METHOD} />
				)} */}
        </div>
        {/* <CreateCard /> */}
      </div>
      {/* Resumen de compra que redirige al resumen */}
      <FloatingSummary
        price={cartTotal}
        disabled={cartTotal === 0}
        buttonLabel='Tu pedido'
        onClick={() => transitionTo(cartSteps.RESUMEN)}
      />
    </>
  )
}

CartPagos.propTypes = {
  transitionTo: PropTypes.func.isRequired
}

export default CartPagos
