import { useState } from 'react'
import storage from '../../firebase/storage'
import useAuth from '../auth/useAuth'

/**
 * A custom hook that provides methods for updating a user's profile picture in Firebase Storage.
 * @returns {Object} An object containing the progress of the upload and a function for updating the profile image.
 */
const useStorage = () => {
	const [progress, setProgress] = useState(0)
	const { updateUserData } = useAuth()

	const updateProfileImage = (image, userID, MIME) => {
		const uploadTask = storage
			.ref(`profile_pics/${userID}/photo_user_${userID}`)
			.put(image, { contentType: MIME })

		uploadTask.on(
			'state_changed',
			(snapshot) => {
				const progressValue = Math.round(
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100
				)
				setProgress(progressValue)
			},
			(error) => {
				console.warn(error.message)
			},
			() => {
				storage
					.ref(`profile_pics/${userID}`)
					.child(`photo_user_${userID}`)
					.getDownloadURL()
					.then((url) => {
						updateUserData({ photo: url })
						setProgress(0)
					})
					.catch((error) => console.warn(error.message))
			}
		)
	}

	return {
		progress,
		updateProfileImage
	}
}

export default useStorage
