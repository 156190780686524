import React, { useState } from 'react'

export const PRODUCTS_SECTION = 'PRODUCTS_SECTION'
export const MARKETS_SECTION = 'MARKETS_SECTION'

export default function useFavorites() {
	const [section, setSection] = useState(PRODUCTS_SECTION)

	const renderSection = (components) => {
		const RenderedComponent = components[section] || (
			<span>Componente indefinido</span>
		)
		return <RenderedComponent />
	}

	const changeSection = (section) => setSection(section)

	return {
		// section
		renderSection,
		changeSection,
		section
	}
}
