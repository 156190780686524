import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

const Slider = ({ children, id, usesCounter }) => {
	const [numberSections, setNumberSections] = useState([])
	const [currentSection, setCurrentSection] = useState(1)

	useEffect(() => {
		const slider = document.querySelector(`#${id}`)
		const arrayChildrens = [...slider.children]
		for (let i = 0; i < arrayChildrens.length; i++) {
			const element = arrayChildrens[i]
			element.style.scrollSnapAlign = 'center'
		} // controla cómo se alinea el elemento en relación con el scroll del contenedor.
		setNumberSections(arrayChildrens)
	}, [id]) //se ejecutara cada vez que cambia el id.
	
	//Agrega un evento de scroll a un elemento del DOM y utiliza una función para actualizar el estado de la aplicación cuando el usuario se desplaza por el slider.
	useEffect(() => {
		const slider = document.querySelector(`#${id}`)
		const indicatorsController = () => {
			const ViewPortWidth = window.innerWidth
			for (let i = 0; i < numberSections.length; i++) {
				if (
					(slider.scrollLeft - ViewPortWidth * i < 10 &&
						slider.scrollLeft - ViewPortWidth * i > 0) ||
					slider.scrollLeft === ViewPortWidth * i
				) {
					setCurrentSection(i + 1)
				}
			}
		}

		slider.addEventListener('scroll', indicatorsController)
		return () => {
			slider.removeEventListener('scroll', indicatorsController)
		}
	}, [numberSections, id]) //dependencias

	return (
		<div className='slider__container'>
			<div id={id} className={`slider`}>
				{children}
			</div>
			{usesCounter && (
				<div className='indicators'>
					{numberSections.map((item, index) => {
						return (
							<div
								key={index}
								className={`indicator ${
									currentSection === index + 1 ? 'active' : ''
								}`}
							></div>
						)
					})}
				</div>
			)}
		</div>
	)
}

Slider.propTypes = {
	children: PropTypes.node,
	id: PropTypes.string,
	usesCounter: PropTypes.bool
}

Slider.defaultProps = {
	usesCounter: false
}
export default Slider
