import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
	Accordion,
	AccordionSummary,
	AccordionDetails
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const accordionStyles = makeStyles({
	root: {
		width: '100%',
		backgroundColor: 'rgba(233, 233, 233, 0.5)',
		boxShadow: 'none',
		borderRadius: '0px'
	}
})
const accordionSummaryStyles = makeStyles({
	root: {
		borderBottom: '1px solid #fff'
	},
	content: {
		justifyContent: 'center',
		alignItems: 'center',
		gap: '0.938rem'
	}
})
const accordionDetailsStyles = makeStyles({
	root: {
		textAlign: 'justify'
	}
})

const ProductDetails = ({ description }) => {
	const accordionClasses = accordionStyles()
	const accordionSummaryClasses = accordionSummaryStyles()
	const accordionDetailClasses = accordionDetailsStyles()

	const [expanded, setExpanded] = useState(false)
	const toggleExpanded = () => setExpanded(!expanded)

	return (
		<Accordion
			expanded={expanded}
			onClick={toggleExpanded}
			classes={{
				root: accordionClasses.root
			}}
		>
			<AccordionSummary
				classes={{
					root: accordionSummaryClasses.root,
					content: accordionSummaryClasses.content
				}}
			>
				<span className='text-16px-700 color-blue'>Descripción</span>
				<i className={`arrow -blue -${expanded ? 'up' : 'down'}`} />
			</AccordionSummary>
			<AccordionDetails
				classes={{
					root: accordionDetailClasses.root
				}}
			>
				<span
					className='text-16px-300 color-light-gray'
					contentEditable='true'
					dangerouslySetInnerHTML={{ __html: description }}
				></span>
			</AccordionDetails>
		</Accordion>
	)
}

ProductDetails.propTypes = {
	description: PropTypes.string.isRequired
}
ProductDetails.defaultProps = {
	description:
		'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo alias impedit qui saepe modi dicta praesentium officiis architecto quibusdam unde voluptates quod, cumque ab dignissimos sapiente. Ipsa praesentium in repudiandae!'
}

export default ProductDetails
