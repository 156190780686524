import PropTypes from 'prop-types'
import React from 'react'
import OrderStatusChip from '../../../../components/OrderStatusChip/OrderStatusChip'
import useStringFormater from '../../../../hooks/stringFormater/useStringFormater'

const OrderCardDesktop = (props) => {
	const {
		// data
		orderNumber,
		price,
		address,
    numOfItems,
    numOfProducts,
    
		status,
		// flags
		selected,
		// actions
		onClick
	} = props

	const { formatPrice, formatAddress } = useStringFormater()

	// styles
	const titleColor = selected ? 'yellow' : 'gray-dark'
	const textColor = selected ? 'white' : 'gray-light'
	const priceColor = selected ? 'yellow' : 'blue'

	return (
		<div
			className={`order-card -${selected ? '' : 'un'}selected`}
			onClick={onClick}>
			<div className={`order-card-title -${selected ? '' : 'un'}selected`}>
				<span className={`text-18px-700 color-${titleColor}`}>
					No. {orderNumber}
				</span>
				<OrderStatusChip status={status} />
			</div>
			<div className='details'>
				<div>
					<span className={`text-11px-400 color-${textColor}`}>
						{address.address ? address.address : formatAddress(address)}
					</span>
					<span className={`text-11px-400 color-${textColor}`}>
						{numOfItems} {numOfItems > 1 ? 'Items' : 'Item'}
          </span>
          <span className={`text-11px-400 color-${textColor}`}>
            {numOfProducts} {numOfProducts > 1 ? 'Productos' : 'Producto'}
          </span>
				</div>
				<div>
					<span className={`text-12px-500 color-${textColor}`}>Subtotal</span>
					<span className={`text-18px-700 color-${priceColor}`}>
						{formatPrice(price)}
					</span>
				</div>
			</div>
		</div>
	)
}

OrderCardDesktop.propTypes = {
	// data
	orderNumber: PropTypes.string.isRequired,
	price: PropTypes.oneOfType([
		PropTypes.number.isRequired,
		PropTypes.string.isRequired
	]),
  address: PropTypes.object.isRequired,
  numOfProducts: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.string.isRequired
  ]),
	numOfItems: PropTypes.oneOfType([
		PropTypes.number.isRequired,
		PropTypes.string.isRequired
	]),
	status: PropTypes.shape({
		cancelled: PropTypes.object,
		received: PropTypes.object,
		enlistment: PropTypes.object,
		dispatched: PropTypes.object,
		finalized: PropTypes.object
	}),
	// flags
	selected: PropTypes.bool,
	// actions
	onClick: PropTypes.func.isRequired
}

export default OrderCardDesktop
