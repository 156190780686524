import { Box } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { ScrollMenu } from 'react-horizontal-scrolling-menu'
import { Link } from 'react-router-dom'
import ProductCardMobile from '../../components/atoms/cards/ProductCard/ProductCardMobile'
import OffersSlider from '../../components/OffersSlider/OffersSlider'
import Slider from '../../components/Slider/Slider'
import { routeFormatter } from '../../routes/routes'
// hooks
import useProducts from '../../hooks/products/useProducts'
import ValidateStock from '../../utils/ValidateStock'

/**
 * Componente de diseño de la página de inicio para dispositivos de mobile.
 * @param {Object} props - Propiedades para el componente.
 * @param {Array} props.categories - Categorías de productos a mostrar.
 * @param {boolean} props.loading - Indica si las categorías están cargando.
 * @param {string} props.city - Ciudad actual seleccionada.
 * @param {string} props.branchOffice - Sucursal actual seleccionada.
 * @returns {React.ReactNode} Elemento React que contiene la página de inicio para dispositivos de mobile.
 */
const HomeMobile = ({ categories, loading, city, branchOffice }) => {
	const RectSkeleton = (props) => (
		<Skeleton animation='wave' variant='rect' {...props} />
	)

	return (
		<div className='homeMobile mobile-page-wrapper'>
			<OffersSlider branch={branchOffice} />
			{loading ? (
				<>
					<section style={{ margin: '0 0.5rem' }}>
						<p>
							<RectSkeleton width={200} />
						</p>
						<Box
							display='flex'
							padding='1em 0'
							style={{
								gap: '.5em',
								overflow: 'hidden'
							}}>
							{Array(10)
								.fill(0)
								.map((_, i) => (
									<div key={i}>
										<RectSkeleton width={80} height={80} />
									</div>
								))}
						</Box>
					</section>
					<section className='category-section'>
						<p>
							<Skeleton variant='rect' width={200} />
						</p>
						<Slider id='categories-slider'>
							{Array(10)
								.fill(0)
								.map((_, i) => (
									<ProductCardMobile loading key={`product-skltn-${i}`} />
								))}
						</Slider>
					</section>
				</>
			) : (
				<>
					<section className='categories'>
						<p className='text-21px-700 color-blue'>Categorías</p>
						<Slider id='categories-slider'>
							{categories.map((category) => {
								const name = category.name
									? category.name[0].toUpperCase() +
									  category.name.toLowerCase().substring(1)
									: 'Undefined'

								return (
									<Link
										key={category.id}
										className='text-decoration-none'
										to={routeFormatter.toCategory(category.id)}>
										<div className='category-mobile-card'>
											<img
												className='card-image'
												src={`/icons/navbar/${name}.svg`}
												alt=''
											/>
											<span className='text-13px-500 color-gray category-name'>
												{name}
											</span>
										</div>
									</Link>
								)
							})}
						</Slider>
					</section>
					{
						// validates categories is not empty
						categories.map((category) => (
							<section key={category.id} className='category-section'>
								<div
									className={
										category?.products?.length === 0 ? 'display-none' : 'title'
									}>
									<p className='text-21px-700 color-blue'>
										{category.name ? category.name.toLowerCase() : 'Undefined'}
									</p>
									<Link
										to={routeFormatter.toCategory(category.id)}
										className='text-decoration-none'>
										<Box display='flex' alignItems='center'>
											<span className='color-red text-14px-700'>
												Ver mas&nbsp;
											</span>
											<i className='arrow -red' />
										</Box>
									</Link>
								</div>
								<LazyCategory
									city={city}
									category={category}
									branchOffice={branchOffice}
								/>
							</section>
						))
					}
				</>
			)}
		</div>
	)
}

const LazyCategory = ({ category, city, branchOffice }) => {
	const { loadLimitedCategory } = useProducts()
	const [isLoading, setIsLoading] = useState(true)

	useEffect(() => {
		const onChange = (entries, observer) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					loadLimitedCategory(
						category.id,
						city,
						branchOffice,
						100,
						setIsLoading
					)
					observer.unobserve(entry.target)
				}
			})
		}

		const observer = new IntersectionObserver(onChange, {
			root: null,
			rootMargin: '100px',
			threshold: 0.3
		})
		observer.observe(document.getElementById(category.id))

		return () => observer.disconnect()
	}, [])

	return (
		<div id={category.id}>
			{category.products.length > 0 && !isLoading && (
				<ScrollMenu>
					{
						// validates products is not empty
						category.products.map(
							(product) =>
								// validates if products is available in selected city
								product.price_branch_offices.some((item) =>
									ValidateStock(item, city)
								) && (
									<ProductCardMobile
										itemId={product.id_item}
										key={product.id_item}
										product={product}
										productId={product.id_item}
										categoryId={category.id}
										branch={product.price_branch_offices.find(
											(item) => item.city.toLowerCase() === city.toLowerCase()
										)}
									/>
								)
						)
					}
				</ScrollMenu>
			)}
			{isLoading && (
				<Slider id='categories-slider'>
					{Array(10)
						.fill(0)
						.map((_, i) => (
							<ProductCardMobile loading key={`product-skltn-${i}`} />
						))}
				</Slider>
			)}
		</div>
	)
}

LazyCategory.propTypes = {
	category: PropTypes.object.isRequired,
	city: PropTypes.string.isRequired,
	branchOffice: PropTypes.string.isRequired
}

HomeMobile.propTypes = {
	categories: PropTypes.array.isRequired,
	products: PropTypes.array.isRequired,
	loading: PropTypes.bool.isRequired,
	city: PropTypes.string.isRequired,
	branchOffice: PropTypes.string.isRequired
}

export default HomeMobile
