import * as actionTypes from './productsActionsTypes'

/**
 * The initial state for the products reducer.
 *
 * @typedef {Object} initialState
 * @property {boolean} loading - Indicates whether the app is currently loading products.
 * @property {null|string} error - The error message for the last failed request, if any.
 * @property {Array} categories - An array of category objects.
 * @property {boolean} categoriesFullLoaded - Indicates whether all categories have been fully loaded.
 * @property {boolean} compareModal - Indicates whether the compare product modal is open.
 * @property {Object} comparedProduct - The product being compared.
 * @property {boolean} marketModal - Indicates whether the market modal is open.
 * @property {Object} marketProduct - The product being displayed in the market modal.
 */
export const initialState = {
	loading: false,
	error: null,
	categories: [],
	categoriesFullLoaded: false,
	compareModal: false,
	comparedProduct: {},
	marketModal: false,
	marketProduct: {}
}

/**
 * The reducer function for the products slice of the store.
 *
 * @param {initialState} prevState - The previous state of the reducer.
 * @param {Object} action - The action object.
 * @returns {Object} The new state of the reducer.
 */
export const productsReducer = (prevState = initialState, action) => {
	switch (action.type) {
		case actionTypes.LOAD_ALL_CATEGORIES:
			return {
				...prevState,
				loading: true
			}
		case actionTypes.LOAD_ALL_CATEGORIES_SUCCESS:
			return {
				...prevState,
				categories: action.value,
				loading: false
			}
		case actionTypes.LOAD_ALL_CATEGORIES_FAILURE:
			return {
				...prevState,
				error: action.error,
				loading: false
			}
		case actionTypes.LOAD_CATEGORY_PROD:
			return {
				...prevState,
				loading: true
			}
		case actionTypes.LOAD_CATEGORY_PROD_SUCCESS: {
			return {
				...prevState,
				categories: prevState.categories.map((cat) =>
					cat.id === action.categoryId
						? {
								...cat,
								products: action.replace
									? action.value
									: [...new Set([...cat.products, ...action.value])]
						  }
						: cat
				),
				loading: false
			}
		}
		case actionTypes.LOAD_CATEGORY_PROD_FAILURE:
			return {
				...prevState,
				error: action.error,
				loading: false
			}
		case actionTypes.CLEAN_SUBCATEGORY_PRODUCTS:
			return {
				...prevState,
				categories: prevState.categories.map((cat) => {
					if (cat.id !== action.categoryId) return cat
					return {
						...cat,
						products: cat.products.filter(
							(product) => product.sub_category_id !== action.sub_category_id
						)
					}
				})
			}
		case actionTypes.ALL_CATEGORIES_FULL_LOADED:
			return {
				...prevState,
				categoriesFullLoaded: true
			}
		case actionTypes.ADD_PRODUCT_TO_CATEGORY_CONTEXT: {
			const updatedCategories = prevState.categories.map((category) => {
				if (category.id !== action.categoryId) return category

				const alreadyAdded = Boolean(
					category.products.find(
						(product) => product.id_item === action.product.id_item
					)
				)
				if (alreadyAdded) return category

				return {
					...category,
					products: [...category.products, action.product]
				}
			})
			return {
				...prevState,
				categories: updatedCategories
			}
		}
		case actionTypes.OPEN_COMPARE_MODAL: {
			return {
				...prevState,
				compareModal: true,
				comparedProduct: action.value
			}
		}
		case actionTypes.CLOSE_COMPARE_MODAL: {
			return {
				...prevState,
				compareModal: false,
				comparedProduct: {}
			}
		}
		case actionTypes.OPEN_MARKET_MODAL: {
			return {
				...prevState,
				marketModal: true,
				marketProduct: action.value
			}
		}
		case actionTypes.CLOSE_MARKET_MODAL: {
			return {
				...prevState,
				marketModal: false,
				marketProduct: {}
			}
		}
		default:
			return prevState
	}
}
