import PropTypes from 'prop-types'
import React from 'react'
import { isMobile } from 'react-device-detect'
import { routes } from '../../routes/routes'
// material ui
import { Menu } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
// my components
import CustomButton from '../../components/atoms/buttons/CustomButton'
import MenuSection from './MenuSection'
// hooks
import { useHistory } from 'react-router-dom'
import useShoppingCart from '../../hooks/shoppingCart/useShoppingCart'
import CartDesktop from '../../pages/Cart/CartDesktop'

// import CartDesktop from '../../pages/Cart/CartDesktop'

/**
 * El componente utiliza el hook makeStyles de Material-UI
 * para definir el estilo del contenedor del menú emergente
 * y otros elementos visuales.
 */
const useStyles = makeStyles({
  paper: {
    width: '23.313rem',
    minWidth: '23.313rem',
    background: '#FFFFFF',
    boxShadow: '4.19689px 11.1917px 27.9793px rgba(74, 74, 74, 0.18)',
    borderRadius: '25px',
    boxSizing: 'border-box',
    padding: '1.563rem 1.438rem',

    '& .MuiList-root': {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '0'
    }
  }
})

/**
 * Muestra el menú del carrito con la información de los productos agregados.
 *
 * @param {Object} anchor - Ancla del menú.
 * @param {Function} onClose - Función para cerrar el menú.
 *
 * @returns {JSX.Element} Elemento JSX que muestra el menú del carrito.
 */
const CartMenu = ({ anchor, onClose }) => {
  const history = useHistory()
  const classes = useStyles()

  const {
    generalTotal,
    wholesalerTotal,
    wholesalerProducts,
    generalProducts,
    restartSteps
  } = useShoppingCart()

  const total = generalTotal + wholesalerTotal

  const originMobile = {
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'right'
    },
    transformOrigin: {
      vertical: 'bottom',
      horizontal: 'right'
    }
  }
  const originDesktop = {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'right'
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'right'
    }
  }
  const origin = isMobile ? originMobile : originDesktop

  /* const [totalWholesalerProducts, setTotalWholesalerProducts] = useState(0)

  useEffect(() => {
    const sumall = wholesalerProducts
      .map((product) => product.count)
      .reduce((prev, curr) => prev + curr, 0)
    setTotalWholesalerProducts(sumall)
  }, [wholesalerProducts])

  const [totalGeneralProducts, setTotalGeneralProducts] = useState(0)

  useEffect(() => {
    const sumall = generalProducts
      .map((product) => product.count)
      .reduce((prev, curr) => prev + curr, 0)
    setTotalGeneralProducts(sumall)
  }, [wholesalerProducts]) */

  return (
    <Menu
      classes={{ paper: classes.paper }}
      className='cart-menu'
      anchorEl={anchor}
      open={Boolean(anchor)}
      onClose={onClose}
      keepMounted
      getContentAnchorEl={null}
      {...origin}>
      <span className='text-14px-500 color-gray-light cart-title'>
        Descripción del carrito
      </span>
      {/* {totalWholesalerProducts > 0 && <MenuSection
        title='Mayorista'
        subtitle={`${totalWholesalerProducts} item${totalWholesalerProducts > 1 ? 's' : ''
          }`}
        price={wholesalerTotal}
      />} */}
      <CartDesktop onlyProductList={true} />
      {/* <MenuSection
        title='Generales'
        subtitle={`${totalGeneralProducts} item${totalGeneralProducts > 1 ? 's' : ''
          }`}
        price={generalTotal}
      /> */}
      <MenuSection
        title='Total'
        priceTitle=''
        price={total}
        message='Incluye los productos generales y mayoristas'
      />
      {wholesalerProducts.length >= 1 || generalProducts.length >= 1 ? (
        <div className='cart-button'>
          <CustomButton
            label='Ir a pagar'
            iconClass='cart-icon -white -menu'
            iconFirst
            width='151px'
            height='51px'
            onClick={() => {
              restartSteps()
              history.push(routes.cart.path)
              onClose()
            }}
          />
        </div>
      ) : (
        <div className='cart-button'>
          <CustomButton
            label='Carrito vacío'
            color='white'
            width='151px'
            height='51px'
            onClick={onClose}
          />
        </div>
      )}
    </Menu>
  )
}

CartMenu.propTypes = {
  anchor: PropTypes.object,
  onClose: PropTypes.func.isRequired
}

export default CartMenu
