import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import OffersDesktopMobile from './OffersDesktopMobile'

const OffersLayout = OffersDesktopMobile
/**
 * Componente que representa la página de resultados de búsqueda.
 * @param {Object} props - Propiedades para el componente.
 * @returns {React.ReactNode} Elemento React que contiene la página de resultados de búsqueda.
 */
const Offers = (props) => {
  const { query } = useParams()
  const [realQuery, setRealQuery] = useState("")
  useEffect(() => {
    if (query && typeof query === "string") setRealQuery(query)
  }, [query]
  )

  return useMemo(() => <OffersLayout
    key={`Offers_${realQuery}`}
    {...props} 
  />, [realQuery])
}

export default Offers
