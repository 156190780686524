import React from 'react'
import PropTypes from 'prop-types'

const Dataphone = (props) => {
	const { isSelected, onSelect, setPayment } = props
	setPayment(3)
	return (
		<div className='payment-method' onClick={onSelect}>
			<div className='description'>
				<div>
					<i
						className={`round-check-icon -${
							isSelected ? 'checked' : 'unchecked'
						}`}
					/>
					<i className='dataphone' />
					<div className='info'>
						<span className='text-13px-700 color-black-light name'>
							Contraentrega - Datafono
						</span>
					</div>
				</div>
			</div>
		</div>
	)
}
Dataphone.propTypes = {
	isSelected: PropTypes.bool.isRequired,
	onSelect: PropTypes.func,
	setPayment: PropTypes.func
}
Dataphone.defaultProps = {
	setPayment: () => {}
}

export default Dataphone
