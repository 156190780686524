// Valida stock
export default function (product, city, branchOffice) {
	if (city) {
		if (product.city.toLowerCase() === city.toLowerCase()) {
			if (product.city.toLowerCase() === 'medellin' && branchOffice) {
				if (
					"Guayabal".toLowerCase() === product.branch_offices.toLowerCase() ||
					"Santa Lucia".toLowerCase() === product.branch_offices.toLowerCase()
				) {
					return product.stock >= 5
				} else {
					return false
				}
			}

			return product.stock >= 5
		}
	}
}
