import React from 'react'
import PropTypes from 'prop-types'
import { routes } from '../../routes/routes'
import { useHistory } from 'react-router-dom'
// resources
import backArrowButton from '../../assets/arrow-back.svg'

const SectionHeader = ({ title, children, goBack }) => {
	const history = useHistory()

	return (
		<div className='section-header'>
			<img
				src={backArrowButton}
				alt='back'
				onClick={() => {
					goBack ? history.goBack() : history.push(routes.home.path)
				}}
			/>
			{children || <span className='text-21px-700 color-blue'>{title}</span>}
		</div>
	)
}

SectionHeader.propTypes = {
	title: PropTypes.string,
	children: PropTypes.node,
	goBack: PropTypes.bool
}

export default SectionHeader
