import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import CustomSnackbar from '../CustomSnackBar/CustomSnackbar'
import AppBar from './AppBar/AppBar'
import Footer from './Footer/Footer'
// global modals
import useConfigs from '../../hooks/configs/useConfigs'
import AuthModals from '../AuthModals/AuthModals'
import CompareProductModal from '../Modals/CompareProductModal'
import SelectedProductToMarketModal from '../Modals/SelectedProductToMarketModal'
import Shoppingvalidationmodal from '../Modals/ShoppingValidationModal'
import SignInWithPhoneModal from '../Modals/SignInWithPhoneModal'
import UserDeletedModal from '../Modals/UserDeletedModal'
import WppFloating from '../WppFloating/WppFloating'

/**
 * Componente de diseño general de la aplicación que incluye la barra de navegación,
 * el contenido principal, un Snackbar personalizado, los modales de autenticación, un modal de confirmación de eliminación de usuario,
 * un modal de validación de compras, un modal de comparación de productos, un modal de selección de productos para mercado,
 * un modal de inicio de sesión con número de teléfono y el pie de página.
 * @param {object} props - Propiedades del componente.
 * @param {node} props.children - Contenido que se renderizará dentro del componente.
 */
const Layout = ({ children }) => {
	// Obtiene la ubicación actual de la página.
	const location = useLocation()
  useConfigs({autoLoadConfigsData:true})
	// Desplaza la vista a la parte superior de la página cuando la ubicación cambia.
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [location])

	return (
    <div>
      <WppFloating />
			<AppBar />
			{children}
			<CustomSnackbar />
			<AuthModals />
			<UserDeletedModal />
			<Shoppingvalidationmodal />
			<CompareProductModal />
			<SelectedProductToMarketModal />
			<SignInWithPhoneModal />
      <Footer />
		</div>
	)
}

Layout.propTypes = {
	children: PropTypes.node
}
Layout.defaultProps = {
	children: null
}

export default Layout
