import React, { useState, useEffect } from 'react'
// material ui
import { Grid } from '@material-ui/core'
// components
import CircularLoader from '../../../components/loaders/CircularLoader'
import CreateMarketForm from '../../../components/CreateMarketForm/CreateMarketForm'
import MercadoCard from '../../../components/MercadoCard/MercadoCard'
import DeleteMarketModal from '../../../components/MercadoCard/DeleteMarketModal'
import ProductListMarket from '../../../components/ProductListMarket/ProductListMarket'
import MarketSummary from '../../../components/Summaries/MarketSummary'
import AddressValidationModal from '../../../components/Modals/AddressValidationModal'
// hooks
import useMarkets from '../../../hooks/markets/useMarkets'
import useAuth from '../../../hooks/auth/useAuth'

const FavoriteMarketsDesktop = () => {
	// hooks
	const { getCityStorage, authValues, getCityBranchOffice } = useAuth()
	const city = getCityStorage()
	const branchOffice = getCityBranchOffice()

	const addresses = authValues.addresses
	const haveAddresses = addresses.length > 0

	const {
		deleteMarket,
		getFavoriteMarkets,
		getUserMarkets,
		markets,
		favoriteMarketsIds
	} = useMarkets()

	// state
	const [selectedMarket, setSelectedMarket] = useState(null)
	const [marketToEdit, setMarketToEdit] = useState(null)
	const [favoriteMarkets, setFavoriteMarkets] = useState(null)

	// effects
	useEffect(() => {
		getUserMarkets()
	}, [])

	useEffect(() => {
		if (markets) setFavoriteMarkets(getFavoriteMarkets())
	}, [markets, favoriteMarketsIds])

	useEffect(() => {
		if (favoriteMarkets) setSelectedMarket(favoriteMarkets[0])
	}, [favoriteMarkets])

	// delete modal control variables
	const [openDM, setOpenDM] = useState(false)
	// addresses validation modal control variables
	const [openAV, setOpenAV] = useState(false)

	const [marketToDelete, setMarketToDelete] = useState('')

	const confirmationToDelete = (marketId) => (e) => {
		e.stopPropagation()
		setOpenDM(true)
		setMarketToDelete(marketId)
	}

	const handleDelete = () => {
		if (marketToDelete) {
			setOpenDM(false)
			deleteMarket(marketToDelete)
		}
	}

	const prepareToEditMarket = (market) => (e) => {
		e.stopPropagation()

		if (!haveAddresses) {
			setOpenAV(true)
			return null
		}
		let marketToEdit = { ...market }
		delete marketToEdit.product
		setMarketToEdit(marketToEdit)
		setSelectedMarket(null)
	}

	return (
		<>
			{!favoriteMarkets ? (
				<CircularLoader />
			) : (
				<Grid container spacing={2}>
					<Grid item xs={4} className='markets-list'>
						{favoriteMarkets.length > 0 ? (
							favoriteMarkets.map((market) => (
								<MercadoCard
									key={market.id}
									selected={
										selectedMarket ? selectedMarket.id === market.id : false
									}
									marketId={market.id}
									address={market.address}
									name={market.name}
									products={market.products}
									city={city}
									branchOffice={branchOffice}
									onEdit={prepareToEditMarket(market)}
									onDelete={confirmationToDelete(market.id)}
									onClick={() => {
										setSelectedMarket(market)
									}}
								/>
							))
						) : (
							<p className='text-14px-400 color-gray-dark'>
								No tienes mercados favoritos
							</p>
						)}
						<DeleteMarketModal
							open={openDM}
							handleClose={() => setOpenDM(false)}
							onDelete={handleDelete}
						/>
						<AddressValidationModal
							open={openAV}
							handleClose={() => setOpenAV(false)}
							actionToValidate='editar un mercado'
						/>
					</Grid>
					<Grid item xs={4} className='products-list custom-scrollbar'>
						{selectedMarket ? (
							//only if a market is selected
							<>
								<ProductListMarket
									city={city}
									branchOffice={branchOffice}
									title='Productos generales'
									marketId={selectedMarket.id}
									resumeLabel='Generales'
								/>
							</>
						) : (
							marketToEdit && <CreateMarketForm market={marketToEdit} />
						)}
					</Grid>
					<Grid item xs={4}>
						{selectedMarket && (
							<MarketSummary
								marketId={selectedMarket.id}
								city={city}
								branchOffice={branchOffice}
							/>
						)}
					</Grid>
				</Grid>
			)}
		</>
	)
}

export default FavoriteMarketsDesktop
