import React from 'react'
import PropTypes from 'prop-types'
import AlertModal from '../AlertModal/AlertModal'

const DeleteMarketModal = ({ open, handleClose, onDelete }) => {
	return (
		<AlertModal
			open={open} //booleano para abrir el modal
			message='¿Seguro que deseas eliminar este mercado?' //mensaje de confirmacion
			acceptAction={onDelete} //callback para ejecutar la funcion de eliminar 
			cancelAction={handleClose} //callback para cancelar la funcion de eliminar 
			cancelBtnRight  // booleano para dejar el boton a la derecha
			handleClose={handleClose} //Funcion para cerrar el modal
		/>
	)
}

DeleteMarketModal.propTypes = {
	// actions
	handleClose: PropTypes.func,
	onDelete: PropTypes.func,
	// flags
	open: PropTypes.bool
}

DeleteMarketModal.defaultProps = {
	handleClose: () => {}, //prop callback  cerrar modal
	onDelete: () => {}  //prop callback  eliminar mercado
}

export default DeleteMarketModal
