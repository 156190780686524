/**
 * Varibales de medida para productos
 */
export const UNIT = {
	value: 'unit',
	intValue: 0,
	label: {
		short: 'Unds',
		long: 'Unidades'
	}
}

export const WEIGHT = {
	value: 'weight',
	intValue: 1,
	label: {
		short: 'Gr',
		long: 'Gramos'
	}
}
