/**
 * Calculates the age of a user based on their birth date.
 * 
 * @param {Object} birth_date - The birth date of the user.
 * @param {number} birth_date.seconds - The seconds component of the birth date timestamp.
 * @param {number} birth_date.nanoseconds - The nanoseconds component of the birth date timestamp.
 * 
 * @returns {number} The age of the user.
 */
const getUserAge = (birth_date) => {
	if (birth_date) {
		const birthdayTimestamp = birth_date
		const ageInMilliseconds =
			birthdayTimestamp.seconds * 1000 + birthdayTimestamp.nanoseconds / 1000000
		const birthday = new Date(ageInMilliseconds)

		const today = new Date()
		const age = today.getFullYear() - birthday.getFullYear()
		const monthDifference = today.getMonth() - birthday.getMonth()

		if (
			monthDifference < 0 ||
			(monthDifference === 0 && today.getDate() < birthday.getDate())
		) {
			return age - 1
		}
		return age
	} 
    return 0
}

export default getUserAge
