import { useContext } from 'react'
import { context } from '../../context/ContextProvider'
import * as productsActions from '../../context/products/productsActions'

const useProducts = () => {
	const { state, dispatch } = useContext(context)

	const { productsReducer } = state
	const {
		categories,
		loading,
		categoriesFullLoaded,
		compareModal,
		marketModal,
		comparedProduct,
		marketProduct
	} = productsReducer

	/**
	 * Finds and returns a category from context productReducer
	 * @param {string} categoryId
	 * @returns {object} category
	 */
	const getCategoryById = (categoryId) => {
		return categories.find((item) => item.id === categoryId)
	}

	/**
	 * Returns a category with all of its products loaded,
	 * products are queried from database
	 * @param {string} categoryId
	 * @returns {object} category
	 */
	const loadFullCategory = async (categoryId) => {
		const category = getCategoryById(categoryId)
		const products = await productsActions.loadCategoryProducts(
			dispatch,
			categoryId
		)
		return {
			...category,
			products
		}
	}

	/**
	 * Returns a category with a limited number of products (10 by default),
	 * products are queried from database
	 * @param {string} categoryId
	 * @returns {object} category
	 */
	const loadLimitedCategory = async (
		categoryId,
		city,
		branchOffice,
		limit = 100,
		setLoading
	) => {
		const category = getCategoryById(categoryId)
		if (setLoading) setLoading(true)
		const products = await productsActions.loadLimitedCategoryProducts(
			dispatch,
			categoryId,
			city,
			branchOffice,
			limit
		)
		if (setLoading) setLoading(false)
		return {
			...category,
			products
		}
	}

	const loadCategories = async (branchOffice = "Chipichape") => {
		await productsActions.loadAllCategories(dispatch, branchOffice)
	}

	/**
	 * Returns a copy of the product from context witch is in category with categoryId as id
	 * and have productId as id. If product is not loaded yet in context, it will be asynchronously fetched
	 * from database
	 * @param {string} categoryId
	 * @param {string} productId
	 * @returns {object | Promise<object>} productData
	 */
	const getProductData = async (categoryId, productId) => {
		const cat = categories.find((cat) => cat.id === categoryId)
		if (!cat) {
			throw console.log(`MercaZ Error: Can't find this category: ${categoryId}`)
		}
		let productReference = cat.products.find(
			(product) => product.id_item === productId
		)
		if (!productReference) {
			// product is not loaded yet in context, so we consult it from databse directly
			const productReference = await productsActions.loadProduct(
				productId,
				categoryId
			)
			// the product may not exist in database
			if (!productReference) return
			// adds the product to context, so it wont be loaded from database next time
			productsActions.addProductToCategory(
				{ ...productReference },
				categoryId,
				dispatch
			)
			return productReference
		}
		if (!productReference) {
			throw new Error(
				`MercaZ Error: Can't find the product with id: ${productId}`
			)
		}
		return { ...productReference }
	}

	const loadSubCategoryProducts = async (
		categoryId,
		subCategoryId,
		city,
		branchOffice,
		limited = false,
		setLoading
	) => {
		if (setLoading) setLoading(true)
		await productsActions.loadSubCategoryProducts(
			dispatch,
			categoryId,
			subCategoryId,
			city,
			branchOffice,
			limited
		)
		if (setLoading) setLoading(false)
	}

	const toggleCompareModal = (product) => {
		if (!compareModal) {
			dispatch({ type: 'OPEN_COMPARE_MODAL', value: product })
		} else {
			dispatch({ type: 'CLOSE_COMPARE_MODAL' })
		}
	}

	const toggleMarketModal = (product) => {
		if (!marketModal) {
			dispatch({ type: 'OPEN_MARKET_MODAL', value: product })
		} else {
			dispatch({ type: 'CLOSE_MARKET_MODAL' })
		}
	}

	const handleSearchHits = (hits) => {
		localStorage.setItem('hits', JSON.stringify(hits))
	}

	const getSearchHits = () => {
		let hits = JSON.parse(localStorage.getItem('hits'))
		if (!hits) {
			localStorage.setItem('hits', [])
			return []
		}
		return hits
	}

	const handleSearchQuery = (query) => {
		localStorage.setItem('searchQuery', query)
	}

	const getSearchQuery = () => {
		let query = localStorage.getItem('searchQuery')
		if (!query) {
			localStorage.setItem('searchQuery', '')
			return ''
		}
		return query
	}

	return {
		// data
		categories,
		// loadings
		loading,
		categoriesFullLoaded,
		// actions
		getProductData,
		loadFullCategory,
		loadLimitedCategory,
		loadSubCategoryProducts,
		loadCategories,
		getCategoryById,
		toggleCompareModal,
		toggleMarketModal,
		handleSearchHits,
		getSearchHits,
		handleSearchQuery,
		getSearchQuery,
		//modal
		compareModal,
		marketModal,
		comparedProduct,
		marketProduct
	}
}

export default useProducts
