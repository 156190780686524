import React from 'react'
import PropTypes from 'prop-types'

const PSEpay = (props) => {
  const { isSelected, onSelect, setPayment } = props
  setPayment(0)
  return (
    <div className='payment-method' onClick={onSelect}>
      <div className='description'>
        <div>
          <i
            className={`round-check-icon -${isSelected ? 'checked' : 'unchecked'
              }`}
          />
          <i className='pse-icon' />
          <div className='info'>
            <span className='text-13px-700 color-black-light name'>
              Online - PSE - CreditCard
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
PSEpay.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func,
  setPayment: PropTypes.func
}
PSEpay.defaultProps = {
  setPayment: () => { }
}

export default PSEpay