import React from 'react'
import { isBrowser } from 'react-device-detect'
import MercadoCardDesktop from './MercadoCardDesktop'
import MercadoCardMobile from './MercadoCardMobile'

const MercadoCard = (props) => {
	// esta constante determina si muestra el componente mobile o desktop dependiendo la pantalla del dispositivo
	const MercadoCardLayout = isBrowser ? MercadoCardDesktop : MercadoCardMobile 
	
	return <MercadoCardLayout {...props} />
}

export default MercadoCard
