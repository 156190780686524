import React from 'react'
import { isBrowser } from 'react-device-detect'
import AccountDesktop from './AccountDesktop'
import AccountMobile from './AccountMobile'

/**
 * Creates an account component based on whether the environment is a browser or mobile device.
 * @returns {JSX.Element} - Returns a React element representing the account component.
 */
const Account = () => {
	const AccountLayout = isBrowser ? AccountDesktop : AccountMobile
	return <AccountLayout />
}

export default Account
