import PropTypes from 'prop-types'
import React from 'react'
import useStringFormater from '../../hooks/stringFormater/useStringFormater'

const SummarySection = (props) => {
	const { title, subtitle, price, priceTitle, message, withBorder } = props
	const { formatPrice } = useStringFormater()

	return (
		<div
			className={`order-summary-section ${withBorder ? '-with-border' : ''}`}>
			<div className='content'>
				<div className='description'>
					<span className='text-14px-500 color-gray-light'>{title}</span>
					{Array.isArray(subtitle) ? (
						<ul className='discounts-list'>
							{subtitle.map((sub, index) => (
								<li key={index} className='text-11px-400 color-gray-light'>
									{sub}
								</li>
							))}
						</ul>
					) : (
						<span className='text-11px-400 color-gray-light'>{subtitle}</span>
					)}
				</div>
				<div className='price'>
					<span className='text-12px-600 color-gray-light'>{priceTitle}</span>
					<span className='text-18px-600 color-blue'>{formatPrice(price)}</span>
				</div>
			</div>
			<span className='text-11px-400 color-gray-light message'>{message}</span>
		</div>
	)
}

SummarySection.propTypes = {
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.any,
	price: PropTypes.number.isRequired,
	priceTitle: PropTypes.string,
	message: PropTypes.string,
	withBorder: PropTypes.bool
}

export default SummarySection
