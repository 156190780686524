const useValidator = () => {
	// validation rules
	/**
	 * Checks if the value is not an empty string
	 * @param {string} value
	 * @param {(errorMessage: string) => void} setErrorMessage
	 * @param {string} errorMessage
	 * @returns field is valid
	 */
	const isNotEmpty = (
		value,
		setErrorMessage,
		errorMessage = 'Este campo es obligatorio'
	) => {
		if (value === '') {
			setErrorMessage(errorMessage)
			return false
		}
		return true
	}
	/**
	 * Checks if the value match the provided regular expression
	 * @param {string} value
	 * @param {RegExp} regex
	 * @param {Function} setErrorMessage
	 * @param {string} errorMessage
	 * @returns field is valid
	 */
	const matchRegex = (
		value,
		regex,
		setErrorMessage,
		errorMessage = 'Este campo no es válido'
	) => {
		if (!regex.test(value)) {
			setErrorMessage(errorMessage)
			return false
		}
		return true
	}
	/**
	 * Checks if the value is between min and max values provided
	 * @param {number} value
	 * @param {number} max
	 * @param {number} min
	 * @param {Function} setErrorMessage
	 * @param {string} errorMessage
	 * @returns
	 */
	const inRange = (
		value,
		max,
		min,
		setErrorMessage,
		errorMessage = `El número debe estar entre ${min} y ${max}`
	) => {
		if (value > max || value < min) {
			setErrorMessage(errorMessage)
			return false
		}
		return true
	}

	// data validation
	const validateName = ({
		name,
		setNameError,
		validationMessage,
		notEmpty = true
	}) => {
		if (!notEmpty && name === '') return true
		return (
			isNotEmpty(name, setNameError) &&
			matchRegex(
				name,
				/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]+$/,
				setNameError,
				validationMessage || 'Ingresa un nombre válido'
			)
		)
	}
	const validateDocument = ({
		document,
		setDocumentError,
		notEmpty = true
	}) => {
		if (!notEmpty && document === '') return true
		return (
			isNotEmpty(document, setDocumentError) &&
			matchRegex(
				document,
				/^\d{8,}$/,
				setDocumentError,
				'Ingresa un número de documento válido'
			)
		)
	}
	const validatePhone = ({ phone, setPhoneError, notEmpty = true }) => {
		if (!notEmpty && phone === '') return true
		return (
			isNotEmpty(phone, setPhoneError) &&
			matchRegex(
				phone,
				/^\d{12}$/,
				setPhoneError,
				'Ingresa un número de contacto válido'
			)
		)
	}
	const validateEmail = ({ email, setEmailError, notEmpty = true }) => {
		if (!notEmpty && email === '') return true
		return (
			isNotEmpty(email, setEmailError) &&
			matchRegex(
				email,
				/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
				setEmailError,
				'Ingresa un correo válido'
			)
		)
	}
	const validateDate = ({ date, setDateError, notEmpty = true }) => {
		if (!notEmpty && date === '') return true
		return (
			isNotEmpty(date, setDateError) &&
			matchRegex(date, /^\d{1,2}$/, setDateError, 'Ingresa un día válido') &&
			inRange(date, 30, 1, setDateError, 'La fecha debe estar entre 1 y 30')
		)
	}

	return {
		isNotEmpty,
		matchRegex,
		inRange,
		// common use cases
		validateName,
		validateDocument,
		validatePhone,
		validateEmail,
		validateDate
	}
}

export default useValidator
