import PropTypes from 'prop-types'
import React from 'react'
import { isBrowser } from 'react-device-detect'
// material ui
import { Dialog, DialogContent } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
// resources
import background from '../../assets/illustrations/background.svg'

// Estilos para escritorio
const useStylesDesktop = makeStyles({
	root: {
		'& .MuiBackdrop-root': {
			backgroundColor: 'rgba(244, 246, 249, 0.75)'
		}
	},
	paper: {
		borderRadius: '25px',
		boxShadow: '4.19689px 11.1917px 27.9793px rgba(74, 74, 74, 0.18)'
	}
})

// Estilos para móviles
const useStylesMobile = makeStyles({
	root: {},
	paper: {
		borderRadius: 0,
		width: '100%',
		height: '100%',
		maxWidth: 'none',
		maxHeight: 'none',
		margin: 0,
		overflowY: 'auto',
		boxSizing: 'border-box',
		backgroundImage: `url(${background})`
	}
})

// Estilos para el contenido
const useStylesContent = makeStyles({
	root: {
		padding: '20px 24px'
	}
})

/**
 * Componente que renderiza un diálogo personalizado en la pantalla.
 *
 * @param {Object} props Las propiedades del componente.
 * @param {Node} props.children El contenido del diálogo.
 * @param {boolean} props.open Indica si el diálogo debe mostrarse o no.
 * @param {function} props.handleClose La función que se ejecuta cuando se cierra el diálogo.
 * @param {boolean} props.mobileSmall Indica si el tamaño de la pantalla es pequeño.
 *
 * @returns {JSX.Element} El componente a renderizar.
 */
const CustomModal = (props) => {
	const { children, open, handleClose, mobileSmall } = props

	// Determina los estilos en función del tamaño de la pantalla
	const classes =
		isBrowser || mobileSmall ? useStylesDesktop() : useStylesMobile()
	const contentClasses = useStylesContent()

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			maxWidth='lg'
			classes={{
				root: classes.root,
				paper: classes.paper
			}}>
			<DialogContent
				className='custom-scrollbar'
				classes={{ root: contentClasses.root }}>
				{children}
			</DialogContent>
		</Dialog>
	)
}

CustomModal.propTypes = {
	children: PropTypes.node,
	open: PropTypes.bool,
	handleClose: PropTypes.func,
	// flags
	mobileSmall: PropTypes.bool
}

export default CustomModal
