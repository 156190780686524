import { FormControl, InputBase, MenuItem, Select } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded'
import PropTypes from 'prop-types'
import React from 'react'

/**
 * EditSelect es un componente React personalizado que muestra un menú desplegable
 * que permite al usuario seleccionar una opción. El componente es personalizable
 * y acepta las siguientes props:
 *
 * @param {string} label - Etiqueta opcional que se muestra encima del menú desplegable.
 * @param {Array} options - Un array de objetos con los valores `value` y `label` para cada opción del menú.
 * @param {number} selectedIndex - El índice de la opción seleccionada por defecto.
 * @param {function} onSelect - Una función que se llama cuando el usuario selecciona una opción del menú.
 * @param {string} variant - El esquema de color del menú desplegable, 'white' o 'blue'.
 * @param {string} size - El tamaño del menú desplegable, 'big' o 'small'.
 * @param {bool} disabled - Un booleano que indica si el componente está deshabilitado o no.
 * @returns {JSX.Element}
 */
const EditSelect = (props) => {
	const { label, options, selectedIndex, onSelect, variant, size, disabled } =
		props

	/**
	 * BootstrapInput es un componente de InputBase con estilos de Bootstrap que
	 * se personalizan a través del componente withStyles de Material UI.
	 */
	const BootstrapInput = withStyles((theme) => ({
		root: {
			'label + &': {
				marginTop: theme.spacing(3)
			}
		},
		input: {
			borderRadius: 4,
			position: 'relative',
			border: '0px',
			height: size === 'small' ? '100%' : '2em',
			padding: '12px 11px 0px 1px',
			paddingTop: size === 'small' ? '8px' : '12px',
			'&:focus': {
				backgroundColor: 'rgba(0,0,0,0)'
			}
		}
	}))(InputBase)

	/**
	 * La función CustomMenuItem toma un MenuItem y devuelve
	 * una versión personalizada del mismo que utiliza estilos
	 * personalizados de Material-UI.
	 */
	const CustomMenuItem = withStyles((theme) => ({
		root: {
			fontSize: '12px',
			color: theme.palette.primary.main
		}
	}))(MenuItem)

	/**
	 * CustomSelect es una función de React que
	 * devuelve un componente Select personalizado,
	 * con algunas propiedades estilísticas predefinidas.
	 */
	const CustomSelect = withStyles(() => ({
		root: {
			fontSize: '0.87rem',
			color: '#858585',
			borderRadius: '30px'
		}
	}))(Select)

	/**
	 * La función iconComponent devuelve un icono representado por el componente
	 *  ExpandMoreRoundedIcon de @material-ui/icons.
	 *  El color del icono es azul oscuro (#164194).
	 * @param {string} className
	 * @returns {JSX.Element}
	 */
	const iconComponent = ({ className }) => {
		return (
			<ExpandMoreRoundedIcon
				className={className + ' '}
				style={{
					color: '#164194'
				}}
			/>
		)
	}

	// index must be greater than 0
	const index = selectedIndex > 0 ? selectedIndex : 0

	return (
		<div className={`edit-data-select ${disabled ? '-disabled' : ''}`}>
			{label && <span className='text-10px-400 color-gray label'>{label}</span>}
			<FormControl className={`select-input -${variant} -${size}`}>
				<CustomSelect
					onChange={onSelect}
					disabled={disabled}
					input={<BootstrapInput />}
					value={options[index].value}
					IconComponent={iconComponent}>
					{options.map((option, i) => (
						<CustomMenuItem value={option.value} key={`option-${i}`}>
							{option.label}
						</CustomMenuItem>
					))}
				</CustomSelect>
			</FormControl>
		</div>
	)
}

EditSelect.propTypes = {
	label: PropTypes.string,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.any,
			label: PropTypes.string
		})
	),
	selectedIndex: PropTypes.number,
	onSelect: PropTypes.func,
	variant: PropTypes.oneOf(['white', 'blue']),
	size: PropTypes.oneOf(['big', 'small']),
	disabled: PropTypes.bool
}

EditSelect.defaultProps = {
	selectedIndex: 0,
	variant: 'blue',
	size: 'big',
	onSelect: () => {}
}

export default EditSelect
