import React from 'react'
import PropTypes from 'prop-types'
// components
import { ScrollMenu } from 'react-horizontal-scrolling-menu'
import ProductCardMobile from '../../../components/atoms/cards/ProductCard/ProductCardMobile'
import ValidateStock from '../../../utils/ValidateStock'

const FavoriteProductsMobile = ({ loading, favCategories, city }) => {
	return (
		<>
			{loading ? (
				<>
					{Array(2)
						.fill(0)
						.map((_, i) => (
							<ScrollMenu key={`scroll-skltn-${i}`}>
								{Array(1)
									.fill(0)
									.map((_, i) => (
										<ProductCardMobile loading key={`product-skltn-${i}`} />
									))}
							</ScrollMenu>
						))}
				</>
			) : // no empty categories
			favCategories.length !== 0 ? (
				favCategories.map(
					(category) =>
						category.products.length && (
							<div key={`cat-${category.id}`}>
								<p className='text-21px-700 color-blue text-capitalize'>
									{category.name.toLowerCase()}
								</p>
								<ScrollMenu>
									{category.products.map(
										(product) =>
											// validates if products is available in selected city
											product.price_branch_offices.some((item) =>
												ValidateStock(item, city)
											) && (
												<ProductCardMobile
													key={product.id_item}
													product={product}
													productId={product.id_item}
													categoryId={category.id}
													branch={product.price_branch_offices.find(
														(item) =>
															item.city.toLowerCase() === city.toLowerCase()
													)}
												/>
											)
									)}
								</ScrollMenu>
							</div>
						)
				)
			) : (
				<span className='text-14px-400 color-gray-dark'>
					{' '}
					No tienes productos favoritos aún
				</span>
			)}
		</>
	)
}

FavoriteProductsMobile.propTypes = {
	loading: PropTypes.bool.isRequired,
	favCategories: PropTypes.array.isRequired,
	city: PropTypes.string.isRequired
}

export default FavoriteProductsMobile
