import PropTypes from 'prop-types'
import React from 'react'
import AlertModal from '../AlertModal/AlertModal'

/**
 * Modal para eliminar una tarjeta de pago.
 * @param {object} props - Las propiedades del componente.
 * @param {boolean} props.open - Indica si el modal está abierto o cerrado.
 * @param {function} props.handleClose - La función que se ejecuta cuando el usuario cierra el modal.
 * @param {function} props.onDelete - La función que se ejecuta cuando el usuario confirma que desea eliminar la tarjeta.
 * @returns {JSX.Element} - El componente de React que muestra el modal.
 */
const DeleteCardModal = ({ open, handleClose, onDelete }) => {
	return (
		<AlertModal
			open={open}
			message='¿Seguro que deseas eliminar esta tarjeta?'
			acceptAction={onDelete}
			cancelAction={handleClose}
			cancelBtnRight
			handleClose={handleClose}
		/>
	)
}

DeleteCardModal.propTypes = {
	// actions
	handleClose: PropTypes.func,
	onDelete: PropTypes.func,
	// flags
	open: PropTypes.bool
}

DeleteCardModal.defaultProps = {
	handleClose: () => {},
	onDelete: () => {}
}

export default DeleteCardModal
