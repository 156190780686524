// auth modals
export const SHOW_AUTH_MODAL = 'AUTH_SHOW_AUTH_MODAL'
export const HIDE_AUTH_MODAL = 'AUTH_HIDE_AUTH_MODAL'
// user deleted modals
export const SHOW_USER_DELETED_MODAL = 'SHOW_USER_DELETED_MODAL'
export const HIDE_USER_DELETED_MODAL = 'HIDE_USER_DELETED_MODAL'
// signIn firebase
export const SHOW_PASSWORD = 'AUTH_SHOW_PASSWORD'
export const REQUEST = 'AUTH_REQUEST'
export const SUCCESS = 'AUTH_SUCCESS'
export const ERROR = 'AUTH_ERROR'
export const UPDATE_AUTH_VALUES = 'AUTH_UPDATE_AUTH_VALUES'
export const SET_AUTH_VALUES = 'AUTH_SET_AUTH_VALUES'
export const SET_AUTH_VALUES_SUCCESS = 'SET_AUTH_VALUES_SUCCESS'
export const SET_AUTH_VALUES_FAILURE = 'SET_AUTH_VALUES_FAILURE'
export const SET_DEFAULTS = 'AUTH_SET_DEFAULTS'
// get user data from firebase
export const GET_CURRENT_USER = 'AUTH_GET_CURRENT_USER'
export const GET_CURRENT_USER_SUCCESS = 'AUTH_GET_CURRENT_USER_SUCCESS'
export const GET_CURRENT_USER_FAILURE = 'AUTH_GET_CURRENT_USER_FAILURE'
// update user data from firebase
export const UPDATE_USER_DATA = 'AUTH_UPDATE_USER_DATA'
export const UPDATE_USER_DATA_SUCCESS = 'AUTH_UPDATE_USER_DATA_SUCCESS'
export const UPDATE_USER_DATA_FAILURE = 'AUTH_UPDATE_USER_DATA_FAILURE'
// shopping cart
export const UPDATE_SHOPPING_CART = 'UPDATE_SHOPPING_CART'
export const EMPTY_CART = 'EMPTY_CART'
//password recovery
export const REQUEST_PASSWORD_RESET = 'AUTH_REQUEST_PASSWORD_RESET'
export const SUCCESS_PASSWORD_RESET = 'AUTH_SUCCESS_PASSWORD_RESET'
export const ERROR_PASSWORD_RESET = 'AUTH_ERROR_PASSWORD_RESET'
// USER CITY
export const USER_CURRENT_CITY = 'USER_CURRENT_CITY'