import React from 'react'
import PropTypes from 'prop-types'
import usePaymentMethod from './usePaymentMethod'
import SmallTransparentSelect from '../atoms/selects/SmallTransparentSelect'
import * as paymentTypes from './PaymentTypes'

const CreditCard = (props) => {
	const {
		// flags
		isSelected,
		defaultCard,
		// presentation
		cardType,
		layout,
		// info
		lastDigits,
		numOfShares,
		// actions
		onDelete,
		onSecondaryAction,
		onSelect
	} = props
//hooks
	const {
		paymentTypeIcon,
		cardTitle,
		sharesOptions,
		principalAction,
		secondaryAction
	} = usePaymentMethod()

	const footerContent = () => {
		switch (layout) {
			case paymentTypes.layouts.USER_ACCOUNT:
				return (
					<div className='shares'>
						<span className='text-9px-400 color-gray-light'>
							{defaultCard ? 'Predeterminado' : 'Tarjeta 1'}
						</span>
					</div>
				)
			case paymentTypes.layouts.ORDER_SUMMARY:
				return (
					<div className='shares'>
						<span className='text-9px-400 color-gray-light'>No. de cuotas</span>
						<span className='text-9px-400 color-gray-light'>{numOfShares}</span>
					</div>
				)
			case paymentTypes.layouts.CARD_SELECTED:
				return (
					<div className='shares'>
						<span className='text-9px-400 color-gray-light'>No. de cuotas</span>
						<span className='text-9px-400 color-gray-light'>{numOfShares}</span>
					</div>
				)
			default:
				return (
					<div className='shares'>
						<span className='text-9px-400 color-gray-light'>No. de cuotas</span>
						<SmallTransparentSelect
							selectProps={{ onClick: (e) => e.stopPropagation() }}
							options={sharesOptions(3)}
						/>
					</div>
				)
		}
	}

	return (
		<div
			className={`payment-method 
            ${
							layout === paymentTypes.layouts.SELECTION_MODE
								? '-selectable'
								: ''
						}`}
			onClick={onSelect}
		>
			<div className='description'>
				<div>
					{principalAction(layout, { isSelected, onSelect, onDelete })}
					<i className={paymentTypeIcon(cardType, lastDigits)} />
					<div className='info'>
						<span className='type text-9px-400 color-gray-light'>
							Tarjeta crédito
						</span>
						<span className='text-13px-700 color-black-light name'>
							{cardTitle(cardType, lastDigits)}
						</span>
					</div>
				</div>
				{secondaryAction(layout, onSecondaryAction)}
			</div>
			{footerContent()}
		</div>
	)
}

CreditCard.propTypes = {
	// flags
	isSelected: PropTypes.bool,
	defaultCard: PropTypes.bool,
	// presentation
	cardType: PropTypes.string.isRequired,
	layout: PropTypes.string.isRequired,
	// info
	lastDigits: PropTypes.oneOfType([
		PropTypes.number.isRequired,
		PropTypes.string.isRequired
	]),
	numOfShares: PropTypes.number,
	// actions
	onSelect: PropTypes.func,
	onDelete: PropTypes.func,
	onSecondaryAction: PropTypes.func
}
CreditCard.defaultProps = {
	onSelect: () => {},
	onDelete: () => {},
	onSecondaryAction: () => {}
}

export default CreditCard
