import React from 'react'
import PropTypes from 'prop-types'
import { routeFormatter } from '../../../routes/routes'
import { useHistory } from 'react-router'
// constants
import * as options from '../../../consts/AccountSections'

const menuIconClass = {
	[options.OPTION_HISTORIAL_PEDIDOS]: 'historial-icon',
	[options.OPTION_MERCADOS]: 'food-1-icon',
	[options.OPTION_TARJETAS]: 'card-icon',
	[options.OPTION_DIRECCIONES]: 'location-2-icon'
}

const MenuOptionDesktop = ({ label, option, isSelected }) => {
	const selected = isSelected(option)
	const history = useHistory()

	return (
		<button
			className={`btn-account-option
            ${selected ? ' btn-account-option-selected' : ''}`}
			onClick={() => {
				history.push(routeFormatter.toAccount(option))
			}}
		>
			<div className='option-label'>
				<i
					className={`${menuIconClass[option]}
                    ${selected ? '-white' : '-black'}`}
				/>
				<span>{label}</span>
			</div>
			<i className={`arrow ${selected ? '-white' : '-blue'}`} />
		</button>
	)
}

MenuOptionDesktop.propTypes = {
	label: PropTypes.string.isRequired,
	option: PropTypes.string.isRequired,
	isSelected: PropTypes.func.isRequired
}

export default MenuOptionDesktop
