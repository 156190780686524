import { useContext } from 'react'
import { context } from '../../context/ContextProvider'
import * as authActions from '../../context/auth/authActions'
// hooks
import useAuth from '../auth/useAuth'
import useProducts from '../products/useProducts'
import useLayout from '../layout/useLayout'

const useFavProducts = () => {
	const { state } = useContext(context)

	const { authValues, showUserDeletedModal } = useAuth()
	const { getProductData } = useProducts()
	const { showSnackbar } = useLayout()

	const { ID } = authValues
	const favoriteProducts = authValues.favorites.products || []

	const { categories } = state.productsReducer
	const favCatsIds = favoriteProducts.map((product) => product.main_category_id)

	/**
	 * takes the user's fav products references and returns the full
	 * reference from each product
	 * @returns
	 */
	const getFavProducts = async () => {
		const fullFavCats = []
		for (const cat of categories) {
			if (favCatsIds.includes(cat.id)) {
				let favProducts = favoriteProducts
					.filter((product) => product.main_category_id === cat.id)
					.map((product) => product.id_item)
				let favCat = {
					...cat,
					products: await Promise.all(
						favProducts.map(
							async (productId) => await getProductData(cat.id, productId)
						)
					)
				}
				fullFavCats.push(favCat)
			}
		}
		return fullFavCats
	}

	const isFavorite = (productId, categoryId) => {
		return Boolean(
			favoriteProducts.find(
				(product) =>
					product.id_item === productId &&
					product.main_category_id === categoryId
			)
		)
	}

	/**
	 * Adds the product with productId as id and and belonging to the category with categoryId as id
	 * to users favorite products
	 * @param {*} productId
	 * @param {*} categoryId
	 * @param {*} notify whether or not to notify with a snacbar when product have been added
	 */
	const addToFavorite = (productId, categoryId, notify = true) => {
		const updated = [
			...favoriteProducts,
			{ id_item: productId, main_category_id: categoryId }
		]
		authActions.changeFavoriteProducts(
			ID,
			updated,
			() => {
				if (notify) showSnackbar('Añadido a productos favoritos', 'info')
			},
			showUserDeletedModal
		)
	}

	/**
	 * Removes the product with productId as id and and belonging to the category with categoryId as id
	 * from users favorite products
	 * @param {*} productId
	 * @param {*} categoryId
	 * @param {*} notify whether or not to notify with a snacbar when product have been added
	 */
	const removeFromFavorite = (productId, categoryId, notify = true) => {
		const updated = favoriteProducts.filter(
			(product) =>
				!(
					product.id_item === productId &&
					product.main_category_id === categoryId
				)
		)

		authActions.changeFavoriteProducts(
			ID,
			updated,
			() => {
				if (notify) showSnackbar('Retirado de productos favoritos', 'info')
			},
			showUserDeletedModal
		)
	}

	const toggleFavorite = (productId, categoryId) => {
		isFavorite(productId, categoryId)
			? removeFromFavorite(productId, categoryId)
			: addToFavorite(productId, categoryId)
	}

	return {
		// flags
		isFavorite,
		// actions
		addToFavorite,
		removeFromFavorite,
		toggleFavorite,
		getFavProducts,
		// data
		favoriteProducts
	}
}

export default useFavProducts
