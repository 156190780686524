import React from 'react'
import * as cardTypes from './cardDireccionTypes'

/**
 * Hook personalizado que proporciona iconos y texto dinámicos a una tarjeta de dirección
 * basado en el tipo de tarjeta que se está mostrando.
 * @param {Object} params - objeto que contiene los parámetros de la tarjeta de dirección
 * @param {string} params.cardType - tipo de tarjeta que se está mostrando
 * @param {boolean} params.isSelected - indica si la tarjeta de dirección está seleccionada
 * @param {Function} params.onDelete - función para eliminar la tarjeta de dirección
 * @param {string} params.timeEstimate - tiempo estimado de entrega de la tarjeta de dirección
 * @param {string} params.dateOfDelivery - fecha de entrega de la tarjeta de dirección
 * @returns {Object} objeto que contiene iconos y texto dinámicos para la tarjeta de dirección
 */
const useCardDireccion = (params) => {
	const { cardType, isSelected, onDelete, timeEstimate, dateOfDelivery } =
		params

	/**
	 * Retorna el icono correspondiente según el tipo de tarjeta que se está mostrando.
	 * @returns {JSX.Element} componente que representa el icono correspondiente
	 */
	const cardIcon = () => {
		switch (cardType) {
			case cardTypes.CART_SELECTION_MODE:
				return (
					<i
						className={`location-icon -blue round-check-icon -${
							isSelected ? 'checked' : 'unchecked'
						}`}
					/>
				)
			case cardTypes.ACCOUNT_EDIT_MODE:
				return (
					<button className='button-delete'>
						<i
							className='delete-icon-circle -blue'
							onClick={(e) => {
								e.stopPropagation()
								onDelete()
							}}
						/>
					</button>
				)
			default:
				return <i className='location-icon -blue' />
		}
	}

	/**
	 * Retorna el texto que se mostrará en la tarjeta de dirección según el tipo de tarjeta
	 * que se esté mostrando.
	 * @returns {string} texto que se mostrará en la tarjeta de dirección
	 */
	const actionName = () => {
		switch (cardType) {
			case cardTypes.CART_SELECTION_MODE:
				return 'Modificar'
			case cardTypes.ACCOUNT_EDIT_MODE:
				return 'Modificar'
			case cardTypes.VISUALIZATION_MODE:
				return 'Cambiar'
			case cardTypes.ADD_MODE:
				return 'Agregar'
			case cardTypes.HISTORIAL_ORDERS:
				return ''
		}
	}

	/**
	 * Retorna el pie de página que se mostrará en la tarjeta de dirección según el tipo de tarjeta
	 * que se esté mostrando.
	 * @returns {JSX.Element} componente que representa el pie de página correspondiente
	 */
	const footer = () => {
		switch (cardType) {
			case cardTypes.HISTORIAL_ORDERS:
				return (
					<>
						<span className='color-gray text-9px-400'>Fecha de entrega</span>
						<span className='color-gray text-9px-500'>{dateOfDelivery}</span>
					</>
				)
			case cardTypes.ADD_MODE:
				return (
					<>
						<span className='color-gray text-9px-400'>
							La entrega no se puede estimar
						</span>
					</>
				)
			default:
				return (
					<>
						<span className='color-gray text-9px-400'>Entrega estimada</span>
						<span className='color-gray text-9px-500'>{timeEstimate}</span>
					</>
				)
		}
	}

	return {
		cardIcon,
		actionName,
		footer
	}
}

export default useCardDireccion
