import React from 'react'
import PropTypes from 'prop-types'
import { routeFormatter } from '../../../routes/routes'
import { useHistory } from 'react-router'
// constants
import * as options from '../../../consts/AccountSections'

const menuIconClass = {
	[options.OPTION_HISTORIAL_PEDIDOS]: 'historial-icon',
	[options.OPTION_MERCADOS]: 'food-1-icon',
	[options.OPTION_TARJETAS]: 'card-icon',
	[options.OPTION_DIRECCIONES]: 'location-2-icon'
}

const MenuOptionMobile = ({ label, option }) => {
	const history = useHistory()

	return (
		<button
			className='btn-account-option'
			onClick={() => {
				history.push(routeFormatter.toAccount(option))
			}}
		>
			<div className='option-label'>
				<i className={menuIconClass[option]} />
				<span>{label}</span>
			</div>
			<i className='arrow -blue' />
		</button>
	)
}

MenuOptionMobile.propTypes = {
	label: PropTypes.string.isRequired,
	option: PropTypes.string.isRequired
}

export default MenuOptionMobile
