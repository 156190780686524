import React, { useEffect } from 'react'
import { isBrowser } from 'react-device-detect'
import useAuth from '../../hooks/auth/useAuth'
import ProductDesktop from './ProductDesktop'
import ProductMobile from './ProductMobile'

/**
 * Componente que selecciona el diseño de la página de detalle de producto dependiendo del dispositivo del usuario.
 * @param {Object} props - Propiedades para el componente.
 * @returns {React.ReactNode} Elemento React que contiene la página de detalle de producto para dispositivos de escritorio o móviles.
 */
const Product = (props) => {  
	const { getCityStorage, getCityBranchOffice, showLoginModal } = useAuth()

	const city = getCityStorage()
	const branchOffice = getCityBranchOffice()

	useEffect(() => {
		if (!city) showLoginModal()
	}, [])

	const ProductLayout = isBrowser ? ProductDesktop : ProductMobile
	return (
		<ProductLayout
			{...props}
			city={city?.toLowerCase()}
			branchOffice={branchOffice}
		/>
	)
}

export default Product
