import React, { useEffect, useState } from 'react'
import { isBrowser } from 'react-device-detect'
import FavoriteProductsDesktop from './FavoriteProductsDesktop'
import FavoriteProductsMobile from './FavoriteProductsMobile'
// hooks
import useFavProducts from '../../../hooks/user/useFavProducts'
import useProducts from '../../../hooks/products/useProducts'
import useAuth from '../../../hooks/auth/useAuth'
import { useMemo } from 'react'

const FavoritesProducts = () => {
	const { getCityStorage, getCityBranchOffice, authValues, showLoginModal } =
		useAuth()
	const city = getCityStorage()
	const branchOffice = getCityBranchOffice()

	const { getFavProducts } = useFavProducts()
	const { categories } = useProducts()

	const [favCategories, setFavCategories] = useState([])
	const [loading, setLoading] = useState(false)

	useEffect(async () => {
		if (categories.length > 0 && !loading) {
			setLoading(true)
			const favs = await getFavProducts()
			setFavCategories(favs)
			setLoading(false)
		}
	}, [categories, authValues.favorites.products.length])

	useEffect(() => {
		if (!city) showLoginModal()
	}, [])

	const FavoriteProudctsLayout = isBrowser
		? FavoriteProductsDesktop
		: FavoriteProductsMobile
	return useMemo(
		() => (
			<FavoriteProudctsLayout
				loading={loading}
				favCategories={favCategories}
				city={city}
				branchOffice={branchOffice}
			/>
		),
		[favCategories, loading, city]
	)
}

export default FavoritesProducts
