import PropTypes from 'prop-types'
import React from 'react'

import CustomButton from '../atoms/buttons/CustomButton'
import CustomModal from '../CustomModal/CustomModal'
// material ui
import { Box } from '@material-ui/core'

/**
 * El componente AlertModal renderiza un modal de alerta con un mensaje y botones de confirmación y cancelación.
 *
 * @param {object} props - Las props para el componente.
 * @param {boolean} props.open - Si el modal debe estar abierto (obligatorio).
 * @param {function} props.handleClose - La función que se llama cuando se cierra el modal (obligatorio).
 * @param {node} props.message - El mensaje del modal (obligatorio).
 * @param {string} [props.acceptLabel='Confirmar'] - La etiqueta del botón de confirmación (opcional).
 * @param {string} [props.cancelLabel='Cancelar'] - La etiqueta del botón de cancelación (opcional).
 * @param {boolean} [props.cancelBtnRight=false] - Si el botón de cancelación debe estar a la derecha (opcional).
 * @param {function} props.acceptAction - La función que se llama cuando se hace clic en el botón de confirmación (obligatorio).
 * @param {function} [props.cancelAction] - La función que se llama cuando se hace clic en el botón de cancelación (opcional).
 * @param {array} [props.listElement] - Un array de objetos con id y name para ser renderizados en una lista dentro del modal (opcional).
 *
 * @returns {JSX.Element} - El modal de alerta con un mensaje y botones de confirmación y cancelación.
 */
const AlertModal = (props) => {
	const {
		message,
		acceptLabel,
		acceptAction,
		cancelLabel,
		cancelAction,
		cancelBtnRight,
		open,
		handleClose,
		listElement
	} = props

	return (
    <CustomModal open={open} handleClose={() => {
      handleClose && handleClose()
    }} mobileSmall>
			<Box boxSizing='border-box' padding='1rem' paddingBottom='0'>
				<span className='text-16px-400 color-black-light'>{message}</span>
				<ul>
					{listElement &&
						listElement.map((item) => <li key={item.id}>{item.name}</li>)}
				</ul>
				<Box
					display='flex'
					flexDirection={`row-${cancelBtnRight ? 'reverse' : ''}`}
					justifyContent={cancelAction ? 'space-between' : 'center'}
					marginTop='2rem'
					style={{ gap: '1rem' }}>
					{cancelAction && (
						<CustomButton
							label={cancelLabel}
							variant='outlined'
							onClick={cancelAction}
						/>
					)}
					<CustomButton label={acceptLabel} onClick={acceptAction} />
				</Box>
			</Box>
		</CustomModal>
	)
}

AlertModal.propTypes = {
	// control
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func,
	// modal message
	message: PropTypes.node.isRequired,
	// labels
	acceptLabel: PropTypes.string,
	cancelLabel: PropTypes.string,
	cancelBtnRight: PropTypes.bool,
	// actions
	acceptAction: PropTypes.func.isRequired,
	cancelAction: PropTypes.func,
	listElement: PropTypes.array
}

AlertModal.defaultProps = {
	acceptLabel: 'Confirmar',
	cancelLabel: 'Cancelar'
}

export default AlertModal
