import React from 'react'
import { isBrowser } from 'react-device-detect'
import LoginPageDesktop from './LoginPageDesktop'
import LoginPageMobile from './LoginPageMobile'

/**
 * Componente que selecciona el diseño de la página de inicio de sesión dependiendo del dispositivo del usuario.
 * @param {Object} props - Propiedades para el componente.
 * @returns {React.ReactNode} Elemento React que contiene la página de inicio de sesión para dispositivos de escritorio o móviles.
 */
const LoginPage = (props) => {
	const LoginPageLayout = isBrowser ? LoginPageDesktop : LoginPageMobile
	return <LoginPageLayout {...props} />
}

export default LoginPage
