/**
 * Removes all non-digit characters from a string.
 *
 * @param {string} value - The input string to be cleaned.
 * @returns {string} A string with only digits.
 */
function clearNumber(value = '') {
	return value.replace(/\D+/g, '')
}

/**
 * Formats a credit card expiration date to "MM/YY" format.
 *
 * @param {string} value - The input string to be formatted.
 * @returns {string} A string in "MM/YY" format.
 */
export function formatExpirationDate(value) {
	const clearValue = clearNumber(value)

	if (clearValue.length >= 3) {
		return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`
	}

	return clearValue
}

/**
 * Formats a credit card CVC number.
 *
 * @param {string} value - The input string to be formatted.
 * @returns {string} A formatted CVC number.
 */
export function formatCVC(value) {
	const clearValue = clearNumber(value)
	let maxLength = 3

	return clearValue.slice(0, maxLength)
}

/**
 * Formats a credit card number by adding spaces between every four digits.
 *
 * @param {string} value - The input string to be formatted.
 * @returns {string} A formatted credit card number.
 */
export function formatCreditCardNumber(value) {
	if (!value) {
		return value
	}
	const clearValue = clearNumber(value)
	let nextValue

	nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
		4,
		8
	)} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 16)}`

	return nextValue.trim()
}

/**
 * Formats a credit card number for display by hiding all digits except the first and last four.
 *
 * @param {string} value - The input string to be formatted.
 * @returns {string} A formatted credit card number for display.
 */
export function formatCreditCardNumberToShow(value) {
	if (!value) {
		return value
	}
	const clearValue = clearNumber(value)

	let hideValue
	hideValue = `${clearValue.substring(0, 4)}********${clearValue.substring(
		12,
		16
	)}`

	let nextValue
	nextValue = `${hideValue.slice(0, 4)} ${hideValue.slice(
		4,
		8
	)} ${hideValue.slice(8, 12)} ${hideValue.slice(12, 16)}`

	return nextValue.trim()
}
