import * as actionsTypes from './marketsActionsTypes'

/**
 * The initial state for the markets reducer.
 * @typedef {Object} MarketsInitialState
 * @property {boolean} loading - Indicates whether the action is currently loading.
 * @property {string} error - The error message that occurred during the action, if any.
 * @property {Object|null} markets - The markets data.
 */
export const initialState = {
	loading: false,
	error: '',
	markets: null
}

/**
 * Reducer function for the markets state.
 * @param {MarketsInitialState} prevState - The previous state of the reducer.
 * @param {Object} action - The action object to be performed.
 * @returns {MarketsInitialState} The new state of the reducer.
 */
export const marketsReducer = (prevState = initialState, action) => {
	switch (action.type) {
		case actionsTypes.GET_MARKETS:
			return {
				...prevState,
				loading: true,
				error: null
			}
		case actionsTypes.GET_MARKETS_SUCCESS:
			return {
				...prevState,
				loading: false,
				error: null,
				markets: action.markets
			}
		case actionsTypes.GET_MARKETS_FAILURE:
			return {
				...prevState,
				loading: false,
				error: action.error
			}
		case actionsTypes.CREATE_MARKET:
			return {
				...prevState,
				loading: true,
				error: null
			}
		case actionsTypes.CREATE_MARKET_SUCCESS:
			return {
				...prevState,
				loading: false,
				error: null,
				markets: [...prevState.markets, action.market]
			}
		case actionsTypes.CREATE_MARKET_FAILURE:
			return {
				...prevState,
				loading: false,
				error: action.error
			}
		case actionsTypes.DELETE_MARKET:
			return {
				...prevState,
				loading: true,
				error: null
			}
		case actionsTypes.DELETE_MARKET_SUCCESS:
			return {
				...prevState,
				loading: false,
				error: null,
				markets: prevState.markets.filter(
					(market) => market.id !== action.marketId
				)
			}
		case actionsTypes.DELETE_MARKET_FAILURE:
			return {
				...prevState,
				loading: false,
				error: action.error
			}
		case actionsTypes.EDIT_MARKET:
			return {
				...prevState,
				loading: true,
				error: null
			}
		case actionsTypes.EDIT_MARKET_SUCCESS:
			return {
				...prevState,
				loading: false,
				error: null,
				markets: prevState.markets.map((market) => {
					if (market.id === action.marketId)
						return { ...market, ...action.data }
					return market
				})
			}
		case actionsTypes.EDIT_MARKET_FAILURE:
			return {
				...prevState,
				loading: false,
				error: action.error
			}
		default:
			return prevState
	}
}
