import React from 'react'
import CustomButton from '../../components/atoms/buttons/CustomButton'
import { useHistory, Link } from 'react-router-dom'
import { routes, routeFormatter } from '../../routes/routes'
// consts
import * as AccountSections from '../../consts/AccountSections'
// resources
import groceries from '../../assets/groceries.svg'
import check from '../../assets/icons/success.svg'

/**
 * Componente de diseño de la página de confirmación de pedido para dispositivos de mobile.
 * @returns {React.ReactNode} Elemento React que contiene la página de confirmación de pedido para dispositivos de mobile.
 */
const OrderFinishedMobile = () => {
	const history = useHistory()

	return (
		<>
			<div className='order-finished-mobile fade-in'>
				<span className='text-21px-700 color-blue title'>Pedido Creado</span>
				<div className='order-image'>
					<img src={groceries} alt='groceries' />
					<img src={check} alt='check' />
				</div>
				<span className='message'>
					<span className='text-16px-300 color-gray-light'>
						Tu pedido ha sido creado con éxito, puedes ver el historial en la
						sección de&nbsp;
					</span>
					<Link
						className='text-16px-500 color-blue mi-cuenta'
						to={routeFormatter.toAccount(
							AccountSections.OPTION_HISTORIAL_PEDIDOS
						)}>
						mi cuenta
					</Link>
				</span>
				<CustomButton
					label='Volver al inicio'
					onClick={() => history.push(routes.home.path)}
				/>
			</div>
		</>
	)
}

export default OrderFinishedMobile
