export const address = {
	address: '',
	branch: '',
	city: '',
	//geopoint: null,
	name: '',
	house: '',
	neighbohood: '',
	observation: '',
	splitAddress: {
		first: '',
		second: '',
		third: '',
		fourth: ''
	}
}
