import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../../assets/logomercaz1.png'
import pare from '../../../assets/pare.png'
import { routes } from '../../../routes/routes'

/**
 * Componente FooterDesktop que renderiza la sección de pie de página en la versión de escritorio.
 * @param {Object} information - Objeto con información de los enlaces del footer.
 * @returns {JSX.Element} Componente FooterDesktop.
 */
const FooterDesktop = ({ information }) => {
	return (
		<footer className='footerDesktop'>
			<div className='content'>
				<div className='contact'>
					<Link to={routes.home.path} className='logo'>
						<img src={Logo} alt='logo_merca_z' />
					</Link>
					<p className='text-12px-400 color-blue'>
						Síguenos en nuestras redes sociales
					</p>
					<div className='social-networks'>
						<a
							href='https://www.facebook.com/supermercaz'
							target='_blank'
							rel='noopener noreferrer'>
							<i className='facebook-icon-blue'></i>
						</a>
						<a
							href='https://www.instagram.com/mercazsupermercados/'
							target='_blank'
							rel='noopener noreferrer'>
							<i className='instagram-icon'></i>
						</a>
						<a
							href='https://wa.link/ty1w8s'
							target='_blank'
							rel='noopener noreferrer'>
							<i className='whatsapp-icon'></i>
						</a>
					</div>
				</div>
				<div className='information'>
					<span className='text-18px-600 color-blue'>
						Informaci&oacute;n de inter&eacute;s
					</span>
					<div className='items'>
						{information.map((item, i) => (
							<div key={i} className='text-12px-400 color-blue'>
								<a
									style={{ textDecoration: 'none', color: '#164194' }}
									href={item.link}
									target='blank'>
									{item.description}
								</a>
							</div>
						))}
					</div>
				</div>
				<div>
					<img src={pare} style={{ width: '80px', paddingLeft: '60px' }} />
				</div>
			</div>
			<div className='year'>
				<p className='text-12px-400 color-blue'>
					&copy;{new Date().getFullYear()} Merca Z
					{" " + (process?.env?.REACT_APP_VERSION || "")}
				</p>
			</div>
		</footer>
	)
}

FooterDesktop.propTypes = {
	information: PropTypes.array.isRequired
}

export default FooterDesktop
