/* eslint-disable react/prop-types */
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import ProductCardDesktop from '../../components/atoms/cards/ProductCard/ProductCardDesktop'
import getPriceByOffice from '../../utils/getPriceByOffice'
//hooks
import clsx from 'clsx'
import { memo, useEffect, useRef } from 'react'
import ProductCardMobile from '../../components/atoms/cards/ProductCard/ProductCardMobile'
import useAuth from '../../hooks/auth/useAuth'
import useSearch from '../../hooks/search/useSearch'
// import ValidateStock from '../../utils/ValidateStock'

/**
 * Screen search component
 * @param {{
 * isMobile?:boolean
 * query:string
 * }} props
 * @returns
 */
const SearchResultDesktopMobile = ({ query, isMobile }) => {
  const lastSearch = useRef({
    query: '',
    page: 0
  })
  const { hits, getMoreData } = useSearch({ limit: 20 })
  const { getCityStorage, getCityBranchOffice } = useAuth()
  const city = useMemo(() => getCityStorage() || 'CALI', [getCityStorage])
  const branchOffice = useMemo(
    () => getCityBranchOffice(),
    [getCityBranchOffice]
  )

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          console.log('El elemento está visible en pantalla')
          getMoreData(query)
        } else {
          // console.log('El elemento no está visible en pantalla');
        }
      })
    })

    const elem = document.querySelector(
      '.searchResultDesktop__scroll__intersect'
    )
    observer.observe(elem)
    return () => {
      observer.unobserve(elem)
    }
  }, [getMoreData, query])

  useEffect(() => {
    if (query !== lastSearch.current.query) {
      lastSearch.current.query = query
      getMoreData(query)
    }
  }, [query])
  return (
    <div
      className={clsx(
        !isMobile && 'searchResultDesktop',
        isMobile && 'searchResultMobile'
      )}>
      <section className='category-section'>
        <div className='title'>
          <p
            className={clsx(
              !isMobile && 'text-21px-700 color-blue',
              isMobile && 'text-16px-300 color-blue'
            )}>
            Resultados de la búsqueda de: {query}
          </p>
        </div>
        <div className='searchResultDesktop__scroll'>
          <Hits
            isMobile={isMobile}
            hits={hits}
            branchOffice={branchOffice}
            city={city}
          />
          <div className='searchResultDesktop__scroll__intersect'></div>
        </div>
      </section>
    </div>
  )
}

const Hits = ({ hits, branchOffice, city, isMobile }) => {
  // console.log("hits", hits);

  return (
    <div className='containerProducts'>
      {!hits.length && (
        <div style={{ height: '200px' }}>No se encontraron resultados</div>
      )}
      {hits.length > 0 &&
        hits.map((product) => (
          <React.Fragment key={`ProductCardDesktop__${product.id_item}`}>
            {!isMobile && (
              <ProductCardDesktop
                productId={product.id_item}
                categoryId={product.main_category_id}
                product={product}
                branch={product.price_branch_offices.find((item) =>
                  getPriceByOffice(item, city, branchOffice)
                )}
              />
            )}
            {isMobile && (
              <ProductCardMobile
                productId={product.id_item}
                categoryId={product.main_category_id}
                product={product}
                branch={product.price_branch_offices.find((item) =>
                  getPriceByOffice(item, city, branchOffice)
                )}
              />
            )}
          </React.Fragment>
        ))}
    </div>
  )
}

Hits.propTypes = {
  hits: PropTypes.array.isRequired,
  branchOffice: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired
}

export default memo(SearchResultDesktopMobile, (prev, next) => {
  console.log('prev', prev, next)

  return prev.query === next.query
})
