import PropTypes from 'prop-types';
import React, { useState } from 'react';
// dummy image
import dummyImage from '../../assets/dummy.png';

const NetworkImage = (props) => {
  const { imageProps, url, placeHolder, size, width } = props;

  const [error, setError] = useState(false);

  const handleImageError = () => {
    setError(true);
  };

  return (
    <>
      <div className='NetworkImage' {...imageProps}
        style={{
          width: size || width || '2rem',
          height: size || width || '2rem',
          backgroundImage: `url(${error ? dummyImage : url})`,
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <img
          style={{
            width: '0px',
            height: '0px',
            opacity: '0',
            visibility: 'hidden',
          }}
          src={url}
          alt="network-image"
          onError={handleImageError}

        />
      </div>
      {/* {error ? (
        <img src={errorImage} alt="error-image" {...imageProps} />
      ) : (
        <img
          src={url}
          alt="network-image"
          onError={handleImageError}
          {...imageProps}
        />
      )} */}
      {!url && placeHolder}
    </>
  );
};

NetworkImage.defaultProps = {
  imageProps: {},
  placeHolder: <img src={dummyImage} alt="Loading" />
};

NetworkImage.propTypes = {
  imageProps: PropTypes.object,
  url: PropTypes.string.isRequired,
  placeHolder: PropTypes.node
}

NetworkImage.defaultProps = {
  imageProps: {},
  placeHolder: <img src={dummyImage} alt='Loading' />
}

export default NetworkImage
