import PropTypes from 'prop-types'
import React from 'react'

/**
 * 
 * @param {{
 * label?:string,
 * value?:string,
 * onChange?:function,
 * onBlur?:function,
 * inputProps?:object,
 * errorMessage?:string,
 * variant?:string,
 * endAdornment?:string,
 * isTextArea?:boolean,
 * disabled?:boolean
 * }} props 
 * @returns 
 */
const EditInputText = (props) => {
  const {
    label,
    value,
    onChange,
    onBlur,
    inputProps,
    errorMessage,
    variant,
    endAdornment,
    isTextArea = false,
    disabled,
    placeholder = ""
  } = props

  return (
    <div className={`edit-data-input-text -${variant}`}>
      <span className='text-10px-400 color-gray label'>{label}</span>
      <div className={`input ${isTextArea && "textarea"}`}>
        {isTextArea && <textarea
          {...inputProps}
          rows={3}
          cols={1}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          placeholder={placeholder}
        ></textarea>}
        {!isTextArea && <input
          {...inputProps}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          placeholder={placeholder}
        ></input>}
        {/* <input
					{...inputProps}
					value={value}
					onChange={onChange}
					onBlur={onBlur}
					disabled={disabled}
				/> */}
        {endAdornment && <span className='endAdornment'>{endAdornment}</span>}
      </div>
      {errorMessage && <span className='text-12px-400 error-message'>{errorMessage}</span>}
    </div>
  )
}

EditInputText.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  inputProps: PropTypes.object,
  errorMessage: PropTypes.string,
  variant: PropTypes.string,
  endAdornment: PropTypes.string,
  disabled: PropTypes.bool
}

EditInputText.defaultProps = {
  errorMessage: '',
  onChange: () => { },
  variant: 'blue',
  disabled: false
}

export default EditInputText
