import * as actionTypes from './offersActionsTypes'

/**
 * The initial state of the offers reducer.
 * @typedef {Object} initialState
 * @property {Array} offers - The array of offers.
 */
export const initialState = {
	offers: []
}

/**
 * The offers reducer, which updates the state based on the action type and payload.
 * @param {initialState} prevState - The previous state.
 * @param {Object} action - The action that contains the type and payload to update the state.
 * @returns {Object} The updated state.
 */
export const offersReducer = (prevState = initialState, action) => {
	switch (action.type) {
		case actionTypes.LOAD_ALL_OFFERS_SUCCESS:
			return {
				...prevState,
				offers: action.value
			}
		default:
			return prevState
	}
}
