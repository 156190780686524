import React from 'react'
import PropTypes from 'prop-types'
import * as cartSteps from '../../../../hooks/shoppingCart/cartSteps'
import useShoppingCart from '../../../../hooks/shoppingCart/useShoppingCart'
// resources
import arrow from '../../../../assets/arrow-back.svg'

const CartNavigation = () => {
	const { currentStep, setCurrentStep, mapNumberToStep, mapStepToNumber } =
		useShoppingCart()

	const step = mapStepToNumber[currentStep]

	const goToPrevStep = () => {
		if (step > 1 && step < 4) return setCurrentStep(mapNumberToStep[step - 1])
		if (step === 1) setCurrentStep(cartSteps.PRODUCTOS)
	}

	return (
		<div className='cart-navigation'>
			{step !== 4 && <img src={arrow} alt='back' onClick={goToPrevStep} />}
			<div className='nav-steps'>
				{[1, 2, 3, 4].map((n, index) => (
					<NavBubble number={n} target={step} key={`nav-bubble-${index}`} />
				))}
			</div>
		</div>
	)
}

const NavBubble = ({ number, target }) => {
	const style = number < target ? 'lower' : number > target ? 'grater' : 'exact'
	return (
		<>
			<div className={`nav-bubble -${style}`}>
				<span className='text-21px-700'>{number}</span>
			</div>
			{number !== 4 && <div className='bubble-separator'></div>}
		</>
	)
}

NavBubble.propTypes = {
	number: PropTypes.number.isRequired,
	target: PropTypes.number.isRequired
}

export default CartNavigation
