import React, { useEffect, useRef } from 'react'
//Hooks
import useAuth from '../../hooks/auth/useAuth'
import useShoppingCart from '../../hooks/shoppingCart/useShoppingCart'
//components
import CustomModal from '../../components/CustomModal/CustomModal'
import { Box, List, ListItem, Avatar } from '@material-ui/core'
import CustomButton from '../../components/atoms/buttons/CustomButton'
import { productImageBaseUrl } from '../../utils/HasImage'

const Shoppingvalidationmodal = () => {
	//hooks
	const { getCityStorage } = useAuth()
	const {
		compareStocks,
		showCartValidation,
		clearChangedProducts,
		changedProducts
	} = useShoppingCart()

	const handleClose = () => {
		clearChangedProducts() //actualiza la funcion ClearChangeProducts
	}
	const city = getCityStorage()

	const usePrevious = (value) => {
		const ref = useRef() // se utiliza para crear una referencia mutable que se puede actualizar y mantener durante toda la vida útil del componente.
		useEffect(() => {
			ref.current = value
		}) // se utiliza para actualizar el valor de la referencia ref.current con el valor de value después de cada actualización.
		return ref.current
	}

	const prevCity = usePrevious(city)

	useEffect(() => {
		if (
			prevCity &&
			prevCity.toLowerCase() !== city.toLowerCase() &&
			prevCity !== 'CALI'
		) {
			compareStocks(prevCity, city)
		}
	}, [city])

	return (
		<>
			<CustomModal open={showCartValidation} handleClose={handleClose}>
				<h1 className='text-21px-700 color-blue title'>
					Algunos productos cambiaron
				</h1>
				<p>Al cambiar de ubicación tu carrito se ha modificado. </p>
				<p>
					Los siguientes productos han cambiado de precio o no estarán
					disponibles en la nueva ubicación.
				</p>
				<List>
					{changedProducts.map((product, i) => (
						<ListItem key={i} button>
							<Box
								display='flex'
								justifyContent='space-between'
								alignItems='center'
								className='full-width'
							>
								<Box display='flex' alignItems='center'>
									<Avatar
										src={`${productImageBaseUrl}${product.id_item}`}
										className='mr-2'
									/>
									{product.name}
								</Box>
								<span className='text_disabled'>({product.validation})</span>
							</Box>
						</ListItem>
					))}
				</List>
				<Box
					display='flex'
					justifyContent='center'
					alignItems='center'
					className='mt-3 mb-3'
				>
					<CustomButton label={'Cerrar'} onClick={handleClose} />
				</Box>
			</CustomModal>
		</>
	)
}

export default Shoppingvalidationmodal
