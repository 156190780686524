import React from 'react'
import { isBrowser } from 'react-device-detect'
import TarjetasDesktop from './TarjetasDesktop'
import TarjetasMobile from './TarjetasMobile'

const Tarjetas = () => {
	const TarjetasLayout = isBrowser ? TarjetasDesktop : TarjetasMobile
	return <TarjetasLayout />
}

export default Tarjetas
