/* eslint-disable react/prop-types */
import { Box, CircularProgress } from '@material-ui/core'
import React from 'react'
/**
 * 
 * @param {{
 * width?:number|string
 * height?:number|string
 * }} props 
 * @returns 
 */
const CircularLoader = ({
  width = '100%',
  height = '100%'
}) => {
  return (
    /*El componente CircularProgress se usa para establecer un indicador de progreso */
    <Box
      width={width}
      height={height}
      display='flex'
      justifyContent='center'
      alignItems='center'
    >
      <CircularProgress color='primary' />
    </Box>
  )
}

export default CircularLoader
