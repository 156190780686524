import React from 'react'
// my components
import OrderCard from '../OrderCard/OrderCard'
import CircularLoader from '../../../../components/loaders/CircularLoader'
// hooks
import { useRouteMatch } from 'react-router'
import useOrders from '../../../../hooks/orders/useOrders'

import { routeFormatter } from './historialRoutes'

const OrdersListPage = () => {
	const { url } = useRouteMatch()

	const { loading, orders } = useOrders()

	return (
		<div className='historial-mobile'>
			<span className='text-21px-700 color-blue title'>
				Historial de pedidos
			</span>
			<div className='order-container'>
				{!orders || loading ? (
					<CircularLoader />
				) : (
					<>
						{orders.length === 0 ? (
							<span className='text-14px-400 color-gray-dark'>
								No has realizado pedidos aún
							</span>
						) : (
							orders.map((order) => (
								<OrderCard
									key={`key-${order.id_order}`}
									orderNumber={order.id_order ? order.id_order : 'Cargando...'}
									price={order.final_price}
									address={order.address}
									status={order.status}
									numOfItems={order.products_order.length}
									to={`${url}${routeFormatter.toDetails(order.id_order)}`}
								/>
							))
						)}
					</>
				)}
			</div>
		</div>
	)
}

export default OrdersListPage
