import PropTypes from 'prop-types'
import React from 'react'
import { isBrowser } from 'react-device-detect'
// resources
import logo from '../../assets/logomercaz1.png'
// my components
import CustomButton from '../atoms/buttons/CustomButton'
// hooks
import useAuth from '../../hooks/auth/useAuth'

import * as cities from '../../consts/Cities'

/**
 * Componente que muestra un selector de ciudades
 *
 * @param {Function} handleTransition - Función que maneja la transición a la pantalla de inicio de sesión.
 * @param {Object} to - Objeto con los nombres de las rutas disponibles.
 * @param {Function} handleClose - Función que maneja el cierre del selector de ciudades.
 *
 * @return {JSX.Element}
 */
const CitySelector = (props) => {
	const { handleTransition, handleClose, to } = props
	// Obtiene las funciones y valores necesarios del hook useAuth
	const { handleCity, isLogged } = useAuth()
	// Define un objeto para el tamaño de los botones del selector
	const buttonSize = { width: '150px', height: '33px' }

	/**
	 * Manejador del evento onClick de los botones del selector de ciudades
	 *
	 * @param {string} city - Nombre de la ciudad seleccionada
	 * @return {Function}
	 */
	const selectCity = (city) => () => {
		handleCity(city)
		handleTransition
			? isLogged
				? handleClose()
				: handleTransition(to.login)
			: handleClose()
	}

	return (
		<div
			className={`city-selector-${isBrowser ? 'desktop' : 'mobile'} fade-in`}>
			<img src={logo} alt='MercaZ' className='logo' />
			<span className='text-36px-700 color-blue'>Bienvenido</span>
			<span
				className='text-16px-300 color-gray
                selector-message'>
				¿Desde dónde te estás conectando?
			</span>
			<div className='selector-cities'>
				<CustomButton
					variant='outlined'
					label='Cali'
					{...buttonSize}
					onClick={selectCity(cities.CALI)}
				/>
				<CustomButton
					variant='outlined'
					label='Tumaco'
					{...buttonSize}
					onClick={selectCity(cities.TUMACO)}
				/>
				<CustomButton
					variant='outlined'
					label='Medellin'
					{...buttonSize}
					onClick={selectCity(cities.MEDELLIN)}
				/>
			</div>
		</div>
	)
}

CitySelector.propTypes = {
	handleTransition: PropTypes.func,
	to: PropTypes.objectOf(PropTypes.string),
	handleClose: PropTypes.func.isRequired
}

export default CitySelector
