import { useEffect, useState } from 'react'
import * as cityTypes from './constants'

import useAuth from '../../hooks/auth/useAuth'
import useLayout from '../../hooks/layout/useLayout'
import useValidator from '../../hooks/validator/useValidator'

import firebase from 'firebase'

export default function useRegisterPage(handleClose) {
	const { showSnackbar } = useLayout()
	const [termsConditions, setTermsConditions] = useState(false)

	const today = new Date()
	const todayFormatted = `${today.getFullYear()}-${(
		today.getMonth() + 1
	).toLocaleString('en-US', { minimumIntegerDigits: 2 })}-${today.getDate()}`

	const errorTranslator = (error) => {
		switch (error.code) {
			case 'auth/weak-password':
				return 'La contraseña debe tener por lo menos 6 caracteres'
			case 'auth/email-already-in-use':
				return 'Ya existe una cuenta registrada con este correo'
			default: {
				console.log(error)
				return 'Ha ocurrido un error, inténtalo de nuevo'
			}
		}
	}

	const cityOptions = [
		{
			label: 'Cali',
			value: cityTypes.CITY_TYPE_CALI
		},
		{
			label: 'Tumaco',
			value: cityTypes.CITY_TYPE_TUMACO
		},
		{
			label: 'Medellin',
			value: cityTypes.CITY_TYPE_MEDELLIN
		}
	]

	useEffect(() => {
		handleAuthValues('city', cityOptions[0].value)
	}, [])

	const {
		validateName,
		validateEmail,
		isNotEmpty,
		validateDocument,
		validatePhone
	} = useValidator()

	const { authValues, handleRegister, handleAuthValues } = useAuth()

	const { name, lastname, email, document, phone, birth_date } = authValues
	const [password, setPassword] = useState('')

	// errors
	const [nameError, setNameError] = useState('')
	const [lastnameError, setLastnameError] = useState('')
	const [emailError, setEmailError] = useState('')
	const [passwordError, setPasswordError] = useState('')
	const [documentError, setDocumentError] = useState('')
	const [phoneError, setPhoneError] = useState('')
	const [birthError, setBirthError] = useState('')
	const [termsError, setTermsError] = useState('')

	const [firebaseError, setFirebaseError] = useState(false)
	const [fbErrorMessage, setfbErrorMessage] = useState('')

	const validate = () => {
		let nameValid = validateName({ name, setNameError, notEmpty: true })
		let lastnameValid = validateName({
			name: lastname,
			setNameError: setLastnameError,
			validationMessage: 'Ingresa un apellido válido',
			notEmpty: true
		})
		let emailValid = validateEmail({ email, setEmailError })
		let passwordValid = isNotEmpty(password, setPasswordError)
		let documentValid = validateDocument({
			document,
			setDocumentError,
			notEmpty: true
		})
		let phoneValid = validatePhone({ phone, setPhoneError, notEmpty: true })
		let birthValid = isNotEmpty(birth_date || '', setBirthError)
		if (!termsConditions) setTermsError('Este campo es requerido')

		return (
			nameValid &&
			lastnameValid &&
			emailValid &&
			passwordValid &&
			documentValid &&
			phoneValid &&
			birthValid &&
			termsConditions
		)
	}

	const cleanErrorMessages = () => {
		setFirebaseError(false)
		setNameError('')
		setLastnameError('')
		setEmailError('')
		setPasswordError('')
		setDocumentError('')
		setPhoneError('')
		setBirthError('')
	}

	const changeDate = (e) => {
		let value = e.target.value
		let year = value.split('-')[0]
		let month = value.split('-')[1]
		let date = value.split('-')[2]

		let birthDate = new Date(year, month - 1, date)
		const timeStap = firebase.firestore.Timestamp.fromDate(birthDate)

		handleAuthValues('birth_date', timeStap)
	}
	const acceptTerm = (e) => {
		setTermsConditions(e.currentTarget.checked)
	}

	const register = (e) => {
		e.preventDefault()
		cleanErrorMessages()

		if (validate()) {
			handleRegister(
				password,
				() => {
					handleClose()
					showSnackbar('Te has registrado con éxito', 'success')
				},
				(error) => {
					setFirebaseError(true)
					setfbErrorMessage(errorTranslator(error))
				}
			)
		}
	}

	return {
		cityOptions,
		setPassword,
		register,
		// errors
		nameError,
		emailError,
		lastnameError,
		passwordError,
		documentError,
		phoneError,
		birthError,
		termsError,
		changeDate,
		acceptTerm,
		firebaseError,
		fbErrorMessage,
		// auth
		authValues,
		handleAuthValues,
		// others
		todayFormatted
	}
}
