import React from 'react'
import { isBrowser } from 'react-device-detect'
import HistorialDesktop from './HistorialDesktop'
import HistorialMobile from './HistorialMobile'

const Historial = () => {
	const HistorialLayout = isBrowser ? HistorialDesktop : HistorialMobile
	return <HistorialLayout />
}

export default Historial
