//Obtiene precio por branch
export default function (item, city, branchOffice) {
	if (item.city.toLowerCase() === city.toLowerCase()) {
		if (item.city.toLowerCase() === 'medellin') {
			if (
				"Guayabal".toLowerCase() === item.branch_offices.toLowerCase() ||
				"Santa Lucia".toLowerCase() === item.branch_offices.toLowerCase()
			)
				return true
			else return false
		}
		return true
	}
	return false
}
