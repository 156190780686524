import PropTypes from 'prop-types'
import React from 'react'
// components
import CustomScrollMenu from '../../../components/CustomScrollMenu/CustomScrollMenu'
import ProductCardDesktop from '../../../components/atoms/cards/ProductCard/ProductCardDesktop'
import ValidateStock from '../../../utils/ValidateStock'
import getPriceByOffice from '../../../utils/getPriceByOffice'
const FavoriteProductsDesktop = ({
	loading,
	favCategories,
	city,
	branchOffice
}) => {
	return (
		<>
			{loading ? (
				<CustomScrollMenu hideArrows>
					{Array(4)
						.fill(0)
						.map((_, i) => (
							<ProductCardDesktop loading key={`product-skltn-${i}`} />
						))}
				</CustomScrollMenu>
			) : favCategories.length !== 0 ? (
				favCategories.map((category) => (
					<div key={`cat-${category.id}`}>
						<p className='text-21px-700 color-blue text-capitalize'>
							{category.name.toLowerCase()}
						</p>
						<CustomScrollMenu>
							{
								// validates products is not empty
								category.products.map(
									(product) =>
										// validates if products is available in selected city
										(product?.price_branch_offices || []).some((item) =>
											ValidateStock(item, city, branchOffice)
										) && (
											<ProductCardDesktop
												key={product.id_item}
												productId={product.id_item}
												categoryId={category.id}
												product={product}
												branch={product.price_branch_offices.find((item) =>
													getPriceByOffice(item, city, branchOffice)
												)}
											/>
										)
								)
							}
						</CustomScrollMenu>
					</div>
				))
			) : (
				<span className='text-18px-400 color-gray-dark'>
					{' '}
					No tienes productos favoritos aún
				</span>
			)}
		</>
	)
}

FavoriteProductsDesktop.propTypes = {
	loading: PropTypes.bool.isRequired,
	favCategories: PropTypes.array.isRequired,
	city: PropTypes.string.isRequired,
	branchOffice: PropTypes.string
}

export default FavoriteProductsDesktop
