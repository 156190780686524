import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import Logo from '../../assets/logomercaz1.png'

import useLoginPage from './useLoginPage'

import { Box } from '@material-ui/core'
import SignInWithPhoneModal from '../../components/Modals/SignInWithPhoneModal'
import CustomButton from '../../components/atoms/buttons/CustomButton'
import EditInputText from '../../components/atoms/inputs/EditInputText'

/**
 * Componente de diseño de la página de inicio de sesión para escritorio.
 * @param {Object} props - Propiedades para el componente.
 * @param {Function} props.handleClose - Función para cerrar la página de inicio de sesión.
 * @param {Function} props.handleTransition - Función para realizar la transición de la página de inicio de sesión a otra página.
 * @param {string} props.to - Ruta de la página a la que se realiza la transición.
 * @returns {React.ReactNode} Elemento React que contiene la página de inicio de sesión para escritorio.
 */
const LoginPageDesktop = (props) => {
	const { handleClose, handleTransition, to } = props

	const [showModalPhone, setshowModalPhone] = useState(false)

	const {
		fbErrorMessage,
		firebaseError,
		emailError,
		setPassword,
		passwordError,
		// useAuth
		handleAuthValues,
		handleLoginWithGoogle,
		// handleLoginWithFacebook,
		handleLoginWithPhone,
		initializesignInWithPhone,
		signInWithPhoneCodeVerification,
		isLogged,
		login
	} = useLoginPage(handleClose)

	const toggleSignInPhoneModal = () => {
		setshowModalPhone(!showModalPhone)
	}

	useEffect(() => {
		if (isLogged) handleClose()
	}, [])

	return (
		<div className='login-modal fade-in'>
			<div className='o-login-container '>
				<div className='o-login-title-container'>
					<img src={Logo} alt='Logo Merca Z'></img>
					<p className='text-21px-700'>Bienvenido</p>
				</div>
				<div className='o-login-form-container'>
					<div className='o-login-form'>
						<h4 className='color-gray-light text-16px-300'>
							Inicia sesión o regístrate en MercaZ
						</h4>
						<button
							type='submit'
							// variant='contained'
							color='primary'
							className='button-primary button-google'
							onClick={(e) => {
								e.preventDefault()
								handleLoginWithGoogle(handleClose, console.log)
							}}>
							<i className='google-icon'></i>
							Continúa con Google
						</button>
						{/* <button
							type='submit'
							// variant='contained'
							className='button-primary button-phone'
							onClick={(e) => {
								e.preventDefault()
								handleLoginWithFacebook(handleClose)
							}}
							style={{ background: '#4267b2', color: 'white' }}>
							<i className='facebook-icon' id=''></i>
							Continúa con Facebook
						</button> */}
						<button
							type='submit'
							// variant='contained'
							className='button-primary button-phone'
							onClick={() => {
								setshowModalPhone(true)
							}}>
							<i className='phone-icon' id='sign-in-phone'></i>
							Continúa con tu telefono
						</button>
					</div>
					<div className='o-line'></div>
					<form className='o-login-form'>
						<Box
							width='16.5rem'
							display='flex'
							flexDirection='column'
							alignItems='center'>
							<h4 className='color-gray-light text-16px-300'>
								Ingresa con tu correo
							</h4>
							<EditInputText
								errorMessage={emailError}
								inputProps={{
									placeholder: 'Correo',
									autoComplete: 'email',
									autoFocus: true
								}}
								onBlur={(e) => handleAuthValues('email', e.target.value)}
							/>
							<EditInputText
								errorMessage={passwordError}
								inputProps={{
									placeholder: 'Contraseña',
									type: 'password',
									autoComplete: 'current-password'
								}}
								onChange={(e) => setPassword(e.target.value)}
							/>
							<Box marginTop='.5rem' width='100%'>
								<CustomButton
									label='Iniciar sesión'
									buttonProps={{ type: 'submit' }}
									width='100%'
									onClick={login}
								/>
							</Box>
							{firebaseError && (
								<Box marginTop='1rem'>
									<span className='color-error text-14px-400'>
										{fbErrorMessage}
									</span>
								</Box>
							)}
							<a
								className='color-blue text-16px-300 text-decoration-none link'
								onClick={() => handleTransition(to.resetPassword)}>
								¿Olvidaste tu contraseña?
							</a>
						</Box>
					</form>
				</div>
				<div className='o-login-link-container'>
					<p className='color-gray-light text-18px-300'>
						¿No tienes cuenta aún?
					</p>
					{/* <Link to="/register" className="link">Registrate aquí</Link> */}
					<a
						className='color-blue text-16px-500 text-decoration-none link'
						onClick={() => handleTransition(to.register)}>
						Registrate aquí
					</a>
				</div>
				<CustomButton
					width='16.25rem'
					color='yellow'
					onClick={handleClose}
					label='Entrar ahora'
					iconClass='arrow -blue'
				/>
			</div>
			<SignInWithPhoneModal
				showModalPhone={showModalPhone}
				initializesignInWithPhone={initializesignInWithPhone}
				handleClose={handleClose}
				handleAuthValues={handleAuthValues}
				toggleSignInPhoneModal={toggleSignInPhoneModal}
				handleLoginWithPhone={handleLoginWithPhone}
				signInWithPhoneCodeVerification={
					signInWithPhoneCodeVerification
				}></SignInWithPhoneModal>
		</div>
	)
}

LoginPageDesktop.propTypes = {
	handleTransition: PropTypes.func,
	to: PropTypes.objectOf(PropTypes.string),
	handleClose: PropTypes.func.isRequired
}

export default LoginPageDesktop
