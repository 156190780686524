import React from 'react'
import { isBrowser } from 'react-device-detect'
import OrderCardDesktop from './OrderCardDesktop'
import OrderCardMobile from './OrderCardMobile'

const OrderCard = (props) => {
	const OrderCardLayout = isBrowser ? OrderCardDesktop : OrderCardMobile
	return <OrderCardLayout {...props} />
}

export default OrderCard
