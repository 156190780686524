import PropTypes from 'prop-types'
import React from 'react'
import Search from '../layout/AppBar/Search/Search'


/**
 * WacoHuntSearch es un componente que contiene un widget de búsqueda instantánea de Algolia.
 * El componente acepta una prop `isMobile` que se utiliza para renderizar diferentes componentes en función del tamaño de la pantalla.
 *
 * @param {boolean} isMobile - Un booleano que indica si el componente se está ejecutando en un dispositivo móvil o no. Por defecto es `false`.
 * @returns {JSX.Element} - Un componente de React que renderiza un widget de búsqueda instantánea de Algolia.
 */
const WacoHuntSearch = ({ isMobile }) => {
  return (
    <>
      <Search isMobile={isMobile ? true : false} />
    </>
  )
}

WacoHuntSearch.propTypes = {
  isMobile: PropTypes.bool
}

WacoHuntSearch.defaultProps = {
  isMobile: false
}

export default WacoHuntSearch
