import React from 'react'
import PropTypes from 'prop-types'
import useStringFormater from '../../hooks/stringFormater/useStringFormater'

/**
 * Componente que muestra una sección de menú en un carrito de compras.
 *
 * @param {object} props - Propiedades del componente.
 * @param {string} props.title - Título de la sección.
 * @param {string} [props.subtitle] - Subtítulo de la sección.
 * @param {number} props.price - Precio de la sección.
 * @param {string} [props.priceTitle="Total"] - Título del precio.
 * @param {string} [props.message] - Mensaje adicional de la sección.
 *
 * @returns {JSX.Element} - Elemento JSX que representa la sección del menú.
 */
const MenuSection = (props) => {
	const { title, subtitle, price, priceTitle, message } = props
	const { formatPrice } = useStringFormater()

	return (
		<div className='cart-menu-section'>
			<div className='content'>
				<div className='description'>
					<span className='text-14px-500 color-gray-light'>{title}</span>
					<span className='text-11px-500 color-gray-light'>{subtitle}</span>
				</div>
				<div className='price'>
					<span className='text-12px-600 color-gray-light'>{priceTitle}</span>
					<span className='text-18px-600 color-blue'>{formatPrice(price)}</span>
				</div>
			</div>
			<span className='text-11px-400 color-gray-light message'>{message}</span>
		</div>
	)
}

MenuSection.propTypes = {
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string,
	price: PropTypes.number.isRequired,
	priceTitle: PropTypes.string,
	message: PropTypes.string
}

MenuSection.defaultProps = {
	priceTitle: 'Total'
}

export default MenuSection
